// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
//import App from "./App";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//initial nbuc mobile firebase
/*const firebaseConfig = {
    apiKey: "AIzaSyDGmKTynjnoa7rtqkhSrh1BUZiKPNVLKrE",
    authDomain: "nbuc-mobile-app.firebaseapp.com",
    databaseURL: "https://nbuc-mobile-app-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "nbuc-mobile-app",
    storageBucket: "nbuc-mobile-app.appspot.com",
    messagingSenderId: "393010524683",
    appId: "1:393010524683:web:d09125d3a5ff0f588f4d0e",
    measurementId: "G-PPCPKJWDPH"
};*/

//BTEC website firebase config
const firebaseConfig = {
    apiKey: "AIzaSyA7dM7svTcLe53g1u6-pIWFdjHO1j0IbUY",
    authDomain: "nais-btec.firebaseapp.com",
    databaseURL: "https://nais-btec-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "nais-btec",
    storageBucket: "nais-btec.appspot.com",
    messagingSenderId: "883654367839",
    appId: "1:883654367839:web:9f6d7234d19ddab16899ea"
  };

  //BTEC Testing website firebase config
  /*const firebaseConfig = {
    apiKey: "AIzaSyAi7DUGmsndH4ix7pKG5izppG1qT0hA83U",
    authDomain: "testfcm-c2114.firebaseapp.com",
    databaseURL: "https://testfcm-c2114-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "testfcm-c2114",
    storageBucket: "testfcm-c2114.appspot.com",
    messagingSenderId: "362063137592",
    appId: "1:362063137592:web:b53eb54aa25f6d3cfd0fcd",
    measurementId: "G-52Y9TLFPC8"
  };*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
// eslint-disable-next-line
const analytics = getAnalytics(app); 
// eslint-disable-next-line
const firestore = getFirestore(app);
const db = getDatabase();
export const storage = getStorage(app);
export { db }
export const auth = getAuth(app);
export default app;