import React, { useState, useEffect } from 'react';
import Btecusername from '../Btecusername';
import Btecsidebar from '../Btecsidebar';
import { getDatabase, ref, onValue, get,set, remove} from "firebase/database";
import '../attendance/AttendanceHistoryMain.css';
import '../attendance/FacultyList';
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DownloadPDF from '../generatepdf/DownloadPDF';
import { FcSearch } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; //enabled the date past the current date

const BtecAttendanceHistory = ({ attendanceData }) => {
	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
	const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
	const [data6, setData6] = useState([]);
	const [data7, setData7] = useState([]);
	const [data8, setData8] = useState([]);

	//const [Venue, setVenue] = useState("");
	const [selectdate, setSelectedDate] = useState(new Date());

	const [searchClass, setSearchClass] =  useState("");
    const [filteredSearchClass, setFilteredSearchClass] = useState([]);
 
	const [filteredClass, setFilteredClass] = useState([]);
	const [FilteredStudentAttendance, setFilteredStudentAttendance] = useState([]);
    const [filteredTotalStudents, setfilteredTotalStudents] = useState([]);

	const [selectedDuration, setSelectedDuration] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [uniqueDurations, setUniqueDurations] = useState([]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);

	const [show, setShow] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const navigate = useNavigate();

      useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                  //uid: studentData.uid,
                }));
                setData5(dataArray);
              }
            });
          }, []);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					ProgramName: BTECClassitem.ProgramName,
					DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
                   
				  };
				});
				});
			  });
			});
			setData6(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

    useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
					DurationUntil: BTECClassListitem.durationUntil,
                    uid: studentUID,
                    Student: BTECClassListitem.student,
					StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData7(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECQrAttendance");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef, (snapshot) => {
		  const BTECQRAttendanceData= snapshot.val();
		  if (BTECQRAttendanceData) {
			const dataArray = Object.entries(BTECQRAttendanceData).flatMap(([ProgramCode, LevelData]) => {
			  return Object.entries(LevelData).flatMap(([Level, DateData]) => {
				return Object.entries(DateData).flatMap(([ClassDate, InstructorNameData]) => {
          return Object.entries(InstructorNameData).flatMap(([InstructorName, TakenFromItem]) => {
            return Object.entries(TakenFromItem).flatMap(([StudUID, UIDItem]) => {
          return Object.entries(UIDItem).map(([TakenFrom, QRAttendanceItem]) => {
				  return {
					ProgramCode,
					Level,
					ClassDate,
					InstructorName,
                    TakenFrom,
                    StudUID,

                    CoursePick: QRAttendanceItem.CoursePick,
                    DurationFrom: QRAttendanceItem.DurationFrom,
                    DurationUntil: QRAttendanceItem.DurationUntil,
                    InstHelper: QRAttendanceItem.InstHelper,
                    //Instructor: QRAttendanceItem.Instructor,
                    //ProgramCode: QRAttendanceItem.ProgramCode,
                    StudentName: QRAttendanceItem.StudentName,
                    TakenFor: QRAttendanceItem.TakenFor,
                    TimeScanned: QRAttendanceItem.TimeScanned,
                    Week: QRAttendanceItem.Week,
                    AttendanceStatus: QRAttendanceItem.status,
                    studentID: QRAttendanceItem.studentID,
                    //uid: QRAttendanceItem.uid,
                    };
                    });
                    });
                    });
                });
                            });
                            });
                            setData8(dataArray);
                            //console.log("data8: ",data8);

                        } else {
                            // No data available in the "Class" location
                            console.log("No data available.");
                        }
                        });
                    }, []);

    useEffect(() => {
		const filteredClass = data6.filter((classItem) => {
		  return classItem.Instructor === currentUser.InstructorName;
		});
		//console.log(filteredClass);
		setFilteredClass(filteredClass);

		const filteredTotalStudents = filteredClass.map((classItem) => { //data6
			const totalStudents = data7.filter((classStudentItem) => 
			  classStudentItem.ProgramCode === classItem.ProgramCode &&
			  classStudentItem.Level === classItem.Level &&
			  classStudentItem.Instructor === classItem.Instructor &&
			  classStudentItem.DurationFrom === classItem.DurationFrom &&
			  classStudentItem.DurationUntil === classItem.DurationUntil
			);

            //console.log("totalStudents: ",totalStudents);
            //console.log("data8: ",data8);

		  
			const filteredStudentsInData8 = totalStudents.filter((student) => {
			  return data8.some((data8Student) => 
				data8Student.ProgramCode === student.ProgramCode &&
				data8Student.Level === student.Level &&
				data8Student.InstructorName === student.Instructor &&
				data8Student.DurationFrom === student.DurationFrom &&
				data8Student.DurationUntil === student.DurationUntil &&
				data8Student.StudentName === student.StudentName &&
                data8Student.studentID === student.Student
			  );
			});
            //console.log(filteredStudentsInData8.length);
            /*console.log(filteredStudentsInData8.ProgramCode);
            console.log(filteredStudentsInData8.Level);
            console.log(filteredStudentsInData8.InstructorName);
            console.log(filteredStudentsInData8.DurationFrom);
            console.log(filteredStudentsInData8.DurationUntil);
            console.log(filteredStudentsInData8.StudentName);
            console.log(filteredStudentsInData8.studentID);*/

            return {
                ProgramCode: classItem.ProgramCode,
                Level: classItem.Level,
                Instructor: classItem.Instructor,
                DurationFrom: classItem.DurationFrom,
                DurationUntil: classItem.DurationUntil,
                totalStudents: filteredStudentsInData8.length // Count the students that exist in data4
                };

		  });
		  // Now set the filteredTotalStudents state with the updated total length
		  setfilteredTotalStudents(filteredTotalStudents);
          //console.log("filteredTotalStudents: ",filteredTotalStudents);
          

	  }, [currentUser, data2, data3]);

	  const setdefaultstate = () => {
		setSelectedRows([]);
	  };
  
	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

      useEffect(() => {
        if (currentUser) {
            const db = getDatabase();
            const userRef = ref(db, `BTECInstructor/${currentUser.uid}`);
            get(userRef)
              .then(snapshot => {
                const userData = snapshot.val();
                setCurrentUser({
                  ...currentUser,
                  InstructorName: userData.instructorName,
                  Faculty: userData.btecregisterFaculty,
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
        }, [currentUser]);

	  const showsoption = () => {
		setShow((prevState) => !prevState);
	  };

	  const resetshow = () => {
		setFilteredStudentAttendance([]);
	  };

	  /*const sortedRows = [...filteredClass].sort((a, b) => { //sorting the filtered class
				// Sort by year
				if (b.DurationFrom !== a.DurationFrom) {
					return b.DurationFrom - a.DurationFrom;
				  }
				  // If years are the same, sort by month in descending order
				  return b.DurationUntil - a.DurationUntil;
	  });*/

	  const sortedRows = [...filteredClass].sort((a, b) => {
		// Convert DurationFrom and DurationUntil from DD-MM-YYYY to Date objects for correct sorting
	  
		// Helper function to convert DD-MM-YYYY to Date object
		const parseDate = (dateStr) => {
		  const [day, month, year] = dateStr.split('-');
		  return new Date(`${year}-${month}-${day}`);
		};
	  
		const dateAFrom = parseDate(a.DurationFrom);
		const dateBFrom = parseDate(b.DurationFrom);
	  
		const dateAUntil = parseDate(a.DurationUntil);
		const dateBUntil = parseDate(b.DurationUntil);
	  
		// Sort by DurationFrom in descending order
		if (dateBFrom - dateAFrom !== 0) {
		  return dateBFrom - dateAFrom;
		}
	  
		// If DurationFrom dates are the same, sort by DurationUntil in descending order
		return dateBUntil - dateAUntil;
	  });
	  

	  /*const sortedRows2 = [...filteredData].sort((a, b) => { //sorting the filteredsearched class
		// Sort by year
		if (b.DurationFrom !== a.DurationFrom) {
			return b.DurationFrom - a.DurationFrom;
		  }
		  // If years are the same, sort by month in descending order
		  return b.DurationUntil - a.DurationUntil;
	});*/

	const sortedRows2 = [...filteredData].sort((a, b) => {
		// Convert DurationFrom and DurationUntil from DD-MM-YYYY to Date objects for correct sorting
	  
		// Helper function to convert DD-MM-YYYY to Date object
		const parseDate = (dateStr) => {
		  const [day, month, year] = dateStr.split('-');
		  return new Date(`${year}-${month}-${day}`);
		};
	  
		const dateAFrom = parseDate(a.DurationFrom);
		const dateBFrom = parseDate(b.DurationFrom);
	  
		const dateAUntil = parseDate(a.DurationUntil);
		const dateBUntil = parseDate(b.DurationUntil);
	  
		// Sort by DurationFrom in descending order
		if (dateBFrom - dateAFrom !== 0) {
		  return dateBFrom - dateAFrom;
		}
	  
		// If DurationFrom dates are the same, sort by DurationUntil in descending order
		return dateBUntil - dateAUntil;
	  });

	  const indexOfLastRow = currentPage * rowsPerPage;
	  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
	  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);

	  const indexOfLastRow2 = currentPage2 * rowsPerPage;
	  const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
	  const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2); //set off the rows assigned and set the array, passed on to viewattendhistory
	  const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);

		const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		};

		const handlePageChange2 = (pageNumber) => {
			setCurrentPage2(pageNumber);
		};

		const sendClassData = (ProgramCode, ProgramName, 
            DurationFrom, DurationUntil, Instructor, Level, CoursePick) => {
			const classdetails = `${ProgramCode},
            ${ProgramName},${DurationFrom},${DurationUntil},
            ${Instructor}`;
			const url = `BTEC_View_Attendance_History?classdetails=
            ${encodeURIComponent(classdetails)}
            &ProgramCode=${encodeURIComponent(ProgramCode)}
            &ProgramName=${encodeURIComponent(ProgramName)}
            &DurationFrom=${encodeURIComponent(DurationFrom)}
            &DurationUntil=${encodeURIComponent(DurationUntil)}
            &Instructor=${encodeURIComponent(Instructor)}
			&Level=${encodeURIComponent(Level)}
			&CoursePick=${encodeURIComponent(CoursePick)}`;
		
			const newTab = window.open(url, '_blank');
			if (newTab) {
				newTab.focus(); // Focus on the new tab if it was successfully opened
			} else {
				alert('The pop-up blocker prevented opening the new tab.');
			}
		};

		useEffect(() => {
			//console.log("logged Instructor: ", currentUser.Instructor);

			const filteredClass = data6.filter((classItem) => {
				//console.log("classitem Instructor: ", classItem.Instructor);
			  return classItem.Instructor === currentUser.InstructorName;
			});
		  
			// Extract unique durations in the format "DurationFrom - DurationUntil"
			const durations = filteredClass.map((classItem) => `${classItem.DurationFrom} - ${classItem.DurationUntil}`);
		  
			// Remove duplicates
			const uniqueDurations = [...new Set(durations)];
			
			setUniqueDurations(uniqueDurations);
		  }, [data6, currentUser]);

		  const SearchClassbyDuration = (e) => {
			e.preventDefault();
			if (selectedDuration) {
			  const [from, until] = selectedDuration.split(' - ');
			  const filteredData = data6.filter(classitem => 
				classitem.DurationFrom === from && classitem.DurationUntil === until
			  );
			  setFilteredData(filteredData);
			  console.log("filteredData: ", filteredData);
			  alert('Class based on duration has been found!');
			}
			else {
				alert('No information found!');
			}
		  };
		
    return (

		<div /*class="body-addstudent"*/>
			<Btecsidebar> {/*show/call sidebar*/}
			<Btecusername /> {/*show/call username*/}
			<p><br/><br/></p>
            <div>
			<h1 class="h1-addstudent">My Class Attendance History</h1>
            </div>
            <div /*className="addstudent-container"*/>
				{/*<div className="addstudent-next-to"> {/* To align faculty, programmes, session */}
            <p><br/></p>
            {currentUser && (
            <>
			<div className="container">
            <p class="p-generateqr">&emsp;Lecturer Name: <b>{currentUser.InstructorName}</b></p>
			<p>&emsp;&emsp;&emsp;&emsp;&emsp;</p>
			<p class="p-generateqr">Faculty: <b>{currentUser.Faculty}</b></p>
			</div>
            </>
            )}
            <p><br/><br/></p>

				<div>
  				<div>
				<p class="p-addstudent4">Class Attendance History</p>
				<p><br/></p>

				<div className="adminregister-search-container">
				{/*<form onSubmit={FindSubject}>*/}
				<div className='container'>
				<label><b>Search:</b>&emsp;</label>
				<select
					title="Filter by Duration of Courses"
					className="select-size-generateqr"
					onChange={(e) => setSelectedDuration(e.target.value)}
					>
					<option value="">Select Duration</option>
					{uniqueDurations.map((duration, index) => (
						<option key={index} value={duration}>{duration}</option>
					))}
					</select>
					    {/* Filter Button */}
						<FcSearch className="search-icon22" onClick={SearchClassbyDuration} title="Search Class"/>
    					{/*<button onClick={SearchClassbyDuration}>Filter</button>*/}
						</div>

				</div>

				<p><br/><br/><br/><br/></p>

				<div className="table-container-editclass">

					<table>
        <thead class="th-generateqr">
          <tr>
		  <th><b>Program Code / Program Name</b></th>
		  <th><b>Studies Duration</b></th>
            <th><b>Current Total Student</b></th>
			<th><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
    {currentRows2.length > 0 ? (
			currentRows2.map((QRHistoryItem, index) => {
			  const totalStudents = filteredTotalStudents.find((item) => 
			  item.ProgramCode === QRHistoryItem.ProgramCode
			  && item.Instructor === QRHistoryItem.Instructor
			  && item.DurationFrom === QRHistoryItem.DurationFrom
			  && item.DurationUntil === QRHistoryItem.DurationUntil);
			  return (
				<tr key={`${QRHistoryItem.SubjectCode}-${index}`}>
				<td className="editclass-td"><b>{QRHistoryItem.ProgramCode} ({QRHistoryItem.ProgramName})</b></td>
				<td className="editclass-td"><b>{QRHistoryItem.DurationFrom} <br/> Until <br/>{QRHistoryItem.DurationUntil}</b></td>
				<td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
				  <td className="editclass-td">
					  <div className="container">
					  <button title = 'View Compiled Attendance' className='button-admin-view-attendance-new8' 
                      onClick={() => {sendClassData(QRHistoryItem.ProgramCode,
                      QRHistoryItem.ProgramName,QRHistoryItem.DurationFrom,
                      QRHistoryItem.DurationUntil,QRHistoryItem.Instructor,QRHistoryItem.Level,QRHistoryItem.CoursePick);}}><FaEye className='admin-view-attendance-icon4'/>&nbsp;VIEW ATTENDANCE</button>

					  <p>&emsp;</p>
			  </div>
	  
				  </td>
				</tr>
			  );
		  })
			  ) : currentRows.length > 0 ? (
			currentRows.map((QRHistoryItem, index) => {
			  const totalStudents = filteredTotalStudents.find((item) => 
			  item.ProgramCode === QRHistoryItem.ProgramCode
			  && item.Instructor === QRHistoryItem.Instructor
			  && item.DurationFrom === QRHistoryItem.DurationFrom
			  && item.DurationUntil === QRHistoryItem.DurationUntil);
			  return (
				  <tr key={`${QRHistoryItem.SubjectCode}-${index}`}>
				  <td className="editclass-td"><b>{QRHistoryItem.ProgramCode} ({QRHistoryItem.ProgramName})</b></td>
				  <td className="editclass-td"><b>{QRHistoryItem.DurationFrom} <br/> Until <br/>{QRHistoryItem.DurationUntil}</b></td>
				  <td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
				  <td className="editclass-td">
					  <div className="container">
					  <button title = 'View Compiled Attendance' className='button-admin-view-attendance-new8' 
                      onClick={() => {sendClassData(QRHistoryItem.ProgramCode,
                      QRHistoryItem.ProgramName,QRHistoryItem.DurationFrom,
                      QRHistoryItem.DurationUntil,QRHistoryItem.Instructor,QRHistoryItem.Level,QRHistoryItem.CoursePick);}}><FaEye className='admin-view-attendance-icon4'/>&nbsp;VIEW ATTENDANCE</button>
					  <p>&emsp;</p>
			  </div>
	  
				  </td>
				</tr>
			  );
		  })
			  ) : (
      	<tr>
        	<td colSpan="6">No Class found under you.</td>
      	</tr>
    	)}
  				</tbody>
				</table>


				</div>
				<p><br/></p>
				<p><b>*</b>You must <b>Generate QR</b> for the class first in order to <b>view the Current Total Student</b>. You can do so in the <b>Generate Attendance QR</b> page</p>
				<p><b>*</b>If you want to <b>View</b> the <b>Initial total student</b> in your class, you can view it in the <b>Class List page</b>.</p>

				<p><br/></p>

				{currentRows.length > 0 && totalPages > 1 && currentRows2.length === 0 &&  (
					<div className="pagination">
					{Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange(pageNumber)}
						className={`button-addStudent5 ${currentPage === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows2.length > 0 && totalPages2 > 1 && (
					<div className="pagination">
					{Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange2(pageNumber)}
						className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				</div>
				</div>
				</div>	
				
				</Btecsidebar>
				</div>
				);
				};
				
				export default BtecAttendanceHistory;