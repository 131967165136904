import React, { useState, useEffect } from 'react';
//import AdminSidebar from '../AdminSidebar';
//import AdminUsername from './AdminUsername';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import { getDatabase, ref, onValue, get,set, remove} from "firebase/database";
import '../admregister/AdminViewClass.css';
import '../attendance/FacultyList';
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import AdminDownloadPDF from '../admregister/AdminDownloadClassPDF';
import { FcSearch } from "react-icons/fc";
import { FaEye, FaFilter, FaSearch } from "react-icons/fa";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineSwap } from "react-icons/ai";

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; //enabled the date past the current date

const AdminbtecViewClass = ({ attendanceData }) => {
	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
	const [data5, setData5] = useState([]);
	const [data6, setData6] = useState([]);

	//const [Venue, setVenue] = useState("");
	const [selectdate, setSelectedDate] = useState(new Date());
	const [selectedLecturer, setSelectedLecturer] = useState("");
	const [Faculty, setFaculty] = useState("");

	const [searchClass, setSearchClass] =  useState("");
    const [filteredSearchClass, setFilteredSearchClass] = useState([]);

	const [filterbyclass, setfilterbyclass] = useState([]);

	const [BtecselectedProgramName, setBtecselectedProgramName] = useState("");
	const [BtecselectedProgram, setBtecselectedProgram] = useState("");
	const [BtecselectedProgramLevel, setBtecselectedProgramLevel] = useState("");
	const [uniqueDurationFrom, setUniqueDurationFrom] = useState([]);
	const [uniqueDurationUntil, setUniqueDurationUntil] = useState([]);

	const [filteredDate, setFilteredDate] = useState([]);
    const [FilteredAttendanceClass, setFilteredAttendanceClass] = useState([]);
	const [FilteredStudentAttendance, setFilteredStudentAttendance] = useState([]);
    const [filteredTotalStudents, setfilteredTotalStudents] = useState([]);
    const [filteredPresentStudents, setFilteredPresentStudents] = useState([]);
	const [FilteredbyAttend, setFilteredbyAttend] = useState([]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);

	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);

	const uniqueRows = [];
	const durationSet = new Set();

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [currentPage3, setCurrentPage3] = useState(1);
	const [currentPage4, setCurrentPage4] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	  useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                  //uid: studentData.uid,
                }));
                setData(dataArray);
              }
            });
          }, []);

	useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					ProgramName: BTECClassitem.ProgramName,
					DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
                   
				  };
				});
				});
			  });
			});
			// Create Sets to remove duplicate DurationFrom and DurationUntil values
			const uniqueDurationFrom = [...new Set(dataArray.map(item => item.DurationFrom))];
			const uniqueDurationUntil = [...new Set(dataArray.map(item => item.DurationUntil))];

			setData2(dataArray);
			setUniqueDurationFrom(uniqueDurationFrom);  // New state to store unique DurationFrom
      		setUniqueDurationUntil(uniqueDurationUntil);  // New state to store unique DurationUntil
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

	useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
					DurationUntil: BTECClassListitem.durationUntil,
                    uid: studentUID,
                    Student: BTECClassListitem.student,
					StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData3(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

	useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECQrAttendance");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef, (snapshot) => {
		  const BTECQRAttendanceData= snapshot.val();
		  if (BTECQRAttendanceData) {
			const dataArray = Object.entries(BTECQRAttendanceData).flatMap(([ProgramCode, LevelData]) => {
			  return Object.entries(LevelData).flatMap(([Level, DateData]) => {
				return Object.entries(DateData).flatMap(([ClassDate, InstructorNameData]) => {
          return Object.entries(InstructorNameData).flatMap(([InstructorName, TakenFromItem]) => {
            return Object.entries(TakenFromItem).flatMap(([StudUID, UIDItem]) => {
          return Object.entries(UIDItem).map(([TakenFrom, QRAttendanceItem]) => {
				  return {
					ProgramCode,
					Level,
					ClassDate,
					InstructorName,
                    TakenFrom,
                    StudUID,

                    CoursePick: QRAttendanceItem.CoursePick,
                    DurationFrom: QRAttendanceItem.DurationFrom,
                    DurationUntil: QRAttendanceItem.DurationUntil,
                    InstHelper: QRAttendanceItem.InstHelper,
                    //Instructor: QRAttendanceItem.Instructor,
                    //ProgramCode: QRAttendanceItem.ProgramCode,
                    StudentName: QRAttendanceItem.StudentName,
                    TakenFor: QRAttendanceItem.TakenFor,
                    TimeScanned: QRAttendanceItem.TimeScanned,
                    Week: QRAttendanceItem.Week,
                    AttendanceStatus: QRAttendanceItem.status,
                    studentID: QRAttendanceItem.studentID,
                    //uid: QRAttendanceItem.uid,
                    };
                    });
                    });
                    });
                });
                            });
                            });
                            setData4(dataArray);
                            //console.log("data8: ",data8);

                        } else {
                            // No data available in the "Class" location
                            console.log("No data available.");
                        }
                        });
                    }, []);

//data2

    useEffect(() => {	  
		const filteredTotalStudents = data2.map((classItem) => {
		  const totalStudents = data3.filter((classStudentItem) => 
		  classStudentItem.ProgramCode === classItem.ProgramCode 
		  && classStudentItem.Instructor === classItem.Instructor
		  && classStudentItem.DurationFrom === classItem.DurationFrom 
		  && classStudentItem.DurationUntil === classItem.DurationUntil 
		  && classStudentItem.Level === classItem.Level
		  );
		  return {
			ProgramCode: classItem.ProgramCode,
			Instructor: classItem.Instructor,
			DurationFrom: classItem.DurationFrom,
			DurationUntil: classItem.DurationUntil,
			Level: classItem.Level,
			totalStudents: totalStudents.length
		  };
		});
		console.log("filteredTotalStudents: ",filteredTotalStudents); // Display the filteredTotalStudents array in the console
		setfilteredTotalStudents(filteredTotalStudents);
		console.log(filteredTotalStudents);
		const uniqueClasses = [];
		const filteredAttendanceClass = [];

			data4.forEach((QRHistoryItem) => {
				const isDuplicateClass = uniqueClasses.some((classItem) =>
				  classItem.ProgramCode === QRHistoryItem.ProgramCode &&
				  classItem.Level === QRHistoryItem.Level &&
				  classItem.InstructorName === QRHistoryItem.InstructorName &&
				 
				  //maybe optional 
				  classItem.DurationFrom === QRHistoryItem.DurationFrom &&
				  classItem.DurationUntil === QRHistoryItem.DurationUntil //&&
				  //classItem.CoursePick === QRHistoryItem.CoursePick
				);
		  
			if (!isDuplicateClass) {
			  uniqueClasses.push(QRHistoryItem);
			  filteredAttendanceClass.push(QRHistoryItem);
			}
		  });
		
		if (filteredAttendanceClass.length > 0) {
		   //console.log("filteredAttendanceClass22: ", filteredAttendanceClass);
		  setFilteredAttendanceClass(filteredAttendanceClass);
		} else {
		   //console.log("No matching classes found.");
		  setFilteredAttendanceClass([]); // or set to an appropriate default value
		}

		console.log("filteredAttendanceClass: ",filteredAttendanceClass)
		
		  const filteredPresentStudents = filteredAttendanceClass.map((classItem) => {
			const presentStudents = data4.filter((QRHistoryItem) =>
			  QRHistoryItem.ProgramCode === classItem.ProgramCode &&
			  QRHistoryItem.ClassDate === classItem.ClassDate &&
			  QRHistoryItem.DurationFrom === classItem.DurationFrom &&
			  QRHistoryItem.DurationUntil === classItem.DurationUntil && 
			  QRHistoryItem.Level === classItem.Level &&
			  (QRHistoryItem.AttendanceStatus === "1" || QRHistoryItem.AttendanceStatus === "3")
			);
			 console.log("test", presentStudents);
			return {
				ProgramCode : classItem.ProgramCode ,
			  ClassDate: classItem.ClassDate,
			  presentStudents: presentStudents.length
			};
		  });
		  //console.log("test", filteredPresentStudents);
		  setFilteredPresentStudents(filteredPresentStudents);

	  }, [currentUser, data2, data3, data4]);

	  useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getDatabase();
                const programRef = ref(db, "BTECProgram");
    
                // Get the snapshot of the BTECProgram node
                const snapshot = await get(programRef);
    
                if (snapshot.exists()) {
                    // Extract the value of the snapshot
                    const programData = snapshot.val();
    
                    // Create an array to hold the extracted data
                    const extractedData = [];
    
                    // Iterate over each BtecProgramCode
                    Object.keys(programData).forEach((code) => {
                        // Iterate over each BtecProgramLevel
                        Object.keys(programData[code]).forEach((level) => {
                            // Get the data at this level
                            const levelData = programData[code][level];
    
                            // Extract BtecProgramName, BtecProgramCode, and BtecProgramLevel
                            const { BtecProgramName, BtecProgramCode, BtecProgramLevel } = levelData;
    
                            // Extract BtecProgramCourse data
                            const BtecProgramCourseData = levelData.BtecProgramCourse;
    
                            // Push the extracted data to the array
                            extractedData.push({
                                BtecProgramName,
                                BtecProgramCode,
                                BtecProgramLevel,
                                BtecProgramCourseData
                            });
                        });
                    });
    
                    // Update the state with the extracted data
                    setData6(extractedData);
                } else {
                    console.log("No BTEC program data found.");
                }
            } catch (error) {
                console.error("Error fetching BTEC program data:", error);
            }
        };
    
        fetchData();
    }, []);

	  const handleBTECProgramChange = (event) => {
		const selectedProgramCode = event.target.value;
	  
		// Extract program name and level based on the selected program code
		const selectedProgramData = data6.find((program) => program.BtecProgramCode === selectedProgramCode);
		const selectedProgramName = selectedProgramData ? selectedProgramData.BtecProgramName : "";
		const selectedProgramLevel = selectedProgramData ? selectedProgramData.BtecProgramLevel : "";
	  
		// Update state with the selected program name and level
		setBtecselectedProgramName(selectedProgramName);
		setBtecselectedProgramLevel(selectedProgramLevel);
	  
		// Update state with the selected program code
		setBtecselectedProgram(selectedProgramCode);
	  }; 

	  useEffect(() => {
		//retrieve the every lecturer data to display on option,select
		const fetchInstructors = async () => {
		  try {
		  const db = getDatabase();
		  const instructorsRef = ref(db, "BTECInstructor");
		  const snapshot = await get(instructorsRef);
		  if (snapshot.exists()) {
			const instructorsData = snapshot.val();
			console.log("instructorsData: ", instructorsData)
			const instructorsArray = Object.values(instructorsData);
			console.log("instructorsArray: ", instructorsArray)
			setData5(instructorsArray);
			console.log("data5: ", data5)
			console.log("instructordata: ", instructorsData)
		  } else {
			setData5([]);
			console.log("data5: ", data5)
		  }
		  } catch (error) {
		  console.log(error.message);
		  }
		};
	  
		fetchInstructors(); //run the function fetchlecturer
		}, []);

		              // Define a function to retrieve the faculty of a selected lecturer
					  const getInstructorFaculty = (InstructorName) => {
						const selectedInstructorData = data2.find((lecturer) => lecturer.InstructorName === InstructorName);
						return selectedInstructorData ? selectedInstructorData.btecregisterFaculty : "";
					  };
		  
						// Handle lecturer selection change
					  const handleInstructorChange = (e) => {
						const selectedLecturer = e.target.value;
						setSelectedLecturer(selectedLecturer);
						const lecturerFaculty = getInstructorFaculty(selectedLecturer);
						setFaculty(lecturerFaculty); // Set the selected faculty*/
						//console.log(selectedLecturer);
						//console.log(Faculty);
					  };

	  const findAttendStudent = (AttendItem) => {
		
		const FilteredStudentAttendance = data4.filter((QRHistoryItem) => {
			return QRHistoryItem.ProgramCode === AttendItem.ProgramCode
			&& QRHistoryItem.ClassDate === AttendItem.ClassDateDate
			&& QRHistoryItem.Level === AttendItem.Level
			&& QRHistoryItem.DurationFrom === AttendItem.DurationFrom
			&& QRHistoryItem.DurationUntil === AttendItem.DurationUntil;

		  });
		  setFilteredStudentAttendance(FilteredStudentAttendance);
		  //console.log("FilteredStudentAttendance: ", FilteredStudentAttendance);
	  };

	  const setdefaultstate = () => {
		setSelectedRows([]);
	  };

	  useEffect(() => {
		const selectedData = data4.filter((QRHistoryItem) =>
		  selectedRows.some(
			(row) =>
			  row.uid === QRHistoryItem.StudUID &&
			  row.SubjectName === QRHistoryItem.ClassSubjectName &&
			  row.Instructor === QRHistoryItem.InstructorName &&
			  row.Date === QRHistoryItem.AttendanceDate
		  )
		);
		console.log("Selected Data1:", selectedData);
	  }, [selectedRows, data4]);

	  const handleCheckboxChange = (uid, SubjectName, Lecturer, Date) => {
		const isSelected = selectedRows.some(
		  (row) =>
			row.uid === uid &&
			row.SubjectName === SubjectName &&
			row.Lecturer === Lecturer &&
			row.Date === Date
		);
	  
		if (isSelected) {
		  setSelectedRows(selectedRows.filter((row) =>
			!(row.uid === uid && row.SubjectName === SubjectName && row.Lecturer === Lecturer && row.Date === Date)
		  ));
		} else {
		  setSelectedRows([
			...selectedRows,
			{ uid, SubjectName, Lecturer, Date }
		  ]);
		}
	  };

	  const ChangeAttend = () => {
		const confirmAttend = window.confirm("Change to 'Present'?");
		if (confirmAttend) {
		selectedRows.forEach((row) => {
		  const { uid, SubjectName, Lecturer, Date } = row;
		  const db = getDatabase();
		  const dbRef = ref(db, `QrAttendance/${SubjectName}/${Date}/${Lecturer}/${uid}/status`);
		  set(dbRef, "1")
			.then(() => {
			  console.log(`AttendanceStatus updated to 1 for StudentUID: ${uid}`);
			})
			.catch((error) => {
			  console.log("Error updating AttendanceStatus:", error);
			});
		});
	  
		console.log("AttendanceStatus updated successfully.");
		alert("Attendance Status set to Present successfully.");
	}
	  };
	  
	  const ChangeNotAttend = () => {

		const confirmNotAttend = window.confirm("Change to 'Absent'?");
		if (confirmNotAttend) {
		selectedRows.forEach((row) => {
			const { uid, SubjectName, Lecturer, Date } = row;
			const db = getDatabase();
			const dbRef = ref(db, `QrAttendance/${SubjectName}/${Date}/${Lecturer}/${uid}/status`);
			set(dbRef, "0")
			  .then(() => {
				console.log(`AttendanceStatus updated to 0 for StudentUID: ${uid}`);
			  })
			  .catch((error) => {
				console.log("Error updating AttendanceStatus:", error);
			  });
		  });
		
		  console.log("AttendanceStatus updated successfully.");
		  alert("Attendance Status set to Absent successfully.");
		}
	  };

	  const ChangeLate = () => {

		const confirmLate = window.confirm("Change to 'Late'?");
		if (confirmLate) {
		selectedRows.forEach((row) => {
			const { uid, SubjectName, Lecturer, Date } = row;
			const db = getDatabase();
			const dbRef = ref(db, `QrAttendance/${SubjectName}/${Date}/${Lecturer}/${uid}/status`);
			set(dbRef, "3")
			  .then(() => {
				console.log(`AttendanceStatus updated to 3 for StudentUID: ${uid}`);
			  })
			  .catch((error) => {
				console.log("Error updating AttendanceStatus:", error);
			  });
		  });
		
		  console.log("AttendanceStatus updated successfully.");
		  alert("Attendance Status set to Late successfully.");
		}
	  };
  
	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

	  const showsoption = () => {
		setShow((prevState) => !prevState);
	  };

	  const resetshow = () => {
		setFilteredStudentAttendance([]);
	  };  
	  
	  const FindSubject = (e) => {
		e.preventDefault();

		setSelectedLecturer("");
		setBtecselectedProgram(""); // Reset the selected program
		setfilterbyclass([]);
		// Check if the search input is empty
		if (!searchClass) {
		alert('Please enter the Subject Name/Code');
		return; // Return early without making any changes
		}

		//console.log('FilteredAttendanceClass: ', FilteredAttendanceClass);
  
		// Perform the search logic based on the selected student ID
		const filteredSearchClass = FilteredAttendanceClass.filter((classsearch) => {
		const SearchClassName = classsearch.InstructorName.toLowerCase();
		const SearchClassCode = classsearch.ProgramCode.toLowerCase();
		const SearchClassLecturer = classsearch.CoursePick.toLowerCase();
		const searchInput = searchClass.toLowerCase();
  
		return SearchClassName.includes(searchInput) || SearchClassCode.includes(searchInput) || SearchClassLecturer.includes(searchInput);
		});


  		setfilterbyclass([]);

		// Update the foundStudents state with the filtered students array
		setFilteredSearchClass(filteredSearchClass);
		//alert('Result Found.');

  
		if (filteredSearchClass.length === 0) {
		alert('The Program Code/ Instructor/ Course does not exists.');
		}
		else {
			alert('Result Found.');
		}
		console.log(`filtered Class: `, filteredSearchClass);
	  };

	  const lecturerFilterby= (e) =>{
		e.preventDefault();

		setSearchClass("");
		setFilteredSearchClass([]);

		/*console.log('filteredLecturer', selectedLecturer)
		console.log('filterSubjectCode', filterSubjectCode)
		console.log('filterSubjectName', filterSubjectName)*/
		
		if (!selectedLecturer && !BtecselectedProgram ) {
		alert('Please select the Instructor Name and Program');
		return; 
		}
		
		if (!selectedLecturer && !BtecselectedProgram){
				if (filterbyclass.length === 0) {
					//console.log(`filtered by lecturer3 length after2: `, filterbyclass.length);
				alert('Please select both of the Program Code and Instructor.');
				}
				else {
					alert('Result Found.');
				}
		}

		else {
			const filterbyclass = FilteredAttendanceClass.filter((classsearch) => {
				
				const FilterProgramCode = classsearch.ProgramCode.toLowerCase();
				const SearchLecturer = classsearch.InstructorName.toLowerCase();
				const searchFilterInstructor = selectedLecturer.toLowerCase();
				const searchFilterProgram = BtecselectedProgram.toLowerCase();
		  
				return FilterProgramCode.includes(searchFilterProgram) && SearchLecturer.includes(searchFilterInstructor);
				});
				setFilteredSearchClass([]);
				// Update the foundStudents state with the filtered students array
				setfilterbyclass(filterbyclass);
				console.log(`filtered by lecturer7: `, filterbyclass);

				if (filterbyclass.length === 0) {
					//console.log(`filtered by lecturer3 length after2: `, filterbyclass.length);
				alert('The Instructor and the program selected does not match based on what the Instructor currently teach.');
				setSelectedLecturer(""); // Reset the selected lecturer
				setBtecselectedProgram(""); // Reset the selected program
				}
				else {
					alert('Result Found.');
				}
		}
	};

	  const sortedRows = [...FilteredAttendanceClass].sort((a, b) => {
		// Sort by Date
		const dateA = new Date(
			a.ClassDate.split('-').reverse().join('-')
		  );
		  const dateB = new Date(
			b.ClassDate.split('-').reverse().join('-')
		  );
		  return dateB - dateA; // Sort by Date in descending order
	  });

	  const sortedRows2 = [...filteredSearchClass].sort((a, b) => {
				// Sort by year
				if (b.IntakeYear !== a.IntakeYear) {
					return b.IntakeYear - a.IntakeYear;
				  }
				  // If years are the same, sort by month in descending order
				  return b.IntakeMonth - a.IntakeMonth;
	  });

	  const sortedRows3 = [...filteredDate].sort((a, b) => {
		// Sort by Date
		const dateA = new Date(
			a.ClassDate.split('-').reverse().join('-')
		  );
		  const dateB = new Date(
			b.ClassDate.split('-').reverse().join('-')
		  );
		  return dateB - dateA; // Sort by Date in descending order
	  });

	  const sortedRows4 = [...filterbyclass].sort((a, b) => {
		// Sort by year
		if (b.IntakeYear !== a.IntakeYear) {
			return b.IntakeYear - a.IntakeYear;
				}
				// If years are the same, sort by month in descending order
				return b.IntakeMonth - a.IntakeMonth;
	  });

	  const indexOfLastRow = currentPage * rowsPerPage;
	  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
	  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);

	  const indexOfLastRow2 = currentPage2 * rowsPerPage;
	  const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
	  const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
	  const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);
	  
	  const indexOfLastRow3 = currentPage3 * rowsPerPage;
	  const indexOfFirstRow3 = indexOfLastRow3 - rowsPerPage;
	  const currentRows3 = sortedRows3.slice(indexOfFirstRow3, indexOfLastRow3);
	  const totalPages3 = Math.ceil(sortedRows3.length / rowsPerPage);	  

	  const indexOfLastRow4 = currentPage4 * rowsPerPage;
	  const indexOfFirstRow4 = indexOfLastRow4 - rowsPerPage;
	  const currentRows4 = sortedRows4.slice(indexOfFirstRow4, indexOfLastRow4);
	  const totalPages4 = Math.ceil(sortedRows4.length / rowsPerPage);	 

		const handlePageChange2 = (pageNumber) => {
			setCurrentPage2(pageNumber);
		};

		const handlePageChange3 = (pageNumber) => {
			setCurrentPage3(pageNumber);
		};

		const handlePageChange4 = (pageNumber) => {
			setCurrentPage4(pageNumber);
		};

		const AdminsendClassData = (ProgramCode, ProgramName, 
            DurationFrom, DurationUntil, Instructor, Level, CoursePick) => {
			console.log(`send class data: `, ProgramCode, ProgramName, DurationFrom, DurationUntil, Instructor, Level, CoursePick)
			const classdetails = `${ProgramCode},
            ${ProgramName},${DurationFrom},${DurationUntil},
            ${Instructor}`;
			const url = `/btec/adminbtec/Admin_BTEC_View_Attendance_History?classdetails=
			${encodeURIComponent(classdetails)}
            &ProgramCode=${encodeURIComponent(ProgramCode)}
            &ProgramName=${encodeURIComponent(ProgramName)}
            &DurationFrom=${encodeURIComponent(DurationFrom)}
            &DurationUntil=${encodeURIComponent(DurationUntil)}
            &Instructor=${encodeURIComponent(Instructor)}
			&Level=${encodeURIComponent(Level)}
			&CoursePick=${encodeURIComponent(CoursePick)}`;
		
			const newTab = window.open(url, '_blank');
			if (newTab) {
				newTab.focus(); // Focus on the new tab if it was successfully opened
			} else {
				alert('The pop-up blocker prevented opening the new tab.');
			}
		};
	  
    return (

		<div /*class="body-addstudent"*/>
			<Btecadminsidebar> {/*show/call admin sidebar*/}
			<AdminbtecUsername /> {/*show/call admin username*/}
			<p><br/><br/></p>
            <div>
			<h1 class="h1-addstudent">Admin View Class History</h1>
            </div>
            <div /*className="addstudent-container"*/>
            <p><br/><br/></p>

				<div>
  				<div>
				<p class="p-addstudent4">Class Attendance History List</p>
				<p><br/></p>
				
				<div className='container'>
				{!show && (
				<div /*className="adminregister-search-container"*/ className="container adjust-adminright-filterby3">
				<button title='Filter Search By' className='button-GenQR8' onClick={()=> {showsoption();}}><FaSearch className='admin-view-attendance-icon3'/> SEARCH BY:</button>
				<p>&emsp;</p>
				<form title='Enter the Subject Name/Code or Lecturer' onSubmit={FindSubject}>
				{/*<label>Search By:&emsp;</label>*/}
				<div className="input-icon-container">
				<input 
				className="input-editclass2"
				type="text"
				placeholder="Enter Lecturer/Subject Name/Code"
				value={searchClass}
				onChange={(e) => setSearchClass(e.target.value)}
				>	
				</input>
				<FcSearch className="search-icon" onClick={FindSubject} title="Search"/>	
				</div>
				</form>
				</div>
				)}

				<p><br/><br/><br/></p>
				{show && (
					<div>
				<div className='container adjust-adminright-filterby2'>
					{/*<AiOutlineSwap className='admin-view-attendance-icon3'/>*/}
				<button title='Filter Search By' className='button-GenQR8' onClick={()=> {showsoption();}}><FaFilter className='admin-view-attendance-icon3'/> FILTER BY:</button>
				<label class="p-editclass2">&emsp;{/*Lecturer:*/} </label>
				<form className='container' title='Enter the Subject Name/Code or Lecturer' onSubmit={lecturerFilterby}>
				
				<select title='Select the name of the lecturer.'
				class="select-size-editclass2 adjust-adminview-lecname" 
				value={selectedLecturer}
				onChange={handleInstructorChange}>
				<option value="">Select an instructor</option>
				{data5
                    .sort((a, b) => a.instructorName.localeCompare(b.instructorName))
                    .map((lecturer) => (
                      <option key={lecturer.uid} value={lecturer.instructorName}>
                        <b className='adminview-lecname-boldlight'>{lecturer.instructorName}</b>
                      </option>
                    ))}
				</select>

				<p>&nbsp;</p>

				<select title='Select the Lecturer that will be assigned to this class (Lecturer must be registered first).' class="input-editclassnew4" 
                  value={BtecselectedProgram}
                  onChange={handleBTECProgramChange}
                  >
                  <option value="">Select a Program</option>
                  {data6
                  .sort((a, b) => a.BtecProgramName.localeCompare(b.BtecProgramName))
                  .map((program) => (
                    <option key={program.BtecProgramCode} value={program.BtecProgramCode}>
                      {program.BtecProgramCode} {program.BtecProgramName} Level {program.BtecProgramLevel}
                    </option>
                  ))}
                  </select>

				<button title='Filter Search By' className='button-GenQR8low' onClick= {lecturerFilterby}><FaSearch className='admin-view-attendance-icon3'/>SEARCH</button>
				{/*<FcSearch className="view-class-search-icon" onClick={lecturerFilterby} title="Filter Search"/>*/}
				</form>
				</div>

				<div className='container adjust-adminright-filterby4'>

				</div>

				</div>

				)}
				</div>
				<div><br></br></div>

				<div className="table-container-editclass">
					<p className='adminview-warning-fontsize'><b><b className="important-createclass">*</b> Click the <b className="important-createclass">'SEARCH BY'/'FILTER BY'</b> button to swap between searching or filtering the results.</b></p>
					<table>
        <thead class="th-generateqr">
          <tr>
		  	<th style={{fontSize: '18px'}}>Program</th>
		  	<th style={{fontSize: '18px'}}>Duration (From - Until)</th>
          	<th style={{fontSize: '18px'}}>Instructor</th>
        	<th style={{fontSize: '18px'}}>Total Student</th>
			<th style={{fontSize: '18px'}}>Action</th>
          </tr>
        </thead>
        <tbody>
    {currentRows3.length > 0 ? (
      currentRows3.map((QRHistoryItem, index) => {
		const programDetails = data2.find(item => item.ProgramCode === QRHistoryItem.ProgramCode);
		const programName = programDetails ? programDetails.ProgramName : '';
        const totalStudents = filteredTotalStudents.find((item) => 
		item.ProgramCode === QRHistoryItem.ProgramCode
		&& item.Instructor === QRHistoryItem.Instructor
		&& item.level === QRHistoryItem.level
		&& item.DurationFrom === QRHistoryItem.DurationFrom
		&& item.DurationUntil === QRHistoryItem.DurationUntil);
		const presentStudents = filteredPresentStudents.find((item2) => item2.ProgramCode === QRHistoryItem.ProgramCode 
		&& item2.ClassDate === QRHistoryItem.ClassDate);
		return (
			<tr key={`${QRHistoryItem.SubjectCode}-${index}`}>
            <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.ProgramCode} {programName}</td>
			<td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.DurationFrom} <br/> Until <br/> {QRHistoryItem.DurationUntil}</td>
            <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.InstructorName}</td>
            <td className="editclass-td" style={{fontWeight: 'bolder'}}>{totalStudents ? totalStudents.totalStudents : 0}</td>
            <td className="editclass-td">
				<div className="container">
				<p>&emsp;</p>
  {close => (
    <div className="modal">
      <button className="button-studentclass4" onClick={() => { close(); setdefaultstate(); resetshow();}}>&times;</button>
      <p><br/><br/></p>

      <div className="container">
	  <div>
	  <p className="p-history2">&emsp;Date of Class: <b>{QRHistoryItem.AttendanceDate}</b></p>
	  <p><br/></p>
	  <p className="p-history2">&emsp;Subject Name: <b>{QRHistoryItem.ClassSubjectName}</b></p>
	  <p><br/></p>
	  <p className="p-history2">&emsp;Session: <b>{QRHistoryItem.IntakeMonth}/{QRHistoryItem.IntakeYear}</b></p>
	  </div>
	  <div>
        <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Lecturer Name: <b>{QRHistoryItem.ClassLecturer}</b></p>
		<p><br/></p>
		<p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Subject Code: <b>{QRHistoryItem.SubjectCode}</b></p>
		<p><br/></p>
		<p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Venue: <b>{QRHistoryItem.VenueDB}</b></p>
		</div>
		</div>

	  <p><br/></p>
	  <button className="button-studentclass3" onClick={() => {findAttendStudent(QRHistoryItem); showsoption();}}>Show Attendance List</button>
      <p><br/><br/></p>

	  <div className="table-container-editclass2">
	  <table>
        <thead className="th-generateqr">
          <tr>
            <th>Student ID</th>
            <th>Student Name</th>
			<th>Attendance Status</th>
			<th>Action</th>
          </tr>
        </thead>
        <tbody>
		{FilteredStudentAttendance.length > 0 ? (
			FilteredStudentAttendance
			.sort((a, b) => a.StudentName.localeCompare(b.StudentName))
			.map((attendance, index) => (
			  <tr key={`${attendance.StudentUID}-${index}`}>
				<td className="editclass-td" >{attendance.StudentID}</td>
				<td className="editclass-td">{attendance.StudentName}</td>
				<td className="editclass-td">
					{attendance.AttendanceStatus === "1" ? 'Present': 
					attendance.AttendanceStatus === "0" ? 'Absent':
					attendance.AttendanceStatus === "3" ? 'Late':
					'Unknown Status'}
					</td>
				<td className="editclass-td">
				  <input
					  className="checkbox-size-addstudent"
					  type="checkbox"
					  onChange={() =>
					  handleCheckboxChange(
						  attendance.StudentUID,
						  QRHistoryItem.ClassSubjectName,
						  QRHistoryItem.ClassLecturer,
						  QRHistoryItem.AttendanceDate
					  )
					  }
					  checked={selectedRows.some(
					  (row) =>
						  row.uid === attendance.StudentUID &&
						  row.SubjectName === QRHistoryItem.ClassSubjectName &&
						  row.Lecturer === QRHistoryItem.ClassLecturer &&
						  row.Date === QRHistoryItem.AttendanceDate
					  )}
				  />
				  </td>
			  </tr>
			  
			))
		  ) : (
			<tr>
			<td colSpan="6">No Student.</td>
		  </tr>
		)
		}
        </tbody>
      </table>
	  <div className="container-history">
	  <button className="button-history-present" onClick={() => {ChangeAttend();}}>Present</button>
	  <button className="button-history2" onClick={() => {ChangeNotAttend();}}>Absent</button>
	  <button className="button-history2" onClick={() => {ChangeLate();}}>Late</button>
	  {FilteredStudentAttendance.length > 0 && (
	  <AdminDownloadPDF attendanceData={FilteredStudentAttendance} />
	  )}
	  </div>
	  <p className="p-studentclass-sidenote"><b>*</b>Click the <b>"SHOW ATTENDANCE LIST"</b> to view the student List for the selected Subject.</p>
	  <p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Attendance List.</p>
		
	  </div>
    </div>
        )}

		</div>
			  <div className="container">
					  <button title='View Attendance' className='button-admin-view-attendance8'/*className="button-studentclass3"*/ onClick={() => {AdminsendClassData(QRHistoryItem.ProgramCode,
                      programName,QRHistoryItem.DurationFrom,
                      QRHistoryItem.DurationUntil,QRHistoryItem.InstructorName,QRHistoryItem.Level,QRHistoryItem.CoursePick);}}><FaEye className='admin-view-attendance-icon6'/>VIEW ATTENDANCE</button>

			  </div>

            </td>
          </tr>
        );
    })
    	) : currentRows2.length > 0 ? (
			currentRows2.map((QRHistoryItem, index) => {
				const programDetails = data2.find(item => item.ProgramCode === QRHistoryItem.ProgramCode);
				const programName = programDetails ? programDetails.ProgramName : '';
			  const totalStudents = filteredTotalStudents.find((item) => 
			  item.ProgramCode === QRHistoryItem.ProgramCode
			  && item.Instructor === QRHistoryItem.InstructorName
			  && item.level === QRHistoryItem.level
			  && item.DurationFrom === QRHistoryItem.DurationFrom
			  && item.DurationUntil === QRHistoryItem.DurationUntil);
			  const presentStudents = filteredPresentStudents.find((item2) => item2.ProgramCode === QRHistoryItem.ProgramCode 
			  && item2.ClassDate === QRHistoryItem.ClassDate);
			  return (
				  <tr key={`${QRHistoryItem.ProgramCode}-${index}`}>
				  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.ProgramCode} {programName}</td>
				  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.DurationFrom} <br/> Until <br/> {QRHistoryItem.DurationUntil}</td>
                  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.InstructorName}</td>
				  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{totalStudents ? totalStudents.totalStudents : 0}</td>
				  <td className="editclass-td">
					  <div className="container">
		{close => (
		  <div className="modal">
			<button className="button-studentclass4" onClick={() => { close(); setdefaultstate(); resetshow();}}>&times;</button>
			<p><br/><br/></p>
	  
			<div className="container">
			<div>
			<p className="p-history2">&emsp;Date of Class: <b>{QRHistoryItem.AttendanceDate}</b></p>
			<p><br/></p>
			<p className="p-history2">&emsp;Subject Name: <b>{QRHistoryItem.ClassSubjectName}</b></p>
			<p><br/></p>
			<p className="p-history2">&emsp;Session: <b>{QRHistoryItem.IntakeMonth}/{QRHistoryItem.IntakeYear}</b></p>
			</div>
			<div>
			  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Lecturer Name: <b>{QRHistoryItem.ClassLecturer}</b></p>
			  <p><br/></p>
			  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Subject Code: <b>{QRHistoryItem.SubjectCode}</b></p>
			  <p><br/></p>
			  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Venue: <b>{QRHistoryItem.VenueDB}</b></p>
			  </div>
			  </div>
	  
			<p><br/></p>
			<button className="button-studentclass3" onClick={() => {findAttendStudent(QRHistoryItem); showsoption();}}>Show Attendance List</button>
			<p><br/><br/></p>
	  
			<div className="table-container-editclass2">
			<table>
			  <thead className="th-generateqr">
				<tr>
				{/*<th>UID</th>*/}
				  <th>Student ID</th>
				  <th>Student Name</th>
				  <th>Attendance Status</th>
				  <th>Action</th>
				</tr>
			  </thead>
			  <tbody>
			  {FilteredStudentAttendance.length > 0 ? (
				  FilteredStudentAttendance
				  .sort((a, b) => a.StudentName.localeCompare(b.StudentName))
				  .map((attendance, index) => (
					<tr key={`${attendance.StudentUID}-${index}`}>
					  <td className="editclass-td">{attendance.StudentID}</td>
					  <td className="editclass-td">{attendance.StudentName}</td>
					  <td className="editclass-td">
					{attendance.AttendanceStatus === "1" ? 'Present': 
					attendance.AttendanceStatus === "0" ? 'Absent':
					attendance.AttendanceStatus === "3" ? 'Late':
					'Unknown Status'}
					</td>
					  <td className="editclass-td">
						<input
							className="checkbox-size-addstudent"
							type="checkbox"
							onChange={() =>
							handleCheckboxChange(
								attendance.StudentUID,
								QRHistoryItem.ClassSubjectName,
								QRHistoryItem.ClassLecturer,
								QRHistoryItem.AttendanceDate
							)
							}
							checked={selectedRows.some(
							(row) =>
								row.uid === attendance.StudentUID &&
								row.SubjectName === QRHistoryItem.ClassSubjectName &&
								row.Lecturer === QRHistoryItem.ClassLecturer &&
								row.Date === QRHistoryItem.AttendanceDate
							)}
						/>
						</td>
					</tr>
					
				  ))
				) : (
				  <tr>
				  <td colSpan="6">No Student.</td>
				</tr>
			  )
			  }
			  </tbody>
			</table>
			<div className="container-history">
			<button className="button-history-present" onClick={() => {ChangeAttend();}}>Present</button>
			<button className="button-history2" onClick={() => {ChangeNotAttend();}}>Absent</button>
			<button className="button-history2" onClick={() => {ChangeLate();}}>Late</button>
			{FilteredStudentAttendance.length > 0 && (
			<AdminDownloadPDF attendanceData={FilteredStudentAttendance} />
			)}
			</div>
			<p className="p-studentclass-sidenote"><b>*</b>Click the <b>"SHOW ATTENDANCE LIST"</b> to view the student List for the selected Subject.</p>
			<p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Attendance List.</p>
			  
			</div>
		  </div>
			  )}
			  {/*</Popup>*/}
			  {/*<p>&emsp;</p>*/}
			   {/*<div className="container">*/}
			   <button title='View Attendance' className='button-admin-view-attendance8'/*className="button-studentclass3"*/ onClick={() => {AdminsendClassData(QRHistoryItem.ProgramCode,
                      programName,QRHistoryItem.DurationFrom,
                      QRHistoryItem.DurationUntil,QRHistoryItem.InstructorName,QRHistoryItem.Level,QRHistoryItem.CoursePick);}}><FaEye className='admin-view-attendance-icon6'/>VIEW ATTENDANCE</button>

					  {/*<p>&emsp;</p>*/}
			  {/*</div>*/}
			  </div>
	  
				  </td>
				</tr>
			  );
		  })
			  ) :
			  currentRows4.length > 0 ? (
				currentRows4.map((QRHistoryItem, index) => {
					const programDetails = data2.find(item => item.ProgramCode === QRHistoryItem.ProgramCode);
    				const programName = programDetails ? programDetails.ProgramName : '';

				  const totalStudents = filteredTotalStudents.find((item) => 
					item.ProgramCode === QRHistoryItem.ProgramCode
				  && item.Instructor === QRHistoryItem.InstructorName
				  && item.level === QRHistoryItem.level
				  && item.DurationFrom === QRHistoryItem.DurationFrom
				  && item.DurationUntil === QRHistoryItem.DurationUntil);
				  
				  const presentStudents = filteredPresentStudents.find((item2) => item2.ProgramCode === QRHistoryItem.ProgramCode 
				  && item2.ClassDate === QRHistoryItem.ClassDate);
				  return (
					  <tr key={`${QRHistoryItem.ProgramCode}-${index}`}>
					  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.ProgramCode} {programName}</td>
					  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.DurationFrom} <br/> Until <br/> {QRHistoryItem.DurationUntil}</td>
					  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{QRHistoryItem.InstructorName}</td>
					  <td className="editclass-td" style={{fontWeight: 'bolder'}}>{totalStudents ? totalStudents.totalStudents : 0}</td>
					  <td className="editclass-td">
						  <div className="container">
			{close => (
			  <div className="modal">
				<button className="button-studentclass4" onClick={() => { close(); setdefaultstate(); resetshow();}}>&times;</button>
				<p><br/><br/></p>
		  
				<div className="container">
				<div>
				<p className="p-history2">&emsp;Date of Class: <b>{QRHistoryItem.AttendanceDate}</b></p>
				<p><br/></p>
				<p className="p-history2">&emsp;Subject Name: <b>{QRHistoryItem.ClassSubjectName}</b></p>
				<p><br/></p>
				<p className="p-history2">&emsp;Session: <b>{QRHistoryItem.IntakeMonth}/{QRHistoryItem.IntakeYear}</b></p>
				</div>
				<div>
				  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Lecturer Name: <b>{QRHistoryItem.ClassLecturer}</b></p>
				  <p><br/></p>
				  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Subject Code: <b>{QRHistoryItem.SubjectCode}</b></p>
				  <p><br/></p>
				  <p className="p-history2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Venue: <b>{QRHistoryItem.VenueDB}</b></p>
				  </div>
				  </div>
		  
				<p><br/></p>
				<button className="button-studentclass3" onClick={() => {findAttendStudent(QRHistoryItem); showsoption();}}>Show Attendance List</button>
				<p><br/><br/></p>
		  
				<div className="table-container-editclass2">
				<table>
				  <thead className="th-generateqr">
					<tr>
					{/*<th>UID</th>*/}
					  <th>Student ID</th>
					  <th>Student Name</th>
					  <th>Attendance Status</th>
					  <th>Action</th>
					</tr>
				  </thead>
				  <tbody>
				  {FilteredStudentAttendance.length > 0 ? (
					  FilteredStudentAttendance
					  .sort((a, b) => a.StudentName.localeCompare(b.StudentName))
					  .map((attendance, index) => (
						<tr key={`${attendance.StudentUID}-${index}`}>
						  <td className="editclass-td">{attendance.StudentID}</td>
						  <td className="editclass-td">{attendance.StudentName}</td>
						  <td className="editclass-td">
						{attendance.AttendanceStatus === "1" ? 'Present': 
						attendance.AttendanceStatus === "0" ? 'Absent':
						attendance.AttendanceStatus === "3" ? 'Late':
						'Unknown Status'}
						</td>
						  <td className="editclass-td">
							<input
								className="checkbox-size-addstudent"
								type="checkbox"
								onChange={() =>
								handleCheckboxChange(
									attendance.StudentUID,
									QRHistoryItem.ClassSubjectName,
									QRHistoryItem.ClassLecturer,
									QRHistoryItem.AttendanceDate
								)
								}
								checked={selectedRows.some(
								(row) =>
									row.uid === attendance.StudentUID &&
									row.SubjectName === QRHistoryItem.ClassSubjectName &&
									row.Lecturer === QRHistoryItem.ClassLecturer &&
									row.Date === QRHistoryItem.AttendanceDate
								)}
							/>
							</td>
						</tr>
						
					  ))
					) : (
					  <tr>
					  <td colSpan="6">No Student.</td>
					</tr>
				  )
				  }
				  </tbody>
				</table>
				<div className="container-history">
				<button className="button-history-present" onClick={() => {ChangeAttend();}}>Present</button>
				<button className="button-history2" onClick={() => {ChangeNotAttend();}}>Absent</button>
				<button className="button-history2" onClick={() => {ChangeLate();}}>Late</button>
				{FilteredStudentAttendance.length > 0 && (
				<AdminDownloadPDF attendanceData={FilteredStudentAttendance} />
				)}
				</div>
				<p className="p-studentclass-sidenote"><b>*</b>Click the <b>"SHOW ATTENDANCE LIST"</b> to view the student List for the selected Subject.</p>
				<p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Attendance List.</p>
				  
				</div>
			  </div>
				  )}
				  {/*</Popup>*/}
				  {/*<p>&emsp;</p>*/}
				   {/*<div className="container">*/}
				   <button title='View Attendance' className='button-admin-view-attendance8'/*className="button-studentclass3"*/ onClick={() => {AdminsendClassData(QRHistoryItem.ProgramCode,
                      programName,QRHistoryItem.DurationFrom,
                      QRHistoryItem.DurationUntil,QRHistoryItem.InstructorName,QRHistoryItem.Level,QRHistoryItem.CoursePick);}}><FaEye className='admin-view-attendance-icon6'/>VIEW ATTENDANCE</button>
	
						  {/*<p>&emsp;</p>*/}
				  {/*</div>*/}
				  </div>
		  
					  </td>
					</tr>
				  );
			  })
				  ) : (
      	<tr>
        	<td colSpan="7">No Class found under the lecturer name or subject code/name.</td>
      	</tr>
    	)}
  				</tbody>
				</table>

				</div>

				<p><br/></p>

				{currentRows2.length > 0 && totalPages2 > 1 && currentRows3.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange2(pageNumber)}
						className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows3.length > 0 && totalPages3 > 1 && currentRows4.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages3 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange3(pageNumber)}
						className={`button-addStudent5 ${currentPage3 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}
				{currentRows4.length > 0 && totalPages4 > 1 && (
					<div className="pagination">
					{Array.from({ length: totalPages4 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange4(pageNumber)}
						className={`button-addStudent5 ${currentPage4 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}
				</div>
				</div>
				</div>	
				
				</Btecadminsidebar>
				</div>
				);
				};
				
				export default AdminbtecViewClass;