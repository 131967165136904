import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

//pdf stylsheet for pdf printing
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    //right: 10,
    //marginLeft: 20,
    //marginLeft: '20px',  // Set the left margin
    //marginRight: '20px', // Set the right margin
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 310,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 'auto',
    borderRightWidth: 1,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '31.70%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol: {
    width: '31.70%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 10,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  tableCell2: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  titleItem: {
    fontSize: 12,
    marginBottom: 10,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  column1: {
    marginRight: 100,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  imageContainer: {
    //flex: 1,
    //justifyContent: 'center',
    alignItems: 'center',
    //marginBottom: '1px',
  },
  image: {
    width: '40px',
    height: '40px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    right: 20,
  },
  address: {
    fontSize: 10,
    marginLeft: 290,
    marginBottom: 5,
  },
  address2: {
    fontSize: 10,
    marginLeft: 220,
  },
  instructor: {
    fontSize: 9,
    fontWeight: 700,
    marginLeft: 90,
    marginTop: 5,
    marginBottom: 3,
  },
  kehadiranpelatih: {
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 350,
    marginTop: 5,
    marginBottom: 5,
  },
  tableRow1: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tablePTPK: { //no. column
    width: '62.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  textPTPK: {
    margin: 5,
    fontSize: 10,
    textAlign: 'center',
    color: '#800000',
    fontWeight: 1000,
  },
  tableAnalisa: { //no. column
    width: '23.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  textAnalisa: {
    margin: 5,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableCatatan: { //no. column
    width: '15.00%',
    height:'30',
    borderStyle: 'solid',
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  textCatatan: {
    margin: 5,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableIntake: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  textIntake: {
    margin: 5,
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableHari: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  textHari: {
    margin: 5,
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 1000,
  },
  textHari2: {
    margin: 5,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  verticalTextContainer: {
    position: 'absolute',
    top: '0px',
    right: '20',  // Ensure the container is relative for the absolute text positioning
    width: '3.25%',        // Adjust as needed for your layout
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  verticalText: {
    transform: 'rotate(270deg)',
    //textAlign: 'left',
    transformOrigin: 'left top 0',
    whiteSpace: 'nowrap',
    fontSize: 8,
    margin: 5,
  },
  tableEmpty: { //no. column
    width: '15.00%',
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyBottom: { //no. column
    width: '15.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableEmptyUpper: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLast: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperRow2: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableUpper: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperLast: {
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperEmpty: {
    width: '15.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableEmptyUpperRow3: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLastRow3: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLastRow2: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hiddens
  },
  tableEmptyUpperLastRow22: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableMinggu: { //no. column
    width: '7.75%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  highlightedTableMinggu: {
    padding: 6.6,
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#FFFF00', // Highlighted background color
  },
  tableHariRow2: {
    width: '6.2%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableHariRow2Last: {
    width: '6.2%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableBulan: { //no. column
    width: '4.43%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  tableBil: { //no. column
    width: '4.43%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  highlightedTableBulan: {
    padding: 3,
    borderWidth: 0.5,
    borderColor: '#000',
    backgroundColor: '#FFFF00', // Highlighted background color
  },
  tableNamaPelatih: { //no. column
    width: '26.57%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableAMPM: { //no. column
    width: '3.092%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableAMPMLast: { //no. column
    width: '3.18%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  textsmallcolumn1: {
    margin: 5,
    fontSize: 8,
    fontWeight: 'bold',
  },
  tableJumlahK: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableHariRow22: {
    width: '6.2%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableHariRow22Last: {
    width: '6.2%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableAMPMNew: { //no. column
    width: '3.092%',
    borderStyle: 'solid',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    fontWeight: 'bold',
  },
  tableAMPMLastNew: { //no. column
    width: '3.05%',
    borderStyle: 'solid',
    //borderLeftWidth: 0.5,
    borderRightWidth: 1,
    borderBottomWidth: 0.5,
    fontWeight: 'bold',
  },
  tableNamaPelajar: { //no. column
    width: '26.57%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderRightWidth: 0.5,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
});

// Utility function to get the month names
const getMonthNames = (month) => {
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  
  // Determine the range of months to display
  if (month >= 0 && month <= 6) {
    return months.slice(0, 7);
  } else {
    return months.slice(7, 12);
  }
};

const BtecDownloadWeekPDF = ({ attendanceData, /*, rowsPerPage = 31*/ }) => {
  const attendance1 = attendanceData[0];

  const firstDate = attendanceData[0].ClassDateDB;
  const dateParts = firstDate.split("-");
  const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert to zero-based index
  const monthNames = getMonthNames(month);

    // Get the week value from the first attendance record
  const week = attendanceData[0].Week;
  //console.log( "week: ", week);

  const weekDates = [...new Set(
    attendanceData
      .filter(attendance => attendance.CoursePick === attendance1.CoursePick)
      .map(attendance => attendance.ClassDateDB)
  )];
  //console.log ("weekDates: ",weekDates.length);

    const renderWeekDates = (dates, currentPage, maxDatesPerPage) => {
      const startIndex = (currentPage - 1) * maxDatesPerPage;
      const endIndex = Math.min(startIndex + maxDatesPerPage, dates.length);
      const datesToRender = dates.slice(startIndex, endIndex);
      const remainingEmptyDates = maxDatesPerPage - datesToRender.length; // Calculate the number of empty dates
    
      const renderedDates = datesToRender.map((date, index) => (
        <View
          style={index === datesToRender.length - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
          key={startIndex + index} // Use a unique key for each date
        >
          <Text style={styles.textHari2}>{date}</Text>
        </View>
      ));
    
      // Fill the remaining slots with empty table cells
      for (let i = 0; i < remainingEmptyDates; i++) {
        renderedDates.push(
          <View
            style={i === remainingEmptyDates - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
            key={`empty-${i}`}
          >
            <Text style={styles.textHari2}></Text>
          </View>
        );
      }
        return renderedDates;
      };
    
    const groupedAttendance = (attendances) => {
      return attendances.reduce((acc, attendance) => {
        const key = `${attendance.StudentName}-${attendance.ClassDate}`;
        if (!acc[key]) {
          acc[key] = {
            StudentName: attendance.StudentName,
            ClassDate: attendance.ClassDate,
            AMStatus: '',
            PMStatus: '',
          };
        }
        if (attendance.TakenFor === 'AM') {
          acc[key].AMStatus = attendance.AttendanceStatus;
        } else if (attendance.TakenFor === 'PM') {
          acc[key].PMStatus = attendance.AttendanceStatus;
        }
        return acc;
      }, {});
    };

    //new 07/06/2024
    const groupAttendanceByStudent = (attendances) => {
      const studentAttendanceMap = {};
      Object.values(attendances).forEach(attendance => {
        if (!studentAttendanceMap[attendance.StudentName]) {
          studentAttendanceMap[attendance.StudentName] = [];
        }
        studentAttendanceMap[attendance.StudentName].push(attendance);
        //console.log(studentAttendanceMap);
      });
      return studentAttendanceMap;
    };

    const renderStatusRows4 = (attendances) => {
      const MAX_DATES_PER_PAGE = 5;
      const studentAttendanceMap = groupAttendanceByStudent(attendances);
      const uniqueStudents = Object.keys(studentAttendanceMap);
    
      const renderAttendanceCells = (attendances) => {
        return attendances.map((attendance, i) => (
          <React.Fragment key={i}>
            <View style={styles.tableAMPMNew}>
              <Text style={styles.tableCell2}>
                {attendance.AMStatus === "1" ? 'O' : 
                 attendance.AMStatus === "0" ? 'X' :
                 attendance.AMStatus === "3" ? 'L' :
                 'Unknown Status'}
              </Text>
            </View>
            <View style={styles.tableAMPMNew}>
              <Text style={styles.tableCell2}>
                {attendance.PMStatus === "1" ? 'O' : 
                 attendance.PMStatus === "0" ? 'X' :
                 attendance.PMStatus === "3" ? 'L' :
                 'Unknown Status'}
              </Text>
            </View>
          </React.Fragment>
        ));
      };
    
      const renderEmptyCells = (count) => {
        return Array.from({ length: count }).map((_, i) => (
          <React.Fragment key={`empty-${i}`}>
            <View style={styles.tableAMPMNew}>
              <Text style={styles.tableCell2}></Text>
            </View>
            <View style={styles.tableAMPMNew}>
              <Text style={styles.tableCell2}></Text>
            </View>
          </React.Fragment>
        ));
      };
    
      const renderPage = (studentName, pageIndex, attendancesSubset, studentIndex) => {
        const filledAttendances = renderAttendanceCells(attendancesSubset);
        const emptyCells = renderEmptyCells(MAX_DATES_PER_PAGE - attendancesSubset.length);
    
        return (
          <View style={styles.tableRow1} key={`${studentName}-${pageIndex}`}>
            <View style={styles.tableBil}>
              <Text style={styles.tableCell2}>{pageIndex === 0 ? studentIndex + 1 : ''}</Text>
            </View>
            <View style={styles.tableNamaPelajar}>
              <Text style={styles.tableCell2}>{pageIndex === 0 ? studentName : ''}</Text>
            </View>
            {filledAttendances}
            {emptyCells}
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperLast}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperEmpty}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
          </View>
        );
      };
    
      return uniqueStudents.flatMap((studentName, studentIndex) => {
        const studentAttendances = studentAttendanceMap[studentName];
        const totalPages = Math.ceil(studentAttendances.length / MAX_DATES_PER_PAGE);
    
        return Array.from({ length: totalPages }).map((_, pageIndex) => {
          const start = pageIndex * MAX_DATES_PER_PAGE;
          const end = start + MAX_DATES_PER_PAGE;
          const attendancesSubset = studentAttendances.slice(start, end);
          
          // Remove the first set of data for pages after the first page
          if (pageIndex > 0) {
            attendancesSubset.shift();
          }
    
          // Remove the remaining data to keep only the required set
          if (attendancesSubset.length > MAX_DATES_PER_PAGE) {
            attendancesSubset.splice(MAX_DATES_PER_PAGE);
          }
    
          return renderPage(studentName, pageIndex, attendancesSubset, studentIndex);
        });
      });
    };
     
    
    const renderStatusRowsNew = (attendances) => {
      const Maxcolumn = 5;
      const MAX_DATES_PER_PAGE = 5;
      const studentAttendanceMap = groupAttendanceByStudent(attendances);
      const uniqueStudents = Object.keys(studentAttendanceMap);

      const renderPage = (studentName, pageIndex, attendancesSubset, studentIndex) => {
        return (
          <View style={styles.tableRow1} key={`${studentName}-${pageIndex}`}>
            <View style={styles.tableBil}>
              <Text style={styles.tableCell2}>{pageIndex === 0 ? studentIndex + 1 : ''}</Text>
            </View>
            <View style={styles.tableNamaPelajar}>
              <Text style={styles.tableCell2}>{pageIndex === 0 ? studentName : ''}</Text>
            </View>
            {attendancesSubset.map((attendance, i) => (
              <React.Fragment key={i}>
                <View style={styles.tableAMPMNew}>
                  <Text style={styles.tableCell2}>
                    {attendance.AMStatus === "1" ? 'O' : 
                     attendance.AMStatus === "0" ? 'X' :
                     attendance.AMStatus === "3" ? 'L' :
                     'Unknown Status'}
                  </Text>
                </View>
                <View style={styles.tableAMPMNew}>
                  <Text style={styles.tableCell2}>
                    {attendance.PMStatus === "1" ? 'O' : 
                     attendance.PMStatus === "0" ? 'X' :
                     attendance.PMStatus === "3" ? 'L' :
                     'Unknown Status'}
                  </Text>
                </View>
              </React.Fragment>
            ))}
            {Array.from({ length: MAX_DATES_PER_PAGE - attendancesSubset.length }).map((_, i) => (
              <React.Fragment key={`empty-${i}`}>
                <View style={styles.tableAMPMNew}>
                  <Text style={styles.tableCell2}></Text>
                </View>
                <View style={styles.tableAMPMNew}>
                  <Text style={styles.tableCell2}></Text>
                </View>
              </React.Fragment>
            ))}
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperLast}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperEmpty}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
          </View>
        );
      };
      

    };

    const renderStatusRows5 = (attendances, currentPage) => {
      const MAX_DATES_PER_PAGE = 5;
      const studentAttendanceMap = groupAttendanceByStudent(attendances);
      const uniqueStudents = Object.keys(studentAttendanceMap);
    
      const paginateAttendances = (attendances, maxPerPage) => {
        const pages = [];
        for (let i = 0; i < attendances.length; i += maxPerPage) {
          pages.push(attendances.slice(i, i + maxPerPage));
        }
        return pages;
      };
    
      return uniqueStudents.flatMap((studentName, studentIndex) => {
        const studentAttendances = studentAttendanceMap[studentName];
        const paginatedAttendances = paginateAttendances(studentAttendances, MAX_DATES_PER_PAGE);
        
        // Calculate the start and end indices for the current page
        const startIndex = (currentPage - 1) * MAX_DATES_PER_PAGE;
        const endIndex = Math.min(startIndex + MAX_DATES_PER_PAGE, paginatedAttendances.length);
    
        const attendancesToRender = paginatedAttendances.slice(startIndex, endIndex).flat();
        const remainingEmptyStatus = MAX_DATES_PER_PAGE - attendancesToRender.length;
    
        const renderedAttendances = attendancesToRender.map((attendance, index) => (
          <View
            style={index === attendancesToRender.length - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
            key={startIndex + index} // Use a unique key for each date
          >
            <Text style={styles.textHari2}>{attendance.AMStatus === "1" ? 'O' : 
              attendance.AMStatus === "0" ? 'X' : 
              attendance.AMStatus === "3" ? 'L' : 'Unknown Status'}</Text>
            <Text style={styles.textHari2}>{attendance.PMStatus === "1" ? 'O' : 
              attendance.PMStatus === "0" ? 'X' : 
              attendance.PMStatus === "3" ? 'L' : 'Unknown Status'}</Text>
          </View>
        ));
    
        for (let i = 0; i < remainingEmptyStatus; i++) {
          renderedAttendances.push(
            <View
              style={i === remainingEmptyStatus - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
              key={`empty-${i}`}
            >
              <Text style={styles.textHari2}></Text>
              <Text style={styles.textHari2}></Text>
            </View>
          );
        };
      });
    };
    
  const generatePDF = () => {
    //let totalPages = 0;
    let rowCounter = 0;
    const rowsPerPageFirstPage = 5;
    const rowsPerPageSubsequentPages = 15;
    const maxDatesPerPage = 5;

    const groupedData = groupAttendanceByStudent(attendanceData);

    //const totalRows = attendanceData.length; //count by the data passed from previous page
    //const totalRows = uniqueStudentCount; //count by amount of student that is not loop
    const totalRows = weekDates.length;

    //need to change to count the number of week where only 5 days per week per page
    //const totalPages = Math.ceil((totalRows - rowsPerPageFirstPage) / rowsPerPageSubsequentPages) + 1;
    const totalPages = Math.ceil((totalRows - rowsPerPageFirstPage) / rowsPerPageSubsequentPages) + 1; //count by data of dates length

    //console.log("totalRows: ", totalRows)
    //console.log("rowsPerPageFirstPage: ",rowsPerPageFirstPage)
    //console.log("rowsPerPageSubsequentPages:", rowsPerPageSubsequentPages)
    //console.log(totalPages)

    return (
      <Document>
      {Array.from({ length: totalPages }).map((_, pageIndex) => {
        /*{paginatedWeekDates.map((dates, pageIndex) => {*/

      let startRow, endRow;
      if (pageIndex === 0) {
        startRow = 0;
        endRow = Math.min(rowsPerPageFirstPage, totalRows);
      } else {
        startRow = rowsPerPageFirstPage + (pageIndex - 1) * rowsPerPageSubsequentPages;
        endRow = Math.min(startRow + rowsPerPageSubsequentPages, totalRows);
      }

      //console.log("startRow: " , startRow)
      //console.log("endRow:" , endRow)

      const pageData = attendanceData.slice(startRow, endRow);
      //const pageData = groupedData.slice(startRow, endRow);
      //console.log("pageData: ",pageData)
        
        // Log values for debugging
        rowCounter = endRow - startRow;
        
        //pageData.sort((a, b) => a.StudentName.localeCompare(b.StudentName));
        pageData.sort((a, b) => a.studentID.localeCompare(b.studentID));

        const pageStyle = pageIndex === 0 ? styles.page : { ...styles.page, marginTop: '30px' };
        const pageNumberStyle = pageIndex === 0 ? styles.pageNumber :{ ...styles.pageNumber, bottom: 50, right: 20 };

        // Utility function to get the month names
        const getMonthNames = (month) => {
          const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
          
          // Determine the range of months to display
          if (month >= 0 && month <= 6) {
            return months.slice(0, 7);
          } else {
            return months.slice(7, 12);
          }
        };

        // Extract the month from the date
        const dateParts = attendance1.ClassDateDB.split("-");
        const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert to zero-based index
        
          return (
            <Page size="A4" style={pageStyle} key={pageIndex} orientation="landscape">
            <View style={styles.section}>
            {pageIndex === 0 && ( // Render the header section only for the first page
                <>
            <View style={styles.imageContainer}>
            <Image src="/bteclogobg.png" style={styles.image} />
              </View>
              <Text style={styles.header}>(JPK Kod Pusat Bertauliah L02387)</Text>

              <View style={styles.titleContainer}>
              <View style={styles.column1}>
              <Text style={styles.address}>Wisma Angkatan Hebat, One Borneo Mall</Text>
              <Text style={styles.address2}>88400 Kota Kinabalu, Sabah.    Tel: 088-396682, Fax: 088-486249</Text>
              <Text style={styles.instructor}>TENAGA PENGAJAR(PP)       1:{attendance1.InstructorName}                    PEMBANTU TENAGA PENGAJAR       2:</Text>
              <Text style={styles.instructor}>KOD DAN TAJUK UNIT KOMPETENSI: {attendance1.CoursePick}</Text>
              <Text style={styles.kehadiranpelatih}>KEHADIRAN PELATIH</Text>
                
              </View>
              <View style={styles.column1}>

              </View>
            </View>
            </>
              )}

              <View style={styles.table}>

              <View style={styles.tableRow1}>
              <View style={styles.tablePTPK}>
              <Text style={styles.textPTPK}>Perbadanan Tabung Pembangunan Kemahiran (PTPK)</Text>
              </View>
              <View style={styles.tableAnalisa}>
              <Text style={styles.textAnalisa}>ANALISA KEHADIRAN HARIAN</Text>
              </View>
              <View style={styles.tableCatatan}>
              <Text style={styles.textCatatan}>CATATAN</Text>
              </View>
              </View>  

              <View style={styles.tableRow1}>
              <View style={styles.tableIntake}>
              <Text style={styles.textIntake}>INTAKE</Text>
              </View>
              <View style={styles.tableHari}>
              <Text style={styles.textHari}>HARI / TARIKH</Text>
              </View>
              <View style={styles.tableEmptyUpper}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpper}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpper}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperLast}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmpty}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              </View>  

              <View style={styles.tableRow1}>
              <View style={week === "1" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                    <Text style={styles.textIntake}>Minggu 1</Text>
                  </View>
                  <View style={week === "2" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                    <Text style={styles.textIntake}>Minggu 2</Text>
                  </View>
                  <View style={week === "3" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                    <Text style={styles.textIntake}>Minggu 3</Text>
                  </View>
                  <View style={week === "4" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                    <Text style={styles.textIntake}>Minggu 4</Text>
                  </View>
              <View style={styles.tableHariRow2}>
              <Text style={styles.textHari}>ISNIN</Text>
              </View>
              <View style={styles.tableHariRow2}>
              <Text style={styles.textHari}>SELASA</Text>
              </View>
              <View style={styles.tableHariRow2}>
              <Text style={styles.textHari}>RABU</Text>
              </View>
              <View style={styles.tableHariRow2}>
              <Text style={styles.textHari}>KHAMIS</Text>
              </View>
              <View style={styles.tableHariRow2Last}>
              <Text style={styles.textHari}>JUMAAT</Text>
              </View>
              <View style={styles.tableEmptyUpperRow2}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperRow2}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperRow2}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperLastRow2}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmpty}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              </View> 

              <View style={styles.tableRow1}>
                  {monthNames.map((monthName, index) => (
                    <View 
                      style={month === index ? styles.highlightedTableBulan : styles.tableBulan} 
                      key={index}>
                      <Text style={styles.textIntake}>{monthName}</Text>
                    </View>
                  ))}
                  
                  {renderWeekDates(weekDates, pageIndex + 1, maxDatesPerPage)}

              <View style={styles.tableEmptyUpperRow2}>
              <Text style={[styles.textsmallcolumn1]}>JUMLAH HADIR</Text>
              </View>
              <View style={styles.tableEmptyUpperRow2}>
              <Text style={styles.textsmallcolumn1}>CUTI SAKIT</Text>
              </View>
              <View style={styles.tableEmptyUpperRow2}>
              <Text style={styles.textsmallcolumn1}>KELUAR SEMENTARA</Text>
              </View>
              <View style={styles.tableEmptyUpperLastRow2}>
              <Text style={styles.textsmallcolumn1}>TIDAK HADIR</Text>
              </View>
              <View style={styles.tableEmpty}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              </View> 

              <View style={styles.tableRow1}>
              <View style={styles.tableBil}>
              <Text style={styles.textIntake}>BIL.</Text>
              </View>
              <View style={styles.tableNamaPelatih}>
              <Text style={styles.textIntake}>NAMA PELATIH</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>AM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>PM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>AM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>PM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>AM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>PM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>AM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>PM</Text>
              </View>
              <View style={styles.tableAMPM}>
              <Text style={styles.textIntake}>AM</Text>
              </View>
              <View style={styles.tableAMPMLast}>
              <Text style={styles.textIntake}>PM</Text>
              </View>
              <View style={styles.tableEmptyUpperRow3}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperRow3}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperRow3}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyUpperLastRow3}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableEmptyBottom}>
              <Text style={styles.tableCellHeader}></Text>
              </View>
              </View> 

              {renderStatusRows4(groupedAttendance(attendanceData))}

              <View style={styles.tableRow1}>
              <View style={styles.tableJumlahK}>
              <Text style={styles.textIntake}>JUMLAH KESULURUHAN</Text>
              </View>
              <View style={styles.tableHariRow22}>
              <Text style={styles.textHari}></Text>
              </View>
              <View style={styles.tableHariRow22}>
              <Text style={styles.textHari}></Text>
              </View>
              <View style={styles.tableHariRow22}>
              <Text style={styles.textHari}></Text>
              </View>
              <View style={styles.tableHariRow22}>
              <Text style={styles.textHari}></Text>
              </View>
              <View style={styles.tableHariRow22Last}>
              <Text style={styles.textHari}></Text>
              </View>
              <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                      </View>
                      <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                      </View>
                      <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                      </View>
                      <View style={styles.tableUpperLast}>
                      <Text style={styles.tableCellHeader}></Text>
                      </View>
                      <View style={styles.tableUpperEmpty}>
                      <Text style={styles.tableCellHeader}></Text>
                      </View>
              </View>
                  
                  </View>
                  <View style={pageNumberStyle}>
                <Text style={styles.pageNumberText}>{pageIndex + 1}</Text>
              </View>
              </View>
            </Page>
          );
        })}
      </Document>
    );
  };

  return (
    <button title='Download Attendance PDF' className="button-history4">
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`${attendance1.CoursePick} ${attendance1.ProgramCode} Student Period from ${attendance1.DurationFrom} until ${attendance1.DurationUntil}.pdf`}
      >
        {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
      </PDFDownloadLink>
    </button>
  );
};

export default BtecDownloadWeekPDF;