import { getDatabase, ref, get } from "firebase/database";
import React, { useState, useEffect } from 'react';
import { auth } from "./firebase";
import './Username.css';
import {  Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { RiLogoutCircleLine} from "react-icons/ri";

const Username = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  

  useEffect(() => {
    // listen for changes to the auth object
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // update the component with the current user
      setCurrentUser(user);
    });

    // cleanup function to unsubscribe when the component unmounts
    return unsubscribe;
  }, []);
  
  useEffect(() => {
    // retrieve the current user's name from the database
    if (currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `Lecturer/${currentUser.uid}`);
      get(userRef)
        .then(snapshot => {
          const userData = snapshot.val();
          setCurrentUser({
            ...currentUser,
            lecturerName: userData.lecturerName,
            lecturerImage: userData.ImageURL,
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [currentUser]);

  return (
    <div className="container-username">

        {currentUser && (
          <>
            <p class="p-username" style={{textAlign: 'right'}}>
              Welcome&nbsp;<b>{currentUser.lecturerName}!</b> &nbsp;
              </p>

          </>
        )}

        <div className="circle-username">
                  {currentUser ? (
                    currentUser.lecturerImage ? (
                    <img src={currentUser.lecturerImage} alt="Profile" className="profile-image-username" onClick={() => setDropdownVisible(!isDropdownVisible)} />
                    ) : (
                    <div className="loading-text-username">
                      <p>No image...</p>
                    </div>
                    )
                  ) : (
                    <div className="no-image-text">
                    <p>No Image</p>
                    </div>
                  )}
                  </div>

                  {isDropdownVisible && (
      <div className="dropdown-content">
        {/* Your dropdown content here */}
        <div>

        <Link to= "/profile">
          <div className="container">
        <AiOutlineUser className="username-icon"/>
        <button className="button-addStudent-new6v2"><b>Profile</b></button>
        </div>
        </Link>

        <p><br/></p>

        <Link to= "/logout">
          <div className="container">
        <RiLogoutCircleLine className="username-icon"/>
        <button className="button-addStudent-new6"><b>Log Out</b></button>
        </div>
        </Link>
      </div>
      </div>
    )}
            
            </div>
  );
};

export default Username;