import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database'; // Import necessary Firebase functions
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import '../admregister/AdminEditClass.css';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";

const Btecenabledisable = () => {
  // State to track whether PDF download is enabled or disabled
  const [isPdfDownloadEnabled, setIsPdfDownloadEnabled] = useState(true);

  // Fetch the initial PDF download status from Realtime Database
  useEffect(() => {
    const fetchPDFStatus = async () => {
        const db = getDatabase();
      const pdfStatusRef = ref(db, 'PDFDownload/PDFStatus'); // Reference to the PDFStatus node
      
      try {
        const snapshot = await get(pdfStatusRef);
        if (snapshot.exists()) {
          const status = snapshot.val();  // Get the PDFStatus value from the database
          setIsPdfDownloadEnabled(status === "1"); // Set the state based on the value ("1" or "0")
        } else {
          console.log("No PDFStatus data available");
        }
      } catch (error) {
        console.error("Error fetching PDFStatus: ", error);
      }
    };

    fetchPDFStatus();
  }, []);

  // Function to handle the PDF download toggle and update Realtime Database
  const handleToggleDownload = async () => {

    const userConfirmed = window.confirm(
      `Are you sure you want to ${isPdfDownloadEnabled ? 'disable' : 'enable'} PDF download?`
    );

    if (userConfirmed) {
    const newStatus = isPdfDownloadEnabled ? "0" : "1"; // Toggle between "1" and "0"
    setIsPdfDownloadEnabled(!isPdfDownloadEnabled);

    const db = getDatabase();
    const pdfStatusRef = ref(db, 'PDFDownload/PDFStatus');
    
    try {
      await set(pdfStatusRef, newStatus); // Update PDFStatus in the Realtime Database
      console.log("PDFStatus successfully updated!");
      alert(`PDF Download is now ${newStatus === "1" ? 'enabled' : 'disabled'}.`);
    } catch (error) {
      console.error("Error updating PDFStatus: ", error);
      alert("Failed to update PDF Download status. Please try again.");
    }
  } else {
    // If the user canceled, you can show a message or handle accordingly
    console.log("User canceled the action");
  }
  };

  return (
    <div>
      <Btecadminsidebar>
        <AdminbtecUsername />
        <p><br /><br /></p>
        <div>
          <h1 className="h1-adminedit">Enable/Disable Class PDF Download</h1>
          <br/><br/><br/><br/><br/>
          {/*<div className='container'>*/}
          <p>PDF Download Status: <b>{isPdfDownloadEnabled ? "Enabled" : "Disabled"}</b></p>
          
          <br/>
          <div className='container'>
          <p>&emsp;&emsp;</p>
          <button onClick={handleToggleDownload} className='button-admin-view-attendance11' /*className="toggle-button"*/>
            {isPdfDownloadEnabled ? "Disable PDF Download" : "Enable PDF Download"}
          </button>
          </div>

          {/*</div>*/}
        </div>
      </Btecadminsidebar>
    </div>
  );
};

export default Btecenabledisable;
