import Btecsidebar from '../Btecsidebar';
import React from "react";
import Btecusername from '../Btecusername';
import { FaTasks, FaBookReader, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { BsQrCodeScan } from "react-icons/bs";
import { MdHistory } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import '../btecpages/BtecDashboard';
import {  Link } from "react-router-dom";

const BtecDashboard = () => {

  return (
    <div /*class="body-dashboard"*/>
      <Btecsidebar>
            <Btecusername />
            <p><br/><br/></p>
            <h1 class="h1-dashboard">Welcome to BTEC Attendance Information System</h1>
          
          <div className="container">

          <div title = "View your Profile" className="dashboard-function-container-new">
          <Link to= "/btec/BTEC_Profile">
            <AiOutlineUser className="dashboard-icon-new"/>
          </Link>
          <p className="p-dashboard-container"><b>Instructor Profile</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div title = "View and Delete student from class" className="dashboard-function-container-new">
          <Link to= "/btec/BTEC_Class">
            <FaRegListAlt className="dashboard-icon-new"/>
          </Link>
         <p className="p-dashboard-container">BTEC Class List</p>
          </div>

          </div>
          <p><br/><br/></p>

          <div className="container">

          <div title = "Generate QR Attendance for Class" className="dashboard-function-container-new">
          <Link to= "/btec/BTEC_Generate_Attendance">
            <BsQrCodeScan className="dashboard-icon-new"/>
          </Link>
          <p className="p-dashboard-container">Generate Attendance QR</p>
          </div>
          <p>&emsp;&emsp;</p>

          <div title = "View and Download Attendance History" className="dashboard-function-container-new">
          <Link to= "/btec/BTEC_Attendance_History">
            <MdHistory className="dashboard-icon-new"/>
          </Link>
          <p className="p-dashboard-container">Attendance History</p>
          </div>

          </div>
          <p><br/><br/></p>

      </Btecsidebar>
    </div>
  );
};

export default BtecDashboard;