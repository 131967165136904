import { getDatabase, ref, get } from "firebase/database";
import React, { useState, useEffect } from 'react';
import { auth } from "../firebase";
import '../Username.css';
import {  Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { RiLogoutCircleLine} from "react-icons/ri";
import { FaUserLock } from "react-icons/fa";

const AdminbtecUsername = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    // listen for changes to the auth object
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // update the component with the current user
      setCurrentUser(user);
    });

    // cleanup function to unsubscribe when the component unmounts
    return unsubscribe;
  }, []);
  
  useEffect(() => {
    // retrieve the current user's name from the database
    if (currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `BtecAdmin/${currentUser.uid}`);
      get(userRef)
        .then(snapshot => {
          const userData = snapshot.val();
          setCurrentUser({
            ...currentUser,
            adminName: userData.BtecAdminName,
            adminImage: userData.ImageURL,
            adminRole: userData.AdminRole,
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [currentUser]);

  return (
    <div className="container-username">

        {currentUser && (
          <>
            <p  class="p-username" style={{textAlign: 'right'}}><FaUserLock className="username-icon"/>&nbsp;Welcome&nbsp;<b>{currentUser.adminName}!</b> &nbsp;</p>

          </>
        )}

        {/*{currentUser?.adminRole === 'Normal' ? (*/}
        <div className="circle-username">
                  {currentUser ? (
                    currentUser.adminImage ? (
                    <img src={currentUser.adminImage} alt="Profile" className="profile-image-username" onClick={() => setDropdownVisible(!isDropdownVisible)}/>
                    ) : (
                    <div className="loading-text-username">
                      <p>No image...</p>
                    </div>
                    )
                  ) : (
                    <div className="no-image-text">
                    <p>No Image</p>
                    </div>
                  )}
                  </div>

                  {isDropdownVisible && (
      <div className="dropdown-content">
        {/* Your dropdown content here */}
        <div>

        <Link to= "/btec/adminbtec/Admin_BTEC_Profile">
          <div className="container">
        <AiOutlineUser className="username-icon"/>
        <button className="button-addStudent6">Admin Profile</button>
        </div>
        </Link>

        <p><br/></p>

        <Link to= "/btec/adminbtec/Admin_BTEC_Logout">
          <div className="container">
        <RiLogoutCircleLine className="username-icon"/>
        <button className="button-addStudent6">Log Out</button>
        </div>
        </Link>
      </div>
      </div>
    )}
            </div>
  );
};

export default AdminbtecUsername;