import React, { useState, useEffect } from 'react';
import { AiOutlineQrcode, AiFillContacts, AiOutlineUser } from "react-icons/ai";
import { FaTh, FaBars, FaTasks, FaBookReader, FaRegListAlt, FaEdit, FaUserEdit, FaClipboardList } from "react-icons/fa";
import { BsQrCodeScan } from "react-icons/bs";
import { RiLogoutCircleLine, RiArrowDownSFill, RiArrowUpSFill, RiAdminFill } from "react-icons/ri";
import { MdEditNote, MdHistory } from "react-icons/md";
import { NavLink, Link } from "react-router-dom";
import { getDatabase, ref, get} from "firebase/database";
import { auth } from "./firebase";
import Username from './Username';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
        const db = getDatabase();
        const userRef = ref(db, `Lecturer/${currentUser.uid}`);
        get(userRef)
          .then(snapshot => {
            const userData = snapshot.val();
            setCurrentUser({
              ...currentUser,
              lecturerName: userData.lecturerName,
              Faculty: userData.registerFaculty,
              lecturerEmail: userData.registerEmail,
              lecturerImage: userData.ImageURL,
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
      //console.log("Current Lecturer Email:", currentUser?.uid); // Add this line
    }, [currentUser]);

	  useEffect(() => {
      // listen for changes to the auth object
      const unsubscribe = auth.onAuthStateChanged((user) => {
        // update the component with the current user
        setCurrentUser(user);
      });
    
      // cleanup function to unsubscribe when the component unmounts
      return unsubscribe;
      }, []);

      // Function to handle logout across tabs
      const handleLogoutAcrossTabs = (event) => {
        if (event.key === 'logout') {
          // Handle the logout action in this tab
          navigate('/');
        }
      };

      // Listen for changes in localStorage
      window.addEventListener('storage', handleLogoutAcrossTabs);

      // Remove the event listener when the component unmounts
      useEffect(() => {
        return () => {
          window.removeEventListener('storage', handleLogoutAcrossTabs);
        };
      }, []);


  const menuItem = [
    {
      id: "dashboard",
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />
    },
    {
      id: "attendance",
      name: "Attendance",
      icon: <AiOutlineQrcode />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      subMenu: [
        /*{
          id: "class",
          path: "/attendance/createclass",
          name: "Create Subject and Add Student",
          icon: <FaBookReader />
        },*/
        {
          id: "generateqr",
          path: "/attendance/generateqr",
          name: "Generate Attendance QR",
          icon: <BsQrCodeScan />
        },
        /*{
          id: "qrhistory",
          path: "/attendance/history",
          name: "Student Attendance History",
          icon: <MdHistory />
        },*/
        {
          id: "qrhistory",
          path: "/attendance/attendance_class_list",
          name: "Student Attendance History",
          icon: <MdHistory />
        },
        /*{
          id: "classlisthistory",
          path: "/attendance/attendance_class_list",
          name: "Class Attendance List",
          icon: <MdHistory />
        },*/
        /*{
          id: "attendancebyweek",
          path: "/attendance/attendance1",
          name: "Attendance History: Compiled",
          icon: <FaClipboardList />
        }*/
      ]
    },
    {
      id: "students",
      name: "Students",
      icon: <AiFillContacts />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      subMenu: [
        /*{
          id: "addstudent",
          path: "/students/addstudent",
          name: "Add Student",
          icon: <FaTasks /> //turn off, combine with add subject
        },*/
        {
          id: "classlist",
          path: "/students/studentclass",
          name: "Class List",
          icon: <FaRegListAlt />
        }
      ]
    },
    {
      id: "profile",
      path: "/profile",
      name: "Profile",
      icon: <AiOutlineUser />
    },
    /*{
      id: "AdminMenu",
      name: "Admin",
      icon: <RiAdminFill />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      hidden: currentUser && currentUser.lecturerEmail !== 'nbucwebattendance@nbucc.com',
      subMenu: [
        {
          id: "registeruser",
          path: "/admregister/adminregister",
          name: "Register User",
          icon: <MdEditNote />,
          hidden: currentUser && currentUser.lecturerEmail !== 'nbucwebattendance@nbucc.com'
        },
        {
          id: "admineditclass",
          path: "/admregister/admineditclass",
          name: "Admin Add/Delete Class",
          icon: <FaEdit />,
          hidden: currentUser && currentUser.lecturerEmail !== 'nbucwebattendance@nbucc.com'
        },
        {
          id: "adminremovestudent",
          path: "/admregister/admineditstudent",
          name: "Admin Add/Delete Student",
          icon: <FaUserEdit />,
          hidden: currentUser && currentUser.lecturerEmail !== 'nbucwebattendance@nbucc.com'
        },
      ]
    },*/
    {
      id: "logout",
      path: "/logout",
      name: "Logout",
      icon: <RiLogoutCircleLine />
    },
  ];

  const [activeMenu, setActiveMenu] = useState(null);
  const showSubMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  return (
    <div className="container">
      <div style={{ width: isOpen ? "300px" : "60px" }} className="sidebar-new">
      {/*<Username />*/}
      {/*<div className="circle-sidebar">
        <img src="../images/logo.png" alt="Profile" className="profile-image-sidebar" />
        </div>*/}
        <div className="top_section">
        
        <Link to = '/dashboard'>
        <img src="../images/logowhite.png" alt="Profile" style={{ display: isOpen ? "block" : "none" }} className="profile-image-sidebar" />
        </Link>

          {/*<h1 style={{ display: isOpen ? "block" : "none" }} className="logo">My Menu</h1>*/}
          <div style={{ marginLeft: isOpen ? "30px" : "0px" ,cursor:"pointer" }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item) => {
          /*if (item.hidden && (currentUser && currentUser.lecturerEmail !== 'nbucwebattendance@nbucc.com' && currentUser.lecturerEmail !== 'arif@nbuc.edu.my')) {
            return null;
          }*/

          return (
            <div key={item.id}>
              {item.path ? (
                <NavLink
                  to={item.path}
                  className={`link ${activeMenu === item.id ? "active" : ""}`}
                  onClick={() => showSubMenu(item.id)}
                >
                  <div className="icon">{item.icon}</div>
                  <div style={{ display: isOpen ? "block" : "none" }} className="link_text"><b>{item.name}</b></div>
                  {item.subMenu && (activeMenu === item.id ? item.iconOpened : item.iconClosed)}
                </NavLink>
              ) : (
                <div
                  className={`link ${activeMenu === item.id ? "active" : ""}`}
                  onClick={() => showSubMenu(item.id)}
                >
                  <div className="icon">{item.icon}</div>
                  <div style={{ display: isOpen ? "block" : "none" }} className="link_text"><b>{item.name}</b></div>
                  {item.subMenu && (activeMenu === item.id ? item.iconOpened : item.iconClosed)}
                </div>
              )}

              {item.subMenu && activeMenu === item.id && (
                <ul className="subMenu">
                  {item.subMenu.map((subItem) => (
                    <NavLink to={subItem.path} key={subItem.id} className="sublink">
                      <div style={{ display: isOpen ? "block" : "none" }} className="subIcon">{subItem.icon}</div>
                      <div style={{ display: isOpen ? "block" : "none" }} className="sublink_text"><b>{subItem.name}</b></div>
                    </NavLink>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;