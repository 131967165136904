import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { getDatabase, ref, get } from "firebase/database";
import './SignIn.css';
import './BtecSignIn.css'
//import './BtecSignIn.css';
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const BtecSignIn = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const navigate = useNavigate();

	  const signIn2 = (e) => { //sign in user
		e.preventDefault();
		signInWithEmailAndPassword(auth, email, password)
		  .then((userCredential) => {
			const user = userCredential.user;
			if (user.emailVerified) { //check if the user have verified their through firebase e-mail
			  const uid = user.uid;
			  const db = getDatabase();
			  const dbRef = ref(db, `BTECInstructor/${uid}`);
			  get(dbRef)
				.then((snapshot) => {
				  if (snapshot.exists()) {
					// Lecturer exists with this uid
					console.log(snapshot.val());
					alert("This user has successfully signed in");
					navigate('/btec/BTEC_Dashboard'); //navigate to dashboar if successful
				  }
				   else {
					// Lecturer does not exist with this uid
					alert("Please make sure you enter the right Email and Password.");
					console.log("No lecturer found with this uid");
				  }
				})
				.catch((error) => {
				  console.log(error);
				  alert("An error occurred while fetching data from the database");
				});
			} else if (email === 'arif@nbuc.edu.my' || email === 'nbucwebattendance@nbucc.com'){ //user exception
				const uid = user.uid;
				const db = getDatabase();
				const dbRef = ref(db, `BTECInstructor/${uid}`);
				get(dbRef)
				  .then((snapshot) => {
					if (snapshot.exists()) {
					  // Lecturer exists with this uid
					  console.log(snapshot.val());
					  alert("This user has successfully signed in");
					  navigate('/BtecDashboard');
					}
					 else {
					  // Lecturer does not exist with this uid
					  alert("Please make sure you enter the right Email and Password.");
					  console.log("No lecturer found with this uid");
					}
				  })
				  .catch((error) => {
					console.log(error);
					alert("An error occurred while fetching data from the database");
				  });	

			} else {
			  // Email is not verified
			  alert("Please verify your email before logging in.");
			}
		  })
		  .catch((error) => {
			alert("Please make sure you enter the right Email and Password.");
			console.log(error);
		  });
	  };

	return (
		<div class="body2">

		<div className='picture-signin'>
        <img src="./BTECLOGO.png" alt="" />
		</div>
		<div className="sign-in-container">
			<div className="logo-position">
                
                {/*<img src="images/logored.png" alt="" width="80%" height="80%"/>*/}
				<img src="images/BTECLOGO.png" alt="" width="80%" height="60%"/>
				<p><br/></p>
				<img src="images/nais_logo.png" alt="" width="90%" height="90%"/>

			</div>

			<form onSubmit={signIn2}>

			<div className="user-detail">
				<label>E-mail:</label>
				<input
					type="email"
					placeholder="Enter email"
					value={email}
					onChange={(e) => setEmail(e.target.value) }>
					</input>
				
				<label><br/>Password:</label>
				<div className='container'>
				<input
					type= {showPassword ? "text":"password"}
					placeholder="Enter password"
					value={password}
					onChange={(e) => setPassword(e.target.value) }>
				</input>
				<button type="button" className='button-GenQR8' onClick={() => setShowPassword(!showPassword)}>
    			{showPassword ? <IoIosEye className='admin-view-attendance-icon3'/> : <IoIosEyeOff className='admin-view-attendance-icon3'/>}
				</button>
				</div>
					<p><br/></p>
					<button type="submit" className='button-signin-user-new2'><b>Log In</b></button>

				</div>
			</form>
			
			<form>
			<Link to ='/ForgotPassword' class ="forgot-user-password"><b>Forgot Password?</b></Link>
			</form>

		</div>

		<div className='picture-signin'>

		</div>
		<p className="footer-signin-container">*Supported and tested in Google Chrome, Mozilla FireFox, and Microsoft Edge.</p>
		<p className="footer-signin-container2">©Copyright NBUC 2024. BTEC-Version 1.0.1</p>
		</div>
		
		
	);
}

export default BtecSignIn;