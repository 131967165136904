export const createImage = (url) =>
  new Promise((resolve, reject) => {
    console.log("URL:", url);
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export const getCroppedImg = async (imageSrc, pixelCrop, quality) => {
  try {
    // Load the image from the provided image URL
    console.log('Image URL:', imageSrc);
    const image = await createImage(imageSrc);
    console.log('Loaded Image:', image);

    // Create a canvas and context for drawing the cropped image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 150;
    canvas.height = 150;

    // Draw the cropped image on the canvas
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Get the base64 data URL from the canvas
   // const dataUrl = canvas.toDataURL();

    // Open the data URL in a new tab
    //window.open(dataUrl, '_blank');

    // Log the canvas content as an image data URL
    //console.log('Canvas content:', canvas.toDataURL());

    // Asynchronously encode the image to the desired format (e.g., JPEG)
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (file) => {
          if (!file) {
            reject(new Error('Error creating Blob'));
            return;
          }
          resolve(file); // Return the cropped image as a Blob
        },
        'image/jpeg',
        quality // Set the desired output quality (0-1)
      );
    });
  } catch (error) {
    console.error('Error cropping image:', error);
    throw error; // Rethrow the error to the calling function (handleCropImage)
  }
};
