import React, { useState, useEffect } from 'react';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';

import { FaUserTie, FaEdit, FaUserEdit, FaRegAddressBook, FaUserLock } from "react-icons/fa";
import { MdEditNote, MdOutlineAdminPanelSettings, MdSystemSecurityUpdate, MdWorkHistory } from "react-icons/md";
import { auth } from "../firebase";
import { getDatabase, ref, get} from "firebase/database";
import { BsPersonLock } from "react-icons/bs";
import { BsFiletypePdf } from "react-icons/bs";
import '../admregister/AdminDashboard.css';
import {  Link } from "react-router-dom";


const AdminbtecDashboard = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    /*useEffect(() => {
      if (currentUser) {
          const db = getDatabase();
          const userRef = ref(db, `Admin/${currentUser.uid}`);
          get(userRef)
            .then(snapshot => {
              const userData = snapshot.val();
              setCurrentUser({
                ...currentUser,
               adminName: userData.AdminName,
                adminRole: userData.AdminRole,
                adminEmail: userData.AdminEmail,
                adminImage: userData.ImageURL,
              });
            })
            .catch(error => {
              console.error(error);
            });
        }
        //console.log("Current Lecturer Email:", currentUser?.uid); // Add this line
      }, [currentUser]);*/

      useEffect(() => {
        if (currentUser) {
            const db = getDatabase();
            const userRef = ref(db, `BtecAdmin/${currentUser.uid}`);
            get(userRef)
              .then(snapshot => {
                const userData = snapshot.val();
                setCurrentUser({
                  ...currentUser,
                 adminName: userData.BtecAdminName,
                  adminRole: userData.AdminRole,
                  adminEmail: userData.BtecAdminEmail,
                  adminImage: userData.ImageURL,
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
          //console.log("Current Lecturer Email:", currentUser?.uid); // Add this line
        }, [currentUser]);
  
        useEffect(() => {
        // listen for changes to the auth object
        const unsubscribe = auth.onAuthStateChanged((user) => {
          // update the component with the current user
          setCurrentUser(user);
        });
      
        // cleanup function to unsubscribe when the component unmounts
        return unsubscribe;
        }, []);

        useEffect(() => {

            const showSuperAdminMenu = () =>{
            if (currentUser?.adminRole === "Super"){
                setShow(true);
            }
            };

            const hideSuperAdminMenu = () =>{
              if (currentUser?.adminRole !== "Super"){
                  setShow2(true);
              } else{
                setShow2(false);
              }
              };
              
              showSuperAdminMenu();
              hideSuperAdminMenu();
            }, [currentUser?.adminRole]);

  return (
    <div /*class="body-dashboard"*/>
      <Btecadminsidebar>
            <AdminbtecUsername />
            <p><br/><br/></p>
            {show &&(<h1 class="superadmin-font"><b>Super Admin Page</b></h1>)}
            {show2 &&(<h1 class="h1-admindashboard"><b>Admin Page</b></h1>)}
            <h1>BTEC ADMIN PAGE</h1>
          
            {show &&(
          <div className="container">

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Register_Admin">
            <MdOutlineAdminPanelSettings className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>Register Admin</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Register_Instructor">
            <MdEditNote className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>Register Instructor</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Register_Student">
            <MdSystemSecurityUpdate className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>Register BTEC Student to N.A.I.S</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <p>&emsp;&emsp;</p>

          </div>
          )}

          <p><br/><br/></p>

          {show &&(
          <div className="container">

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_View_Class">
            <MdWorkHistory className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>View Instructor Class History</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Profile">
            <FaUserTie className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>Admin Profile</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <Link to= "/btec/adminbtec/Admin_BTEC_Class">
          <div className="dashboard-function-container-admin">
            <FaEdit className="dashboard-icon-admin-new"/>
          <p className="p-dashboard-container"><b>Admin BTEC Edit Class</b></p>
          </div>
          </Link>
          <p>&emsp;&emsp;</p>

          </div>
          )}

          <p><br/><br/></p>

          {show &&(
          <div className="container">
          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Edit_Student">
            <FaUserEdit className="dashboard-icon-admin-new"/>
          </Link>
         <p className="p-dashboard-container"><b>Admin Add/Delete Student</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Edit_Log">
            <FaRegAddressBook className="dashboard-icon-admin-new"/>
          </Link>
         <p className="p-dashboard-container"><b>Admin Edit Student Login Status</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Enabledisable_PDF_DLD">
            <BsFiletypePdf className="dashboard-icon-admin-new"/>
          </Link>
         <p className="p-dashboard-container"><b>Enable/Disable PDF Download</b></p>
          </div>
          <p>&emsp;&emsp;</p>
          </div>
          )}

          {show2 &&(
          <div>
          <div className="container">
          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Profile">
            <FaUserTie className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container">Admin Profile</p>
          </div>
          <p>&emsp;&emsp;</p>

          <Link to= "/btec/adminbtec/Admin_BTEC_Class">
          <div className="dashboard-function-container-admin">
            <FaEdit className="dashboard-icon-admin-new"/>
          <p className="p-dashboard-container">Admin BTEC Edit Class</p>
          </div>
          </Link>
          <p>&emsp;&emsp;</p>

          {/*<div className="dashboard-function-container-admin">
          <Link to= "/Admin/Admin_Edit_Student">
            <FaUserEdit className="dashboard-icon-admin-new"/>
          </Link>
         <p className="p-dashboard-container">Enable/Disable PDF Download</p>
          </div>*/}
          <p>&emsp;&emsp;</p>
          </div>

          <br/>

          <div className='container'>
          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_View_Class">
            <MdWorkHistory className="dashboard-icon-admin-new"/>
            </Link>
          <p className="p-dashboard-container"><b>View Instructor Class History</b></p>
          </div>
          <p>&emsp;&emsp;</p>

          <div className="dashboard-function-container-admin">
          <Link to= "/btec/adminbtec/Admin_BTEC_Edit_Student">
            <FaUserEdit className="dashboard-icon-admin-new"/>
          </Link>
         <p className="p-dashboard-container"><b>Admin Add/Delete Student</b></p>
          </div>
          <p>&emsp;&emsp;</p>


          <p>&emsp;&emsp;</p>
          </div>
          </div>
          )}

      </Btecadminsidebar>
    </div>
  );
};

export default AdminbtecDashboard;