import React from 'react';
import { useNavigate } from 'react-router-dom';
import Btecsidebar from '../Btecsidebar';
import Btecusername from '../Btecusername';
import { auth} from "../firebase";
import { signOut } from 'firebase/auth';
import '../Logout.css';

const BtecLogout = () => {
	const navigate = useNavigate();

	const logout = async () => {
		const confirmAttend = window.confirm("Are you sure you want to Log Out?");
		if (confirmAttend) {
		alert("You have logged out!")
		await signOut(auth);
		console.log("You have succesfully logged out.");
		
		// Set a shared logout token in localStorage
		localStorage.setItem('logout', Date.now());
		
		//navigate('/btec');
		navigate('/');
		}
	}
	return (
		<div class= "body-logout">
			<Btecsidebar>
			<h1 class= "h1-logout">Logout</h1>
			<Btecusername />
			<p class="p-logout2" style={{textAlign: 'center'}}><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>Confirm to log out?<br></br><br></br></p>
				<button class= "button-logout-new" onClick={logout}>Log Out?</button>
				</Btecsidebar>
	</div>
	);
};

export default BtecLogout;