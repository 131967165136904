import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

//pdf stylsheet for pdf printing
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  rightContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight:'11px',
  },
  leftContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  header: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: '1',
    borderRightWidth: 1,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '31.70%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol: {
    //width: '31.70%',
    width: '20%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 8,
    fontWeight: 'bold',
  },
  tableCell: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  tableCell2: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  tableCell3: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  titleItem: {
    fontSize: 12,
    marginBottom: 10,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  column1: {
    marginRight: 100,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  imageContainer: {
    //flex: 1,
    //justifyContent: 'center',
    marginRight: '20px',
    //marginBottom: '1px',
  },
  image: {
    width: '50px',
    height: '50px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    right: 20,
  },
  generatebyNais: {
    position: 'absolute',
    fontSize: 10,
    bottom: 50,
    left: 20,
  },
  address: {
    fontSize: 8,
    marginBottom: 2,
  },
  address2: {
    fontSize: 8,
    marginLeft: 325,
  },
  instructor: {
    fontSize: 8,
    marginBottom: 2,
  },
  kehadiranpelatih: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 350,
    marginTop: 2,
    marginBottom: 2,
  },
  tableRow1: {
    margin: 'auto',
    //margin: '-5', //ubah 23082024
    flexDirection: 'row',
  },
  tableRow1PTPK: {
    margin: 'auto',
    //margin: '-5', //ubah 23082024
    flexDirection: 'row',
  },
  tablePTPK: { //no. column
    width: '62.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    padding: 2, // Add padding property with a smaller value
  },
  textPTPK: {
    margin: 2,
    fontSize: 9,
    textAlign: 'center',
    color: '#800000',
    fontWeight: 1000,
  },
  tableAnalisa: { //no. column
    width: '23.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  textAnalisa: {
    margin: 2,
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableCatatan: { //no. column
    width: '15.00%',
    //height:'30',
    height: 'auto',
    borderStyle: 'solid',
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  textCatatan: {
    margin: 2,
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 1000,
  },

  //row2 table
  tableIntake: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  textIntake: {
    margin: 2,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableHari: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  textHari: {
    margin: 2,
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableEmptyUpper: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLast: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmpty2: { //no. column
    width: '17.00%',
    borderStyle: 'solid',
    fontWeight: 'bold',
  },

  //row 3
  tableMinggu: { //no. column
    width: '8.546%',
    padding: 2,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  highlightedTableMinggu: {
    padding: 2,
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#FFFF00', // Highlighted background color
  },
  tableHariRow4: {
    width: '6.2%',
    padding: 2,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableHariRow4Last: {
    width: '6.2%',
    padding: 2,
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  textHari3: {
    margin: 2,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableEmptyUpperRowhari: { //no. column
    //width: '5.75%',
    width: '5.749%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyUpperRowhari2: { //no. column
    //width: '5.72%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyUpperRowhari3: { //no. column
    //width: '5.72%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyUpperLastRowhari: { //no. column
    //width: '5.72%',
    width: '5.749%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hiddens
  },
  tableEmptyhari: { //no. column
    width: '17.00%',
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableCellHeader3: {
    margin: 2,
    fontSize: 5,
    fontWeight: 'bold',
  },

  //row 4
  tableBulan: { //no. column
    //width: '4.685%', //old24092024
    width: '4.43%',
    padding: 2, //new add
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  highlightedTableBulan: {
    width: '4.43%', //new24092024
    //padding: 2, //old 24092024
    //borderWidth: 0.5, //old 24092024
    borderColor: '#000',
    backgroundColor: '#FFFF00', // Highlighted background color
  },
  textIntake2: {
    margin: 2,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  tableBulan2: { //no. column
    //width: '8.04%',
    width: '8.84%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  tableHariRow3: {
    width: '6.2%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableHariRow2new: {
    paddingVertical: 2,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    width: '100%',  // Ensure each row spans the width of the column
  },
  tableHariRow2newLast: {
    paddingVertical: 2,
    width: '100%',
  },
  textHari2new: {
    fontSize: 8,
    color: '#333',
    textAlign: 'center', // Ensure text is centered within each row
  },
  tableEmptyside: { //no. column
    //width: '5.74%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyside2: { //no. column
    //width: '5.74%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyside3: { //no. column
    //width: '5.74%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmptyside4: { //no. column
    //width: '5.74%',
    width: '5.749%',
    borderRightWidth: 0.85,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableEmpty: { //no. column
    //width: '17.00%',
    width: '25.00%',
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  textsmallcolumn1: {
    margin: 2,
    fontSize: 6,
    fontWeight: 'bold',
  },
  tableCellHeader2: {
    margin: 2,
    fontSize: 8,
    fontWeight: 'bold',
  },

  //row 5
  tableAMPMNew2: { //no. column
    width: '3.092%',
    borderStyle: 'solid',
    borderLeftWidth: 0.5,
    //borderRightWidth: 0.5,
    borderRightWidth: 1,
    //borderBottomWidth: 0.5,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableCell4: { //if overflow will go to the next line in the same column
    margin: 0.5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
    textAlign: 'center',
  },
  tableCell5: { //if overflow will go to the next line in the same column
    margin: 0.5,
    fontSize: 8,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  tableBil: { //no. column
    width: '4.43%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
  },
  tableUpper2: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperLast2: {
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperEmpty2: {
    width: '15.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },

  //row6

  //
  tableHariRow2: {
    width: '6.2%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  textBidangPengajar: { //words seem strectched
    margin: 2,
    letterSpacing: -0.5,
    fontSize: 8,
    textAlign: 'left',
    fontWeight: 1000,
  },
  textBidangPengajar2: { //words seem strectched
    marginLeft: 5,
    marginBottom: 2,
    lineHeight: 1.0, // Adjust as needed
    letterSpacing: -0.5,
    fontSize: 8,
    textAlign: 'left',
    fontWeight: 1000,
  },
  textNamaPengajar: { //words seem strectched
    margin: 2,
    letterSpacing: -0.5,
    fontSize: 8,
    textAlign: 'left',
    fontWeight: 1000,
  },
  textHari2: {
    margin: 5,
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 1000,
  },
  textContainer: {
    flexDirection: 'column', // Keeps the text stacked vertically
  },
  verticalTextContainer: {
    position: 'absolute',
    top: '0px',
    right: '20',  // Ensure the container is relative for the absolute text positioning
    width: '3.25%',        // Adjust as needed for your layout
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  verticalText: {
    transform: 'rotate(270deg)',
    //textAlign: 'left',
    transformOrigin: 'left top 0',
    whiteSpace: 'nowrap',
    fontSize: 8,
    margin: 5,
  },


  tabletidakhadir: { //no. column
    width: '17.00%',
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyBottom: { //no. column
    width: '15.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  /*tableEmptyUpper: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLast: { //no. column
    width: '5.75%',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },*/
  tableEmptyUpperRow2: { //no. column
    width: '5.75%',
    borderRightWidth: 0.5,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
  },
  tableUpper: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperLast: {
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableUpperEmpty: {
    width: '15.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableEmptyUpperRow3: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLastRow3: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },
  tableEmptyUpperLastRow2: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hiddens
  },
  tableEmptyUpperLastRow22: { //no. column
    width: '5.75%',
    borderRightWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
  },

  tableHariRow23: {
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
    width: '100%',  // Ensure each row spans the width of the column
  },
  tableHariRow23Last: {
    paddingVertical: 8,
    width: '100%',
  },
  tableHariRow2Last: {
    width: '6.2%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },


  tableNamaPelatih: { //no. column
    width: '26.57%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableAMPM: { //no. column
    width: '3.092%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableAMPMLast: { //no. column
    width: '3.18%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableJumlahK: { //no. column
    width: '31.00%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableHariRow22: {
    width: '6.2%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableHariRow22Last: {
    width: '6.2%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'hidden', // Ensure overflow is hidden
    textAlign: 'center', // Center align the text
  },
  tableAMPMNew: { //no. column
    width: '3.092%',
    borderStyle: 'solid',
    borderLeftWidth: 0.5,
    //borderRightWidth: 0.5,
    borderRightWidth: 1,
    //borderBottomWidth: 0.5,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableAMPMLastNew: { //no. column
    width: '3.05%',
    borderStyle: 'solid',
    //borderLeftWidth: 0.5,
    borderRightWidth: 1,
    borderBottomWidth: 0.5,
    fontWeight: 'bold',
  },
  tableNamaPelajar: { //no. column
    width: '26.57%',
    //marginBottom:1,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    //borderRightWidth: 0.5,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    fontWeight: 'bold',
  },
  tableRow1new: {
    flexDirection: 'row',  // Ensure columns are rendered horizontally
    justifyContent: 'space-around',  // Adjust as per your layout needs
    alignItems: 'flex-start',  // Align columns at the top
    marginTop: 20,  // Adjust spacing as needed
  },
  tableColumn: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 1,
  },


  textpegawaipenilai: {
    margin: 1,
    fontSize: 7,
    textAlign: 'center',
    fontWeight: 1000,
  },
  textpegawaipenilaidalaman: {
    margin: 1,
    fontSize: 7.5,
    textAlign: 'center',
    fontWeight: 1000,
  },
  autogeneratedtext: {
    fontSize: 7,
    marginTop: 5,
  },
});

// Utility function to get the month names
const getMonthNames = (month) => {
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  // Determine the range of months to display
  if (month >= 0 && month <= 6) {
    return months.slice(0, 7);
  } else {
    return months.slice(7, 12);
  }
};

const BTECDownloadAttPDF = ({ attendanceData, /*, rowsPerPage = 31*/ }) => {
  const attendance1 = attendanceData[0];

  const firstDate = attendanceData[0].ClassDateDB;
  const dateParts = firstDate.split("-");
  const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert to zero-based index
  const monthNames = getMonthNames(month);

  const additionalColumnNeeded = month >= 7; // August is month 7 (zero-based)

  // Get the week value from the first attendance record
  const week = attendanceData[0].Week;
  //console.log( "week: ", week);

  const weekDates = [...new Set(
    attendanceData
      .filter(attendance => attendance.CoursePick === attendance1.CoursePick)
      .map(attendance => attendance.ClassDateDB)
  )];
  //console.log ("weekDates: ",weekDates.length);

  const renderWeekDates = (dates, currentPage, maxDatesPerPage) => {
    const startIndex = (currentPage - 1) * maxDatesPerPage;
    const endIndex = Math.min(startIndex + maxDatesPerPage, dates.length);
    const datesToRender = dates.slice(startIndex, endIndex);
    const remainingEmptyDates = maxDatesPerPage - datesToRender.length; // Calculate the number of empty dates

    // Extract the dayScanned for each date
    const dateWithDayScanned = datesToRender.map(date => {
      const attendanceForDate = attendanceData.find(attendance => attendance.ClassDateDB === date);
      return {
        date,
        dayScannedDB: attendanceForDate ? attendanceForDate.dayScannedDB : ''
      };
    });

    const renderedDates = dateWithDayScanned.map((dateInfo, index) => (
      <View
        style={index === dateWithDayScanned.length - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
        key={startIndex + index} // Use a unique key for each date
      >
        <Text style={styles.textHari2}>{dateInfo.date} - {dateInfo.dayScannedDB}</Text>
      </View>
    ));

    // Fill the remaining slots with empty table cells
    for (let i = 0; i < remainingEmptyDates; i++) {
      renderedDates.push(
        <View
          style={i === remainingEmptyDates - 1 ? styles.tableHariRow2Last : styles.tableHariRow2}
          key={`empty-${i}`}
        >
          <Text style={styles.textHari2}></Text>
        </View>
      );
    }
    return renderedDates;
  };

  //log for why the date return is empty

  //need to aligned the dates shown with the specified column

  const renderWeekDates3 = (dates, currentPage, maxDatesPerPage) => {
    const startIndex = (currentPage - 1) * maxDatesPerPage;
    const endIndex = Math.min(startIndex + maxDatesPerPage, dates.length);
    const datesToRender = dates.slice(startIndex, endIndex);
    //console.log("Current Page:", currentPage);
    //console.log("Start Index:", startIndex, "End Index:", endIndex);
    //console.log("Dates to Render:", datesToRender);

    // Mapping dayScannedDB to day index (Monday: 0, Tuesday: 1, ..., Friday: 4)
    const dayIndexMap = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4
    };

    // Prepare columns for rendering
    const columns = [[], [], [], [], []]; // One array for each weekday column

    // Check attendanceData structure
    //console.log("Attendance Data:", attendanceData);

    // Extract the dayScanned for each date and distribute into columns
    const dateWithDayScanned = datesToRender.map(date => {
      const attendanceForDate = attendanceData.find(attendance => attendance.ClassDateDB === date);
      //console.log("Date:", date, "Attendance Found:", attendanceForDate); // Log to check matching
      const dayScannedDB = attendanceForDate ? attendanceForDate.dayScannedDB : '';
      //console.log("Day Scanned DB:", dayScannedDB); // Log dayScannedDB
      const dayIndex = dayIndexMap[dayScannedDB] !== undefined ? dayIndexMap[dayScannedDB] : null;
      //console.log("Day Index:", dayIndex); // Log dayIndex
      return {
        date,
        dayScannedDB: attendanceForDate ? attendanceForDate.dayScannedDB : '',
        dayIndex
      };
    });

    //console.log("Date with Day Scanned:", dateWithDayScanned); // Log the dates with day scanned

    dateWithDayScanned.forEach(dateInfo => {
      if (dateInfo.dayIndex !== null) {
        columns[dateInfo.dayIndex].push(dateInfo);
      }
    });

    //console.log("Columns after distribution:", columns); // Log columns after distribution
    //settle issue with no highlight for when month is august and above
    // Ensure each column has at least one element (empty if necessary)
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].length === 0) {
        columns[i].push({ date: '', dayScannedDB: '', dayIndex: i });
      }
    }

    //console.log("Columns after ensuring non-empty:", columns); // Log columns after ensuring non-empty

    // Render each column separately
    const renderedColumns = columns.map((column, columnIndex) => (
      //<View style={styles.tableColumn} key={`column-${columnIndex}`}>
      <View style={styles.tableHariRow3} key={`column-${columnIndex}`}>
        {column.map((dateInfo, index) => (
          <View
            style={index === column.length - 1 ? styles.tableHariRow2newLast : styles.tableHariRow2new}
            key={`${columnIndex}-${startIndex + index}`}
          >
            <Text style={styles.textHari2new}>{dateInfo.date} {/*- {dateInfo.dayScannedDB}*/}</Text>
          </View>
        ))}
      </View>
    ));

    //console.log("Rendered Columns:", renderedColumns); // Log rendered columns
    return renderedColumns;
  };

  const groupedAttendance = (attendances) => {
    return attendances.reduce((acc, attendance) => {
      const key = `${attendance.StudentName}-${attendance.ClassDate}`;
      if (!acc[key]) {
        acc[key] = {
          StudentName: attendance.StudentName,
          ClassDate: attendance.ClassDate,
          dayScannedDB: attendance.dayScannedDB,
          AMStatus: '',
          PMStatus: '',
        };
      }
      if (attendance.TakenFor === 'AM') {
        acc[key].AMStatus = attendance.AttendanceStatus;
      } else if (attendance.TakenFor === 'PM') {
        acc[key].PMStatus = attendance.AttendanceStatus;
      }
      return acc;
    }, {});
  };

  //new 07/06/2024
  const groupAttendanceByStudent = (attendances) => {
    const studentAttendanceMap = {};
    Object.values(attendances).forEach(attendance => {
      if (!studentAttendanceMap[attendance.StudentName]) {
        studentAttendanceMap[attendance.StudentName] = [];
      }
      studentAttendanceMap[attendance.StudentName].push(attendance);
      //console.log(studentAttendanceMap);
    });
    return studentAttendanceMap;
  };

  const renderStatusRows6 = (attendances) => {
    const MAX_DATES_PER_PAGE = 5;
    const studentAttendanceMap = groupAttendanceByStudent(attendances);
    const uniqueStudents = Object.keys(studentAttendanceMap);

    const dayStatusIndexMap = {
      Monday: [0, 1],
      Tuesday: [2, 3],
      Wednesday: [4, 5],
      Thursday: [6, 7],
      Friday: [8, 9]
    };

    const renderAttendanceCells = (attendances) => {
      const cells = Array(10).fill(null);

      attendances.forEach((attendance, i) => {
        const indices = dayStatusIndexMap[attendance.dayScannedDB];
        if (indices) {
          cells[indices[0]] = (
            <View style={styles.tableAMPMNew2} key={`am-${indices[0]}`}>
              <Text style={styles.tableCell4}>
                {attendance.AMStatus === "1" ? 'O' :
                  attendance.AMStatus === "0" ? 'X' :
                    attendance.AMStatus === "3" ? 'L' : ''}
              </Text>
            </View>
          );
          cells[indices[1]] = (
            <View style={styles.tableAMPMNew2} key={`pm-${indices[1]}`}>
              <Text style={styles.tableCell4}>
                {attendance.PMStatus === "1" ? 'O' :
                  attendance.PMStatus === "0" ? 'X' :
                    attendance.PMStatus === "3" ? 'L' : ''}
              </Text>
            </View>
          );
        }
      });

      return cells.map((cell, index) => (
        cell || (
          <View key={`empty-${index}`} style={styles.tableAMPMNew2}>
            {/*<View style={styles.tableAMPMNew}>*/}
            <View>
              <Text style={styles.tableCell4}></Text>
            </View>
          </View>
        )
      ));
    };

    const renderPage = (studentName, pageIndex, attendancesSubset, studentIndex) => {
      const filledAttendances = renderAttendanceCells(attendancesSubset);

      return (
        <View>
          <View style={styles.tableRow1} key={`${studentName}-${pageIndex}`}>
            <View style={styles.tableBil}>
              <Text style={styles.tableCell4}>{pageIndex === 0 ? studentIndex + 1 : ''}</Text>
            </View>
            <View style={styles.tableNamaPelajar}>
              <Text style={styles.tableCell5}>{pageIndex === 0 ? studentName : ''}</Text>
            </View>
            {filledAttendances}
            {/* You might need to adjust these based on your table structure */}
            <View style={styles.tableUpper2}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper2}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpper2}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperLast2}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
            <View style={styles.tableUpperEmpty2}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
          </View>
          {/*{additionalEmptyRows}*/}
        </View>

      );
    };

    return uniqueStudents.flatMap((studentName, studentIndex) => {
      const studentAttendances = studentAttendanceMap[studentName];
      const totalPages = Math.ceil(studentAttendances.length / MAX_DATES_PER_PAGE);

      return Array.from({ length: totalPages }).map((_, pageIndex) => {
        const start = pageIndex * MAX_DATES_PER_PAGE;
        const end = start + MAX_DATES_PER_PAGE;
        const attendancesSubset = studentAttendances.slice(start, end);

        return renderPage(studentName, pageIndex, attendancesSubset, studentIndex);
      });
    });
  };


  const generatePDF = () => {
    //let totalPages = 0;
    let rowCounter = 0;
    /*const rowsPerPageFirstPage = 5;
    const rowsPerPageSubsequentPages = 15;
    const maxDatesPerPage = 5;*/

    const rowsPerPageFirstPage = 5;
    const rowsPerPageSubsequentPages = 15;
    const maxDatesPerPage = 5;

    const groupedData = groupAttendanceByStudent(attendanceData);

    const totalRows = weekDates.length;

    const totalPages = Math.ceil((totalRows - rowsPerPageFirstPage) / rowsPerPageSubsequentPages) + 1; //count by data of dates length

    return (
      <Document>
        {Array.from({ length: totalPages }).map((_, pageIndex) => {
          /*{paginatedWeekDates.map((dates, pageIndex) => {*/

          let startRow, endRow;
          if (pageIndex === 0) {
            startRow = 0;
            endRow = Math.min(rowsPerPageFirstPage, totalRows);
          } else {
            startRow = rowsPerPageFirstPage + (pageIndex - 1) * rowsPerPageSubsequentPages;
            endRow = Math.min(startRow + rowsPerPageSubsequentPages, totalRows);
          }

          //console.log("startRow: " , startRow)
          //console.log("endRow:" , endRow)

          const pageData = attendanceData.slice(startRow, endRow);
          //const pageData = groupedData.slice(startRow, endRow);
          //console.log("pageData: ",pageData)

          // Log values for debugging
          rowCounter = endRow - startRow;

          pageData.sort((a, b) => a.StudentName.localeCompare(b.StudentName));
          //pageData.sort((a, b) => a.studentID.localeCompare(b.studentID));

          const pageStyle = pageIndex === 0 ? styles.page : { ...styles.page, marginTop: '30px' };
          const pageNumberStyle = pageIndex === 0 ? styles.pageNumber : { ...styles.pageNumber, bottom: 50, right: 20 };

          // Extract the month from the date
          const dateParts = attendance1.ClassDateDB.split("-");
          //const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert to zero-based indexper

          return (
            <Page size="A4" style={pageStyle} key={pageIndex} orientation="landscape">
              <View style={styles.section}>
                {pageIndex === 0 && ( // Render the header section only for the first page
                  <>
                    <View style={styles.headerContainer}>
                      <View style={styles.imageContainer}>
                        <Image src="/bteclogobg.png" style={styles.image} />
                      </View>
                      <View style={styles.leftContainer}>
                        <Text style={styles.header}>(JPK Kod Pusat Bertauliah L02387)</Text>
                        <Text style={styles.address}>Wisma Angkatan Hebat, One Borneo Mall 88400 Kota Kinabalu, Sabah.</Text>
                        <Text style={styles.address}>Tel: 088-396682, Fax: 088-486249</Text>
                      </View>
                      <View style={styles.rightContainer}>
                        <Text style={styles.instructor}>TENAGA PENGAJAR(PP) 1: {attendance1.InstructorName}</Text>
                        <Text style={styles.instructor}>PEMBANTU TENAGA PENGAJAR 2:</Text>
                        <Text style={styles.instructor}>KOD DAN TAJUK UNIT KOMPETENSI: {attendance1.CoursePick}</Text>
                      </View>
                    </View>


                    <View style={styles.titleContainer}>
                      <View style={styles.column1}>
                        <Text style={styles.kehadiranpelatih}>KEHADIRAN PELATIH</Text>
                      </View>
                      <View style={styles.column1}>

                      </View>
                    </View>
                  </>
                )}

                <View style={styles.table}>

                  <View style={styles.tableRow1}>
                    <View style={styles.tablePTPK}>
                      <Text style={styles.textPTPK}>Perbadanan Tabung Pembangunan Kemahiran (PTPK)</Text>
                    </View>
                    <View style={styles.tableAnalisa}>
                      <Text style={styles.textAnalisa}>ANALISA KEHADIRAN HARIAN</Text>
                    </View>
                    <View style={styles.tableCatatan}>
                      <Text style={styles.textCatatan}>CATATAN</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow1}>
                    <View style={styles.tableIntake}>
                      <Text style={styles.textIntake}>INTAKE</Text>
                    </View>
                    <View style={styles.tableHari}>
                      <Text style={styles.textHari}>HARI / TARIKH</Text>
                    </View>
                    <View style={styles.tableEmptyUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperLast}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmpty2}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                  </View>

                  <View style={styles.tableRow1}>
                    <View style={week === "1" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                      <Text style={styles.textIntake}>Minggu 1</Text>
                    </View>
                    <View style={week === "2" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                      <Text style={styles.textIntake}>Minggu 2</Text>
                    </View>
                    <View style={week === "3" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                      <Text style={styles.textIntake}>Minggu 3</Text>
                    </View>
                    <View style={week === "4" ? styles.highlightedTableMinggu : styles.tableMinggu}>
                      <Text style={styles.textIntake}>Minggu 4</Text>
                    </View>
                    <View style={styles.tableHariRow4}>
                      <Text style={styles.textHari3}>ISNIN</Text>
                    </View>
                    <View style={styles.tableHariRow4}>
                      <Text style={styles.textHari3}>SELASA</Text>
                    </View>
                    <View style={styles.tableHariRow4}>
                      <Text style={styles.textHari3}>RABU</Text>
                    </View>
                    <View style={styles.tableHariRow4}>
                      <Text style={styles.textHari3}>KHAMIS</Text>
                    </View>
                    <View style={styles.tableHariRow4Last}>
                      <Text style={styles.textHari3}>JUMAAT</Text>
                    </View>
                    <View style={styles.tableEmptyUpperRowhari}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperRowhari2}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperRowhari3}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperLastRowhari}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyhari}>
                      <Text style={styles.tableCellHeader3}></Text>
                    </View>
                  </View>

                  <View style={styles.tableRow1}>
                    {/*{monthNames.map((monthName, index) => (
                    <View 
                      style={month === index ? styles.highlightedTableBulan : styles.tableBulan} 
                      key={index}>
                      <Text style={styles.textIntake}>{monthName}</Text>
                    </View>
                  ))}*/}

                    {monthNames.map((monthName, index) => {
                      const adjustedIndex = month >= 7 ? index + 7 : index;
                      return (
                        <View
                          style={adjustedIndex === month ? styles.highlightedTableBulan : styles.tableBulan}
                          key={index}
                        >
                          <Text style={styles.textIntake2}>{monthName}</Text>
                        </View>
                      );
                    })}

                    {additionalColumnNeeded && (
                      <View style={styles.tableBulan2} key="extra-column">
                        <Text style={styles.textIntake2}></Text>
                      </View>
                    )}

                    {renderWeekDates3(weekDates, pageIndex + 1, maxDatesPerPage)}

                    <View style={styles.tableEmptyside}>
                      <Text style={[styles.textsmallcolumn1]}>JUMLAH HADIR</Text>
                    </View>
                    <View style={styles.tableEmptyside2}>
                      <Text style={styles.textsmallcolumn1}>CUTI SAKIT</Text>
                    </View>
                    <View style={styles.tableEmptyside3}>
                      <Text style={styles.textsmallcolumn1}>KELUAR SEMENTARA</Text>
                    </View>
                    <View style={styles.tableEmptyside4}>
                      <Text style={styles.textsmallcolumn1}>TIDAK HADIR</Text>
                    </View>
                    <View style={styles.tableEmpty}>
                      <Text style={styles.tableCellHeader2}></Text>
                    </View>
                  </View>

                  <View style={styles.tableRow1}>
                    <View style={styles.tableBil}>
                      <Text style={styles.textIntake}>BIL.</Text>
                    </View>
                    <View style={styles.tableNamaPelatih}>
                      <Text style={styles.textIntake}>NAMA PELATIH</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>AM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>PM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>AM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>PM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>AM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>PM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>AM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>PM</Text>
                    </View>
                    <View style={styles.tableAMPM}>
                      <Text style={styles.textIntake}>AM</Text>
                    </View>
                    <View style={styles.tableAMPMLast}>
                      <Text style={styles.textIntake}>PM</Text>
                    </View>
                    <View style={styles.tableEmptyUpperRow3}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperRow3}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperRow3}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyUpperLastRow3}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableEmptyBottom}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                  </View>

                  {renderStatusRows6(groupedAttendance(attendanceData))}

                  <View style={styles.tableRow1}>
                    <View style={styles.tableJumlahK}>
                      <Text style={styles.textIntake}>JUMLAH KESULURUHAN</Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                    </View>
                    <View style={styles.tableHariRow22Last}>
                      <Text style={styles.textHari}></Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpperLast}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpperEmpty}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                  </View>

                  <View style={styles.tableRow1}>
                    <View style={styles.tableJumlahK}>
                      <Text style={styles.textBidangPengajar}>TENAGA PENGAJAR BIDANG {attendance1.ProgramName}</Text> {/*kecilkan wording, spacing kecilkan*/} */
                      <Text style={styles.textBidangPengajar2}>({attendance1.ProgramCode})</Text>
                      <Text style={styles.textBidangPengajar2}></Text>
                      <Text style={styles.textNamaPengajar}>Nama: {attendance1.InstructorName}</Text>
                      {attendance1.InstructorName}
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                      <Text style={styles.textHari}>................</Text>
                      <Text style={styles.textpegawaipenilai}>PEGAWAI</Text>
                      <Text style={styles.textpegawaipenilai}>PENILAI</Text>
                      <Text style={styles.textpegawaipenilai}>( PP )</Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                      <Text style={styles.textHari}>................</Text>
                      <Text style={styles.textpegawaipenilai}>PEGAWAI</Text>
                      <Text style={styles.textpegawaipenilai}>PENILAI</Text>
                      <Text style={styles.textpegawaipenilai}>( PP )</Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                      <Text style={styles.textHari}>................</Text>
                      <Text style={styles.textpegawaipenilai}>PEGAWAI</Text>
                      <Text style={styles.textpegawaipenilai}>PENILAI</Text>
                      <Text style={styles.textpegawaipenilai}>( PP )</Text>
                    </View>
                    <View style={styles.tableHariRow22}>
                      <Text style={styles.textHari}></Text>
                      <Text style={styles.textHari}>................</Text>
                      <Text style={styles.textpegawaipenilai}>PEGAWAI</Text>
                      <Text style={styles.textpegawaipenilai}>PENILAI</Text>
                      <Text style={styles.textpegawaipenilai}>( PP )</Text>
                    </View>
                    <View style={styles.tableHariRow22Last}>
                      <Text style={styles.textHari}></Text>
                      <Text style={styles.textHari}>................</Text>
                      <Text style={styles.textpegawaipenilai}>PEGAWAI</Text>
                      <Text style={styles.textpegawaipenilai}>PENILAI</Text>
                      <Text style={styles.textpegawaipenilai}>( PP )</Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpper}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpperLast}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View>
                    <View style={styles.tableUpperEmpty}>
                      <Text style={styles.tableCellHeader}>Disemak Oleh:</Text>
                      <Text style={styles.textpegawaipenilai}></Text>
                      <Text style={styles.textpegawaipenilai}>...............................................</Text>
                      <Text style={styles.textpegawaipenilaidalaman}>PEGAWAI PENILAIAN DALAMAN (PPD)</Text>
                    </View>
                  </View>

                </View>
                {/*<View style={pageNumberStyle}>
                <Text style={styles.pageNumberText}>{pageIndex + 1}</Text>
              </View>*/}
                <View>
                  <Text style={styles.autogeneratedtext}>[AUTOGENERATED BY N.A.I.S SYSTEM]</Text>
                </View>
              </View>
              {/*<View>
              <Text style={styles.generatebyNais}>[Generated by N.A.I.S System]</Text>
              </View>*/}
            </Page>
          );
        })}
      </Document>
    );
  };

  return (
    <button title='Download Attendance PDF' className="button-history4">
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`${attendance1.CoursePick} ${attendance1.ProgramCode} Student Period from ${attendance1.DurationFrom} until ${attendance1.DurationUntil}.pdf`}
      >
        {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
      </PDFDownloadLink>
    </button>
  );
};

export default BTECDownloadAttPDF;