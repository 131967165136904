import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn"
import AdminSignIn from "./pages/AdminSignIn"
import BtecSignIn from "./pages/BtecSignIn"
import MainMenu from "./pages/MainMenu"
import Dashboard from "./pages/Dashboard";
import Attendance from "./pages/Attendance";
import Students from "./pages/StudentProfile";
import Profile from "./pages/Profile";
import Logout from "./pages/Logout";
import Username from "./pages/Username";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";

import AdminRegister from "./pages/admregister/AdminRegister";
import AdminEditClass from "./pages/admregister/AdminEditClass";
import AdminEditStudent from "./pages/admregister/AdminEditStudent";
import AdminDashboard from "./pages/admregister/AdminDashboard";
import AdminRegisterNew from "./pages/admregister/AdminRegisterNew";
import AdminRegisterStudent from "./pages/admregister/AdminRegisterStudent";
import AdminProfile from "./pages/admregister/AdminProfile";
import AdminViewClass from "./pages/admregister/AdminViewClass";
import AdminLogOut from "./pages/admregister/AdminLogOut";
import AdminViewAttendance from "./pages/admregister/AdminViewAttendance";
import AdminEditStudentLog from "./pages/admregister/AdminEditStudentLog";

import Adminbtecsignin from "./pages/btecadmin/Adminbtecsignin";
import AdminbtecDashboard from "./pages/btecadmin/AdminbtecDashboard";
import AdminbtecUsername from "./pages/btecadmin/AdminbtecUsername";
import AdminbtecRegInst from "./pages/btecadmin/AdminbtecRegInst";
import AdminbtecRegAdmin from "./pages/btecadmin/AdminbtecRegAdmin";
import AdminbtecLogout from "./pages/btecadmin/AdminbtecLogout";
import AdminBtecRegStud from "./pages/btecadmin/AdminBtecRegStud";
import AdminbtecProfile from "./pages/btecadmin/AdminbtecProfile";
import AdminbtecEditClass from "./pages/btecadmin/AdminbtecEditClass";
import AdminbtecViewClass from "./pages/btecadmin/AdminbtecViewClass";
import AdminbtecViewAttendance from "./pages/btecadmin/AdminbtecViewAttendance";
import AdminbtecEditStudent from "./pages/btecadmin/AdminbtecEditStudent";
import AdminbtecEditStudentLog from "./pages/btecadmin/AdminbtecEditStudentLog";
import Btecenabledisable from "./pages/btecadmin/Btecenabledisable";

import BtecDashboard from "./pages/btecpages/BtecDashboard";
import BtecLogout from "./pages/btecpages/BtecLogout";
import Btecadminsidebar from "./pages/Btecadminsidebar";
import Btecsidebar from "./pages/Btecsidebar";
import Btecusername from "./pages/Btecusername";
import BtecProfile from "./pages/btecpages/BtecProfile";
import BtecStudentClass from "./pages/btecpages/BtecStudentClass";
import BtecGenerateAttendance from "./pages/btecpages/BtecGenerateAttendance";
import BtecAttendanceHistory from "./pages/btecpages/BtecAttendanceHistory";
import BtecAttendanceViewHistory from "./pages/btecpages/BtecAttendanceViewHistory";
import BtecQRAttendancepage from "./pages/btecpages/BtecQRAttendancepage";
//import ScanQRStudent from "./pages/ScanQRStudent";

//import Attendance1 from "./pages/attendance/Attendance1";
import CreateClass from "./pages/attendance/CreateClass";
import GenerateQR from "./pages/attendance/GenerateQR";
import QRattendancepage from "./pages/attendance/QRattendancepage";
import History from "./pages/attendance/History";
import AttendanceHistoryMain from "./pages/attendance/AttendanceHistoryMain";
import ViewAttendHistory from "./pages/attendance/ViewAttendHistory";
import StudentIndvSum from "./pages/attendance/StudentIndvSum";

import SetPDF from "./pages/generatepdf/SetPDF";

import AddStudent from "./pages/studentprofile/AddStudent";
import StudentClass from "./pages/studentprofile/StudentClass";

import NotFound from "./pages/NotFound";

function App() {
    return (
        <BrowserRouter>
        <Routes>
        {/*<Route path="/" element={<SignIn />} />*/}
        <Route path="/" element={<BtecSignIn />} />
        {/*<Route path="/administrator" element={<AdminSignIn />} />*/}
        <Route path="/administrator" element={<Adminbtecsignin />} />
        <Route path="/btec" element={<BtecSignIn />} />
        <Route path="/btec/admin" element={< Adminbtecsignin />} />
        {/*<Route path="/mainmenu" element={<MainMenu />} />
        <Route path="/dashboard" element={<Dashboard />} />*/}

        {/*<Route path="/Admin/Admin_Dashboard" element={<AdminDashboard />} />
        <Route path="/Admin/Admin_Register_Lecturer" element={<AdminRegister />} />
        <Route path="/Admin/Admin_Edit_Class" element={<AdminEditClass />} />
        <Route path="/Admin/Admin_Edit_Student" element={<AdminEditStudent />} />
        <Route path="/Admin/Register_New_Admin" element={<AdminRegisterNew  />} />
        <Route path="/Admin/Admin_Register_Student" element={<AdminRegisterStudent  />} />
        <Route path="/Admin/Admin_Profile" element={<AdminProfile  />} />
        <Route path="/Admin/Admin_View_Class" element={<AdminViewClass  />} />
        <Route path="/Admin/Admin_View_Attendance" element={<AdminViewAttendance />} />
        <Route path="/Admin/Admin_Edit_Student_Log" element={<AdminEditStudentLog />} />
        <Route path="/Admin/Admin_Logout" element={<AdminLogOut />} />*/}

        <Route path="/btec/adminbtec/Admin_BTEC_Dashboard" element={< AdminbtecDashboard />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Username" element={< AdminbtecUsername />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Sidebar" element={< Btecadminsidebar />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Register_Instructor" element={< AdminbtecRegInst />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Register_Admin" element={< AdminbtecRegAdmin />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Logout" element={< AdminbtecLogout />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Register_Student" element={< AdminBtecRegStud />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Profile" element={< AdminbtecProfile />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Class" element={< AdminbtecEditClass />} />
        <Route path="/btec/adminbtec/Admin_BTEC_View_Class" element={< AdminbtecViewClass />} />
        <Route path="/btec/adminbtec/Admin_BTEC_View_Attendance_History" element={< AdminbtecViewAttendance />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Edit_Student" element={< AdminbtecEditStudent />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Edit_Log" element={< AdminbtecEditStudentLog />} />
        <Route path="/btec/adminbtec/Admin_BTEC_Enabledisable_PDF_DLD" element={< Btecenabledisable />} />

        <Route path="/btec/BTEC_Dashboard" element={< BtecDashboard />} />
        <Route path="/btec/BTEC_Sidebar" element={< Btecsidebar />} />
        <Route path="/btec/BTEC_Username" element={< Btecusername />} />
        <Route path="/btec/BTEC_Profile" element={< BtecProfile />} />
        <Route path="/btec/BTEC_Class" element={< BtecStudentClass />} />
        <Route path="/btec/BTEC_Generate_Attendance" element={< BtecGenerateAttendance />} />
        <Route path="/btec/BtecQRAttendancepage" element={< BtecQRAttendancepage />} />
        <Route path="/btec/BTEC_Attendance_History" element={< BtecAttendanceHistory />} />
        <Route path="/btec/BTEC_View_Attendance_History" element={< BtecAttendanceViewHistory />} />
        <Route path="/btec/BTEC_Logout" element={< BtecLogout />} />   
        {/*<Route path="/btec/ScanQRStudent" element={< ScanQRStudent />} />*/}

        {/*<Route path="/attendance" element={<Attendance />} />*/}
        {/*<Route path="/attendance/attendance1" element={<Attendance1 />} />*/}
        {/*<Route path="/attendance/createclass" element={<CreateClass />} />
        <Route path="/attendance/generateqr" element={<GenerateQR />} />
        <Route path="/attendance/qrattendancepage" element={<QRattendancepage />} />
        <Route path="/attendance/history" element={<History />} />
        <Route path="/attendance/attendance_class_list" element={<AttendanceHistoryMain />} />
        <Route path="/attendance/view_attendance_history" element={<ViewAttendHistory />} />
        <Route path="/attendance/student_summary" element={<StudentIndvSum />} />*/}
        
        {/*<Route path="/students" element={<Students />} />
        <Route path="/students/addstudent" element={<AddStudent />} />
        <Route path="/students/studentclass" element={<StudentClass />} */}

        <Route path="/generatepdf/setpdf" element={<SetPDF />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/username" element={<Username />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
    );
}

export default App;