import React, { useState, useEffect } from 'react';
import Btecusername from '../Btecusername';
import Btecsidebar from '../Btecsidebar';
import { getDatabase, ref, remove, onValue, child, get,set} from "firebase/database";
import '../studentprofile/StudentClass.css';
import '../attendance/FacultyList';
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { MdDelete, MdAddCircle } from "react-icons/md";
import { FaEye } from "react-icons/fa";

const BtecStudentClass= () => {

	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);

    const [searchClass, setSearchClass] =  useState("");

    const [filteredSearchClass, setFilteredSearchClass] = useState([]);
	const [filteredTotalStudents, setfilteredTotalStudents] = useState([]);
	const [filteredClass, setFilteredClass] = useState([]);
	const [filteredStudentClass, setFilteredStudentClass] = useState([]);
	
	const [selectedRows3, setSelectedRows3] = useState([]);
	
	const [currentUser, setCurrentUser] = useState(null);
	const [searchStudentClass, setSearchStudentClass] =  useState("");
	const [filteredSearchStudent, setFilteredSearchStudent] = useState([]);
	const [studentNameSearch, setStudentNameSearch] = useState("");
	const [selectedDuration, setSelectedDuration] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [uniqueDurations, setUniqueDurations] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

          useEffect(() => {
            //to receive student data in realtime
                const db = getDatabase();
                const dbRef = ref(db, "BTECStudent");
        
                onValue(dbRef, (snapshot) => {
                  const firebaseData = snapshot.val();
                  if (firebaseData) { 
                    const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                      uid,
                      durationfrom: studentData.durationfrom,
                      durationuntil: studentData.durationuntil,
                      email: studentData.email,
                      faculty: studentData.faculty,
                      icnumber: studentData.icnumber,
                      imageUrl: studentData.imageUrl,
                      level: studentData.level,
                      name: studentData.name,
                      skmprogram: studentData.skmprogram,
                      skmprogramcode: studentData.skmprogramcode,
                      student: studentData.student,
                      //uid: studentData.uid,
                    }));
                    setData(dataArray);
                  }
                });
              }, []);

    	//for finding class database
	  useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
                    ProgramName: BTECClassitem.ProgramName,

				  };
				});
				});
			  });
			});
			setData2(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

    useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
                    uid: studentUID,
                    DurationUntil: BTECClassListitem.durationUntil,
                    Student: BTECClassListitem.student,
					StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData3(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

	useEffect(() => {
		const filteredClass = data2.filter((classItem) => {
            //console.log("classItem.Instructor: ", classItem.Instructor);
            //console.log("currentUser.Instructor: ", currentUser.Instructor);
			//console.log("data2: ", data2);
			return classItem.Instructor === currentUser.Instructor;
		});
	  
		setFilteredClass(filteredClass);
		
		//console.log("filteredClass: ", filteredClass);
	  
		const filteredTotalStudents = filteredClass.map((classItem) => { //data2
		  const totalStudents = data3.filter((classStudentItem) => //data3
		  classStudentItem.Instructor === classItem.Instructor 
		  && classStudentItem.ProgramCode === classItem.ProgramCode
		  && classStudentItem.DurationFrom === classItem.DurationFrom
		  && classStudentItem.DurationUntil === classItem.DurationUntil);
		  return {
			Instructor: classItem.Instructor,
			ProgramCode: classItem.ProgramCode,
			DurationFrom: classItem.DurationFrom,
			DurationUntil: classItem.DurationUntil,
			totalStudents: totalStudents.length
			
		  };
		});
		//console.log(filteredTotalStudents); // Display the filteredTotalStudents array in the console
		setfilteredTotalStudents(filteredTotalStudents);

	  }, [currentUser, data2, data3]);

	useEffect(() => {
		if (currentUser) {
			//console.log("here1");
			const db = getDatabase();
			const userRef = ref(db, `BTECInstructor/${currentUser.uid}`);
			//console.log("here2");
			get(userRef)
			  .then(snapshot => {
				const userData = snapshot.val();
				setCurrentUser({
				  ...currentUser,
				  //instructorName: userData.instructorName
				  Instructor: userData.instructorName
				});
				//console.log("here3");
				//console.log("here4:");
			  })
			  .catch(error => {
				console.error(error);
			  });
		  }

	}, [currentUser]);

	  useEffect(() => {
		const selectedData3 = data3.filter((studentClassItem) =>
		  selectedRows3.some(
			(item) =>
			  item.Instructor === studentClassItem.Instructor && 
			  item.Level === studentClassItem.Level && 
			  item.ProgramCode === studentClassItem.ProgramCode &&
			  item.DurationFrom === studentClassItem.DurationFrom &&
			  item.Student === studentClassItem.Student
			  //item.year === studentClassItem.year
		  )
		);
		console.log("Selected Data3:", selectedData3);
		
	  }, [selectedRows3, data3]);

	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
	
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

	  const findStudentClass = (classItem) => {
		const filteredStudentClass = data3.filter((studentClassItem) => {
			//console.log("classitem: ", classItem);
			setSelectedRows3([]);
		  return (
			studentClassItem.DurationFrom === classItem.DurationFrom &&
			studentClassItem.DurationUntil === classItem.DurationUntil &&
			studentClassItem.ProgramCode === classItem.ProgramCode &&
			//studentClassItem.ProgramName === classItem.ProgramName &&
			studentClassItem.Instructor === classItem.Instructor 
			//&& studentClassItem.semester === classItem.semester
		  );
		});
		//console.log("Filtered Student Class:", filteredStudentClass);
		setFilteredStudentClass(filteredStudentClass);
	  };

	  //choose unique identifier when selecting class
	  const handleCheckboxChange2 = (Student, ProgramCode, DurationFrom, DurationUntil, Instructor, Level, uid) => {
		const selectedItem = { Student, ProgramCode, DurationFrom, DurationUntil, Instructor, Level, uid };
		console.log("data passed: ", Student, ProgramCode, DurationFrom, DurationUntil, Instructor, Level, uid );
		
		if (selectedRows3.some((item) => item.Student === Student && 
		item.ProgramCode === ProgramCode &&
		 item.DurationFrom === DurationFrom &&
		 item.DurationUntil === DurationUntil &&
		 item.Instructor === Instructor &&
		item.Level === Level &&
		item.uid === uid)) {
		  // If the studentID and subjectCode combination is already in the selectedRows, remove it
		  setSelectedRows3(selectedRows3.filter((item) => item.Student !== Student
		   || item.ProgramCode !== ProgramCode
		    || item.DurationFrom !== DurationFrom
			|| item.DurationUntil !== DurationUntil
			|| item.Instructor !== Instructor
			|| item.Level !== Level
			|| item.uid !== uid));
		} else {
		  
		  // If the studentID and subjectCode combination is not in the selectedRows, add it
		  setSelectedRows3([...selectedRows3, selectedItem]);
		  console.log('selectedrows3: ', selectedRows3);
		  //console.log('selectedrows3 student uid: ', studentID);
		  console.log('selectedrows3 student id: ', Student);
		  console.log('selectedItem: ', selectedItem);
		}
	  };

	const deleteSelectedRows = (classItem) => {
		console.log("studentid: ", classItem.Student);
		console.log("ProgramCode: ", classItem.ProgramCode);
		console.log("DurationFrom: ", classItem.DurationFrom);
		console.log("DurationUntil: ", classItem.DurationUntil);
		console.log("Instructor: ", classItem.Instructor);
		console.log("Level: ", classItem.Level);
		const confirmDelete = window.confirm("Delete this student?");
		const db6 = getDatabase();
	  
		if (confirmDelete) {
		selectedRows3.forEach((item) => {
		  const { Student, ProgramCode, DurationFrom, DurationUntil, Instructor, Level, uid} = item;
		  
		  const refToDelete = ref(db6, `BTECClassStudentList/${Instructor}/${Level}/${ProgramCode}/${DurationFrom}/${uid}`);
		  console.log("studentid: ", Student);
		  console.log("ProgramCode: ", ProgramCode);
		  console.log("DurationFrom: ", DurationFrom);
		  console.log("DurationUntil: ", DurationUntil);
		  console.log("Instructor: ", Instructor);
		  console.log("Level: ", Level);
	  
		  remove(refToDelete)
			.then(() => {
				alert("Student have been removed from class.");
			  console.log("Data deleted successfully.");
			  console.log(`Data for student: ${Student}, ProgramCOde: ${ProgramCode} deleted successfully.${DurationFrom} - ${DurationUntil} ${Level}`);
			  findStudentClass(classItem);
			})
			.catch((error) => {
				alert("Error on removing the Student from the class.");
			  console.error("Error deleting data:", error);
			  console.error(`Error deleting data for studentID: ${Student}, ProgramCode: ${ProgramCode}`);
			});
		});
	}
	  };

	  const clearTable = () => {
		setSelectedRows3([]);
		setFilteredStudentClass([]);
		setFilteredSearchClass([]);
		setFilteredSearchStudent([]);
		setStudentNameSearch("");
		setSearchStudentClass("");

		//console.log("filteredstudent: ");
  
	  };

	  const clearTable2 = () => {
		setSelectedRows3([]);
		setFilteredStudentClass([]);
		//setFilteredSearchClass([]);
		setStudentNameSearch("");
		setFilteredSearchStudent([]);
		setSearchStudentClass("");

		//console.log("filteredstudent: ");
  
	  };

	  const SearchClass = (e) => {
		e.preventDefault();
		// Check if the search input is empty
		if (!searchClass) {
		alert('Please enter the Subject Name/Code');
		return; // Return early without making any changes
		}
  
		// Perform the search logic based on the selected student ID
		const filteredSearchClass = filteredClass.filter((classsearch) => {
		const SearchClassName = classsearch.SubjectName.toLowerCase();
		const SearchClassCode = classsearch.SubjectCode.toLowerCase();
		//const SearchClassLecturer = classsearch.lecturerName2.toLowerCase();
		const searchInput = searchClass.toLowerCase();
  
		return SearchClassName.includes(searchInput) || SearchClassCode.includes(searchInput) /*|| SearchClassLecturer.includes(searchInput)*/;
		});
  
		// Update the foundStudents state with the filtered students array
		setFilteredSearchClass(filteredSearchClass);
  
		if (filteredSearchClass.length === 0) {
			setFilteredClass([]);
			setFilteredSearchClass([]);
		alert('The Subject Name/Code does not exists.');
		
		}
		console.log(`filtered Class: `, filteredSearchClass);
		};

		const sortedRows = [...filteredClass].sort((a, b) => {
			// Sort by year
			if (b.year !== a.year) {
			  return b.year - a.year;
			}
			// If years are the same, sort by month in descending order
			return b.month - a.month;
		  });

		  const sortedRows2 = [...filteredData].sort((a, b) => {
			// Sort by year
			if (b.year !== a.year) {
			  return b.year - a.year;
			}
			// If years are the same, sort by month in descending order
			return b.month - a.month;
		  });
	
		  const indexOfLastRow = currentPage * rowsPerPage;
		  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
		  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
		  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
	
		  const indexOfLastRow2 = currentPage2 * rowsPerPage;
		  const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
		  const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
		  const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);	  
	
			const handlePageChange = (pageNumber) => {
			setCurrentPage(pageNumber);
			};
	
			const handlePageChange2 = (pageNumber) => {
				setCurrentPage2(pageNumber);
			};

			const SearchStudent = (e,classitem) => {
				e.preventDefault();
				findStudentClass(classitem);
				// Check if the search input is empty
				if (!searchStudentClass) {
				alert('Please enter the Subject Name/Code/Lecturer');
				return; // Return early without making any changes
				}
		  
				// Perform the search logic based on the selected student ID
				const filteredSearchStudent = filteredStudentClass.filter((studentsearch) => {
				const SearchStudentName = studentsearch.StudentName.toLowerCase(); //search any possible result that have the letters/numbers
				const SearchStudentID = studentsearch.Student.toLowerCase();
				const searchInput = searchStudentClass.toLowerCase();
		  
				return SearchStudentName.includes(searchInput) || SearchStudentID.includes(searchInput);
				});
		  
				// Update the foundStudents state with the filtered students array
				setFilteredSearchStudent(filteredSearchStudent);
				console.log(`filtered Class length: `, filteredSearchStudent.length);
		  
				if (filteredSearchStudent.length === 0) {
				alert('The Student Name/ID does not exists.');
				}
				else if (filteredSearchStudent.length > 0){
					alert('Student Found!');
				  }
				console.log(`filtered Class: `, filteredSearchStudent);
			  };

			  const SearchStudentIndv = (e,classitem) => { //handle the function to search specific student using the student name/id
				e.preventDefault();
				console.log("classitem data: ", classitem)
				
				if (!studentNameSearch) {
				alert('Please enter the Student Name ID Number');
				return; // Return early without making any changes
				}

				const filteredSearchStudent = data
				.filter((studentsearch) => {
					//const SearchStudentName = studentsearch.name.toLowerCase(); 
					const SearchStudentID = studentsearch.student.toLowerCase();
					const searchInput = studentNameSearch.toLowerCase();
			
					return /*SearchStudentName.includes(searchInput) ||*/ SearchStudentID.includes(searchInput);
				})
				.filter((studentsearch) => {
					console.log("studentsearch: ",  studentsearch)
					//console.log("studentsearch.skmprogramcode: ",  studentsearch.skmprogramcode)
					//console.log("studentsearch.durationfrom: ",  studentsearch.durationfrom)
					return studentsearch.skmprogramcode === classitem.ProgramCode;
					//&& studentsearch.durationfrom === classitem.DurationFrom;
				});
		  
				// Update the foundStudents state with the filtered students array
				setFilteredSearchStudent(filteredSearchStudent);
				console.log("filteredSearchStudent: ", filteredSearchStudent)
		  
				if (filteredSearchStudent.length === 0) {
				alert('The Student Name/ID does not exists.');
				}
				else if (filteredSearchStudent.length > 0){
					alert('Student Found!');
				  }
				console.log(`filtered Class: `, filteredSearchStudent);
			  };

			      const addStudenttoClass = async (name, student, ProgramCode, ProgramName, Instructor, DurationFrom, DurationUntil, Level, uid) => { //save the selected student to class
					/*console.log("name: ", name);
					console.log("student: ", student);
					console.log("ProgramCode: ", ProgramCode);
					console.log("ProgramName: ", ProgramName);
					console.log("Instructor: ", Instructor);
					console.log("DurationFrom: ", DurationFrom);
					console.log("DurationUntil: ", DurationUntil);
					console.log("Level: ", Level);
					console.log("uid: ", uid);*/
			const confirmAdd = window.confirm("Add this student into this class?"); //confirmation windows
			
			if (confirmAdd) {
			const db3 = getDatabase();
				const usersRef = ref(db3, "BTECClassStudentList");
					const InstructorRef = child(usersRef, Instructor);
					const LevelRef = child(InstructorRef,Level);
			const ProgramCodeRef = child(LevelRef, ProgramCode);
			const DurationFromRef = child(ProgramCodeRef, DurationFrom);
			//const studentuid2 = child(DurationFromRef, uid);

			/*const existingStudent2 = data.find((student) => student.student === studentID);
			const studentuid2 = existingStudent2.uid;*/

			//await set(child(lectRef, studentID), {
				//await set(child(lectRef, studentuid2), {
				await set(child(DurationFromRef, uid), {
				Instructor: Instructor,
				durationFrom: DurationFrom,
				durationUntil: DurationUntil,
				programcode: ProgramCode,
				student: student,
				studentname: name,
				uid: uid,
			});
			alert("Student have been added!.")
			console.log("Student have been added!");
			}
			};

			useEffect(() => {
				const filteredClass = data2.filter((classItem) => {
				  return classItem.Instructor === currentUser.Instructor;
				});
			  
				// Extract unique durations in the format "DurationFrom - DurationUntil"
				const durations = filteredClass.map((classItem) => `${classItem.DurationFrom} - ${classItem.DurationUntil}`);
			  
				// Remove duplicates
				const uniqueDurations = [...new Set(durations)];
				
				setUniqueDurations(uniqueDurations);
			  }, [data2, currentUser]);

			  const SearchClassbyDuration = (e) => {
				e.preventDefault();
				if (selectedDuration) {
				  const [from, until] = selectedDuration.split(' - ');
				  const filteredData = data2.filter(classitem => 
					classitem.DurationFrom === from && classitem.DurationUntil === until
				  );
				  setFilteredData(filteredData);
				  alert('Class based on duration has been found!');
				}
				else {
					alert('No information found!');
				}
			  };

    return (

		<div /*class="body-addstudent"*/>
			<Btecsidebar> {/*show/call sidebar*/}
			<Btecusername /> {/*show/call username*/}
			<p><br/><br/></p>
            <div>
			<h1 class="h1-addstudent">Class List</h1>
            </div>
            <div /*className="addstudent-container"*/> 
            <p><br/><br/><br/></p>

				<div>
  				<div>

				<p class="p-addstudent4">My Class List</p>
				<div className="adminregister-search-container">
				<form title='Enter your Subject Name/Code' onSubmit={SearchClass}>
				<label>Search:&emsp;</label>
				<div className="input-icon-container">
				<div className='container'>
				<select
					title="Filter by Duration of Courses"
					className="select-size-generateqr"
					onChange={(e) => setSelectedDuration(e.target.value)}
					>
					<option value="">Select Duration</option>
					{uniqueDurations.map((duration, index) => (
						<option key={index} value={duration}>{duration}</option>
					))}
					</select>
					    {/* Filter Button */}
						<FcSearch className="search-icon22" onClick={SearchClassbyDuration} title="Search Class"/>
    					{/*<button onClick={SearchClassbyDuration}>Filter</button>*/}
						</div>
				</div>
				</form>
				</div>

				<p><br/><br/><br/></p>
				
				<div class="table-container-studentclass">
					<table>
        <thead class="th-addstudent">
          <tr>

		  <th><b>Program</b></th>
            <th><b>Studies Duration</b></th>
			<th><b>Total Student</b></th>
			<th><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
		{currentRows2.length > 0 ?(
					currentRows2.map((classitem, index) => {
							const totalStudents = filteredTotalStudents.find((item) => 
							item.ProgramCode === classitem.ProgramCode
							&& item.Instructor === classitem.Instructor
							&& item.DurationFrom === classitem.DurationFrom
							&& item.DurationUntil === classitem.DurationUntil);
							return(
						<tr key={`${classitem.ProgramCode}_${index}`}>
							<td className="editclass-td"><b>{classitem.ProgramCode} {classitem.ProgramName}</b></td>
							<td className="editclass-td"><b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b></td>
							<td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
					<td className="editclass-td">
					
					<div className='container'>
					<Popup trigger={<button title = 'View/Delete Student in class' className="button-admin-view-attendance-new8" /*className="button-studentclass3"*/>&nbsp;<FaEye className='admin-view-attendance-icon3'/><b>VIEW / DELETE STUDENT</b></button>} modal nested>
					{close => (
						<div className="modal">
						<button title = 'Close Menu' className="button-studentclass4" onClick={() => { close(); clearTable(); }}>&times;</button>
						<h2 className="h2-studentclass">Student List</h2>
						<p><br/></p>

						<p className="p-history2">Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
						<p className="p-history2">Instructor: <b>{classitem.Instructor}</b> </p>
						<p className="p-history2">Duration: <b>{classitem.DurationFrom}</b> Until <b>{classitem.DurationUntil}</b> </p>
						<p style={{fontSize: "13px", color: "blue"}}>&emsp;*Press the <b>VIEW STUDENT LIST</b> to view the student list.</p>

						<p><br/></p>

						<button title = 'Click to view the student list in this class' className="button-admin-view-attendance-new8" /*className="button-studentclass"*/ onClick={() => findStudentClass(classitem)}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;VIEW STUDENT LIST</button>
						<p><br/><br/></p>

						<p><br/></p>
						<div class="table-container-editclass2">
          				<table className='table-editstudent2'>
            			<thead className="th-addstudent">
							<tr>
								<th><b>Name</b></th>
								<th><b>Student ID</b></th>
								<th><b>Duration of Studies</b></th>
								<th><b>Action</b></th>
							</tr>
							</thead>
							<tbody>
							{filteredSearchStudent.length > 0 ? (
								filteredSearchStudent
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((studentClassItem) => (
									<tr key={studentClassItem.Student} className={selectedRows3.includes(studentClassItem.Student) ? 'selected' : ''}>
									{/*<td className="editclass-td"><b>{studentClassItem.ProgramCode}</b></td>*/}
									<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
									<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
									<td className="editclass-td"><b>{studentClassItem.DurationFrom}</b> Until <b>{studentClassItem.DurationUntil}</b></td>
									<td className="editclass-td">
									<input
									className="checkbox-size-addstudent"
									type="checkbox"
									onChange={() => handleCheckboxChange2(studentClassItem.Student, studentClassItem.ProgramCode, studentClassItem.DurationFrom, studentClassItem.DurationUntil, studentClassItem.Instructor, studentClassItem.Level, studentClassItem.uid)}
									checked={selectedRows3.some((item) => item.Student === studentClassItem.Student && item.ProgramCode === studentClassItem.ProgramCode && item.DurationFrom === studentClassItem.DurationFrom && item.Instructor === studentClassItem.Instructor && item.Level === studentClassItem.Level && item.uid === studentClassItem.uid)}
									/>
	
									</td>
								</tr>
								))
							) : filteredStudentClass.length > 0 ? (
								filteredStudentClass
								.sort((a, b) => a.StudentName.localeCompare(b.StudentName))
								.map((studentClassItem) => (
								<tr key={studentClassItem.Student} className={selectedRows3.includes(studentClassItem.Student) ? 'selected' : ''}>
									{/*<td className="editclass-td"><b>{studentClassItem.ProgramCode}</b></td>*/}
									<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
									<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
									<td className="editclass-td"><b>{studentClassItem.DurationFrom}</b> Until <b>{studentClassItem.DurationUntil}</b></td>
									<td className="editclass-td">
									<input
									className="checkbox-size-addstudent"
									type="checkbox"
									onChange={() => handleCheckboxChange2(studentClassItem.Student, studentClassItem.ProgramCode, studentClassItem.DurationFrom, studentClassItem.DurationUntil, studentClassItem.Instructor, studentClassItem.Level, studentClassItem.uid)}
									checked={selectedRows3.some((item) => item.Student === studentClassItem.Student && item.ProgramCode === studentClassItem.ProgramCode && item.DurationFrom === studentClassItem.DurationFrom && item.Instructor === studentClassItem.Instructor && item.Level === studentClassItem.Level && item.uid === studentClassItem.uid)}
									/>
	
									</td>
								</tr>
								))
							) : (
								<tr>
								<td colSpan="6">No Student found.</td>
								</tr>
							)}
							</tbody>
						</table>
						</div>
						<p><br></br><br></br></p>
						<div>
						{/*<div style={{display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center"}}>*/}
						{/*<button title= 'Delete the Student from class' class="button-studentclass5" onClick={() => deleteSelectedRows(classitem)}><MdDelete className='studentclass-icon'/>DELETE STUDENT</button>*/}
						<div className='container'>
						<button title= 'Delete the Student from class' class="button-admin-view-attendance-new8" onClick={() => deleteSelectedRows(classitem)}><MdDelete className='admin-view-attendance-icon3'/><b>DELETE STUDENT</b></button>
						</div>
						</div>
						<p><br></br></p>
						<p className="p-studentclass-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
						<p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
						</div>
					)}
					</Popup>
					
					<Popup trigger={<button title = 'Add Student in class' className="button-admin-view-attendance-new8" /*className="button-studentclass3"*/>&nbsp;<FaEye className='admin-view-attendance-icon3'/><b>&nbsp;ADD STUDENT</b></button>} modal nested>
					{close => (
						<div className="modal">
						<button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
						<p><br /><br /></p>
						<h2 className="h2-studentclass">Add Student</h2>
						<p><br/></p>

						<p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
						<p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
						<p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom}</b> Until <b>{classitem.DurationUntil}</b> </p>


        <p><br/><br/></p>
        <div className="adminregister-search-container">
				<form onSubmit={(e) => SearchStudentIndv(e, classitem)}>
				<label>Search:&emsp;</label>
        <div className="input-icon-container">
				<input 
				className="input-editclass2"
        title='Enter Student Name/ID to View Student details'
				type="text"
				placeholder="Enter Student Name/ID"
				value={studentNameSearch}
				onChange={(e) => setStudentNameSearch(e.target.value)}
				>	
				</input>
        <FcSearch className="search-icon" onClick={(e) => SearchStudentIndv(e, classitem)} title="Search"/>
				</div>
				</form>
				</div>
        <p><br/><br/><br/></p>
        
        <table className="table-editstudent">
          <thead className="th-addstudent">
          <tr>
            <th>Name</th>
            <th>Student ID</th>
            <th>Programme</th>
			<th>Action</th>
          </tr>
          </thead>
          <tbody>
          {filteredSearchStudent.length > 0 ? (
            filteredSearchStudent.map((studentItem) => (
            <tr key={studentItem.student}>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.name}</td>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.student}</td>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.skmprogramcode} {studentItem.skmprogram}</td>
              <td className="editstudent-td">

              <button  className='button-GenQR6' /*className="button-studentclass3" */
              onClick={() => { addStudenttoClass(studentItem.name, studentItem.student, 
              classitem.ProgramCode, classitem.ProgramName, classitem.Instructor, classitem.DurationFrom, classitem.DurationUntil, classitem.Level, studentItem.uid); }}>
              <MdAddCircle className='admin-view-attendance-icon3'/>ADD</button>

              </td>
            </tr>
            ))
          ) : (
            <tr>
            <td colSpan="6">No Student found.</td>
            </tr>
          )}
          </tbody>
        </table>

        </div>
      )}
					</Popup>
					</div>
							</td>
					</tr>
					);
					})
					) : currentRows.length > 0 ?(
					currentRows.map((classitem, index) => {
							const totalStudents = filteredTotalStudents.find((item) => 
							item.ProgramCode === classitem.ProgramCode
							&& item.Instructor === classitem.Instructor
							&& item.DurationFrom === classitem.DurationFrom
							&& item.DurationUntil === classitem.DurationUntil);
							return(
						<tr key={`${classitem.ProgramCode}_${index}`}>
							<td className="editclass-td"><b>{classitem.ProgramCode} {classitem.ProgramName}</b></td>
							<td className="editclass-td"><b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b></td>
							<td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
					<td className="editclass-td">
					
					<div className='container'>
					<Popup trigger={<button title = 'View/Delete Student in class' className="button-admin-view-attendance-new8" /*className="button-studentclass3"*/>&nbsp;<FaEye className='admin-view-attendance-icon3'/><b>VIEW / DELETE STUDENT</b></button>} modal nested>
					{close => (
						<div className="modal">
						<button title = 'Close Menu' className="button-studentclass4" onClick={() => { close(); clearTable(); }}>&times;</button>
						<h2 className="h2-studentclass">Student List</h2>
						<p><br/></p>

						<p className="p-history2">Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
						<p className="p-history2">Instructor: <b>{classitem.Instructor}</b> </p>
						<p className="p-history2">Duration: <b>{classitem.DurationFrom}</b> Until <b>{classitem.DurationUntil}</b> </p>

						<p><br/></p>

						<button title = 'Click to view the student list in this class' className="button-admin-view-attendance-new8" /*className="button-studentclass"*/ onClick={() => findStudentClass(classitem)}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;VIEW STUDENT LIST</button>
						<p><br/><br/></p>

						<p><br/></p>
						<div class="table-container-editclass2">
          				<table className='table-editstudent2'>
            			<thead className="th-addstudent">
							<tr>
								<th><b>Name</b></th>
								<th><b>Student ID</b></th>
								<th><b>Duration of Studies</b></th>
								<th><b>Action</b></th>
							</tr>
							</thead>
							<tbody>
							{filteredSearchStudent.length > 0 ? (
								filteredSearchStudent
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((studentClassItem) => (
									<tr key={studentClassItem.Student} className={selectedRows3.includes(studentClassItem.Student) ? 'selected' : ''}>
									{/*<td className="editclass-td"><b>{studentClassItem.ProgramCode}</b></td>*/}
									<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
									<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
									<td className="editclass-td"><b>{studentClassItem.DurationFrom}</b> Until <b>{studentClassItem.DurationUntil}</b></td>
									<td className="editclass-td">
									<input
									className="checkbox-size-addstudent"
									type="checkbox"
									onChange={() => handleCheckboxChange2(studentClassItem.Student, studentClassItem.ProgramCode, studentClassItem.DurationFrom, studentClassItem.DurationUntil, studentClassItem.Instructor, studentClassItem.Level, studentClassItem.uid)}
									checked={selectedRows3.some((item) => item.Student === studentClassItem.Student && item.ProgramCode === studentClassItem.ProgramCode && item.DurationFrom === studentClassItem.DurationFrom && item.Instructor === studentClassItem.Instructor && item.Level === studentClassItem.Level && item.uid === studentClassItem.uid)}
									/>
	
									</td>
								</tr>
								))
							) : filteredStudentClass.length > 0 ? (
								filteredStudentClass
								.sort((a, b) => a.StudentName.localeCompare(b.StudentName))
								.map((studentClassItem) => (
								<tr key={studentClassItem.Student} className={selectedRows3.includes(studentClassItem.Student) ? 'selected' : ''}>
									{/*<td className="editclass-td"><b>{studentClassItem.ProgramCode}</b></td>*/}
									<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
									<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
									<td className="editclass-td"><b>{studentClassItem.DurationFrom}</b> Until <b>{studentClassItem.DurationUntil}</b></td>
									<td className="editclass-td">
									<input
									className="checkbox-size-addstudent"
									type="checkbox"
									onChange={() => handleCheckboxChange2(studentClassItem.Student, studentClassItem.ProgramCode, studentClassItem.DurationFrom, studentClassItem.DurationUntil, studentClassItem.Instructor, studentClassItem.Level, studentClassItem.uid)}
									checked={selectedRows3.some((item) => item.Student === studentClassItem.Student && item.ProgramCode === studentClassItem.ProgramCode && item.DurationFrom === studentClassItem.DurationFrom && item.Instructor === studentClassItem.Instructor && item.Level === studentClassItem.Level && item.uid === studentClassItem.uid)}
									/>
	
									</td>
								</tr>
								))
							) : (
								<tr>
								<td colSpan="6">No Student found.</td>
								</tr>
							)}
							</tbody>
						</table>
						</div>
						<p><br></br><br></br></p>
						<div>
						{/*<div style={{display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center"}}>*/}
						{/*<button title= 'Delete the Student from class' class="button-studentclass5" onClick={() => deleteSelectedRows(classitem)}><MdDelete className='studentclass-icon'/>DELETE STUDENT</button>*/}
						<div className='container'>
						<button title= 'Delete the Student from class' class="button-admin-view-attendance-new8" onClick={() => deleteSelectedRows(classitem)}><MdDelete className='admin-view-attendance-icon3'/><b>DELETE STUDENT</b></button>
						</div>
						</div>
						<p><br></br></p>
						<p className="p-studentclass-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
						<p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
						</div>
					)}
					</Popup>
					
					<Popup trigger={<button title = 'Add Student in class' className="button-admin-view-attendance-new8" /*className="button-studentclass3"*/>&nbsp;<FaEye className='admin-view-attendance-icon3'/><b>&nbsp;ADD STUDENT</b></button>} modal nested>
					{close => (
						<div className="modal">
						<button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
						<p><br /><br /></p>
						<h2 className="h2-studentclass">Add Student</h2>
						<p><br/></p>

						<p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
						<p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
						<p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom}</b> Until <b>{classitem.DurationUntil}</b> </p>


        <p><br/><br/></p>
        <div className="adminregister-search-container">
				<form onSubmit={(e) => SearchStudentIndv(e, classitem)}>
				<label>Search:&emsp;</label>
        <div className="input-icon-container">
				<input 
				className="input-editclass2"
        title='Enter Student Name/ID to View Student details'
				type="text"
				placeholder="Enter Student Name/ID"
				value={studentNameSearch}
				onChange={(e) => setStudentNameSearch(e.target.value)}
				>	
				</input>
        <FcSearch className="search-icon" onClick={(e) => SearchStudentIndv(e, classitem)} title="Search"/>
				</div>
				</form>
				</div>
        <p><br/><br/><br/></p>
        
        <table className="table-editstudent">
          <thead className="th-addstudent">
          <tr>
            <th>Name</th>
            <th>Student ID</th>
            <th>Programme</th>
			<th>Action</th>
          </tr>
          </thead>
          <tbody>
          {filteredSearchStudent.length > 0 ? (
            filteredSearchStudent.map((studentItem) => (
            <tr key={studentItem.student}>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.name}</td>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.student}</td>
              <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.skmprogramcode} {studentItem.skmprogram}</td>
              <td className="editstudent-td">

              <button  className='button-GenQR6' /*className="button-studentclass3" */
              onClick={() => { addStudenttoClass(studentItem.name, studentItem.student, 
              classitem.ProgramCode, classitem.ProgramName, classitem.Instructor, classitem.DurationFrom, classitem.DurationUntil, classitem.Level, studentItem.uid); }}>
              <MdAddCircle className='admin-view-attendance-icon3'/>ADD</button>

              </td>
            </tr>
            ))
          ) : (
            <tr>
            <td colSpan="6">No Student found.</td>
            </tr>
          )}
          </tbody>
        </table>

        </div>
      )}
					</Popup>
					</div>
							</td>
					</tr>
					);
					})
					) : (
				<tr>
				<td colSpan="5">No Class found.</td>
				</tr>
				)}
				</tbody>
				</table>
				<br/>
				<p style={{fontSize: "13px", color: "blue"}}><b>VIEW/DELETE STUDENT:</b> To <b>View</b> the student and <b>Delete</b> and <b>Week</b> to view the specific class history.</p>
				<p style={{fontSize: "13px", color: "blue"}}><b>ADD STUDENT:</b> To <b>Search</b> and <b>Add</b> Student to the class picked (the same row of the class selected).</p>


				</div>
				<p><br/></p>
				{currentRows.length > 0 && totalPages > 1 && currentRows2.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange(pageNumber)}
						className={`button-addStudent5 ${currentPage === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows2.length > 0 && totalPages2 > 1 && (
					<div className="pagination">
					{Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange2(pageNumber)}
						className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				</div>
				</div>
				</div>
				
				</Btecsidebar>
				</div>
				);
				};
				
	export default BtecStudentClass;