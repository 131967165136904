import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, child, set, remove} from "firebase/database";
import './QRattendancepage.css';
import QRCode from "react-qr-code";
import "react-datepicker/dist/react-datepicker.css";

const QRattendancepage = () => {
  //const props = { placeholder: 'Please Select...' };
  const [startDate, setStartDate] = useState(new Date());
  const [FilteredAttendStudent, SetFilteredAttendStudent] = useState([]);
 
  const [data, setData] = useState([]);
  
  const queryParams = new URLSearchParams(window.location.search); //fetch data from GenerateQR page
  const qrCodeValue2 = queryParams.get('qrValue');
  const [qrCodeValue, setQRCodeValue] = useState("");

  const passedDate2 = queryParams.get('Date');
  const [passedDate, setPassedDate] = useState("");

  const passedLecturer2 = queryParams.get('Lecturer');
  const [passedLecturer, setPassedLecturer] = useState("");

  const passedSubject2 = queryParams.get('Subject');
  const [passedSubject, setPassedSubject] = useState("");

  const passedVenue2 = queryParams.get('Venue');
  const [passedVenue, setPassedVenue] = useState("");

  useEffect(() => {

    const generateUniqueKey = () => {
      const random = Math.random().toString(36).substring(2, 10); // Generate a random string
      return `${random}`;
    };

    const updateQRCodeValue = async () => {
      const uniqueKey = generateUniqueKey();
      //const formattedStartDate = format(startDate, 'dd-MM-yyyy');
      const newQRCodeValue = `${qrCodeValue2},${uniqueKey}`;
      setQRCodeValue(newQRCodeValue);

      try {
        const db = getDatabase();
        const qrRef = ref(db, "QR");
        const subjectRef = child(qrRef, passedSubject2);
        const venueRef = child(subjectRef, passedVenue2);
        const dateRef = child(venueRef, passedDate2);
        const lecturerRef = child(dateRef, passedLecturer2);
        //const uniqueKeyRef = child(lecturerRef, uniqueKey);
        
          //input into firebase 'Faculty'
        await set(lecturerRef, {
        SubjectName: passedSubject2,
        Venue: passedVenue2,
        ClassDate: passedDate2,
        Lecturer: passedLecturer2,
        Uniquekey: uniqueKey,
        });
        //alert("QR have beeen adddded.")
        //console.log("Subject have been added!");
  
      } catch (error) {
        //alert("Something went wrong.")
        console.log(error.message);
      }
    };

    setPassedDate(passedDate2);
    setPassedLecturer(passedLecturer2);
    setPassedSubject(passedSubject2);
    setPassedVenue(passedVenue2);

    // Set the initial QR code value
    updateQRCodeValue();

    const timer = setInterval(updateQRCodeValue, 4000); //update QR value each 30 seconds
    
    // Cleanup the timer on component unmount
    return () => {
      clearInterval(timer);

          // Get the unique key from the current qrCodeValue
    const uniqueKey = qrCodeValue.split(",")[1];
    // Call the function to delete QR code data passing the unique key
    deleteQRCodeData(uniqueKey, passedSubject2, passedVenue2, passedDate2, passedLecturer2);

    };
  }, [startDate,qrCodeValue2,passedDate2,passedLecturer2,passedSubject2,passedVenue2]);

  //console.log(qrCodeValue);
  //console.log(passedDate);
  //console.log(passedLecturer);

  const deleteQRCodeData = async (uniqueKey, subname, classvenue, dateclass, classlec) => {
    try {
      const db = getDatabase();
      const qrRef = ref(db, "QR");
      const subjectRef = child(qrRef, passedSubject2);
      const venueRef = child(subjectRef, passedVenue2);
      const dateRef = child(venueRef, passedDate2);
      const lecturerRef = child(dateRef, passedLecturer2);

      const refToDelete = ref(db, `QR/${subname}/${classvenue}/${dateclass}/${classlec}`);
  
      // Remove the data from the database
      remove(refToDelete);
      //await set(lecturerRef, null);
  
      console.log("QR code data removed successfully.");
    } catch (error) {
      console.log("Error deleting QR code data:", error.message);
    }
  };

  useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "QrAttendance");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef, (snapshot) => {
		  const QRAttendanceData= snapshot.val();
		  if (QRAttendanceData) {
			const dataArray = Object.entries(QRAttendanceData).flatMap(([ClassSubjectName, AttendanceDateData]) => {
			  return Object.entries(AttendanceDateData).flatMap(([AttendanceDate, ClassLecturerData]) => {
				return Object.entries(ClassLecturerData).flatMap(([ClassLecturer, StudentUIDData]) => {
				return Object.entries(StudentUIDData).map(([StudentUID, QRAttendanceItem]) => {
				  return {
					ClassSubjectName,
					AttendanceDate,
					ClassLecturer,
					StudentUID,

          //ClassDateDB: QRAttendanceItem.ClassDate,
					//IntakeMonth: QRAttendanceItem.IntakeMonth,
					//IntakeYear: QRAttendanceItem.IntakeYear,
					//LecturerDB: QRAttendanceItem.Lecturer,
					StudentName: QRAttendanceItem.StudentName,
          SubjectCode: QRAttendanceItem.SubjectCode,
					//SubjectNameDB: QRAttendanceItem.SubjectName,
          //TimeScanned: QRAttendanceItem.TimeScanned,
          VenueDB: QRAttendanceItem.Venue,
          AttendanceStatus: QRAttendanceItem.status,
          StudentID: QRAttendanceItem.studentID,
          //UIDDB: QRAttendanceItem.uid,
					// Add more properties as needed
				  };
				});
				});
			  });
			});
			setData(dataArray);

		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

  useEffect(() => {
		const FilteredAttendStudent = data.filter((QRAttendanceItem) => {
		  return QRAttendanceItem.ClassLecturer === passedLecturer2 &&  QRAttendanceItem.ClassSubjectName === passedSubject2 &&
      QRAttendanceItem.VenueDB === passedVenue2 && QRAttendanceItem.AttendanceDate === passedDate2 && QRAttendanceItem.AttendanceStatus === "1";
		});
	  
		SetFilteredAttendStudent(FilteredAttendStudent);

	  }, [data, passedDate2, passedLecturer2, passedSubject2, passedVenue2]);

  return (
    <div class="body-qrattendancepage">
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: '70px' ,height: '70%' }}>
            <div>
            <p><br/><br/><br/><br/><br/><br/></p>
            <QRCode
        size={450}
        bgcolor="white"
        fgColor="black"
        value={qrCodeValue}
        />
        <p><br/><br/><br/><br/></p>
        {/*<p className="p-qrattendancepage">QR value: {qrCodeValue}</p>*/}
        <p className="p-qrattendancepage"><b>Date: {passedDate}</b></p>
        <p className="p-qrattendancepage"><b>Lecturer: {passedLecturer}</b></p>
        <p className="p-qrattendancepage"><b>Subject Name: {passedSubject}</b></p>
        <p className="p-qrattendancepage"><b>Venue: {passedVenue}</b></p>
        </div>


        <div className="background-qrattendance-new">
        <h1 class="h1-qrattendancepage">Class {passedSubject} {passedDate} Attendance List</h1>
        <table className="table-qrattendance">
        <thead className="th-generateqr">
          <tr>
            <th>Student ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
		{FilteredAttendStudent.length > 0 ? (
          FilteredAttendStudent.map((QRAttendanceItem) => (
            <tr key={QRAttendanceItem.StudentUID}>
              <td className="generateqr-td">{QRAttendanceItem.StudentID}</td>
              <td className="generateqr-td">{QRAttendanceItem.StudentName}</td>
            </tr>
          ))
		):(
			<tr>
			<td colSpan="6">No Student.</td>
		  </tr>
		)
		}
        </tbody>
      </table>
      </div>
        </div>

        <p><br></br></p>

      {/*</Sidebar>*/}

    </div>
  );
};

export default QRattendancepage;