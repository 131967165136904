import React, { useState, useEffect, useRef } from 'react';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import { getDatabase, ref, set, get, onValue, child, remove, query, orderByChild, equalTo } from "firebase/database";
import '../admregister/AdminEditClass.css'
import '../admregister/AdminEditStudentLog.css'
import { auth } from "../firebase";
import { FcSearch } from "react-icons/fc";
import { IoFilter } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from 'xlsx';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const AdminbtecEditStudentLog= () => {

      const [filteredSearchLog, setfilteredSearchLog] = useState([]);
      const [searchLog, setSearchLog] = useState("");
      /*const [searchStudentLog, setSearchStudentLog] = useState("");
      const [selectedInstructor, setSelectedInstructor] = useState("");*/

      const [data2, setData2] = useState([]); //data for fetching BTECClass
      const [data3, setData3] = useState([]); //data for fetching BTECProgram
      const [data4, setData4] = useState([]); //data for fetching student
      const [data5, setData5] = useState([]); //data for fetching student_active_sessions
      const [data6, setData6] = useState([]); //data for fetching BTEC Instructor

      const [filteredStudentLog, setfilteredStudentLog] = useState([]);
      const [uniqueDurationFrom, setUniqueDurationFrom] = useState([]);
      const [uniqueDurationUntil, setUniqueDurationUntil] = useState([]);
      const [skmProgram2, setskmProgram2] = useState (null);
      const [skmProgramCode2, setskmProgramCode2] = useState (null);
      const [skmuntil2, setskmuntil2] = useState(new Date());
      
      const [currentPage3, setCurrentPage3] = useState(1);
      const [currentPage4, setCurrentPage4] = useState(1);
      const [rowsPerPage, setRowsPerPage] = useState(8);

      const [currentUser, setCurrentUser] = useState(null);

      useEffect(() => {
      // listen for changes to the auth object
      const unsubscribe = auth.onAuthStateChanged((user) => {
      // update the component with the current user
      setCurrentUser(user);
      });

      // cleanup function to unsubscribe when the component unmounts
      return unsubscribe;
      }, []);

      useEffect(() => {
      // retrieve the current user's name from the database
      if (currentUser) {
        const db = getDatabase();
        const userRef = ref(db, `Admin/${currentUser.uid}`);
        get(userRef)
        .then(snapshot => {
          const userData = snapshot.val();
          setCurrentUser({
          ...currentUser,
          adminName: userData.AdminName
          });
        })
        .catch(error => {
          console.error(error);
        });
      }
      }, [currentUser]);

      useEffect(() => {
        const db = getDatabase();
        const dbRef = ref(db, "BTECClass");
    
        onValue(dbRef, (snapshot) => {
          const classData = snapshot.val();
          if (classData) {
          const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
            return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                        return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
              return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
              //return Object.entries(lectNameData).map(([lectName, classItem]) => {
              return {
                        Instructor: instructor,
              Level: level,
              ProgramCode: programCode,
              ProgramName: BTECClassitem.ProgramName,
              DurationFrom: durationfrom,
                        DurationUntil: BTECClassitem.DurationUntil,
                       
              };
            });
            });
            });
          });
          // Create Sets to remove duplicate DurationFrom and DurationUntil values
          const uniqueDurationFrom = [...new Set(dataArray.map(item => item.DurationFrom))];
          const uniqueDurationUntil = [...new Set(dataArray.map(item => item.DurationUntil))];
    
          setData2(dataArray);
          setUniqueDurationFrom(uniqueDurationFrom);  // New state to store unique DurationFrom
              setUniqueDurationUntil(uniqueDurationUntil);  // New state to store unique DurationUntil
          } else {
          // No data available in the "Class" location
          console.log("No data available.");
          }
        });
      }, []);

      useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                }));
                setData4(dataArray);
              }
            });
          }, []);

      useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "btec_active_sessions");

		onValue(dbRef, (snapshot) => {
		  const firebaseData = snapshot.val();
		  if (firebaseData) {
			const dataArray = Object.entries(firebaseData).map(([sessionuid, sessionData]) => ({
			  sessionuid,
			  logStatus: sessionData.logStatus,
			}));
			setData5(dataArray);
      //console.log("data5", data5)
		  }
		});
	  }, []);

    useEffect(() => {
      //retrieve the every lecturer data to display on option,select
      const fetchInstructors = async () => {
        try {
        const db = getDatabase();
        const instructorsRef = ref(db, "BTECInstructor");
        const snapshot = await get(instructorsRef);
        if (snapshot.exists()) {
        const instructorsData = snapshot.val();
        console.log("instructorsData: ", instructorsData)
        const instructorsArray = Object.values(instructorsData);
        console.log("instructorsArray: ", instructorsArray)
        setData6(instructorsArray);
        console.log("data6: ", data6)
        console.log("instructordata: ", instructorsData)
        } else {
        setData6([]);
        console.log("data6: ", data6)
        }
        } catch (error) {
        console.log(error.message);
        }
      };
      
      fetchInstructors(); //run the function fetchlecturer
      }, []);

      useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getDatabase();
                const programRef = ref(db, "BTECProgram");
    
                // Get the snapshot of the BTECProgram node
                const snapshot = await get(programRef);
    
                if (snapshot.exists()) {
                    // Extract the value of the snapshot
                    const programData = snapshot.val();
    
                    // Create an array to hold the extracted data
                    const extractedData = [];
    
                    // Iterate over each BtecProgramCode
                    Object.keys(programData).forEach((code) => {
                        // Iterate over each BtecProgramLevel
                        Object.keys(programData[code]).forEach((level) => {
                            // Get the data at this level
                            const levelData = programData[code][level];
    
                            // Extract BtecProgramName, BtecProgramCode, and BtecProgramLevel
                            const { BtecProgramName, BtecProgramCode, BtecProgramLevel } = levelData;
    
                            // Extract BtecProgramCourse data
                            const BtecProgramCourseData = levelData.BtecProgramCourse;
    
                            // Push the extracted data to the array
                            extractedData.push({
                                BtecProgramName,
                                BtecProgramCode,
                                BtecProgramLevel,
                                BtecProgramCourseData
                            });
                        });
                    });
    
                    // Update the state with the extracted data
                    setData3(extractedData);
                } else {
                    console.log("No BTEC program data found.");
                }
            } catch (error) {
                console.error("Error fetching BTEC program data:", error);
            }
        };
    
        fetchData();
    }, []);

    //const filterStudentLog = async(e) => {
      //e.preventDefault();
      useEffect(() => {
      
      const filteredStudentLog = data4.map((studentitem) => {
        const matchingSession = data5.find((studentlogitem) =>
        studentlogitem.sessionuid === studentitem.uid
        );
        return {
          passed_uid: studentitem.uid,
          passed_durationfrom: studentitem.durationfrom,
          passed_durationuntil: studentitem.durationuntil,
          passed_email: studentitem.email,
          passed_icnumber: studentitem.icnumber,
          passed_imageUrl: studentitem.imageUrl,
          passed_level: studentitem.level,
          passed_name: studentitem.name,
          passed_skmprogram: studentitem.skmprogram,
          passed_skmprogramcode: studentitem.skmprogramcode,
          passed_student_id: studentitem.student,
          passed_logstatus: matchingSession ? matchingSession.logStatus : null,
        };
      });
      setfilteredStudentLog(filteredStudentLog);
      //console.log("filteredstudentlog: ", filteredStudentLog);
    }, [data4, data5]);
    //};

    const findStudentLog = (e) => {
      e.preventDefault();

      if (!searchLog) {
        alert('Please enter the Student Name/ID/Email');
        return; // Return early without making any changes
        }
      
      const filteredSearchLog = filteredStudentLog.filter((logSearch) => { //filter Student Log Status
        const SearchName= logSearch.passed_name.toLowerCase(); //search any possible result that have the letters/numbers
        const SearchStudentID = logSearch.passed_student_id.toLowerCase();
        const SearchEmail = logSearch.passed_email.toLowerCase();
        const searchInput = searchLog.toLowerCase();
      
        return SearchName.includes(searchInput) || SearchStudentID.includes(searchInput) || SearchEmail.includes(searchInput);
        });

        setfilteredSearchLog(filteredSearchLog);
        console.log("filteredsearchlog", filteredSearchLog)

        if (filteredSearchLog.length === 0) {
          alert('The Student Name/ID/Email does not exists.');
          }
          else{
            alert('Search found');
          }
    };

    const sortedRows3 = [...filteredStudentLog].sort((a, b) => { //sort the data for class by month/year
      if (b.passed_durationfrom !== a.passed_durationfrom) {
        return b.passed_durationfrom - a.passed_durationfrom;
      }
      // If years are the same, sort by month in descending order
      //return b.passed_studentintakemonth - a.passed_studentintakemonth;
      });

      const indexOfLastRow3 = currentPage3 * rowsPerPage;
      const indexOfFirstRow3 = indexOfLastRow3 - rowsPerPage;
      const currentRows3 = sortedRows3.slice(indexOfFirstRow3, indexOfLastRow3);
      const totalPages3 = Math.ceil(sortedRows3.length / rowsPerPage);

      const handlePageChange3 = (pageNumber) => { //handlee page change for searched class
        setCurrentPage3(pageNumber);
      };

      const sortedRows4 = [...filteredSearchLog].sort((a, b) => { //sort the data for class by month/year
        if (b.passed_durationfrom !== a.passed_durationfrom) {
            return b.passed_durationfrom - a.passed_durationfrom;
          }
        });
  
        const indexOfLastRow4 = currentPage4 * rowsPerPage;
        const indexOfFirstRow4 = indexOfLastRow4 - rowsPerPage;
        const currentRows4 = sortedRows4.slice(indexOfFirstRow4, indexOfLastRow4);
        const totalPages4 = Math.ceil(sortedRows4.length / rowsPerPage);
  
        const handlePageChange4 = (pageNumber) => { //handlee page change for searched class
          setCurrentPage4(pageNumber);
        };

        const switchLogStatus = (studentitem) => {
         
          const db = getDatabase();
          const sessionRef = ref(db, `btec_active_sessions/${studentitem.passed_uid}`);
        
          if (studentitem.passed_logstatus === "true") {
            // If the current status is "true", switch to "false"
            set(sessionRef, { logStatus: "false" });
          } else if (studentitem.passed_logstatus === "false") {
            // If the current status is "false", switch to "true"
            set(sessionRef, { logStatus: "true" });
          } else {
            // If the current status is "null", show an alert
            alert("LogStatus is not activated");
          }
        };
            
    return (
      <div /*class="body-dashboard"*/>
        <Btecadminsidebar>
          <AdminbtecUsername />
            <p><br/><br/></p>
            
			<div>
			<h1 class="h1-adminedit">Admin Edit BTEC Student Login Status</h1>

				<p><br/></p>

				<div>
				<p class="p-createclass4">BTEC Student Log List:</p>

        <br/>

        <div className="adminregister-search-container">
				<form onSubmit={findStudentLog}>
				<label>Search:&emsp;</label>
        <div className="input-icon-container">
				<input 
				className="input-editclass2"
				type="text"
				placeholder="Enter Student Name/ID/Email"
				value={searchLog}
				onChange={(e) => setSearchLog(e.target.value)}
				>	
				</input>
        <FcSearch className="search-icon" onClick={findStudentLog} title="Search"/>
				</div>
				</form>
				</div>
        <br/>

        <p><br/></p>

				<div class="table-container-editclass">
					<table>
        <thead class="th-addstudent">
          <tr>

		    <th>Student Name</th>
        <th>Student ID</th>
        <th>E-Mail</th>
        <th>Course</th>
        <th>Session</th>
        <th>Log Status</th>
			
          </tr>
        </thead>
        <tbody>
		    {currentRows4.length > 0 ?
            (currentRows4.map((studentitem, index) => (
			<tr key={`${studentitem.uid}_${index}`}>
            <td className="editclass-td">{studentitem.passed_name}</td>
			<td className="editclass-td">{studentitem.passed_student_id}</td>
            <td className="editclass-td">{studentitem.passed_email}</td>
			<td className="editclass-td">{studentitem.passed_skmprogramcode} <br/> {studentitem.passed_skmprogram}</td>
            <td className="editclass-td">{studentitem.passed_durationfrom} <br/>Until<br/> {studentitem.passed_durationuntil}</td>
            <td><div className= "background-rectangle"style={{ display: 'flex', alignItems: 'center', }}>
            <button
              className={`toggle-button ${studentitem.passed_logstatus === 'true' ? 'active' : studentitem.passed_logstatus === 'false' ? 'inactive' : 'null'}`}
              onClick={() => switchLogStatus(studentitem)}
            >
              {studentitem.passed_logstatus === 'true' ? 'Active' : studentitem.passed_logstatus === 'false' ? 'Inactive' : 'Not Activated'}
            </button>
          </div>
        </td>
				</tr>
				))
				) /*: currentRows3.length > 0 ?
          (currentRows3.map((studentitem, index) => (
        <tr key={`${studentitem.uid}_${index}`}>
            <td className="editclass-td">{studentitem.passed_name}</td>
			<td className="editclass-td">{studentitem.passed_student_id}</td>
            <td className="editclass-td">{studentitem.passed_email}</td>
			<td className="editclass-td">{studentitem.passed_skmprogramcode} <br/> {studentitem.passed_skmprogram}</td>
            <td className="editclass-td">{studentitem.passed_durationfrom} <br/>Until<br/> {studentitem.passed_durationuntil}</td>
          <td>
          <div className= "background-rectangle"style={{ display: 'flex', alignItems: 'center', }}>
            <button
              className={`toggle-button ${studentitem.passed_logstatus === 'true' ? 'active' : studentitem.passed_logstatus === 'false' ? 'inactive' : 'null'}`}
              onClick={() => switchLogStatus(studentitem)}
            >
              {studentitem.passed_logstatus === 'true' ? 'Active' : studentitem.passed_logstatus === 'false' ? 'Inactive' : 'Not Activated'}
            </button>
          </div>
        </td>
      </tr>
      ))
      )*/ : (
				<tr>
				<td colSpan="6">No Student Log found.</td>
				</tr>
				)}
				</tbody>
				</table>
        </div>

        <p><br/></p>

        <p>*To change the Login Status of the Student, click on the button to change the current status of the Login Student</p>

        <p><br></br></p>

        {/*{currentRows3.length > 0 && totalPages3 > 1 && currentRows4.length === 0 && (
          <div className="pagination">
          {Array.from({ length: totalPages3 }, (_, i) => i + 1).map((pageNumber) => (
            <button
            key={pageNumber}
            onClick={() => handlePageChange3(pageNumber)}
            className={`button-addStudent5 ${currentPage3 === pageNumber ? "active" : ""}`}
            >
            {pageNumber}
            </button>
          ))}
          </div>
        )}*/}

        {currentRows4.length > 0 && totalPages4 > 1 && (
          <div className="pagination">
          {Array.from({ length: totalPages4 }, (_, i) => i + 1).map((pageNumber) => (
            <button
            key={pageNumber}
            onClick={() => handlePageChange4(pageNumber)}
            className={`button-addStudent5 ${currentPage4 === pageNumber ? "active" : ""}`}
            >
            {pageNumber}
            </button>
          ))}
          </div>
        )}
				</div>
        </div>

      </Btecadminsidebar>
	  
    </div>
  );
};

export default AdminbtecEditStudentLog;