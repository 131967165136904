import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

//pdf stylsheet for pdf printing
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    //right: 10,
    //marginLeft: 20,
    //marginLeft: '20px',  // Set the left margin
    //marginRight: '20px', // Set the right margin
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 14,
    //marginBottom: 10,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 'auto', //auto 
    //borderRightWidth: 1,
  },
  tableRow: {
    //margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '31.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol: {
    width: '31.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader2: { //no. column
    width: '35px',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol2: { //no. column
    width: '35px',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader3: { //student name column
    width: '280px',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol8: { //student name column
    width: '280px',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader4: { //student id column
    width: '140px',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableColHeader6: { //student id column
    width: '40px',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    //borderLeftWidth: 1,
    borderRightWidth: 1,
    fontWeight: 'bold',
  },
  tableCol6: { //student id column
    width: '140px',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader5: { //attendance status column
    width: '30px',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    //borderRightWidth: 1,
    fontWeight: 'bold',
  },
  tableCol7: { //attendance status column
    width: '30px',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    //borderRightWidth: 1,
  },
  tableCol9: { //attendance status column
    width: '40px',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    //borderLeftWidth: 1,
    borderRightWidth: 1,
    //borderRightWidth: 1,
  },
  tableCol3: { 
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCol4: { 
    width: '55.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCol5: { 
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCellHeader2: {
    margin: 0,
    fontSize: 9,
    fontWeight: 500,
  },
  tableCell: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 9,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  titleItem: {
    fontSize: 12,
    marginBottom: 10,
  },
  noteItem: {
    fontSize: 9,
    marginBottom: 10,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  column1: {
    marginRight: 100,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  imageContainer: {
    //flex: 1,
    //justifyContent: 'center',
    alignItems: 'center',
    //marginBottom: '1px',
  },
  image: {
    width: '250px',
    height: '70px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    right: 20,
  },
  lastColumn: {
    borderRightWidth: 1,
  },
});

//student data example 07122023
const mockAttendanceData = [
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'John Doe',
    Status: '1',
    '01-01-2023': { StudentID: '123', Status: '1' },
    '01-02-2023': { StudentID: '123', Status: '0' },
    '01-03-2023': { StudentID: '123', Status: '1' },
    // ... add more dates as needed
  },
  {
    studentName: 'Jane Smith',
    Status: '0',
    '01-01-2023': { StudentID: '456', Status: '0' },
    '01-02-2023': { StudentID: '456', Status: '1' },
    '01-03-2023': { StudentID: '456', Status: '1' },
    // ... add more dates as needed
  },
  // ... add more student entries as needed
];

const mockClassUniqueDate = ['01-01-2023', '01-02-2023', '01-03-2023', /* add more dates */];

const mockAttendance1 = {
  SubjectCode: 'CS101',
  SubjectNameDB: 'Introduction to Computer Science',
  IntakeMonth: 'January',
  IntakeYear: '2023',
  ClassLecturer: 'Dr. Smith',
};

//end student data example 07122023

//pdf data shown placement
//rowsperpage for how many rows is allowed to be shown in a page
//attendancedata = the data that is carry over from generateQR page
const DownloadCompiledClass = ({ classdatedata, classuniquedate, attendanceData2 }) => {

  const attendance1 = attendanceData2 && attendanceData2.length > 0 ? attendanceData2[0] : null;
  const studentArray = Object.entries(classdatedata).map(([studentName, data]) => ({ studentName, ...data }));
  {/*{console.log('dateToAttendanceMap.length:', Object.keys(classdatedata).length)}
				{console.log('FilteredCompiledAttendance.length:', attendanceData2.length)}
{console.log('uniqueDates.length:', classuniquedate.length)}*/}

function calculateTotalWeeks(classuniquedate) {
  // Assuming classuniquedate is an array of date strings (e.g., ["01-01-2023", "08-01-2023", ...])
  const dateObjects = classuniquedate.map(dateString => {
    const [day, month, year] = dateString.split("-");
    // Months are 0-indexed in JavaScript Date, so subtract 1 from the month
    return new Date(year, month - 1, day);
  });

  // Sort the date objects
  dateObjects.sort((a, b) => a - b);

  // Get the start and end dates
  const startDate = dateObjects[0];
  const endDate = dateObjects[dateObjects.length - 1];

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Convert milliseconds to weeks
  const weeksDifference = timeDifference / (1000 * 60 * 60 * 24 * 7);

  // Round the result to get the total number of weeks
  const totalWeeks = Math.round(weeksDifference);

  return totalWeeks;
}

//{console.log('dateToAttendanceMap.length:', Object.keys(classdatedata))}
  const generatePDF = () => {
    //let totalPages = 0;
    let rowCounter = 0;
    const rowsPerPage = 31;

    //const rowsPerPageFirstPage = 24;
    //const rowsPerPageSubsequentPages = 31;
    const rowsPerPageFirstPage = 17;
    const rowsPerPageSubsequentPages = 22;

    //const totalRows = sampleData.length;

    const totalRows = studentArray.length;
    const totalPages = Math.ceil((totalRows - rowsPerPageFirstPage) / rowsPerPageSubsequentPages) + 1;

      // Initialize variables to hold the counts for each attendance status
      let presentCount = 0;
      let absentCount = 0;
      let lateCount = 0;
      let unknownCount = 0;
      let totalpresentcount = 0;

      //const allStudentCount = sampleData.length;
      //const allStudentCount = attendanceData2.length;
      const allStudentCount = studentArray.length;
      const totalWeeksCount = calculateTotalWeeks(classuniquedate);
      const totalClassCount = classuniquedate.length;

                        // Initialize variables to count attended and late students
                        let attendedCount = 0;
                        let lateCount2 = 0;
      
                        // Iterate over each student to count attended and late students for the current date
                        {classuniquedate.map((date, index) => (
                        studentArray.forEach(student => {
                          const status = student[date] && student[date].Status;
                          if (status === '1') {
                            attendedCount++;
                          } else if (status === '3') {
                            lateCount2++;
                          }
                        })
                        ))};

                        const studAttendanceStat = attendedCount + lateCount2;
      
    return (
      <Document>
      {Array.from({ length: totalPages }).map((_, pageIndex) => {

      let startRow, endRow;
      if (pageIndex === 0) {
        startRow = 0;
        endRow = Math.min(rowsPerPageFirstPage, totalRows);
      } else {
        startRow = rowsPerPageFirstPage + (pageIndex - 1) * rowsPerPageSubsequentPages;
        endRow = Math.min(startRow + rowsPerPageSubsequentPages, totalRows);
      }

      //const pageData = attendanceData2.slice(startRow, endRow);
      //const pageData = Object.entries(classdatedata).slice(startRow, endRow);
      const pageData = studentArray.slice(startRow, endRow);
      //{console.log('pagedata:', pageData)}

        rowCounter = endRow - startRow;

        pageData.sort((a, b) => a.studentName.localeCompare(b.studentName));

        const pageStyle = pageIndex === 0 ? styles.page : { ...styles.page, marginTop: '30px' };
        const pageNumberStyle = pageIndex === 0 ? styles.pageNumber :{ ...styles.pageNumber, bottom: 50, right: 20 };
        
        pageData.forEach((studentArray) => {
          switch (studentArray.Status) {
            case '1': // Present
              presentCount++;
              break;
            case '0': // Absent
              absentCount++;
              break;
            case '3': // Late
              lateCount++;
              break;
            default: // Unknown Status
              unknownCount++;
              break;
          }
          totalpresentcount = presentCount + lateCount;
        });
        
          return (
          //<Page size="A4" style={pageStyle} key={pageIndex} orientation="landscape" >
            //<Page size="A4" style={pageStyle} key={pageIndex}>
            <Page size="A4" style={pageStyle} key={pageIndex} orientation="landscape" >
            <View style={styles.section}>
            {pageIndex === 0 && ( // Render the header section only for the first page
                <>
            <View style={styles.imageContainer}>
            <Image src="/logored.png" style={styles.image} />
              </View>
              <Text style={styles.header}>Class Summary for <Text style={{ fontWeight: 'bold' }}>{attendance1.SubjectCode} {attendance1.SubjectNameDB} , Session: {attendance1.IntakeMonth} / {attendance1.IntakeYear}</Text> </Text>
              <Text></Text>
              <View style={styles.titleContainer}>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Lecturer: {attendance1.ClassLecturer}</Text>
                <Text style={styles.noteItem}><Text style={{fontWeight: 'bold', color: 'red', fontSize: 10}}>*</Text>O = Present; X = Absent; L = Late</Text> {/*make the text a bit smaller*/}
              </View>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Total Week: {totalWeeksCount}</Text>
              </View>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Total Class Conducted: {totalClassCount}</Text>
              </View>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Total Student: {allStudentCount}</Text>
              </View>
            </View>
            </>
              )}

              <View style={styles.table}>
                <View style={styles.tableRow}>
                <View style={styles.tableColHeader2}>
                    <Text style={styles.tableCellHeader}>No.</Text>
                  </View>
                  <View style={styles.tableColHeader3}>
                    <Text style={styles.tableCellHeader}>Student Name</Text>
                  </View>
                  <View style={styles.tableColHeader4}>
                    <Text style={styles.tableCellHeader}>Student ID</Text>
                  </View>
                  {classuniquedate.map((date, columnIndex) => {
                  const [day, month, year] = date.split('-');
                  const formattedDate = `${month}/${day}/${year}`;
                  const isLastColumn = columnIndex === classuniquedate.length - 1;

                  // Initialize variables to count attended and late students
                  let attendedCount = 0;
                  let lateCount = 0;

                  // Iterate over each student to count attended and late students for the current date
                  /*studentArray.forEach(student => {
                    const status = student[date] && student[date].Status;
                    if (status === '1') {
                      attendedCount++;
                    } else if (status === '3') {
                      lateCount++;
                    }
                  });*/

                  return (
                    <View
                    style={{
                      ...styles.tableColHeader5,
                      ...(isLastColumn ? styles.lastColumn : {}),
                    }}
                    key={date}
                  >
                      <Text style={{...styles.tableCellHeader2, fontWeight: 'bold'}}>
                        {new Date(formattedDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' })}
                      </Text>

                    </View>
                  );
                })}

                            <View style={styles.tableRow}>
                              <View style={styles.tableColHeader6}>
                            <Text style={styles.tableCellHeader}>Total</Text>
                            </View>
                            </View>
                </View>
                {pageData.map((studentArray, index) => (
                <View style={styles.tableRow} key={`${studentArray.studentName}-${index}`}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>{startRow + index + 1}</Text>
                  </View>

                  <View style={styles.tableRow} key={studentArray.studentName}>
                    <View style={styles.tableCol8}>
                      <Text style={styles.tableCell}>{studentArray.studentName}</Text>{/*student name*/}
                    </View>
                    <View style={styles.tableCol6}>
                      {/*student id*/}
                      <Text style={styles.tableCell}>{classdatedata[studentArray.studentName][classuniquedate[0]] && classdatedata[studentArray.studentName][classuniquedate[0]].StudentID}</Text>
                    </View>
                    {classuniquedate.map((date, index) => (
                      <View
                        style={{
                          ...styles.tableCol7,
                          borderRightWidth: index === classuniquedate.length - 1 ? 1 : 0,
                        }}
                        key={date}
                      >
                        <Text style={styles.tableCell}>
                          {classdatedata[studentArray.studentName][date] &&
                            classdatedata[studentArray.studentName][date].Status === '1'
                            ? 'O'
                            : classdatedata[studentArray.studentName][date] &&
                              classdatedata[studentArray.studentName][date].Status === '0'
                            ? 'X'
                            : classdatedata[studentArray.studentName][date] &&
                              classdatedata[studentArray.studentName][date].Status === '3'
                            ? 'L'
                            : ''}
                        </Text>
                      </View>
                    ))}
                    
                    {/* Calculate the sum total of 'present' and 'late' status */}
                    <View 
                      style={{
                        ...styles.tableCol9
                      }}
                    >
                      <Text style={styles.tableCell} /*style={{ textAlign: 'center' }}*/>
                        {/* Count the occurrences of 'present' and 'late' status */}
                        {classuniquedate.reduce((total, date) => {
                          const status = classdatedata[studentArray.studentName][date]?.Status;
                          return total + (status === '1' || status === '3' ? 1 : 0);
                        }, 0)} / {totalClassCount}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}

            </View>

                  <View style={pageNumberStyle}>
                <Text style={styles.pageNumberText}>{pageIndex + 1}</Text>
              </View>
                          {/*<View style={styles.footer}>
              <Text>Total Present: {presentCount}</Text>
              <Text>Total Late: {lateCount}</Text>
            </View>*/}
              </View>
            </Page>
          );
        })}
      </Document>
    );
  };

  return (
    <button className="button-history5">
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`${attendance1.SubjectCode}_${attendance1.SubjectNameDB}_Session:_${attendance1.IntakeMonth}_${attendance1.IntakeYear}_Summary.pdf`}
      >
        {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download Summary Attendance PDF')}
      </PDFDownloadLink>
    </button>
  );
};

export default DownloadCompiledClass;