import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

//pdf stylsheet for pdf printing
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    //right: 10,
    //marginLeft: 20,
    //marginLeft: '20px',  // Set the left margin
    //marginRight: '20px', // Set the right margin
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 14,
    //marginBottom: 10,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 'auto',
    borderRightWidth: 1,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '31.70%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol: {
    width: '31.70%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol2: { //no. column
    width: '5.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol3: { //student id column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol4: { //student name column
    width: '55.10%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableColHeader5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    fontWeight: 'bold',
  },
  tableCol5: { //attendance status column
    width: '20.00%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: { //if overflow will go to the next line in the same column
    margin: 5,
    fontSize: 10,
    wordWrap: 'break-word', // Enable word wrapping
    hyphens: 'auto', // Enable hyphenation
    flexGrow: 1, // Allow the cell to grow vertically
  },
  titleItem: {
    fontSize: 12,
    marginBottom: 10,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  column1: {
    marginRight: 100,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  imageContainer: {
    //flex: 1,
    //justifyContent: 'center',
    alignItems: 'center',
    //marginBottom: '1px',
  },
  image: {
    width: '250px',
    height: '70px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    right: 20,
  },
});

//student data example 28112023
//
//
//end student data example 28112023

//pdf data shown placement
//rowsperpage for how many rows is allowed to be shown in a page
//attendancedata = the data that is carry over from generateQR page
//const AdminDownloadClassPDF = ({ attendanceData, rowsPerPage = 25 }) => {
const AdminDownloadClassPDF = ({ attendanceData/*, rowsPerPage = 31*/ }) => {
  const attendance1 = attendanceData[0];

  const generatePDF = () => { 
    //const totalPages = Math.ceil(attendanceData.length / rowsPerPage); //comment on 11/12/2023

      // Initialize variables to hold the counts for each attendance status
      /*let presentCount = 0; //comment on 11/12/2023
      let absentCount = 0;
      let lateCount = 0;
      let unknownCount = 0;
      let totalpresentcount = 0;*/

      let rowCounter = 0;

      const rowsPerPageFirstPage = 24;
      const rowsPerPageSubsequentPages = 31;

      const totalRows = attendanceData.length;
      const totalPages = Math.ceil((totalRows - rowsPerPageFirstPage) / rowsPerPageSubsequentPages) + 1;

      // Initialize variables to hold the counts for each attendance status
      let presentCount = 0; //comment on 11/12/2023
      let absentCount = 0;
      let lateCount = 0;
      let unknownCount = 0;
      let totalpresentcount = 0;

      const allStudentCount = attendanceData.length;

    return (
      <Document>
      {Array.from({ length: totalPages }).map((_, pageIndex) => {
        /*let startRow = pageIndex * rowsPerPage;
        let endRow = startRow + rowsPerPage;
        let pageData;*/

        /*if (pageIndex === 0) {
          pageData = attendanceData.slice(startRow, endRow);
        } else {
          if (pageIndex === 1) {
            rowsPerPage = 32; // Set rowsPerPage to 30 for the second page
            startRow = 25 + (pageIndex - 1) * rowsPerPage; // Adjust the startRow for the second page
          } else {
            rowsPerPage = 32; // Set rowsPerPage to 25 for subsequent pages
            startRow = 57 + (pageIndex - 2) * rowsPerPage; // Adjust the startRow for subsequent pages
          }
          endRow = startRow + rowsPerPage;
          pageData = attendanceData.slice(startRow, endRow);
        }*/

        let startRow, endRow;
        if (pageIndex === 0) {
          startRow = 0;
          endRow = Math.min(rowsPerPageFirstPage, totalRows);
        } else {
          startRow = rowsPerPageFirstPage + (pageIndex - 1) * rowsPerPageSubsequentPages;
          endRow = Math.min(startRow + rowsPerPageSubsequentPages, totalRows);
        }

        const pageData = attendanceData.slice(startRow, endRow);

        rowCounter = endRow - startRow;
        pageData.sort((a, b) => a.StudentName.localeCompare(b.StudentName));
        // Sort the pageData array by StudentName in ascending order
        //pageData.sort((a, b) => a.StudentName.localeCompare(b.StudentName));

        const pageStyle = pageIndex === 0 ? styles.page : { ...styles.page, marginTop: '30px' };
        const pageNumberStyle = pageIndex === 0 ? styles.pageNumber :{ ...styles.pageNumber, bottom: 50, right: 20 };

        // While mapping through the pageData, calculate the counts for each status
        pageData.forEach((attendance1) => {
          switch (attendance1.AttendanceStatus) {
            case '1': // Present
              presentCount++;
              break;
            case '0': // Absent
              absentCount++;
              break;
            case '3': // Late
              lateCount++;
              break;
            default: // Unknown Status
              unknownCount++;
              break;
          }
          totalpresentcount = presentCount + lateCount;
        });

          return (
          //<Page size="A4" style={pageStyle} key={pageIndex} orientation="landscape" >
            <Page size="A4" style={pageStyle} key={pageIndex}>
            <View style={styles.section}>
            {pageIndex === 0 && ( // Render the header section only for the first page
                <>
            <View style={styles.imageContainer}>
            <Image src="/logored.png" style={styles.image} />
              </View>
              <Text style={styles.header}>Student Attendance Report</Text>

              <View style={styles.titleContainer}>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Subject Code: <Text style={{ fontWeight: 'bold' }}>{attendance1.SubjectCode}</Text></Text>
                <Text style={styles.titleItem}>Session: {attendance1.IntakeMonth}/{attendance1.IntakeYear}</Text>
                <Text style={styles.titleItem}>Lecturer: {attendance1.ClassLecturer}</Text>
                <Text style={styles.titleItem}>Total Present: {totalpresentcount} / {allStudentCount}</Text>
                {/*<Text style={styles.titleItem}>Total Present: {presentCount}</Text>*/}
                <Text style={styles.titleItem}>Total Late: {lateCount}</Text>
                
              </View>
              <View style={styles.column1}>
                <Text style={styles.titleItem}>Subject Name: {attendance1.SubjectNameDB}</Text>
                <Text style={styles.titleItem}>Date: {attendance1.ClassDateDB}</Text>
                <Text style={styles.titleItem}>Venue: {attendance1.VenueDB}</Text>
                <Text style={styles.titleItem}>Total Absent: {absentCount}</Text>
                <Text style={styles.titleItem}>Total Student: {allStudentCount}</Text>
                {/*<Text style={styles.titleItem}>Total Absent: {absentCount}</Text>
                <Text style={styles.titleItem}>Total Student: {allStudentCount}</Text>*/}
                 {/*allStudentCount*/}
              </View>
            </View>
            </>
              )}

              <View style={styles.table}>
                <View style={styles.tableRow}>
                <View style={styles.tableColHeader2}>
                    <Text style={styles.tableCellHeader}>No.</Text>
                  </View>
                  <View style={styles.tableColHeader3}>
                    <Text style={styles.tableCellHeader}>Student ID</Text>
                  </View>
                  <View style={styles.tableColHeader4}>
                    <Text style={styles.tableCellHeader}>Student Name</Text>
                  </View>
                  <View style={styles.tableColHeader5}>
                    <Text style={styles.tableCellHeader}>Attendance Status</Text>
                  </View>
                </View>
                {pageData.map((attendance1, index) => (
                    <View style={styles.tableRow} key={`${attendance1.StudentUID}-${index}`}>
                      <View style={styles.tableCol2}>
                        <Text style={styles.tableCell}>{startRow + index + 1}</Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>{attendance1.StudentID}</Text>
                      </View>
                      <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>{attendance1.StudentName}</Text>
                      </View>
                      <View style={styles.tableCol5}>
                        <Text style={styles.tableCell}>
                        {attendance1.AttendanceStatus === "1" ? 'Present': 
					              attendance1.AttendanceStatus === "0" ? 'Absent':
					              attendance1.AttendanceStatus === "3" ? 'Late':
					              'Unknown Status'}
                          </Text>
                      </View>
                    </View>
                  ))}
                  
                  </View>
                  <View style={pageNumberStyle}>
                <Text style={styles.pageNumberText}>{pageIndex + 1}</Text>
              </View>
              </View>
            </Page>
          );
        })}
      </Document>
    );
  };

  return (
    <button className="button-history4">
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`${attendance1.AttendanceDate} ${attendance1.SubjectCode} ${attendance1.SubjectNameDB} attendance_report.pdf`}
      >
        {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
      </PDFDownloadLink>
    </button>
  );
};

export default AdminDownloadClassPDF;