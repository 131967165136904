import React, { useState, useEffect, useCallback } from 'react';
import Btecusername from '../Btecusername';
import Btecsidebar from '../Btecsidebar';
import { getDatabase, ref, set, get, child, update } from "firebase/database";
import '../Profile.css';
import { auth, storage } from "../firebase";
import { updatePassword } from "firebase/auth";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import Popup from 'reactjs-popup';
import { FcPlus } from "react-icons/fc";

import Cropper from 'react-easy-crop'; //react-easy-crop library
import { getCroppedImg } from '../studentprofile/getCroppedImg'; // Create a separate utility function for image cropping

const BtecProfile = () => {
 
	const [EditFaculty, setEditFaculty] = useState("");
	//const [EditName, setEditName] = useState("");
	//const [EditEmail, setEditEmail] = useState("");
	const [EditPassword, setEditPassword] = useState("");
	const [ConfirmPassword, setConfirmPassword] = useState("");

	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
	  // listen for changes to the auth object
	  const unsubscribe = auth.onAuthStateChanged((user) => {
		// update the component with the current user
		setCurrentUser(user);
	  });
  
	  // cleanup function to unsubscribe when the component unmounts
	  return unsubscribe;
	}, []);

	/*useEffect(() => {
		// retrieve the current user's name from the database
		if (currentUser) {
		  const db = getDatabase();
		  const userRef = ref(db, `Lecturer/${currentUser.uid}`);
		  get(userRef)
			.then(snapshot => {
			  const userData = snapshot.val();
			  setCurrentUser({
				...currentUser,
				lecturerName: userData.lecturerName,
				lecturerEmail: userData.registerEmail,
				lecturerFaculty: userData.registerFaculty,
				lecturerImage: userData.ImageURL,
			  });
			})
			.catch(error => {
			  console.error(error);
			});
		}
	  }, [currentUser]);*/

      useEffect(() => {
		// retrieve the current user's name from the database
        console.log("here1");
		if (currentUser) {
		  const db = getDatabase();
		  const userRef = ref(db, `BTECInstructor/${currentUser.uid}`);
          console.log("here2");
		  get(userRef)
			.then(snapshot => {
			  const userData = snapshot.val();
              console.log("here3");
			  setCurrentUser({
				...currentUser,
				btecregisterEmail: userData.btecregisterEmail                ,
				btecregisterFaculty: userData.btecregisterFaculty,
				instructorName: userData.instructorName,
				instructorImage: userData.ImageURL,
                uid: userData.uid,
			  });
              console.log("currentuser: ", currentUser);
			})
			.catch(error => {
			  console.error(error);
			});
		}
	  }, [currentUser]);

	  /*useEffect(() => {
		//gather the lecturer data from firebase
		const db = getDatabase();
		const dbRef = ref(db, "Class");
	  
		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([year, monthData]) => {
			  return Object.entries(monthData).flatMap(([month, subjectNameData]) => {
				return Object.entries(subjectNameData).flatMap(([subjectName, lectNameData]) => {
					return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
					year,
					month,
					subjectName,
					SubjectCode: classItem.SubjectCode,
					Faculty: classItem.Faculty,
					Programmes: classItem.Programmes,
					SubjectName: classItem.SubjectName,
					lecturerName2: classItem.lecturerName,
					// Add more properties as needed
				  };
				});
				});
			  });
			});
			setData(dataArray);
		  } else {
			console.log("No data available.");
		  }
		});
	  
		// Perform the search logic based on the selected faculty and program
		const filteredClass = data.filter((classItem) => {
		  return classItem.lecturerName2 === currentUser?.lecturerName;
		});
	  
		// Update the filteredClass state
		setFilteredClass(filteredClass);
	  
	  }, [currentUser?.lecturerName]);*/

	  /*const HandleEmptyPassword = async () =>{
		if (EditPassword === "") {
			setConfirmPassword("")
		}
	  };*/

	  const handleChangePassword = (e) => {
		const newPassword = e.target.value;
		setEditPassword(newPassword);
	
		// Clear ConfirmPassword if EditPassword is emptied
		if (newPassword === '') {
		  setConfirmPassword('');
		}
	  };
	
	  const handleConfirmPassword = (e) => {
		setConfirmPassword(e.target.value);
	  };

	const EditUserDetails = async () => {
		const confirmAttend = window.confirm("Confirm change?");

						if (EditPassword === "" || ConfirmPassword === ""){
							alert("The password cannot be empty! Please fill in all the form!");
					}
					else if (EditPassword.length < 6 || ConfirmPassword.length < 6){
						alert("The password length must be equal or more than 6!");

					}
				else if (confirmAttend) {
				try {
					const db = getDatabase();
					const instructorUid = currentUser.uid;
					const lecturerRef = child(ref(db), `BTECInstructor/${instructorUid}`);
					const user = auth.currentUser;

					if (EditPassword === ConfirmPassword) {
						await updatePassword(user, EditPassword);
						alert("Password updated successfully!");
						//setEditFaculty("");
						setEditPassword("");
						setConfirmPassword("");
					}
					else{
						alert("The Password you entered does not match. Please try again.");
						setEditPassword("");
						setConfirmPassword("");
					}
				
					setEditPassword("");
					setConfirmPassword("");
				} catch (error) {
					console.log(error.message);
				}
				}
			//}
			};

			const [crop, setCrop] = useState({ x: 0, y: 0 });
			const [zoom, setZoom] = useState(1);
		    const [croppedImage, setCroppedImage] = useState(null);
			const [selectedImage, setSelectedImage] = useState(null);
			const [showCropPopup, setShowCropPopup] = useState(false);
			const [showPopup, setShowPopup] = useState(true);
			const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

			const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => { //set the crop image based on the zoom and crop
				setCroppedAreaPixels(croppedAreaPixels)
			  }, [])

			const handleImageChange = (event) => { //function to handle the selected image
				try {
				  const file = event.target.files[0]; // set the uploaded file to array[0]
				  console.log('Selected File:', file); // Log the selected file to check if it's valid
				  if (!file) {
					alert("No image selected.");
					//console.error('No image selected.');
					return;
				  }
				  if (!file.type.startsWith('image/')) {
					alert("Invalid file type. Please select an image.");
					//console.error('Invalid file type. Please select an image.');
					return;
				  }
				  //console.log('Selected Image:', file);
				
				  // Create a local URL for the selected image file
				  const imageUrl = URL.createObjectURL(file); //create the image URL
				  //console.log('Image URL:', imageUrl); // Log the created URL
				
				  // Pass the imageUrl to the getCroppedImg function instead of the file
				  setSelectedImage(imageUrl); // set the image from file selected
				  setShowCropPopup(true); // show crop windows
				  setShowPopup(false); // hide edit picture windows
				} catch (error) {
				  console.error('Error loading image:', error);
				}
			  };
			  
			  const handleCropImage = useCallback(async () => {
				console.log('donee1', { selectedImage});
				try {
				  const croppedImageBlob = await getCroppedImg(
					selectedImage, // Pass the selected image file to the getCroppedImg function
					croppedAreaPixels, // pass the crop & zoom pixel
					1 // Output image quality if needed
				  );
				  // Upload the cropped image or further process it as needed
				  setCroppedImage(croppedImageBlob);
				  console.log('donee', { croppedImageBlob });
				  // Close the crop menu after cropping
				  setShowCropPopup(false); // hide crop windows
				  setShowPopup(true); // Show edit image popup menu
				  setSelectedImage(null); // Reset the selected image state
				} catch (error) {
				  //console.error('Error cropping image:', error); //crop error handling
				}
			  },[croppedAreaPixels,selectedImage]);
			  
			  const uploadImage = async () => { // handle uploading the image
				const db = getDatabase(); //connect to the db

				//get the lecturer data
				const instructorUid = currentUser.uid;
				const instructorRef = child(ref(db), `BTECInstructor/${instructorUid}`);
				const instructorSnapshot = await get(instructorRef);
				const instructorName = instructorSnapshot.val().instructorName;
			  
				if (croppedImage == null) return alert('No Image selected! Please choose an image first.');;
			  
				const fileName = `${instructorName}.jpg`; //set the image uploaded format
				const imageRef = storageRef(storage, `BTECInstructor/${instructorName}/${fileName}`);// Set the desired file name
			  
				try {
				  await uploadBytes(imageRef, croppedImage); //upload the cropped image
				  //alert('Profile Picture Updated!');
				  setCroppedImage(null);
			  
				  const imageUrl = await getDownloadURL(imageRef); //get image url from firebase storage
			  	  const db2 = getDatabase(); //connect to the db
				  const imageURLRef = ref(db2, `BTECInstructor/${instructorUid}/ImageURL`); //set to the url into the lecturer node
			  
				  await set(imageURLRef, imageUrl); //save into the lecturer node
				  alert('Profile Picture Updated!');
				  //alert('Image URL saved!');
				} catch (error) {
				  console.error('Error uploading image:', error); //error handling
				}
			  };

			  const resetimage = () => { //reset image uploaded state
				setSelectedImage(null);
				setCroppedImage(null);

			  };

		return (
		
		<div /*class="body-createclass"*/>
			<Btecsidebar>
			<Btecusername />
			<p><br/><br/></p>
			<div>
			<h1 class="h1-createclass">Instructor User Profile</h1>
			</div>
            <div /*className="createclass-container"*/> 

			<p><br></br></p>
			
			<div title='Change Your Profile Picture'>
			<Popup
				trigger={
				<div className="input-icon-container-profile">
					<div className="circle">
					{currentUser ? (
						currentUser.instructorImage ? (
						<img
							src={currentUser.instructorImage}
							alt="Profile"
							className="profile-image"
						/>
						) : (
						<div className="loading-text">
							<p>No image...</p>
						</div>
						)
					) : (
						<div className="no-image-text">
						<p>No Image</p>
						</div>
					)}
					<FcPlus className="profile-icon" />
					</div>
				</div>
				}
				modal
				nested
			>
				{close => (
				<div className="modal">
					{showPopup &&(
					<button className="button-studentclass4" onClick={()=>{close(); resetimage();}}>&times;</button>
					)}
					{showPopup &&(
						<div>
					<h2 className="h2-profile">Edit Profile Picture</h2>
					<p><br /><br /><br /></p>

					<label className="p-profile2">
					&emsp;<b>Upload Image:</b>&emsp;&emsp;&emsp;&nbsp;
					</label>
					</div>
					)}

					<div>
						{showPopup && (
					<input type="file" onChange={handleImageChange} accept="image/*" />
					)}
					</div>

					{showPopup && (
					<div>
						<b>
					<p className="p-profile4">*You can zoom-in/zoom-out the picture by using the mouse scroll.</p>
					<p className="p-profile4">*Drag the picture by clicking and hold the picture to adjust your profile picture.</p>
					<p className="p-profile4">*If accidently exited the crop menu, just click the edit <FcPlus /> icon back and it will show up again.</p>
					</b>
					</div>
					)}

					<p><br/></p>								

					{showCropPopup && selectedImage && (
						<div>
					<div className="modal-profile2">
						<Cropper
						image={selectedImage}
						crop={crop}
						zoom={zoom}
						objectFit="vertical-cover"
						aspect={4 / 3}
						cropShape="round"
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						/>
						<p><br/></p>

					</div>
					<div>
					<div className="container">
						<button className="confirm-crop-button" onClick={handleCropImage}>Confirm Crop</button>
						<button className="confirm-crop-button" onClick={() => {setShowCropPopup(false); setShowPopup(true); resetimage();}}>Cancel</button>
					</div>
					</div>
					</div>
					)}
					<p><br/></p>

					{croppedImage && (
						<div className="preview-center">
									<div className="circle2">
									<img src={URL.createObjectURL(croppedImage)} alt="Cropped" />
									</div>
									<p><br/></p>
									<p className="p-profile3"><b>Preview</b></p>
									</div>
								)}

					<p><br /><br /><br /><br /></p>
					{showPopup && (
					<button title = 'Upload the selected image' className="button-profile-new2" onClick={uploadImage}>
					UPLOAD IMAGE
					</button>
					)}
				</div>
				)}
			</Popup>
			</div>


			<p><br/></p>

			<div className="container">
			<div>
            <label class="p-createclass2">Name:&emsp;<b>{currentUser?.instructorName}</b></label>
			<p><br></br></p>
			<label class="p-createclass2">E-Mail:&emsp;<b>{currentUser?.btecregisterEmail}</b></label>	
			</div>
			<div>
			<label class="p-createclass2">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Faculty:&emsp;<b>{currentUser?.btecregisterFaculty}</b></label>
			</div>
			</div>
			<p><br></br></p>

			<Popup trigger={<button title = 'Change your password' className="button-profile-new" /*onClick={() => {findAttendStudent(QRHistoryItem);}}*/>EDIT DETAILS</button>} modal nested>
			{close => (
			<div className="modal">
			<button className="button-studentclass4" onClick={() => { close();}}>&times;</button>
			<p><br /><br /></p>

			<h2 className="h2-profile">Edit User Details</h2>
			<p><br></br><br></br></p>


					<p><br/></p>

					<p><br/></p>
					
				<div>
				<label className="p-profile2">&emsp;<b>Change Password:</b>&emsp;&emsp;&emsp;&nbsp;&nbsp;</label>
				<input
                class="p-profile createclass-profile"
					type="password"
					placeholder="Enter New Password"
					value={EditPassword}
					onChange={handleChangePassword }
                    >
					</input>
					</div>
					<p><br></br></p>

					{EditPassword &&(
					<div>
					<label className="p-profile2">&emsp;<b>Confirm New Password:</b>&emsp;</label>
				<input
                class="p-profile createclass-profile"
					type="password"
					placeholder="Enter the New Password Again"
					value={ConfirmPassword}
					onChange={handleConfirmPassword }
                    >
					</input>
					</div>
					)}
					<p><br></br><br></br></p>
					<button className="button-profile-new2" onClick={() => {EditUserDetails();}}>CONFIRM CHANGE</button>

				</div>
			)}
			</Popup>
               
                </div>
			</Btecsidebar>
		</div>
	);
};

export default BtecProfile;