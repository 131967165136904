import React, { useState, useEffect } from 'react';
import { useNavigate/*, Link*/ } from 'react-router-dom';
import { auth } from "../firebase";
import { createUserWithEmailAndPassword, deleteUser, sendEmailVerification } from "firebase/auth";
import { getDatabase, /*onValue,*/ref, get,/*push,*/ set, child, remove} from "firebase/database";
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import '../admregister/AdminRegister.css';
import '../admregister/AdminDashboard.css';
import { FcSearch } from "react-icons/fc";
import { MdEditNote, MdOutlineAdminPanelSettings, MdRefresh } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";

const AdminbtecRegInst = () => {
	const [btecregisterEmail, setBtecRegisterEmail] = useState("");
	const [btecregisterNewEmail, setBtecRegisterNewEmail] = useState("");
	const [registerPassword, setRegisterPassword] = useState("");
	const [registerNewPassword, setRegisterNewPassword] = useState("");
	const [registerFaculty, setRegisterFaculty] = useState("");
	const [instructorName, setInstructorName] = useState("");  
	const [newInstructorName, setNewInstructorName] = useState("");  
	const [data, setData] = useState([]);
	const [searchLecturer, setSearchLecturer] = useState("");
	const [filteredLecturer, setFilteredLecturer] = useState([]);
	const [showExistingRegistration, setShowExistingRegistration] = useState(true);
	const [activeIcon, setActiveIcon] = useState('');
	const [data2, setData2] = useState([]);
	//const [selectedRows, setSelectedRows] = useState([]);
	const navigate = useNavigate();
	//const [message, setMessage] = useState({ error: false, msg: ""});
	//const navigate = useNavigate();

	useEffect(() => {
		const fetchLecturers = async () => {
		  try {
			const db = getDatabase();
			const lecturersRef = ref(db, "Lecturer");
			const snapshot = await get(lecturersRef);
			if (snapshot.exists()) {
			  const lecturersData = snapshot.val();
			  const lecturersArray = Object.values(lecturersData);
			  setData2(lecturersArray);
			  console.log("instructor name: ",data2);
			} else {
			  setData2([]);
			}
		  } catch (error) {
			console.log(error.message);
		  }
		};
		fetchLecturers();
	  }, []);

	const registernewInstructor = async (e) => {
		e.preventDefault();
		//console.log(auth.currentUser.uid);

		const confirmRegister = window.confirm("Register this user?");

		if (confirmRegister) {
		try {
		  const db = getDatabase();
		  const usersRef = ref(db, "BTECInstructor");
	  
		  const snapshot = await get(child(usersRef, auth.currentUser.uid));
		  const userData = snapshot.val();
	  
		  if (userData && userData.btecregisterNewEmail === btecregisterNewEmail) {
			// Email already exists in the database
			alert("Email is already registered.");
			console.log("Email is already registered.");
		  } else {
			const user = await createUserWithEmailAndPassword(
				auth,
				btecregisterNewEmail,
				registerNewPassword
			);
			console.log(user)

			await set(child(usersRef, auth.currentUser.uid), {
			  uid: auth.currentUser.uid,
			  btecregisterEmail: btecregisterNewEmail,
			  btecregisterFaculty: 'Borneo TVET College (BTEC)',
			  instructorName: newInstructorName,
			});

			//to send an email verification to the e-mail
			await sendEmailVerification(auth.currentUser);
	  
			console.log("New Instructor registered:", auth.currentUser.uid);
			console.log("btecregisterEmail:", btecregisterEmail);
			console.log("instructorName:", instructorName);
			alert(btecregisterNewEmail +" have been registered.")
			navigate('/btec/BTEC_Dashboard');
		  }
		} catch (error) {
			alert(`Email: ${btecregisterNewEmail} is already registered.`);
		  console.log(error.message);
		}
	}
	  };

	  const registerlecturertoInstructor = async (e) => {
		e.preventDefault();
		const confirmRegister = window.confirm("Register this user?");
		
		if (confirmRegister) {
			try {
				const db = getDatabase();
				const usersRef = ref(db, "BTECInstructor");
	
				// Check if the user's email already exists in the database
				const snapshot = await get(child(usersRef, auth.currentUser.uid));
				const userData = snapshot.val();
	
				if (userData && userData.btecregisterEmail === btecregisterEmail) {
					// Email already exists in the database
					alert("Email is already registered.");
					console.log("Email is already registered.");
				} else {
					const lecturerRef = ref(db, "Lecturer");
					const lecturerSnapshot = await get(lecturerRef);
	
					if (lecturerSnapshot.exists()) {
						const lecturersData = lecturerSnapshot.val();
						const matchingLecturer = Object.values(lecturersData).find(lecturer => lecturer.registerEmail === btecregisterEmail);
	
						if (matchingLecturer) {
							// Insert the instructor's data into the database
							await set(child(usersRef, matchingLecturer.uid), {
								uid: matchingLecturer.uid, // Set UID to the UID of matching lecturer
								btecregisterEmail,
								btecregisterFaculty: 'Borneo TVET College (BTEC)',
								instructorName,
								registerPassword,
							});
	
							console.log("New lecturer registered:", matchingLecturer.uid);
							alert(btecregisterEmail + " has been registered.");
							navigate('/btec/BTEC_Dashboard');
						} else {
							alert("Lecturer with the specified name not found.");
						}
					} else {
						alert("No lecturer data found.");
					}
				}
			} catch (error) {
				alert(`Email: ${btecregisterEmail} is already registered.`);
				console.log(error.message);
			}
		}
	};

	  const validateForm = () => {
		if (
            instructorName === "" ||
		  btecregisterEmail === "" ||
		  registerPassword === "" //||
		  //registerFaculty === ""
		) {
		  alert("Please fill in all fields");
		  return false;
		}
		//alert("");
		return true;
	  };

	const register = async (e) =>{
		e.preventDefault();
		if (!validateForm()) {
			return;
		  }
		
		//await RegisterNew();
		await  registerlecturertoInstructor(e);

		  setInstructorName("");
		  setBtecRegisterEmail("");
		  setRegisterPassword("");
	};

	useEffect(() => {
		const fetchLecturers = async () => {
		  try {
			const db = getDatabase();
			const lecturersRef = ref(db, "Lecturer");
			const snapshot = await get(lecturersRef);
			if (snapshot.exists()) {
			  const lecturersData = snapshot.val();
			  const lecturersArray = Object.values(lecturersData);
			  setData(lecturersArray);
			} else {
			  setData([]);
			}
		  } catch (error) {
			console.log(error.message);
		  }
		};
	
		fetchLecturers();
	  }, []);

	  const DeleteLecturer = async (uid, email) => {
		const db = getDatabase();
		const confirmDelete = window.confirm("Are you sure you want to delete the selected Lecturer?");
	  
		if (confirmDelete) {
		  try {
			// Delete lecturer data from the Realtime Database
			const dbRef = ref(db, `Lecturer/${uid}`);
			await remove(dbRef);
			alert(`Lecturer with UID ${email} deleted successfully from the Realtime Database.`)
			console.log(`Lecturer with UID ${uid} deleted successfully from the Realtime Database.`);
	  
			// Delete user's email from Firebase Authentication
			await deleteUser(auth, uid);
	  
			console.log(`Email ${email} deleted successfully from Firebase Authentication.`);
	  
			alert("Lecturer has been deleted.");
		  } catch (error) {
			console.error("Error deleting lecturer:", error.message);
		  }
		}
	  };

	  const FindLecturer = (e) => {
		e.preventDefault();

	    // Check if the search input is empty
		if (!searchLecturer) {
			alert('Please enter the Lecturer Name/E-Mail');
			return; // Return early without making any changes
		}

		// Perform the search logic based on the selected student ID
		const filteredLecturer = data.filter((lecturer2) => {
			const LecturerName = lecturer2.lecturerName.toLowerCase();
			const LecturerEmail = lecturer2.btecregisterEmail.toLowerCase();
			const searchInput = searchLecturer.toLowerCase();
		
			return LecturerName.includes(searchInput) || LecturerEmail.includes(searchInput);
		  });
	  
		// Update the foundStudents state with the filtered students array
		setFilteredLecturer(filteredLecturer);
		
		if (filteredLecturer.length === 0) {
			alert('The Lecturer Name/E-Mail does not exists.');
		  }
		  console.log(`filtered Lecturer: `, filteredLecturer);
	  };

	  const resetfntn = () => {
		window.location.reload(); 
	  };

	  const handleAdminPanelSettingsClick = () => {
		setShowExistingRegistration(true);
		setActiveIcon('adminPanelSettings');
	  };
	
	  const handleEditNoteClick = () => {
		setShowExistingRegistration(false);
		setActiveIcon('editNote');
	  };

	return (
		
		<div /*className="body-adminregister"*/>
		<Btecadminsidebar> {/*show/call sidebar*/}
		<AdminbtecUsername /> {/*show/call username*/}
		<p><br/><br/></p>
		<div>
		{/*<h1 className="h1-adminregister">BTEC New Instructor Registration</h1>*/}
		</div>

		<div className='container'>
		<div className={`dashboard-function-container-btecadmin ${activeIcon === 'adminPanelSettings' ? 'active' : ''}`} onClick={handleAdminPanelSettingsClick}>
		<MdOutlineAdminPanelSettings className="dashboard-icon-admin-btec" />
		<p className="p-dashboard-container"><b>Already registered as lecturer</b></p>
		</div>
		&emsp;
		<div className={`dashboard-function-container-btecadmin ${activeIcon === 'editNote' ? 'active' : ''}`} onClick={handleEditNoteClick}>
		<MdEditNote className="dashboard-icon-admin-btec" />
		<p className="p-dashboard-container"><b>First Time Registration</b></p>
		</div>
		  </div>
		
		<div /*className="adminregister-container"*/>
			<p><br/></p>

			{showExistingRegistration ? (
				<div>
			{/*BTEC Instructor Existing on N.A.I.S Instructor Registration*/}
			<h1 className="h1-adminregister">BTEC Instructor Existing on N.A.I.S Instructor Registration</h1>
			<p><br/></p>
			<div className="container">
			<div class="container">
			<label class="p-adminregister2"><br></br>&emsp;Name: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;</label>
				<input 
				title='Enter the Lecturer Name'
				className="input-adminregister"
					type="text"
					placeholder="Enter Instructor name"
					value={instructorName}
					onChange={(e) => setInstructorName(e.target.value) }>
					</input>
					</div>

				<label class="p-adminregister2"><br/>&emsp;E-mail: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</label>
				<div className="container">
				<input 
				title='Enter E-Mail'
				className="input-adminregister"
					type="email"
					placeholder="Enter instructor email"
					value={btecregisterEmail}
					onChange={(e) => setBtecRegisterEmail(e.target.value) }>
					</input>
					</div>
					</div>

					<p><br/><br/></p>

				<div className="container">
				<label class="p-adminregister2"><br/>&emsp;Password: <b className="important-createclass">*</b>&emsp;&nbsp;</label>
				<input 
				title='Enter Password'
				className="input-adminregister"
					type="password"
					placeholder="Enter password"
					value={registerPassword}
					onChange={(e) => setRegisterPassword(e.target.value) }>
				</input>

				<p><br/></p>
				</div>	

				<p><br/><br/></p>

				<button title='Register Instructor' onClick={register} className='button-admin-view-attendance8'/*class="button-adminregister"*/><AiOutlineUserAdd className='admin-view-attendance-icon3'/><b>REGISTER</b></button>
				</div>
			): (
			<div>

				{/*Register New Instructor that havent registered in the N.A.I.S System*/}
				
				<h1 className="h1-adminregister">BTEC New Instructor Registration</h1>
	
		
		<div /*className="adminregister-container"*/>
			<p><br></br></p>

			<div className="container">
			<div class="container">
			<label class="p-adminregister2"><br></br>&emsp;Name: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;</label>
				<input 
				title='Enter the Lecturer Name'
				className="input-adminregister"
					type="text"
					placeholder="Enter lecturer name"
					value={newInstructorName}
					onChange={(e) => setNewInstructorName(e.target.value) }>
					</input>
					</div>

				<label class="p-adminregister2"><br/>&emsp;E-mail: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</label>
				<div className="container">
				<input 
				title='Enter E-Mail'
				className="input-adminregister"
					type="email"
					placeholder="Enter instructor email"
					value={btecregisterNewEmail}
					onChange={(e) => setBtecRegisterNewEmail(e.target.value) }>
					</input>
					</div>
					</div>

					<p><br/><br/></p>

				<div className="container">
				<label class="p-adminregister2"><br/>&emsp;Password: <b className="important-createclass">*</b>&emsp;&nbsp;</label>
				<input 
				title='Enter Password'
				className="input-adminregister"
					type="password"
					placeholder="Enter password"
					value={registerNewPassword}
					onChange={(e) => setRegisterNewPassword(e.target.value) }>
				</input>

				<p><br/></p>
				</div>	

				<p><br/><br/></p>

				<button title='Register Instructor' onClick={registernewInstructor} className='button-admin-view-attendance8'/*class="button-adminregister"*/><AiOutlineUserAdd className='admin-view-attendance-icon3'/><b>REGISTER</b></button>
				</div>
				</div>
				)}

					<p class="p-adminregister3"><br></br>Lecturer List</p>

					<p><b>*The form marked with <b className="important-createclass">*</b> is compulsory.</b></p>
					<p><b>*Admin will need to re log-in after registering a new user due to the Firebase Policy after registering a new user, the new user will automatically logged in as the current user.</b></p>
					<p><br/></p>
				
				<div className="adminregister-search-container">
				<form title='Enter Lecturer Name and E-Mail' onSubmit={FindLecturer}>
				<label>Search:&emsp;</label>
				<div className="input-icon-container">
				<input 
				className="input-adminregister2"
				type="text"
				placeholder="Enter Lecturer Name/E-Mail"
				value={searchLecturer}
				onChange={(e) =>setSearchLecturer(e.target.value)}
				>	
				</input>
				<FcSearch className="search-icon" onClick={FindLecturer} title="Search"/>
				</div>
				</form>
				</div>

				<p><br/><br/></p>
				<div class="table-container-adminregister">
					
				</div>
				<p><br /></p>

				<button title='Refresh the page to view the newly added Lecturer' onClick={() => resetfntn()} className='button-admin-view-attendance9' /*className="button-adminregister3"*/>
				<MdRefresh className="admin-register-icon"/>
				<b>REFRESH PAGE</b>
				</button>

		</div>
		</Btecadminsidebar>
		</div>
		
	);
}

export default AdminbtecRegInst;