import { useState, useEffect } from "react";
import '../admregister/AdminRegisterStudent.css';
import { auth } from "../firebase";
import { getDatabase, ref, set, get, onValue, child} from 'firebase/database';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, deleteUser, sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { AiOutlineUserAdd } from "react-icons/ai";
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import bcrypt from 'bcryptjs';

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths, format } from "date-fns"; //enabled the date past the current date

import * as XLSX from 'xlsx';

const AdminBtecRegStud = () => {

    const [excelFile, setExcelFile] = useState (null);
    const [typeError, setTypeError] = useState (null);
    const [excelData, setExcelData] = useState (null);


    const [data2, setData2] = useState([]);

    const [filteredBtecStudent, setFilteredBtecStudent] = useState([]);

    const [skmProgram2, setskmProgram2] = useState (null);
    const [skmProgramCode2, setskmProgramCode2] = useState (null);
    const [skmLevel2, setskmLevel2] = useState (null);

    const [skmfrom2, setskmfrom2] = useState(new Date());
    const [skmuntil2, setskmuntil2] = useState(new Date());

    const [currentUser, setCurrentUser] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        // listen for changes to the auth object
        const unsubscribe = auth.onAuthStateChanged((user) => {
          // update the component with the current user
          setCurrentUser(user);
        });
    
        // cleanup function to unsubscribe when the component unmounts
        return unsubscribe;
      }, []);
      
      useEffect(() => {
        console.log("from: ", skmfrom2);
        console.log("until: ", skmuntil2);
        console.log("skmprogram: ", skmProgram2);
        console.log("skmprogramcode: ", skmProgramCode2);
        console.log("skmlevel: ", skmLevel2);
        console.log("filteredBtecStudent: ", filteredBtecStudent);
      }, [skmfrom2,skmuntil2,skmProgram2,skmProgramCode2,skmLevel2,filteredBtecStudent]);

    const handleFileBtec = (e) => {
        let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
        let selectedFile = e.target.files[0];
        if(selectedFile){
            if (selectedFile && fileTypes.includes(selectedFile.type)){
                setTypeError(null);
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFile(e.target.result);
                }
            }
            else{
                setTypeError('Please select only excel file types!');
                setExcelFile(null);
            }

            console.log(selectedFile.type);
        }
        else{

            console.log("Please select your file.");
        }
    } 

    const handleFileSubmitBTECm = (e) => {
        e.preventDefault();
        if(excelFile !== null){
            const workbook = XLSX.read(excelFile,{type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // Check if all required headers are present
            const requiredHeaders = ["Student Name", "IC Number", "Student ID", "Faculty", "E-Mail"];
            const excelHeaders = Object.keys(data[0]); // Get headers from the first row
            const missingHeaders = requiredHeaders.filter(header => !excelHeaders.includes(header));


            if (data.length === 0) {
                alert('The Excel File might have no student data or is empty. Please check and try to upload again. If the problem persists, please contact the IT Administrator.');
            } else if (missingHeaders.length > 0) {
                alert(`The following headers are missing in the Excel file: ${missingHeaders.join(", ")}. Please check and try again.`);
                return;
            } else {
                setExcelData(data);
                filterstudentuid();
            }
        } else {
            alert('No Excel file uploaded. Please upload an Excel file.');
        }
    }
      
        const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(10);

        const renderTable2 = () => {
            if (!excelData) return <p>No file uploaded!</p>;
        
            // Calculate indexes for pagination
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            const currentItems = excelData.slice(indexOfFirstItem, indexOfLastItem);

            return (
                <div className="table-container-editclass">
                    <table>
                        <thead>
                            <tr style={{ fontSize: '18px' }}>
                                    {Object.keys(excelData[0]).map((key) => (
                                    <th key={key}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((student, index) => (
                                <tr key={index + indexOfFirstItem}>
                                    {Object.values(student).map((value, index) => (
                                        <td key={index}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        };

        const renderPagination2 = () => {
            if (!excelData) return null; // Return null if excelData is null
        
            // Calculate the total number of pages based on the total data rows and items per page
            const totalPages = Math.ceil(excelData.length / itemsPerPage);
        
            // Create an array of page numbers
            const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
        
            return (
                <div className="pagination">
                    {pageNumbers.map((number) => (
                        <button
                            key={number} 
                            className={`button-addStudent5 ${currentPage === number ? 'active' : ''}`} 
                            onClick={() => setCurrentPage(number)}
                        >
                            {number}
                        </button>
                    ))}
                </div>
            );
        };

          const filterstudentuid = () => {
            if (!excelData) return;

            const filteredData = [];
        
            excelData.forEach((excelItem) => {
                const emailToFilter = excelItem["E-Mail"];
                const matchedUser = data2.find((user) => user.email === emailToFilter);
        
                if (matchedUser) {
                    // Extract relevant data from the matched user
                    const extractedData = {
                        uid: matchedUser.uid,
                        // Add other relevant fields you want to extract
                    };
        
                    // Add the extracted data to the filteredData array
                    filteredData.push(extractedData);
                }
            });

            setFilteredBtecStudent(filteredData);

          };


          const registerBtecStudenttoApps2 = async (e) => {
            e.preventDefault();
        
            if (!excelData) {
                alert('No Excel File is uploaded!');
                return;
            }
        
            const confirmRegisterStudent = window.confirm("Register the selected student into the application?");
          
            if (confirmRegisterStudent) {
                try {
                    const db = getDatabase();
                    const userRef = ref(db, "BTECStudent");
                       
                    // Prepare an array to store all the promises
                    const promises = [];
                    
                    excelData.forEach(async (studentData) => {
                        try {
                            // Assuming your Excel data has the following columns: email, faculty, name, programmes, student
                            const {
                                "Student Name": name,
                                "IC Number": icnumber,
                                "Student ID": student,
                                "Faculty": faculty,
                                "E-Mail": email,
                            } = studentData;
        
                            // Check if the email exists in Firebase Auth
                            const existingUser = await fetchSignInMethodsForEmail(auth, email);
                            let uid;
        
                            if (existingUser.length === 0) {
                                console.log("test1");
                                // If the email is not registered, create a new user
                                const userCredential = await createUserWithEmailAndPassword(auth, email, icnumber);
                                uid = userCredential.user.uid;

                                const formattedfrom = format(skmfrom2, 'dd-MM-yyyy');
                                const formatteduntil = format(skmuntil2, 'dd-MM-yyyy');
            
                                const fromString = formattedfrom.toString();
                                const untilString = formatteduntil.toString();
                                const levelString = skmLevel2.toString();

                                console.log("ic number: ", icnumber);

                                // Hash the IC number (password)
                                const hashedPassword = await bcrypt.hash(icnumber, 10);
                                console.log("hashedPassword: ", hashedPassword);
                      
                                // Create a promise for each push operation
                                const pushPromise = set(child(userRef, uid), {
                                    name,
                                    icnumber,
                                    student,
                                    faculty,
                                    email,
                                    uid,
                                    skmmonth: Month,
                                    skmyear: Year,
                                    password: icnumber, //change to password hash
                                    //password: hashedPassword, //change to password hash
                                    skmprogram: skmProgram2,
                                    skmprogramcode: skmProgramCode2,
                                    level: levelString,
                                    durationfrom: fromString,
                                    durationuntil: untilString,
                                    imageUrl: '', // Let it be empty for now
                                });

                                promises.push(pushPromise);

                                await sendEmailVerification(auth.currentUser); //turn comment on 22082024
                            } else {
                                // If the email is already registered, retrieve the user's uid
                                const matchedUser = data2.find(user => user.email === email);
                                if (matchedUser) {
                                    uid = matchedUser.uid;
                                } else {
                                    console.error('User not found:', email);
                                    alert('User not found in Firebase Auth. Please check the data and try again.');
                                    return;
                                }
                                console.log("test2");

                                const formattedfrom = format(skmfrom2, 'dd-MM-yyyy');
                                const formatteduntil = format(skmuntil2, 'dd-MM-yyyy');
            
                                const fromString = formattedfrom.toString();
                                const untilString = formatteduntil.toString();
                                const levelString = skmLevel2.toString();
                      
                                // Create a promise for each push operation
                                const pushPromise = set(child(userRef, uid), {
                                    name,
                                    icnumber,
                                    student,
                                    faculty,
                                    email,
                                    uid,
                                    skmprogram: skmProgram2,
                                    skmprogramcode: skmProgramCode2,
                                    level: levelString,
                                    durationfrom: fromString,
                                    durationuntil: untilString,
                                    imageUrl: '', // Let it be empty for now
                                });
                                promises.push(pushPromise);
                                //uid = existingUser[0].localId; // This is just an example, adjust based on actual data structure
                            }
                            
                        } catch (error) {
                            if (error.code === 'auth/email-already-in-use') {
                                console.error('Email already in use:', studentData["E-Mail"]);
                                alert(`Email '${studentData["E-Mail"]}' is already registered.`);
                            } else {
                                console.error('Error creating user:', error);
                                alert('An error occurred while creating the user. Please try again later.');
                            }
                        }
                    });
                  
                    // Wait for all the push operations to complete
                    await Promise.all(promises);
        
                    alert("All the students have been registered! Please logged in again to continue using as the Super Admin.");
                    navigate('/btec/admin');
                    console.log('Data uploaded to Firebase.');
                } catch (error) {
                    alert("Error uploading data!");
                    console.error('Error uploading data:', error);
                    // Handle errors and display them to the user (e.g., show an error message in the UI)
                }
            }
        };

          const [Month, setMonth] = useState("");
		  const [Year, setYear] = useState("");

		  const MonthList = () => { //list the month
			const months = [];
	  
			for (let month = 1; month <= 12; month++) {
			  months.push(month);
			}
	  
			return months.map((month) => ( //map in option
			  <option key={month} value={month}>
			  {month}
			  </option>
			));
			};
	  
			const YearList = () => { //list the year 2023-2099
			const years = [];
	  
			for (let year = 2023; year <= 2099; year++) {
			  years.push(year);
			}
	  
			return years.map((year) => (
			  <option key={year} value={year}>
			  {year}
			  </option>
			));
			};


    return (
        <div>
		<Btecadminsidebar> {/*show/call sidebar*/}
		<AdminbtecUsername /> {/*show/call username*/}
            <p><br/><br/></p>
			<div>
			<h1 className="h1-createclass">Register Student to N.A.I.S</h1>
			</div>
            <p><br/></p>

            {/*<form onSubmit={BTECshow}>*/}
            {/*<form onSubmit={handleFileSubmitBTEC}>*/}
            <form onSubmit={handleFileSubmitBTECm}>
            <div> 
            <p>Select File: <b className="important-createclass">*</b></p>
            <input title="Select Excel file to upload" className="p-createclass registerstudent-container3" type="file" required onChange={handleFileBtec}></input>
            </div>
            <p><br/></p>
            <button title="View the selected excel file" type="submit" className='button-admin-view-attendance11'>&emsp;<FaEye className='admin-view-attendance-icon6'/>SHOW DATA</button>
            </form>
            <p><br/></p>

            <div className="container">
            <p><b>Program: &emsp;</b></p>
            <select title='Select Program' class="select-size-adminregister" value={skmProgram2}
              onChange={(e) => {setskmProgram2(e.target.value); setskmProgramCode2(null);}}>
				<option>Select Program</option>
                    <option value="MAKE-UP ARTIST"> 
                    MAKE-UP ARTIST</option>
                    <option value="SECURITY SERVICES OPERATION">
                    SECURITY SERVICES OPERATION</option>
                    <option value="FOOD PREPARATION AND PRODUCTION">
                    FOOD PREPARATION AND PRODUCTION</option>
					<option value="PASTRY PRODUCTION">
					PASTRY PRODUCTION</option>
					{/*<option value="DEFENCE & SECURITY SERVICES">
					DEFENCE & SECURITY SERVICES</option>*/}
					<option value="EARLY CHILDHOOD CARE AND DEVELOPMENT EDUCATION">
					EARLY CHILDHOOD CARE AND DEVELOPMENT EDUCATION</option>
                    <option value="GEOMATICS & LAND SURVEYING">
					GEOMATICS & LAND SURVEYING</option>
                    <option value="CULINARY ARTS OPERATION">
					CULINARY ARTS OPERATION</option>
                    <option value="SECURITY SERVICES SUPERVISION">
					SECURITY SERVICES SUPERVISION</option>
				</select>
                &emsp;
                <p><br/></p>
                <p><b>Kod Program: &emsp;</b></p>
                <select title='To show the Programmes, you must first select the lecturer that will be assigned to the class.' class="select-size-createclass" value={skmProgramCode2}
              onChange={(e) => setskmProgramCode2(e.target.value)}
              >

				<option value="">Select Code Programmes</option>
				{skmProgram2 === "MAKE-UP ARTIST" &&(
					<>
					<option value="MP-063-2:2012"> 
					MP-063-2:2012 </option>
					</>
				)}

				{skmProgram2 === "SECURITY SERVICES OPERATION" &&(
					<>
					<option value="DS-010-2:2013"> 
					DS-010-2:2013</option>
					</>
				)}

				{skmProgram2 === "FOOD PREPARATION AND PRODUCTION" &&(
					<>
					<option value="I561-005-2:2012"> 
					I561-005-2:2012</option>
					</>
				)}
				
				{skmProgram2 === "PASTRY PRODUCTION" &&(
					<>
					<option value="HT-014-2:2011"> 
					HT-014-2:2011</option>
                    <option value="HT-014-3:2011"> 
					HT-014-3:2011</option>
					</>
				)}

				{/*{skmProgram2 === "DEFENCE & SECURITY SERVICES" &&(
					<>
					<option value="DS-010-2:2013"> 
					DS-010-2:2013</option>
					</>
				)}*/}

				{skmProgram2 === "EARLY CHILDHOOD CARE AND DEVELOPMENT EDUCATION" &&(
					<>
					<option value="T982-001-3:2017"> 
					T982-001-3:2017</option>
					</>
				)}

                {skmProgram2 === "GEOMATICS & LAND SURVEYING" &&(
					<>
					<option value="BC-060-3:2013"> 
					BC-060-3:2013</option>
					</>
				)}

                {skmProgram2 === "CULINARY ARTS OPERATION" &&(
					<>
					<option value="I561-005-3:2022"> 
					I561-005-3:2022</option>
					</>
				)}

                {skmProgram2 === "SECURITY SERVICES SUPERVISION" &&(
					<>
					<option value="DS-010-3:2013"> 
					DS-010-3:2013</option>
					</>
				)}

				</select>
                </div>

                &emsp;

                <div className="container">
                <p><b>Level/Tahap: &emsp;</b></p>
            <select title='Select Program' class="select-size-adminregister" value={skmLevel2}
              onChange={(e) => setskmLevel2(e.target.value)}>
				<option>Select Level</option>
				<option value="Level 1"> 
				Level 1</option>
				<option value="Level 2">
                Level 2</option>
				<option value="Level 3">
                Level 3</option>
				</select>
                </div>

                <p><br/></p>
                <div className="container">
                <p><b>Tarikh Pengajian (Daripada)/ Duration of Study (From):</b>&emsp;</p>
				<DatePicker
				className="input-History "
				selected={skmfrom2}
				onChange={(date) => setskmfrom2(date)}
				dateFormat="dd-MM-yyyy"
				placeholderText="From"
				/>

                <p><b>Tarikh Pengajian (Sehingga)/ Duration of Study (Until): </b><b className="important-createclass">*</b>&emsp;</p>
				<DatePicker
				className="input-History "
				selected={skmuntil2}
				onChange={(date) => setskmuntil2(date)}
				dateFormat="dd-MM-yyyy"
				placeholderText="After"
				/>
                </div>

       <br/>
                <div class="container">
                    <label class="p-createclass2"><b>Session:</b> <b className="important-createclass">*</b>&ensp;&ensp;&ensp;</label>

					<select title='Enter the Intake Month of the will be conducted class' class="select-size-createclass2" value={Month}
              onChange={(e) => setMonth(e.target.value)}
              >
				<option>Month</option>
				{MonthList()}
				</select>

				<select title='Enter the Intake Year of the will be conducted class' class="select-size-createclass3" value={Year}
              onChange={(e) => setYear(e.target.value)}
              >
				<option>Year</option>
				{YearList()}
				</select>
        </div>

            <p><br/></p>

            {typeError&&(
                <div role="alert"> {typeError} </div>
            )}
            <p><br/></p>

            <p><b>Content inside File:</b></p>
            <p><br/></p>
            
            <p><br/></p>


                {renderTable2()}
                <br/>
                {renderPagination2()}

            <p><br/><br/></p>
            <button title="Register the student extracted from excel file" onClick={registerBtecStudenttoApps2} className="button-admin-view-attendance11"/*className="button-adminregister3"*/><AiOutlineUserAdd className="admin-view-attendance-icon3"/><b>REGISTER STUDENT</b></button>

            <p><br/><br/><br/><br/></p>
            <p><b>*The form marked with <b className="important-createclass">*</b> is compulsory.</b></p>
            <p><b>*File must be in .xlsx format for it to be read properly.</b></p>
            <p><b>*Super Admin will need to re log-in after registering the student due to the Firebase Policy after registering a new user, the new user will automatically logged in as the current user.</b></p>
            </Btecadminsidebar>
        </div>

    );
}

export default AdminBtecRegStud