import React, { useState, useEffect, useRef } from 'react';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import '../admregister/AdminEditStudent.css';
import { getDatabase, ref, remove, onValue, child, get , set } from "firebase/database";
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import { FcSearch } from "react-icons/fc";
//for DatePicker
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
//import { addMonths } from "date-fns"; //enabled the date past the current date
import { MdAddCircle, MdDelete  } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdPersonAdd } from "react-icons/md";

const AdminbtecEditStudent = () => {
    const [data, setData] = useState([]); //data for class
    const [data2, setData2] = useState([]); //data for student in the class
    const [data3, setData3] = useState([]); //data for student
    const [data4, setData4] = useState([]);

    const [searchClass, setSearchClass] =  useState("");
    const [searchStudent, setSearchStudent] =  useState("");
    const [filteredSearchClass, setFilteredSearchClass] = useState([]);
    const [filteredSearchStudent, setFilteredSearchStudent] = useState([]);
    const [filteredClass, setFilteredClass] = useState([]);
    const [filteredStudentClass, setFilteredStudentClass] = useState([]);
    
    const [selectedRows3, setSelectedRows3] = useState([]);
    
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
                    Level: level,
                    ProgramCode: programCode,
                    ProgramName: BTECClassitem.ProgramName,
                    DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
                   
				  };
				});
				});
			  });
			});
			// Create Sets to remove duplicate DurationFrom and DurationUntil values
			const uniqueDurationFrom = [...new Set(dataArray.map(item => item.DurationFrom))];
			const uniqueDurationUntil = [...new Set(dataArray.map(item => item.DurationUntil))];

			setData(dataArray);
			//setUniqueDurationFrom(uniqueDurationFrom);  // New state to store unique DurationFrom
      		//setUniqueDurationUntil(uniqueDurationUntil);  // New state to store unique DurationUntil
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

    useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
                    Level: level,
                    ProgramCode: programCode,
                    DurationFrom: durationfrom,
                    DurationUntil: BTECClassListitem.durationUntil,
                    uid: studentUID,
                    Student: BTECClassListitem.student,
					          StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData2(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

      useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                  //uid: studentData.uid,
                }));
                setData3(dataArray);
              }
            });
          }, []);

    useEffect(() => {
        //retrieve the every lecturer data to display on option,select
        const fetchInstructor = async () => {
          try {
          const db = getDatabase();
          const instructorRef = ref(db, "BTECInstructor");
          const snapshot = await get(instructorRef);
          if (snapshot.exists()) {
            const InstructorsData = snapshot.val();
            const InstructorArray = Object.values(InstructorsData);
            setData4(InstructorArray);
          } else {
            setData4([]);
          }
          } catch (error) {
          console.log(error.message);
          }
        };
        //console.log("data4: ", data4 )
      
        fetchInstructor(); //run the function fetchInstructor
        }, []);

	  useEffect(() => {
      //filter the checkbox selected
		const selectedData3 = data2.filter((studentClassItem) =>
		  selectedRows3.some(
			(item) =>
			  item.Student === studentClassItem.Student && 
        item.uid === studentClassItem.uid && 
        item.ProgramCode === studentClassItem.ProgramCode && 
        item.Level === studentClassItem.Level && 
        item.Instructor === studentClassItem.Instructor &&
        item.DurationFrom === studentClassItem.DurationFrom &&
        item.DurationUntil=== studentClassItem.DurationUntil
		  )
		);
		console.log("Selected Data3:", selectedData3);
		//console.log("Selected Data3r:", selectedRows3);
		
	  }, [selectedRows3, data2]);

	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
	
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

	  const findStudentClass = (classItem) => { //find the class
		const filteredStudentClass = data2.filter((studentClassItem) => { //filter the student that exist in the class
		  return (
			studentClassItem.DurationFrom === classItem.DurationFrom &&
			studentClassItem.DurationUntil === classItem.DurationUntil &&
      studentClassItem.Level === classItem.Level &&
			studentClassItem.ProgramCode === classItem.ProgramCode &&
			studentClassItem.Instructor === classItem.Instructor
		  );
        });
        
        console.log("Filtered Student Class:", filteredStudentClass);
        setFilteredStudentClass(filteredStudentClass);
        };

        //choose unique identifier when selecting class
        const handleCheckboxChange2 = (Instructor, Level, ProgramCode, DurationFrom, uid, Student) => { //handle the selected student to delete
        const selectedItem = {Instructor, Level, ProgramCode, DurationFrom, uid, Student};
        console.log ("data passed: ", Instructor, Level, ProgramCode, DurationFrom, uid, Student);
        
        if (selectedRows3.some((item) => item.uid === uid && 
        item.ProgramCode === ProgramCode &&
        item.Instructor === Instructor)) {
          // If the studentID and subjectCode combination is already in the selectedRows, remove it
          setSelectedRows3(selectedRows3.filter((item) => item.uid !== uid
          || item.ProgramCode !== ProgramCode
            || item.Instructor !== Instructor));
        } else {
          
          // If the studentID and subjectCode combination is not in the selectedRows, add it
          setSelectedRows3([...selectedRows3, selectedItem]);
          console.log('selectedrr:', selectedRows3);
        }
        };

	const deleteSelectedRows = (classItem) => { //handle the delete of student from the class selected
    if (selectedRows3.length > 0){
    const confirmDelete = window.confirm("Delete this student?");

    if (confirmDelete) {
		const db6 = getDatabase();
	  
		selectedRows3.forEach((item) => {
		  const { Instructor, Level, ProgramCode, DurationFrom, uid, Student } = item;
		  const refToDelete = ref(db6, `BTECClassStudentList/${Instructor}/${Level}/${ProgramCode}/${DurationFrom}/${uid}`);
		  console.log("Instructor:", Instructor); // Log the reference for debugging purposes
		  console.log("Level:", Level); // Log the reference for debugging purposes
		  console.log("ProgramCode:", ProgramCode); // Log the reference for debugging purposes
		  console.log("DurationFrom:", DurationFrom); // Log the reference for debugging purposes
		  console.log("uid:", uid); // Log the reference for debugging purposes
	  
		  remove(refToDelete)
			.then(() => {
				alert("Student have been removed from class.");
			  console.log("Data deleted successfully.");
			  console.log(`Data for Instructor: ${Instructor}, Level: ${Level}, ProgramCode: ${ProgramCode}, DurationFrom: ${DurationFrom}, uid: ${uid} deleted successfully.`);
			  findStudentClass(classItem);
			})
			.catch((error) => {
				alert("Error on removing the Student from the class.");
			  console.error("Error deleting data:", error);
			  console.error(`Error deleting data for Instructor: ${Instructor}, Level: ${Level}, ProgramCode: ${ProgramCode}, DurationFrom: ${DurationFrom}, uid: ${uid}`);
			});
		});
  }
}
else{
  alert("No Student is selected.");
}
	  };

    const AdminSearchClass = (e) => { //handle the function to search specific class using subject name/code
      e.preventDefault();
      // Check if the search input is empty
      if (!searchClass) {
      alert('Please enter the Student Name/ID');
      return; // Return early without making any changes
      }
      // Perform the search logic based on the selected student ID
      const filteredSearchClass = data.filter((classsearch) => {
      const SearchProgramName = classsearch.ProgramName.toLowerCase(); //search any possible result that have the letters/numbers
      const SearchProgramCode = classsearch.ProgramCode.toLowerCase();
      const SearchInstructor = classsearch.Instructor.toLowerCase();
      const searchInput = searchClass.toLowerCase();
      return SearchProgramName.includes(searchInput) || SearchProgramCode.includes(searchInput) || SearchInstructor.includes(searchInput);
      });
      // Update the foundStudents state with the filtered students array
      setFilteredSearchClass(filteredSearchClass);

      if (filteredSearchClass.length === 0) {
      alert('The Subject Code/Name does not exists.');
      }
      else{
        alert('Results Found!');
      }
      console.log(`filtered Class: `, filteredSearchClass);

      };

      const AdminSearchStudent = (e, classitem) => { 
        e.preventDefault();
        console.log("classitem data: ", classitem)
      
        if (!searchStudent) {
          alert('Please enter the Student Name/ID');
          return;
        }
      
        const filteredSearchStudent = data3
          .filter((studentsearch) => {
            const SearchStudentName = studentsearch.name.toLowerCase(); 
            const SearchStudentID = studentsearch.student.toLowerCase();
            const searchInput = searchStudent.toLowerCase();
      
            return SearchStudentName.includes(searchInput) || SearchStudentID.includes(searchInput);
          })
          .filter((studentsearch) => {
            //console.log("studentsearch.skmprogramcode: ",  studentsearch.skmprogramcode)
            //console.log("studentsearch.durationfrom: ",  studentsearch.durationfrom)
            return studentsearch.skmprogramcode === classitem.ProgramCode ;
            //&& studentsearch.durationfrom === classitem.DurationFrom;
          });
      
        setFilteredSearchStudent(filteredSearchStudent);
        //console.log("filteredSearchStudent: ",  filteredSearchStudent.length)
      
        if (filteredSearchStudent.length === 0) {
          alert('The Student Name/ID does not match with the course that they have taken.');
        }
        else if (filteredSearchStudent.length > 0){
        alert('Student Found!');
      }
      
        console.log(`filtered Class: `, filteredSearchStudent);
      };      

    const clearTable = () => { //clear the table data
      setSelectedRows3([]);
      setFilteredSearchStudent([]);
      setFilteredStudentClass([]);
      setSearchStudent("");
      setExcelData(null);
      setExcelFile(null);

    };

    const addStudenttoClass = async (name, studentID, ProgramCode, durationfrom, durationuntil, Instructor, level) => {
      //console.log("data pressed: ", name,studentID,ProgramCode,durationfrom,durationuntil,Instructor, level);
      const confirmAdd = window.confirm("Add this student into this class?"); //confirmation windows
    
      if (confirmAdd) {
      const db3 = getDatabase();
		  const usersRef = ref(db3, "BTECClassStudentList");
			const instructorRef = child(usersRef, Instructor);
			const levelRef = child(instructorRef,level);
      const programcodeRef = child(levelRef, ProgramCode);
      const durationfromRef = child(programcodeRef, durationfrom);

      const existingStudent2 = data3.find((student) => student.student === studentID);
      const studentuid2 = existingStudent2.uid;

      //await set(child(lectRef, studentID), {
        await set(child(durationfromRef, studentuid2), {
        Instructor: Instructor,
        durationFrom: durationfrom,
        durationUntil: durationuntil,
        programcode: ProgramCode,
        student: studentID,
        studentname: name,
        uid: studentuid2,
      });
      alert("Student have been added!.")
      console.log("Student have been added!");
    }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

	  const sortedRows = [...data].sort((a, b) => { //sort by year/month of the class data
      // Sort by year
      if (b.year !== a.year) {
        return b.year - a.year;
      }
      // If years are the same, sort by month in descending order
      return b.month - a.month;
      });
  
      const sortedRows2 = [...filteredSearchClass].sort((a, b) => { //sort by year/month of the searched class data
      // Sort by year
      if (b.year !== a.year) {
        return b.year - a.year;
      }
      // If years are the same, sort by month in descending order
      return b.month - a.month;
      });

    //pagination for class table
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(sortedRows.length / rowsPerPage);

    //pagination for searched class table
    const indexOfLastRow2 = currentPage2 * rowsPerPage;
    const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
    const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
    const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);	  

    const handlePageChange = (pageNumber) => { //handle page change for class table
    setCurrentPage(pageNumber);
    };

    const handlePageChange2 = (pageNumber) => { //handle page change for searhed class table
      setCurrentPage2(pageNumber);
    };

    const [excelFile, setExcelFile] = useState (null);
    const [typeError, setTypeError] = useState (null);
    const [excelData, setExcelData] = useState (null);
    const [excelName, setExcelName] = useState (null);

	  const inputRef = useRef(null);

        const handleFile = (e) => {
            let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
            let selectedFile = e.target.files[0];
            if(selectedFile){
                if (selectedFile && fileTypes.includes(selectedFile.type)){
                    setTypeError(null);
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(selectedFile);
                    reader.onload = (e) => {
                        setExcelFile(e.target.result);
                    }
                }
                else{
                    setTypeError('Please select only excel file types!');
            alert("Please select only excel file types!");

                    // Reset the file input value
                if (inputRef.current) {
                  inputRef.current.value = null;
                  }
            
                    setExcelFile(null);
                }

                console.log(selectedFile.type);
                console.log('selectedfile: ', selectedFile);
                console.log('Filename: ', selectedFile.name);

                // Extract only the file name without extension
              const fileNameWithoutExtension = selectedFile.name.split('.')[0];
              setExcelName(fileNameWithoutExtension);
              console.log('Filename without: ', excelName);
            }
            else{
                console.log("Please select your file.");
            }
        };
	
      const handleFileSubmit = (e) => {
            e.preventDefault();
            if(excelFile !== null){
                const workbook = XLSX.read(excelFile,{type: 'buffer'});
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                //setExcelData(data.slice(0,10));
                setExcelData(data);

          alert("Student Data have been extracted!")
                console.log('exceldata: ', excelData);
                console.log('worksheetname: ', worksheetName);

            } else {

          alert("The file format selected is not supported!");
        }
        };

  const addStudentFile = async (ProgramCode, ProgramName, Instructor, Level, DurationFrom, DurationUntil) => {
    console.log('excelData Content: ', ProgramCode, ProgramName, Instructor, Level, DurationFrom, DurationUntil);
    console.log('excelData Content: ', excelData);
    if (excelData !== null){
    const confirmAdd = window.confirm("Add this student into this class?"); //confirmation windows
      
    if (confirmAdd) {
    const db3 = getDatabase();
    const usersRef = ref(db3, "BTECClassStudentList");
    const instructorRef = child(usersRef, Instructor);
    const levelRef = child(instructorRef,Level);
    const programcodeRef = child(levelRef, ProgramCode);
    const durationfromRef = child(programcodeRef, DurationFrom);

    excelData.forEach(async(studentData) => {
      // Assuming your Excel data has the following columns: email, faculty, name, programmes, student
      const {
        "Student Name": extractstudentname,
        "Student ID": extractstudentid,
      } = studentData;

      const existingStudent = data3.find((student) => student.student === extractstudentid);
      //const studentuid = existingStudent.uid;

      if (existingStudent) {
        const studentuid = existingStudent.uid;

    await set(child(durationfromRef, studentuid), {
    studentname: extractstudentname,
    student: extractstudentid,
    uid: studentuid,
    durationFrom: DurationFrom,
    durationUntil: DurationUntil,
    Instructor: Instructor,
    programcode: ProgramCode,
    });

  }else {
      //alert(`Student with ID ${extractstudentid} not found in data6.`);
      alert(`Student with ID ${extractstudentid} not found in database.`);
    }
  });
    alert("Students have been added to classes!");
    console.log("Students have been added to classes!");
    console.log('excelData Content: ', excelData);
  }
}
else {
  alert("Please select an Excel file!")
  console.log('excelData Content fail: ', excelData);
}

  };
  
  return (
    <div /*class="body-addstudent"*/>
    <Btecadminsidebar> {/*show/call sidebar*/}
    <AdminbtecUsername /> {/*show/call username*/}
    <p><br/><br/></p>
          <div>
    <h1 class="h1-admineditstudent">Admin BTEC Add Student To Class/Delete Student From Class</h1>
          </div>
          <div /*className="addstudent-container"*/> 

      <p><br/><br/><br/><br/></p>

      <div>
        <div>

      <p class="p-addstudent4"> SKM Class List</p>

      <div className="adminregister-search-container">
				<form onSubmit={AdminSearchClass}>
				<label>Search:&emsp;</label>
        <div className="input-icon-container">
				<input 
        title='Please enter the Specific Subject Name/Code/Lecturer'
				className="input-editclass2"
				type="text"
				placeholder="Enter Subject Name/Code/Instructor Name"
				value={searchClass}
				onChange={(e) => setSearchClass(e.target.value)}
				>	
				</input>
        <FcSearch className="search-icon" onClick={AdminSearchClass} title="Search"/>
				</div>
				</form>
				</div>
        <p><br/><br/></p>

      <div className="table-container-editclass">

        {/*<table class="table-studentclass">*/}
        <table>
      <thead class="th-addstudent">
        <tr>

    <th style={{fontSize:'18px'}}>Class</th>
    <th style={{fontSize:'18px'}}>Duration of Studies</th>
    <th style={{fontSize:'18px'}}>Instructor Name</th>
    <th style={{fontSize:'18px'}}>Add/Delete Student</th>
        </tr>
      </thead>
      <tbody>
  {currentRows2.length > 0 ?(
      currentRows2.map((classitem, index) => (
        <tr key={`${classitem.SubjectCode}_${index}`}>
          <td className="editclass-td" style={{fontWeight:'bolder'}}>{classitem.ProgramCode} / {classitem.ProgramName}</td>
          <td className="editclass-td" style={{fontWeight:'bolder'}}>{classitem.DurationFrom}<br/> Until <br/>{classitem.DurationUntil}</td>
          <td className="editclass-td" style={{fontWeight:'bolder'}}>{classitem.Instructor}</td>
      <td className="editclass-td">
      
      <div className='container'>
      <Popup trigger={<button className='button-GenQRv2' title='Add Student by searching their Name/Student ID'><MdAddCircle className='admin-view-attendance-icon3'/> INDIVIDUAL</button>} modal nested>
        {close => (
          <div className="modal">
            <button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
            <p><br /><br /></p>
            <h2 className="h2-studentclass">Admin Add Student</h2>
            <p><br/></p>
            
            <p className="p-history2">&emsp;Class: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
            <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
            <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>

            <p><br/><br/></p>
            <div className="adminregister-search-container">
              <form onSubmit={(e) => AdminSearchStudent(e, classitem)}>
                <label>Search:&emsp;</label>
                <div className="input-icon-container">
                  <input 
                    className="input-editclass2"
                    type="text"
                    title='Enter Student Name/ID to View Student details'
                    placeholder="Enter Student Name/ID"
                    value={searchStudent}
                    onChange={(e) => setSearchStudent(e.target.value)}
                  />	
                  <FcSearch className="search-icon" onClick={(e) => AdminSearchStudent(e, classitem)} title="Search"/>
                </div>
              </form>
            </div>

            <p><br/><br/><br/></p>
            <table className="table-editstudent">
              <thead className="th-addstudent">
                <tr>
                  <th>Name</th>
                  <th>Student ID</th>
                  <th>Course</th>
                  <th>Duration</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSearchStudent.length > 0 ? (
                  filteredSearchStudent.map((studentItem) => (
                    <tr key={studentItem.student}>
                      <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.name}</td>
                      <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.student}</td>
                      <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.skmprogramcode} {studentItem.skmprogram}</td>
                      <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.durationfrom}<br/> Until <br/>{studentItem.durationuntil}</td>
                      <td className="editstudent-td">
                        <button className='button-GenQR6' 
                      onClick={() => { addStudenttoClass(studentItem.name, studentItem.student, 
                        studentItem.skmprogramcode, classitem.DurationFrom, classitem.DurationUntil, classitem.Instructor, classitem.Level); }}>
                          <MdAddCircle className='admin-view-attendance-icon3'/>ADD
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No Student found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Popup>

      <p><br/></p>
     
      <Popup trigger={<button className='button-GenQRv2' title='Add Using Excel File(Must Follow the template given.)' /*className="button-studentclass3"*/><MdAddCircle className='admin-view-attendance-icon3' style={{ fontSize: '30px' }}/>{/*ADD */} FILE</button>} modal nested>
      {close => (
        <div className="modal">
        <button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
        <p><br /><br /></p>
        <h2 className="h2-studentclass">Admin Add Using File</h2>
        <p><br/></p>
       
        <p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
        <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
        <p className="p-history2">&emsp;Level: <b>{classitem.Level}</b> </p>
        <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>

        <p><br/><br/></p>

          <form onSubmit={handleFileSubmit}>
          <div className="container">
          <p>Select File: &emsp;</p>
          <input title="Select Excel file to upload" className="p-createclass registerstudent-container3" type="file" required onChange={handleFile} ref={inputRef}></input>
          <p><br/></p>
          <button type="submit" title='Show the data from the selected Excel File.' className='button-GenQR' /*className="button-addStudent6"*/><FaEye className='button-view-attendance-icons3' style={{ fontSize: '30px' }}/>&nbsp;&nbsp;<b>SHOW DATA</b></button>
          </div>
          </form>

        <p><br/></p>

                    {typeError&&(
                          <div role="alert"> {typeError} </div>
                      )}
                      <p><br/></p>

                
                {excelData?(
                        <div class="table-container-editclass2">
                              <table class="table-editstudent2">
                                  <thead className="th-addstudent">
                                      <tr>
                                          {Object.keys(excelData[0]).map((key) => (
                                              <th key={key}>{key}</th>

                                          ))}
                                      </tr>
                                  </thead>
                                  
                                  <tbody>
                                      {excelData.map((individualExcelData, index) => (
                                          <tr key={index}>
                                              {Object.keys(individualExcelData).map((key)=>(
                                              <td style={{fontWeight:'bolder'}} key={key}>{individualExcelData[key]}</td>
                                              ))}
                                          </tr>
                                      
                                      ))}
                                  </tbody>

                              </table>
                          </div>

                      ):(
                          <div>No file Uploaded!</div>
                      )}

                      <p><br/></p>

              <button className='button-admin-view-attendance8'/*className="button-studentclass2"*/ title='Add the student from the selected Excel File.'
              onClick={() => { addStudentFile(classitem.ProgramCode, classitem.ProgramName, classitem.Instructor, classitem.Level, classitem.DurationFrom, classitem.DurationUntil); }}>
              <MdPersonAdd className='button-view-attendance-icons3' style={{ fontSize: '30px' }}/>ADD</button>

        </div>
      )}
      </Popup>
        
      <Popup trigger={<button className='button-admindelete-class' title='Delete student from class.'/*className="button-studentclass3"*/><MdDelete className='admin-view-attendance-icon3' style={{ fontSize: '30px' }}/>DELETE</button>} modal nested>
      {close => (
        <div className="modal">
        <button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }}>&times;</button>
        <p><br/><br /></p>
        <h2 className="h2-studentclass">Student List</h2>
        <p><br/></p>

        <p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
        <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
        <p className="p-history2">&emsp;Level: <b>{classitem.Level}</b> </p>
        <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>
        <br/>

        <button className="button-admin-view-attendance8" title='View the Student list of this class'/*className="button-studentclass"*/ onClick={() => findStudentClass(classitem)}><FaEye className='admin-view-attendance-icon3'/>&nbsp;&nbsp;VIEW STUDENT LIST</button>
        <p><br/><br/></p>
        <div class="table-container-editclass2">
          <table className='table-editstudent2'>
            <thead className="th-addstudent">
          <tr>
            <th>Name</th>
            <th>Student ID</th>
            {/*<th>Course</th>
            <th>Duration</th>*/}
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {filteredStudentClass.length > 0 ? (
              filteredStudentClass.map((studentClassItem, index) => {
                // Find the program in the data array where ProgramCode matches studentClassItem.ProgramCode
                const programDetails = data.find(item => item.ProgramCode === studentClassItem.ProgramCode);
                const programName = programDetails ? programDetails.ProgramName : ''; // Get the ProgramName or set it to an empty string if not found

                return (
                  <tr key={`${studentClassItem.Student}_${index}`} className={selectedRows3.includes(studentClassItem.StudentName) ? 'selected' : ''}>
                    <td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.StudentName}</td>
                    <td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.Student}</td>
                    {/*<td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.ProgramCode} {programName}</td>
                    <td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.DurationFrom} <br/>Until<br/> {studentClassItem.DurationUntil}</td>*/}
                    <td className="editclass-td">
                      <input
                        className="checkbox-size-addstudent"
                        title='Select to pick this student'
                        type="checkbox"
                        onChange={() => handleCheckboxChange2(classitem.Instructor, classitem.Level, classitem.ProgramCode, classitem.DurationFrom, classitem.uid, studentClassItem.Student)}
                        checked={selectedRows3.some((item) => item.Instructor === classitem.Instructor && item.Level === classitem.Level && item.ProgramCode === classitem.ProgramCode && item.DurationFrom === classitem.DurationFrom && item.uid === studentClassItem.uid && item.Student === studentClassItem.Student)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">No data available.</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <p><br></br></p>
        <button className='button-admindelete-class' title='Delete student from class.' /*class="button-studentclass2"*/ onClick={() => deleteSelectedRows(classitem)}><MdDelete className='admin-view-attendance-icon3' />DELETE STUDENT</button>
        <p><br></br></p>
        <p className="p-studentclass-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
        <p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
        </div>
      )}
      </Popup>

      </div>
      

          </td>
      </tr>
      ))
      ) /*: currentRows.length > 0 ?(
        currentRows.map((classitem, index) => (
          <tr key={`${classitem.SubjectCode}_${index}`}>
            <td className="editstudent-td" style={{fontWeight:'bolder'}}>{classitem.ProgramCode} / {classitem.ProgramName}</td>
            <td className="editstudent-td" style={{fontWeight:'bolder'}}>{classitem.DurationFrom}<br/> <b>Until</b> <br/>{classitem.DurationUntil}</td>
            <td className="editstudent-td" style={{fontWeight:'bolder'}}>{classitem.Instructor}</td>
        <td className="editstudent-td">
        
        <div className='container'>
        <Popup trigger={<button className='button-GenQRv2' title='Add Student by searching their Name/Student ID'><MdAddCircle className='admin-view-attendance-icon3'/> INDIVIDUAL</button>} modal nested>
    {close => (
      <div className="modal">
        <button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
        <p><br /><br /></p>
        <h2 className="h2-studentclass">Admin Add Student</h2>
        <p><br/></p>
        
        <p className="p-history2">&emsp;Class: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
        <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
        <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>

        <p><br/><br/></p>
        <div className="adminregister-search-container">
          <form onSubmit={(e) => AdminSearchStudent(e, classitem)}>
            <label>Search:&emsp;</label>
            <div className="input-icon-container">
              <input 
                className="input-editclass2"
                type="text"
                title='Enter Student Name/ID to View Student details'
                placeholder="Enter Student Name/ID"
                value={searchStudent}
                onChange={(e) => setSearchStudent(e.target.value)}
              />	
              <FcSearch className="search-icon" onClick={(e) => AdminSearchStudent(e, classitem)} title="Search"/>
            </div>
          </form>
        </div>

        <p><br/><br/><br/></p>
        <table className="table-editstudent">
          <thead className="th-addstudent">
            <tr>
              <th>Name</th>
              <th>Student ID</th>
              <th>Course</th>
              <th>Duration</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSearchStudent.length > 0 ? (
              filteredSearchStudent.map((studentItem) => (
                <tr key={studentItem.student}>
                  <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.name}</td>
                  <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.student}</td>
                  <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.skmprogramcode} {studentItem.skmprogram}</td>
                  <td className="editstudent-td" style={{fontWeight:'bolder'}}>{studentItem.durationfrom}<br/> Until <br/>{studentItem.durationuntil}</td>
                  <td className="editstudent-td">
                    <button className='button-GenQR6' 
                      onClick={() => { addStudenttoClass(studentItem.name, studentItem.student, 
                        studentItem.skmprogramcode, classitem.DurationFrom, classitem.DurationUntil, classitem.Instructor, classitem.Level); }}>
                      <MdAddCircle className='admin-view-attendance-icon3'/>ADD
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No Student found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )}
  </Popup>

      <p><br/></p>
        
        <Popup trigger={<button className='button-GenQRv2' title='Add Using Excel File(Must Follow the template given.)'><MdAddCircle className='button-view-attendance-icons3' style={{ fontSize: '30px' }}/> USING FILE</button>} modal nested>
      {close => (
        <div className="modal">
        <button className="button-studentclass4" title='Close menu' onClick={() => { close(); clearTable(); }} >&times;</button>
        <p><br /><br /></p>
        <h2 className="h2-studentclass">Admin Add Using File</h2>
        <p><br/></p>

        <p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
        <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
        <p className="p-history2">&emsp;Level: <b>{classitem.Level}</b> </p>
        <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>

        <p><br/><br/></p>

      <form onSubmit={handleFileSubmit}>
			<div className="container">
			<p>Select File: &emsp;</p>
			<input title="Select Excel file to upload" className="p-createclass registerstudent-container3" type="file" required onChange={handleFile} ref={inputRef}></input>
			<p><br/></p>
      <button type="submit" title='Show the data from the selected Excel File.' className='button-GenQR' ><FaEye className='button-view-attendance-icons3' style={{ fontSize: '30px' }}/>&nbsp;&nbsp;<b>SHOW DATA</b></button>
      </div>
				</form>
        <p><br/></p>

          {typeError&&(
                          <div role="alert"> {typeError} </div>
                      )}
                      <p><br/></p>

                {excelData?(
                        <div class="table-container-editclass2">
                              <table class="table-editstudent2">
                                  <thead className="th-addstudent">
                                      <tr>
                                          {Object.keys(excelData[0]).map((key) => (
                                              <th key={key}>{key}</th>

                                          ))}
                                      </tr>
                                  </thead>
                                  
                                  <tbody>
                                      {excelData.map((individualExcelData, index) => (
                                          <tr key={index}>
                                              {Object.keys(individualExcelData).map((key)=>(
                                              <td style={{fontWeight:'bolder'}} key={key}>{individualExcelData[key]}</td>
                                              ))}
                                          </tr>
                                      
                                      ))}
                                  </tbody>

                              </table>
                          </div>


                      ):(
                          <div>No file Uploaded!</div>
                      )}

                      <p><br/></p>

              <button className='button-admin-view-attendance8' title='Add the student from the selected Excel File.'
              onClick={() => { addStudentFile(classitem.ProgramCode, classitem.ProgramName, classitem.Instructor, classitem.Level, classitem.DurationFrom, classitem.DurationUntil); }}>
              <MdPersonAdd className='button-view-attendance-icons3' style={{ fontSize: '30px' }}/>ADD</button>

        </div>
      )}
      </Popup>

      <p><br/></p>
        
        <Popup trigger={<button className='button-admindelete-class' title='Delete student from class.' ><MdDelete className='admin-view-attendace-icon3' style={{ fontSize: '30px' }}/>DELETE</button>} modal nested>
        {close => (
          <div className="modal">
          <button className="button-studentclass4" onClick={() => { close(); clearTable(); }}>&times;</button>
          <p><br/><br/></p>
          <h2 className="h2-studentclass">Student List</h2>
          <p><br/></p>

          <p className="p-history2">&emsp;Program: <b>{classitem.ProgramCode} {classitem.ProgramName}</b>  </p>
        <p className="p-history2">&emsp;Instructor: <b>{classitem.Instructor}</b> </p>
        <p className="p-history2">&emsp;Level: <b>{classitem.Level}</b> </p>
        <p className="p-history2">&emsp;Duration: <b>{classitem.DurationFrom} Until {classitem.DurationUntil}</b> </p>
        <br/>

          <button className="button-admin-view-attendance8" title='View the Student list of this class' onClick={() => findStudentClass(classitem)}><FaEye className='admin-view-attendance-icon3'/>&nbsp;&nbsp;VIEW STUDENT LIST</button>
          <p><br/><br/></p>

          <div class="table-container-editclass2">
          <table className='table-editstudent2'>
            <thead className="th-addstudent">
            <tr>
            <th>Name</th>
            <th>Student ID</th>
            <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {filteredStudentClass.length > 0 ? (
              filteredStudentClass.map((studentClassItem, index) => {
                const programDetails = data.find(item => item.ProgramCode === studentClassItem.ProgramCode);
                const programName = programDetails ? programDetails.ProgramName : '';

                return (
                  <tr key={`${studentClassItem.Student}_${index}`} className={selectedRows3.includes(studentClassItem.StudentName) ? 'selected' : ''}>
                    <td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.StudentName}</td>
                    <td className="generateqr-td" style={{fontWeight:'bolder'}}>{studentClassItem.Student}</td>
                    <td className="editclass-td">
                      <input
                        className="checkbox-size-addstudent"
                        title='Select to pick this student'
                        type="checkbox"
                        onChange={() => handleCheckboxChange2(classitem.Instructor, classitem.Level, classitem.ProgramCode, classitem.DurationFrom, studentClassItem.uid, studentClassItem.Student)}
                        checked={selectedRows3.some((item) => item.Instructor === classitem.Instructor && item.Level === classitem.Level && item.ProgramCode === classitem.ProgramCode && item.DurationFrom === classitem.DurationFrom && item.uid === studentClassItem.uid && item.Student === studentClassItem.Student)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">No data available.</td>
              </tr>
            )}

            </tbody>
          </table>
          </div>
          <p><br/></p>
          <button className='button-admindelete-class' title='Delete student from class.' onClick={() => deleteSelectedRows(classitem)}><MdDelete className='admin-view-attendance-icon3'/>DELETE STUDENT</button>
          <p><br></br></p>
          <p className="p-studentclass-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
          <p className="p-studentclass-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
          </div>
        )}
        </Popup>
        </div>

            </td>
        </tr>
        ))
        )*/ : (
            <tr>
            <td colSpan="5">No Class found.</td>
            </tr>
            )}
            </tbody>
            </table>
      </div>
      <p><br/></p>

          {currentRows.length > 0 && totalPages > 1 && currentRows2.length === 0 && (
            <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
              <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`button-addStudent5 ${currentPage === pageNumber ? "active" : ""}`}
              >
              {pageNumber}
              </button>
            ))}
            </div>
          )}

          {currentRows2.length > 0 && totalPages2 > 1 && (
            <div className="pagination">
            {Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
              <button
              key={pageNumber}
              onClick={() => handlePageChange2(pageNumber)}
              className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
              >
              {pageNumber}
              </button>
            ))}
            </div>
          )}
          <p><br/><br/><br/></p>
          
          <p><b>*To View the class list please enter the Subject Code/Subject Name/ Instructor Name</b></p>
          <p><b>*To view the student list, Click DELETE {'>'} VIEW STUDENT</b></p>
      </div>

      </div>
      </div>
      
      </Btecadminsidebar>
      </div>
      );
      };

export default AdminbtecEditStudent;