import React, { useState, useEffect } from 'react';
import { /*useNavigate,*/ Link } from 'react-router-dom';
import { auth } from "../firebase";
//import { createUserWithEmailAndPassword, deleteUser, sendEmailVerification } from "firebase/auth";
import { getDatabase, /*onValue,*/ref, get,/*push,*/ set, child, remove} from "firebase/database";
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import '../admregister/AdminRegister.css';
import '../admregister/AdminRegisterNew.css';
import { FcSearch } from "react-icons/fc";
import { MdDelete, MdRefresh } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";

const AdminbtecRegAdmin = () => {

	const [data, setData] = useState([]);

    const [AdminEmail, setAdminEmail] = useState("");
    const [AdminName, setAdminName] = useState("");
    const [AdminUID, setAdminUID] = useState("");

	const [BtecAdminEmail, setBtecAdminEmail] = useState("");
    const [BtecAdminName, setBtecAdminName] = useState("");
    const [BtecAdminUID, setBtecAdminUID] = useState("");

    const [searchAdmin, setSearchAdmin] = useState("");
    const [filteredAdmin, setFilteredAdmin] = useState([]);

	  const registerbtecnewAdmin = async (e) => {
		e.preventDefault();
		console.log(auth.currentUser.uid);

		const confirmRegister = window.confirm("Register this as admin?");

		if (confirmRegister) {
		try {
		  const db = getDatabase();
		  const usersRef = ref(db, "BtecAdmin");
		  const AdminRole = "Normal";

			await set(child(usersRef, BtecAdminUID), {
			  uid: BtecAdminUID,
			  BtecAdminEmail,
			  BtecAdminName,
			  AdminRole,
              ImageURL: "",
			});

			//to send an email verification to the e-mail
			//await sendEmailVerification(auth.currentUser);
	  
			console.log("New Admin registered:", auth.currentUser.uid);
			alert(BtecAdminEmail +" have been registered.")
			//navigate('/admregister/admindashboard');
        /*}*/
		} catch (error) {
			alert(`Email: ${BtecAdminEmail} is already registered.`);
		  console.log(error.message);
		}
	}
	  };
	  //if the newly btecadmin already existed in the 

	  const validateForm = () => {
		if (
			BtecAdminName === "" ||
		  BtecAdminEmail === "" ||
		  BtecAdminUID === "" 
		  //|| AdminRole === ""
		) {
		  alert("Please fill in all fields");
		  return false;
		}
		return true;
	  };

	const register = async (e) =>{
		e.preventDefault();
		if (!validateForm()) {
			return;
		  }
		
		//await RegisterNew();
		await registerbtecnewAdmin(e);

		  setBtecAdminName("");
		  setBtecAdminEmail("");
		  //setAdminRole("");
          setBtecAdminUID("");
	};

	  useEffect(() => {
		const fetchAdmin = async () => {
		  try {
			const db = getDatabase();
			const adminRef = ref(db, "BtecAdmin");
			const snapshot = await get(adminRef);
			if (snapshot.exists()) {
			  const adminData = snapshot.val();
			  const adminArray = Object.values(adminData);
			  setData(adminArray);
			} else {
			  setData([]);
			}
		  } catch (error) {
			console.log(error.message);
		  }
		};
	
		fetchAdmin();
	  }, []);

	  const DeleteAdmin = async (uid, email) => {
		const db = getDatabase();
		const confirmDelete = window.confirm("Are you sure you want to delete the selected Admin?");
	  
		if (confirmDelete) {
		  try {
			// Delete lecturer data from the Realtime Database
			const dbRef = ref(db, `Admin/${uid}`);
			await remove(dbRef);
			alert(`Admin with UID ${email} deleted successfully from the Realtime Database.`)
			console.log(`Admin with UID ${uid} deleted successfully from the Realtime Database.`);
	  
			// Delete user's email from Firebase Authentication
			//await deleteUser(auth, uid);
	  
			console.log(`Email ${email} deleted successfully from Firebase Authentication.`);
	  
			alert(`${email} has been deleted.`);
		  } catch (error) {
			console.error("Error deleting Admin:", error.message);
		  }
		}
	  };

	  const FindAdmin = (e) => {
		e.preventDefault();

	    // Check if the search input is empty
		if (!searchAdmin) {
			alert('Please enter the Admin Name/E-Mail');
			return; // Return early without making any changes
		}

		// Perform the search logic based on the selected student ID
		const filteredAdmin = data.filter((admin2) => {
			const AdminNames = admin2.AdminName.toLowerCase();
			const AdminEmails = admin2.AdminEmail.toLowerCase();
			const searchInput = searchAdmin.toLowerCase();
		
			return AdminNames.includes(searchInput) || AdminEmails.includes(searchInput);
		  });
	  
		// Update the foundStudents state with the filtered students array
		setFilteredAdmin(filteredAdmin);
		
		if (filteredAdmin.length === 0) {
			alert('The Admin Name/E-Mail does not exists.');
		  }
		  console.log(`filtered Admin: `, filteredAdmin);
	  };

	  const resetfntn = () => {
		window.location.reload(); 
	  };

	return (
		
		<div /*className="body-adminregister"*/>
		<Btecadminsidebar> {/*show/call sidebar*/}
		<AdminbtecUsername /> {/*show/call username*/}
		<p><br/><br/></p>
		<div>
		<h1 className="h1-adminregister">Admin Registration</h1>
		</div>
		
		<div /*className="adminregister-container"*/>
			<p><br></br></p>

			<div className="container">
			<label class="p-adminregister2">&emsp;Admin Name: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;</label>
			<div className="container">
				<input title = "Enter Admin Name" className="input-adminregister"
					type="text"
					placeholder="Enter Admin name"
					value={BtecAdminName}
					onChange={(e) => setBtecAdminName(e.target.value) }>
					</input>
					</div>

					<p><br/></p>

				<label class="p-adminregister2">&emsp;Admin E-mail: <b className="important-createclass">*</b>&emsp;&emsp;</label>
				<div className="container">
				<input className="input-adminregister"
					type="email"
					placeholder="Enter Admin email"
					value={BtecAdminEmail}
					title='The email must be registered as a lecturer first.'
					onChange={(e) => setBtecAdminEmail(e.target.value) }>
					</input>
					</div>
					</div>
					<p><br></br></p>
			
					
					<p><br/><br/></p>

				<div className="container">
				<label class="p-adminregister2"><br></br>&emsp;UID: <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;</label>
				<input className="input-adminregister"
					type="text"
					placeholder="Enter UID"
                    title='UID can be refer in the Firebase Authentication Tab.'
					value={BtecAdminUID}
					onChange={(e) => setBtecAdminUID(e.target.value) }>
				</input>

				<p><br/></p>

				</div>	

				<p><br/><br/></p>

				<button title= "Click to Register Admin" onClick={register} className='button-admin-view-attendance-admin'><AiOutlineUserAdd className='admin-view-attendance-icon3'/><b>REGISTER ADMIN</b></button>

				<p><b>*The form marked with <b className="important-createclass">*</b> is compulsory.</b></p>
			
					<p class="p-adminregister3"><br></br>Admin List</p>
				
				<div className="adminregister-search-container">
				<form title='Search Admin' onSubmit={FindAdmin}>
				<label>Search:&emsp;</label>
				<div className="input-icon-container">
				<input 
				className="input-adminregister2"
				type="text"
				placeholder="Enter Admin Name/E-Mail"
				value={searchAdmin}
				onChange={(e) =>setSearchAdmin(e.target.value)}
				>	
				</input>
				<FcSearch className="search-icon" onClick={FindAdmin} title="Search"/>
				</div>
				</form>
				</div>

				<p><br/><br/></p>
				<div class="table-container-adminregister">
				<table>
            <thead>
              <tr>
                <th style={{fontSize: '18px'}}>Admin Name</th>
                <th style={{fontSize: '18px'}}>Admin E-Mail</th>
                <th style={{fontSize: '18px'}}>Role</th>
				<th style={{fontSize: '18px'}}>Action</th>
              </tr>
            </thead>
			<tbody>
			{filteredAdmin.length > 0 ? (
				filteredAdmin
				.sort((a, b) => a.BtecAdminName.localeCompare(b.BtecAdminName)) // Sort by AdminName
				.map((Admin) => (
				<tr key={Admin.uid}>
					<td style={{fontWeight: 'bold'}}>{Admin.BtecAdminName}</td>
					<td>{Admin.BtecAdminEmail}</td>
					<td>{Admin.AdminRole}</td>
					<td>
					{Admin.AdminRole !== 'Super' && ( // Check if role is not 'super'
					<button className="button-delete-adminregister-new" title='Delete Admin' onClick={() => DeleteAdmin(Admin.uid, Admin.BtecAdminEmail)} >
					<MdDelete className="admin-register-icon"/> 
					<b>DELETE ADMIN</b>
				</button>
					)}
					</td>
				</tr>
				))
			) : data.length > 0 ? (
				data
				.sort((a, b) => a.BtecAdminName.localeCompare(b.BtecAdminName)) // Sort by lecturerName
				.map((Admin) => (
				<tr key={Admin.uid}>
					<td style={{fontWeight: 'bolder'}}>{Admin.BtecAdminName}</td>
					<td style={{fontWeight: 'bolder'}}>{Admin.BtecAdminEmail}</td>
					<td style={{fontWeight: 'bolder'}}>{Admin.AdminRole}</td>
					<td>
					{Admin.AdminRole !== 'Super' && ( // Check if role is not 'super'
					<button className="button-delete-adminregister-new" title='Delete Admin' onClick={() => DeleteAdmin(Admin.uid, Admin.BtecAdminEmail)} >
						<MdDelete className="admin-register-icon"/> 
						<b>DELETE ADMIN</b>
					</button>
					)}
					</td>
				</tr>
				))
			) : (
				<tr>
				<td colSpan="4">No Admin data found.</td>
				</tr>
			)}
			</tbody>

          </table>
				</div>
				<p><br /></p>
				<p>*<b>Super Admin</b> can only be manually deleted in the database</p>
				<p>*User must be registered as an first before able to set as an admin!&nbsp;<Link to ='/btec/adminbtec/Admin_BTEC_Register_Instructor' className="adminnewregister-link link-register" title = "Register as a lecturer before registring as an admin is enable">Register HERE first!</Link></p>

				<p><br /></p>
				<button title = "Refresh the page to view newly added admin" onClick={() => resetfntn()} className='button-admin-view-attendance9'>
				<MdRefresh className="admin-register-icon"/>
				<b>REFRESH PAGE</b>
				</button>

		</div>
		</Btecadminsidebar>
		</div>
		
	);
}

export default AdminbtecRegAdmin;