import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from "./firebase";
import { sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
import './ForgotPassword.css';

const ForgotPassword = () => {

	const [ForgotUserEmail, setForgotUserEmail] = useState("");   
	//const [message, setMessage] = useState({ error: false, msg: ""});
	const navigate = useNavigate(); //must declare if want to use navigate

	const gotosignin = () => { //navigate to signin.jsx
		navigate('./');
	};

	const SendResetPassword = async (e) => { //send an email to reser password if user is registered
		e.preventDefault(); //prevent from refreshing

		try {
			const methods = await fetchSignInMethodsForEmail(auth, ForgotUserEmail);
			if (methods.length === 0) {
			  console.log("Email does not exist in Firebase.");
			  alert("Email does not exist. Please make sure you enter the correct E-Mail. If any further assistance required, you may contact the Web Admin.");
			  // Display an error message to the user
			  return;
			}
		
			await sendPasswordResetEmail(auth, ForgotUserEmail); //send password reset email
			console.log("Password reset email sent successfully.");
			alert("Password reset email sent successfully.");
			// Optionally, display a success message to the user
		  } catch (error) {
			console.log("Error sending password reset email:", error);
			// Display an error message to the user
		  }

	  };

	return (
		<div className="body-forgot-password-container">
		<div className="forgotpassword-container">
			<div className="logo-position-register">
				{/*<img src="images/logo.png" alt="" />*/}
				<div className="admin-logo-position">
				{/*<img src="images/logored.png" alt="" width="80%" height="80%"/>*/}
				<img src="images/BTECLOGO.png" alt="" width="80%" height="80%"/>
				<p></p>
				<img src="images/nais_logo.png" alt="" width="90%" height="90%"/>
			</div>
			</div>
			
			<br/><br/><br/><br/><br/><br/><br/><br/>
			<h2 className="h2-signin">Forgot Password?</h2>

			
			<div className="user-detail">

			<div>
			<label>E-Mail:</label>
			<p><br></br></p>
				<input
				title="Enter your registered E-Mail"
					type="text"
					placeholder="Enter Your E-Mail"
					value={ForgotUserEmail}
					onChange={(e) => setForgotUserEmail(e.target.value) }>
					</input>
					</div>

					<div>
					<button onClick={SendResetPassword} className='button-forgotpassword'><b>Reset Password</b></button>
					</div>
				</div>
			
			<form onSubmit={gotosignin}>
				<div>
					<p><br/><br/></p>
			<label nameclass="account-notification"><b>Already have an account?</b></label>
			<Link to ='/' class ="register-link-user"> <b>Sign In</b></Link>
			</div>
			</form>
			
			{/*<form>
			<Link to ='/ForgotPassword' class ="forgot forgot-password">Forgot Password?</Link>
	</form>*/}

		</div>
		</div>
	);
}

export default ForgotPassword;