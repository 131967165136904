import { Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Attendance from "./Attendance";
import Students from "./StudentProfile";
import Profile from "./Profile";
import Logout from "./Logout";

function MainMenu() {
    return (
            <Sidebar>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/attendance" element={<Attendance />} />
                    <Route path="/students" element={<Students />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </Sidebar>

    );
};

export default MainMenu;
