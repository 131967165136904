import React, { useState, useEffect } from 'react';
import Btecusername from '../Btecusername';
import Btecsidebar from '../Btecsidebar';
import { getDatabase, ref, onValue, child, get,set,off} from "firebase/database";
import '../attendance/GenerateQR.css';
import '../attendance/FacultyList';
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useNavigate } from 'react-router-dom';
import { FcSearch } from "react-icons/fc";
import { BsQrCodeScan } from "react-icons/bs";
import { FaEye } from "react-icons/fa";

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths, format } from "date-fns"; //enabled the date past the current date

const BtecGenerateAttendance = () => {

    const [data5, setData5] = useState([]);
	const [data6, setData6] = useState([]);
	const [data7, setData7] = useState([]);
	
	const [startDate, setStartDate] = useState(new Date());
	const datepickerCustomFormat = "dd-MM-yyyy"; // Custom date format

	const [filteredClass, setFilteredClass] = useState([]);

	const [currentUser, setCurrentUser] = useState(null);
	const [filteredTotalStudents, setfilteredTotalStudents] = useState([]);
	const [filteredStudentClass, setfilteredStudentClass] = useState([]);

	const [attendanceTaken, setAttendanceTaken] = useState(""); //AM/PM
	const [instructorHelper, setinstructorHelper] = useState(""); //int value
	const [classWeek, setClassWeek] = useState(""); //week of the class

	const [selectedData4, setSelectedData4] = useState([]);
	const [timer, setTimer] = useState(null); // Declare the timer variable
	const [qrCodeValue, setQRCodeValue] = useState(""); // State to hold the QR code value
	const [PassedDate, setPassedDate] = useState(""); // State to hold the QR code value

	const [selectedDuration, setSelectedDuration] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [uniqueDurations, setUniqueDurations] = useState([]);
	
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const navigate = useNavigate();

	const [programData, setProgramData] = useState([]);
	const [btecCoursepick, setBtecCoursePick] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getDatabase();
            const btecProgramsRef = ref(db, 'BTECProgram');
            
            onValue(btecProgramsRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const programs = Object.entries(data).map(([programCode, programLevelData]) => {
                        const programLevels = Object.entries(programLevelData).map(([programLevel, levelData]) => {
                            // Extracting program course data
                            const courses = Object.values(levelData.BtecProgramCourse || {});
                            
                            // Extracting other level data
                            const { BtecProgramLevel, BtecProgramName } = levelData;
                            
                            return {
                                programLevel,
                                BtecProgramLevel,
                                BtecProgramName,
                                courses,
                            };
                        });

                        return {
                            programCode,
                            programLevels,
                        };
                    });

                    setProgramData(programs);
                } else {
                    console.log('No BTEC programs found.');
                }
            });
        };

        fetchData();
    }, []);

	const handleCourseChange = (event, programCode, programLevel) => {
		const selectedCourse = event.target.value;
		setBtecCoursePick(selectedCourse);
		console.log(`Selected course for ${programCode} - Level ${programLevel}:`, selectedCourse);
	};	

      useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                  //uid: studentData.uid,
                }));
                setData5(dataArray);
              }
            });
          }, []);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					ProgramName: BTECClassitem.ProgramName,
					DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
                   
				  };
				});
				});
			  });
			});
			setData6(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

    useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
					DurationUntil: BTECClassListitem.durationUntil,
                    uid: studentUID,
                    Student: BTECClassListitem.student,
					StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData7(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

	const getDayOfWeek = (dateString) => { //get the day based on the date
		// Convert from DD-MM-YYYY to YYYY-MM-DD
		const [day, month, year] = dateString.split('-');
		const isoDateString = `${year}-${month}-${day}`;
		
		const date = new Date(isoDateString);
		return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
	  };
	
	const findStudentClass = (classItem) => {
		const filteredStudentClass = data7.filter((studentClassItem) => {
			//classitem data2 class :BTECClass
			//studentClassItem data3 classstudentlist :data7
		  return (
			studentClassItem.Instructor === classItem.Instructor &&
			studentClassItem.Level === classItem.Level &&
			studentClassItem.ProgramCode === classItem.ProgramCode &&
			studentClassItem.DurationFrom === classItem.DurationFrom 
		  );
		});
	  
		//console.log('test check findstudent class', filteredClass)
		const matchingData = filteredStudentClass.map((studentClassItem) => {
		  const matchingStudent = data5.find((item) => item.student === studentClassItem.Student);
		  if (matchingStudent) {
			return { ...studentClassItem, uid: matchingStudent.uid };
		  }
		  return studentClassItem;
		});
	  
		console.log("Filtered Student Class:", matchingData);
		
		setfilteredStudentClass(matchingData);
	  };

	const generateUniqueKey = () => {
		//const timestamp = new Date().getTime(); // Get the current timestamp
		const random = Math.random().toString(36).substring(2, 10); // Generate a random string
	  
		//return `${timestamp}-${random}`; // Combine the timestamp and random string
		return `${random}`;
	  };

	  const [PassedProgramName, setPassedProgramName] = useState("");
	  const [PassedProgramCode, setPassedProgramCode] = useState("");
	  const [PassedInstructor, setPassedInstructor] = useState("");
	  const [PassedLevel, setPassedLevel] = useState("");

	  	const handleGenerateQR = (classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper) => {
		// Generate a unique key
		const uniqueKey = generateUniqueKey(); // Implement the logic to generate a unique key
		const formattedStartDate = format(startDate, 'dd-MM-yyyy');
		// Combine the values to create the QR code value
		//const qrValue = `${data.subjectName}, ${Venue}, ${formattedStartDate}, ${data.Lecturer}, ${uniqueKey}`;
		const qrValue = `${classItem.ProgramCode},${classItem.ProgramName},${classItem.Level},${formattedStartDate},${classItem.Instructor},${attendanceTaken},${btecCoursepick}`; //adjust qr to follow apps format
		console.log(qrValue);
		console.log(formattedStartDate);
		// Generate QR code using the qrValue
		// ...
		// Add the selected data to the array

		setSelectedData4((prevData) => [...prevData, { ...classItem, key: uniqueKey }]);
		setQRCodeValue(qrValue);
		setPassedDate(formattedStartDate);
		//setPassedInstructor(classItem.Instructor);
		setPassedProgramCode(classItem.ProgramCode);
		setPassedProgramName(classItem.ProgramName);
		setPassedLevel(classItem.Level);
		
		// Create a timer to change the unique key every 40 seconds
		console.log("selectedData4: ",selectedData4);
		const newTimer = setInterval(() => {
			const newKey = generateUniqueKey();
			setSelectedData4((prevData) =>
			  prevData.map((item) => {
				if (item.key === uniqueKey) {
				  return { ...item, key: newKey };
				}
				return item;
			  })
			);
		  }, 40000);

		  setTimer(newTimer); // Set the timer variable
		// Open the generated QR code in a new tab
	// Redirect to the QRattendance page with the generated QR code value
	navigate(`/btec/BtecQRAttendancepage?qrValue=${encodeURIComponent(qrValue)}
	&Date=${encodeURIComponent(formattedStartDate)}
	&Instructor=${encodeURIComponent(classItem.Instructor)}
	&ProgramName=${encodeURIComponent(classItem.ProgramName)}
	&ProgramCode=${encodeURIComponent(classItem.ProgramCode)}
	&Course=${encodeURIComponent(btecCoursepick)}
	&ClassWeek=${encodeURIComponent(classWeek)}
	&AttendanceTaken=${encodeURIComponent(attendanceTaken)}
	&Level=${encodeURIComponent(classItem.Level)}
	&InstructorHelper=${encodeURIComponent(instructorHelper)}`);
	  };

	  //save QRattendance alongside student value to firebase
	  const saveQRattendance = async (classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper) => {
		const filteredStudentClass = data7.filter((studentClassItem) => {
			return (
			  studentClassItem.Level === classItem.Level &&
			  studentClassItem.DurationFrom === classItem.DurationFrom &&
			  studentClassItem.DurationUntil === classItem.DurationUntil &&
			  studentClassItem.ProgramCode === classItem.ProgramCode &&
			  studentClassItem.Instructor === classItem.Instructor
			);
		  });
		try {
		  const db = getDatabase(); //getdatabase
		  const usersRef = ref(db, "BTECQrAttendance"); //create main database
		  //need to add durationfrom for student categories so it does not mix the data with the same date
		  
		  const programCodeRef = child(usersRef, classItem.ProgramCode);
		  
		  const formattedStartDate = format(startDate, 'dd-MM-yyyy'); //format passed date
		  
		  const levelref = child(programCodeRef, classItem.Level);
		  const dateref = child(levelref, formattedStartDate);
		  const instructorref = child(dateref, classItem.Instructor);
		  const attendanceTakenref = child(instructorref, attendanceTaken);

		 //data checking 
		  /*console.log("filteredStudentClass:", filteredStudentClass);
		console.log("data:", data);*/

		//loop all student data in filteredstudentclass array
		  for (const studentClassItem of filteredStudentClass) {
			const currentStudentId = studentClassItem.Student;
			let liststudentUID = ""; // Initialize the studentUID variable

			//data checking 
			/*console.log("Data Array:", data);
			console.log("Current Student ID:", currentStudentId);*/
	  
			// Find the matching UID in the data array
			//const matchingData = data.find((item) => item.student === currentStudentId);
			const matchingData = data5.find((item) => item.student === currentStudentId);

			if (matchingData) {
			  liststudentUID = matchingData.uid; // Get the UID of the matching student
			  console.log("Student Data:", matchingData); // Log the data of the matching student
	  
			  const studentuidref = child(attendanceTakenref, liststudentUID);

			  const defaultstatus = "0";
			  const defaulttimescanned = 0;
			  const extractedDateDay = getDayOfWeek(formattedStartDate);
	  
			  await set(studentuidref, {
				ClassDate: formattedStartDate, //dateof class
				uid: liststudentUID, //student uid
				status: defaultstatus, //update Attendance Status to 1 after student scan, default value 0
				StudentName: studentClassItem.StudentName, //student Name
				studentID: studentClassItem.Student, //student ID
				dayScanned: extractedDateDay,
				TimeScanned: defaulttimescanned, //update Time Scanned using apps
				DurationFrom: studentClassItem.DurationFrom, //Year Session
				DurationUntil: studentClassItem.DurationUntil, //Month Session
				ProgramCode: studentClassItem.ProgramCode,
				Level: studentClassItem.Level,
				//SubjectName: studentClassItem.subjectName, //Subject Name
				Instructor: studentClassItem.Instructor, //Lecturer Name
				CoursePick: btecCoursepick,
				Week:classWeek,
				TakenFor: attendanceTaken,
				InstHelper: instructorHelper,
			  });
			}
		  }
	  
		  alert("Attendances created!");
		  return true;
		  //console.log(classItem.subjectName);
		  //console.log(classItem.subjectName);
		  //console.log(formattedStartDate);
		} catch (error) {
		  console.log(error.message);
		  return false;
		}
	  };

	  //call out function for QR
	  const generateqrandsave = async (classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper) => {
		if (!validateForm()) {
		  return;
		} else {
		  try {
			const confirmGenerate = window.confirm("Generate QR for this class?");
			if (confirmGenerate) {
			const attendanceCreated = await saveQRattendance(classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper);
			if (attendanceCreated) {

			  handleGenerateQR(classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper);
				}
			}
		  } catch (error) {
			// Handle the error here (e.g., display an error message)
			console.error("Error saving attendance:", error);
		  }
		}
	  };
	
	  // Clear the timer when necessary
	  // For example, you can clear the timer when the component unmounts
	  useEffect(() => {
		return () => {
		  clearInterval(timer);
		};
	  }, [timer]);

	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
	
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

		useEffect(() => {
			if (currentUser) {
				const db = getDatabase();
				const userRef = ref(db, `BTECInstructor/${currentUser.uid}`);
				get(userRef)
				  .then(snapshot => {
					const userData = snapshot.val();
					setCurrentUser({
					  ...currentUser,
					  InstructorFaculty: userData.btecregisterFaculty,
					  InstructorEmail: userData.btecregisterEmail,
					  InstructorName: userData.instructorName,
					  uid: userData.uid,
					});
				  })
				  .catch(error => {
					console.error(error);
				  });
			  }
			}, [currentUser]);

	  useEffect(() => {
		const filteredClass = data6.filter((classItem) => {
		  return classItem.Instructor === currentUser.InstructorName;
		});
	  
		setFilteredClass(filteredClass);
		//console.log("filteredClass: ",filteredClass);

		const filteredTotalStudents = filteredClass.map((classItem) => { //data6
			const totalStudents = data7.filter((classStudentItem) => 
			  classStudentItem.ProgramCode === classItem.ProgramCode &&
			  classStudentItem.Level === classItem.Level &&
			  classStudentItem.Instructor === classItem.Instructor &&
			  classStudentItem.DurationFrom === classItem.DurationFrom &&
			  classStudentItem.DurationUntil === classItem.DurationUntil
			);
		  
			return {
			ProgramCode: classItem.ProgramCode,
			Level: classItem.Level,
			Instructor: classItem.Instructor,
			DurationFrom: classItem.DurationFrom,
			DurationUntil: classItem.DurationUntil,
			  totalStudents: totalStudents.length // Count the students that exist in data4
			};
		  });
		  // Now set the filteredTotalStudents state with the updated total length
		  setfilteredTotalStudents(filteredTotalStudents);
		  //console.log("filteredTotalStudents: ",filteredTotalStudents)

	  }, [currentUser, data7, data6]);

	  const validateForm = () => {
		if (
		  instructorHelper === "" ||
		  attendanceTaken === "" ||
		  //classWeek === "" ||
		  btecCoursepick === ""
		) {
		  alert("Please fill in all required fields!");
		  return false;
		}
		//alert("");
		return true;
	  };

			const cleartable = () => {
				setfilteredStudentClass([]);
			};

			  const sortedRows = [...filteredClass].sort((a, b) => {
				// Sort by year
				if (b.year !== a.year) {
				  return b.year - a.year;
				}
				// If years are the same, sort by month in descending order
				return b.month - a.month;
			  });

			  const sortedRows2 = [...filteredData].sort((a, b) => {
				// Sort by year
				if (b.year !== a.year) {
				  return b.year - a.year;
				}
				// If years are the same, sort by month in descending order
				return b.month - a.month;
			  });
		
			  const indexOfLastRow = currentPage * rowsPerPage;
			  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
			  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
			  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
		
			  const indexOfLastRow2 = currentPage2 * rowsPerPage;
			  const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
			  const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
			  const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);	  
		
				const handlePageChange = (pageNumber) => {
				setCurrentPage(pageNumber);
				};
		
				const handlePageChange2 = (pageNumber) => {
					setCurrentPage2(pageNumber);
				};

				const getWeekFromDate = (date) => {
					const day = date.getDate();
					if (day >= 1 && day <= 7) {
					  return '1';
					} else if (day >= 8 && day <= 14) {
					  return '2';
					} else if (day >= 15 && day <= 21) {
					  return '3';
					} else if (day >= 22 && day <= 31) {
					  return '4';
					}
					return ''; // default case, although not expected to happen
				  };

				  useEffect(() => {
					if (startDate) {
					  const week = getWeekFromDate(startDate);
					  setClassWeek(week);
					  setSelectedData4((prevData) => ({
						...prevData,
						startDate,
						ClassWeek: week
					  }));
					}
				  }, [startDate]);

				  useEffect(() => {
					//console.log("logged Instructor: ", currentUser.Instructor);

					const filteredClass = data6.filter((classItem) => {
						//console.log("classitem Instructor: ", classItem.Instructor);
					  return classItem.Instructor === currentUser.InstructorName;
					});
				  
					// Extract unique durations in the format "DurationFrom - DurationUntil"
					const durations = filteredClass.map((classItem) => `${classItem.DurationFrom} - ${classItem.DurationUntil}`);
				  
					// Remove duplicates
					const uniqueDurations = [...new Set(durations)];
					
					setUniqueDurations(uniqueDurations);
				  }, [data6, currentUser]);
	
				  const SearchClassbyDuration = (e) => {
					e.preventDefault();
					if (selectedDuration) {
					  const [from, until] = selectedDuration.split(' - ');
					  const filteredData = data6.filter(classitem => 
						classitem.DurationFrom === from && classitem.DurationUntil === until
					  );
					  setFilteredData(filteredData);
					  alert('Class based on duration has been found!');
					}
					else {
						alert('No information found!');
					}
				  };
				  
    return (

		<div class="body-generateqr">
			<Btecsidebar> {/*show/call sidebar*/}
			<Btecusername /> {/*show/call username*/}
			<p><br/><br/></p>

            <div>
			<h1 class="h1-generateqr">Generate QR</h1>
            </div>
            <div /*className="addstudent-container"*/>
            <p><br></br></p>
            {currentUser && (
            <>
            <p classname="p-generateqr">&emsp;Instructor Name: <b>{currentUser.InstructorName}</b> 
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Instructor Faculty: <b>{currentUser.InstructorFaculty}</b></p>
            </>
            )}
            <p><br/><br/></p>

				<div>
  				<div>
				<p class="p-addstudent4">My Class List</p>
				<p><br/></p>
				
				<div className="adminregister-search-container">
				<div className="adminregister-search-container">

				<div className='container'>
				<select
					title="Filter by Duration of Courses"
					className="select-size-generateqr"
					onChange={(e) => setSelectedDuration(e.target.value)}
					>
					<option value="">Select Duration</option>
					{uniqueDurations.map((duration, index) => (
						<option key={index} value={duration}>{duration}</option>
					))}
					</select>
					    {/* Filter Button */}
						<FcSearch className="search-icon22" onClick={SearchClassbyDuration} title="Search Class"/>
    					{/*<button onClick={SearchClassbyDuration}>Filter</button>*/}
						</div>

				</div>
				</div>
				<p><br/><br/><br/></p>

		<div className="table-container-editclass">
		<table>
        <thead class="th-generateqr">
		<p><br/></p>
          <tr>
		  <th>Program</th>
            <th>Current Total Student</th>
			<th>Duration of Studies</th>
			<th>Action</th>
          </tr>
        </thead>
        <tbody>
    {currentRows2.length > 0 ? (
				currentRows2.map((classItem, index) => {
			  const totalStudents = filteredTotalStudents.find((item) => 
			  item.Instructor === classItem.Instructor
			  && item.ProgramCode === classItem.ProgramCode
			  && item.DurationFrom === classItem.DurationFrom
			  && item.DurationUntil === classItem.DurationUntil);
			  return (
				<tr key={`${classItem.ProgramCode}_${index}`}>
				  <td className="editclass-td"><b>{classItem.ProgramCode} <br/>{classItem.ProgramName}</b></td>
				  <td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
				  <td className="editclass-td"><b>{classItem.DurationFrom} <br/>until<br/> {classItem.DurationUntil}</b></td>
				  <td className="editclass-td">
					<div className="button-GenQR-container" style={{ marginLeft: '-50px', marginRight: '-50px'}}>
				  <Popup trigger={<button title = 'Generate Class QR Code' className="button-GenQR-new"><b>GENERATE</b>&nbsp;<BsQrCodeScan className='admin-view-attendance-icon4'/></button>} modal nested>
		{close => (
		  <div className="modal">
			<button title = 'Close' className="button-studentclass4" onClick={() =>{close(); cleartable();}}>&times;</button>
			<p><br /><br /></p>
	  
			<div>
			  <p className="p-generateqr2">&emsp;Program Code: <b>{classItem.ProgramCode}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Program Name: <b>{classItem.ProgramName}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Instructor Name: <b>{classItem.Instructor}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Level: <b>{classItem.Level}</b></p>
			</div>
	  
			<p><br /><br /></p>
	  
			<div className='container'>
			<div className='container'>
			<p className="p-generateqr2">&emsp;Pembantu Tenaga Pengajar:<b>*</b>&emsp;</p>
	  
			<select
			title= 'Pilih jumlah pembantu tenaga pengajar'
			  className="select-size-generateqr2"
			  value={instructorHelper}
			  onChange={(e) => {
				setinstructorHelper(e.target.value);
				setSelectedData4((prevData) => ({ //
				  ...prevData,
				  InstructorHelper: e.target.value
				}));
			  }}
			>
			<option>Pilih Jumlah</option>
			{/*{Array.from({ length: 99 }, (_, index) => (
				<option key={index + 1} value={index + 1}>{index + 1}</option>
			))}*/}
						{Array.from({ length: 4 }, (_, index) => (
				<option key={index + 1} value={index + 1}>{index + 1}</option>
			))}
			</select>
			</div>
			
			<div className='container'>
			<p className="p-generateqr2">&emsp;Masa Kehadiran:<b>*</b>&emsp;</p>

			<select
			title= 'Pilih Masa Kehadiran'
			  className="select-size-generateqr2"
			  value={attendanceTaken}
			  onChange={(e) => {
				setAttendanceTaken(e.target.value);
				setSelectedData4((prevData) => ({ //
				  ...prevData,
				  AttendanceTaken: e.target.value
				}));
			  }}
			>
			<option>Pilih Masa Kehadiran</option>
				<option value="AM"> AM </option>
				<option value="PM"> PM </option>
			</select>
			</div>
			</div>
			<br/>
			<div className='container'>
			<div className='container'>
			<p className="p-generateqr2">&emsp;Minggu:<b>*</b>&emsp;</p>
			<p className="p-generateqr2"><b>{classWeek}</b></p>

			</div>

						<br/>
						<div className='container'>
				<p className="p-generateqr2">Kod dan Tajuk Unit Kompetensi:<b>*</b></p>
					{programData
						.filter((program) => program.programCode === classItem.ProgramCode)
						.map((program) => (
							program.programLevels.map((level) => (
								<select
									key={level.programLevel}
									value={btecCoursepick}
									className="select-size-generateqr"
									onChange={(e) => handleCourseChange(e, program.programCode, level.programLevel)}
								>
									<option value="">Select Course</option>
									{level.courses.map((course, index) => (
										<option key={index} value={course}>{course}</option>
									))}
								</select>
							))
						))
					}
					</div>
					</div>

			<p><br /></p>
	  
			<div className="p-generateqr3">
			  <p className="p-generateqr2">&emsp;Date:</p>
			  <DatePicker
			  title='Select date for your Class'
				className="p-generateqr4"
				selected={startDate}
				onChange={(date) => {
					const week = getWeekFromDate(date);
				  setStartDate(date);
				  //setClassWeek(week); // Set the class week based on the selected date
				  setClassWeek(week); 
				  setSelectedData4((prevData) => ({
					...prevData,
					startDate: date,
					ClassWeek: week // Also update the selectedData4 state with the week
				  }));
				}}
				dateFormat={datepickerCustomFormat}
				minDate={new Date()}
				maxDate={addMonths(new Date(), 5)}
				showDisabledMonthNavigation
			  />
	  
			  <p><br/><br/></p>

			</div>
	  
			<p><br/></p>
			{/*<button title="Generate Class QR" className="button-admin-view-attendance-new8" onClick={() => {generateqrandsave(classItem, startDate, Venue);}}><FaEye className='admin-view-attendance-icon3'/>&nbsp;<b>GENERATE QR</b></button>*/}
			<button title="Generate Class QR" className="button-admin-view-attendance-new8" onClick={() => {generateqrandsave(classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper);}}><FaEye className='admin-view-attendance-icon3'/>&nbsp;<b>GENERATE QR</b></button>
			<p><br/></p>

			The form with the mark <b>*</b> is compulsory to be fill.
			{/*<p className="p-generateqr-sidenote">*Click the <b>"View Student List Button"</b> each time changing which class to view</p>*/}
		  </div>
		)}
	  </Popup>
	  {/*<p>&emsp;</p>*/}
	  <Popup trigger={<button title = 'View the Student List in this class' className="button-GenQR-new">&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;VIEW STUDENT</button>} modal nested>
		{(close) => (
		  <div className="modal">
			<button title = 'Close' className="button-studentclass4" onClick={() =>{close(); cleartable();}}>&times;</button>
			<p><br /><br /></p>
	  
			<h2 className="h2-studentclass">Student List</h2>
			<p><br></br><br></br></p>

			<p className="p-history2">Program Code: <b>{classItem.ProgramCode}</b></p>
			<p className="p-history2">Level: <b>{classItem.Level}</b></p>
			<p className="p-history2">Instructor: <b>{classItem.Instructor}</b></p>
			<p className="p-history2">Duration: <b>{classItem.DurationFrom} Until {classItem.DurationUntil}</b></p>
			<p><br/></p>
		   <button title = 'View Student List' className="button-admin-view-attendance-new8 " onClick={() => findStudentClass(classItem)}>&nbsp;<FaEye className='.admin-view-attendance-icon5'/>&nbsp;&nbsp;<b>VIEW STUDENT LIST</b></button>
		  <p><br/></p>
		  <div className="table-container-editclass2">
			<table>
			  <thead className="th-generateqr">
				<tr>
				  <th>Student ID</th>
				  <th>Name</th>
				</tr>
			  </thead>
			  <tbody>
			  {filteredStudentClass.length > 0 ? (
				filteredStudentClass
				.sort((a, b) => a.StudentName.localeCompare(b.StudentName))
				.map((studentClassItem) => (
				  <tr key={studentClassItem.studentID}>
					<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
					<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
				  </tr>
				))
				
			  ) : (
				  <tr>
				  <td colSpan="6">No Student found.</td>
				</tr>
			  )
			  }
			  </tbody>
			</table>
			</div>
			<p><br></br></p>
			<p className="p-generateqr-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
			<p className="p-generateqr-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
		  </div>
		)}
	  </Popup>
	  </div>
				  </td>
				</tr>
			  );
		  })
			  ) : currentRows.length > 0 ? (
				currentRows.map((classItem, index) => {
			  const totalStudents = filteredTotalStudents.find((item) => 
			  item.Instructor === classItem.Instructor
			  && item.ProgramCode === classItem.ProgramCode
			  && item.DurationFrom === classItem.DurationFrom
			  && item.DurationUntil === classItem.DurationUntil);
			  return (
				<tr key={`${classItem.ProgramCode}_${index}`}>
				  <td className="editclass-td"><b>{classItem.ProgramCode} <br/>{classItem.ProgramName}</b></td>
				  <td className="editclass-td"><b>{totalStudents ? totalStudents.totalStudents : 0}</b></td>
				  <td className="editclass-td"><b>{classItem.DurationFrom} <br/>until<br/> {classItem.DurationUntil}</b></td>
				  <td className="editclass-td">
					<div className="button-GenQR-container" style={{ marginLeft: '-50px', marginRight: '-50px'}}>
				  <Popup trigger={<button title = 'Generate Class QR Code' className="button-GenQR-new"><b>GENERATE</b>&nbsp;<BsQrCodeScan className='admin-view-attendance-icon4'/></button>} modal nested>
		{close => (
		  <div className="modal">
			<button title = 'Close' className="button-studentclass4" onClick={() =>{close(); cleartable();}}>&times;</button>
			<p><br /><br /></p>
	  
			<div>
			  <p className="p-generateqr2">&emsp;Program Code: <b>{classItem.ProgramCode}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Program Name: <b>{classItem.ProgramName}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Instructor Name: <b>{classItem.Instructor}</b></p>
			  <p className="p-generateqr2"><br />&emsp;Level: <b>{classItem.Level}</b></p>
			</div>
	  
			<p><br /><br /></p>
	  
			<div className='container'>
			<div className='container'>
			<p className="p-generateqr2">&emsp;Pembantu Tenaga Pengajar:<b>*</b>&emsp;</p>
	  
			<select
			title= 'Pilih jumlah pembantu tenaga pengajar'
			  className="select-size-generateqr2"
			  value={instructorHelper}
			  onChange={(e) => {
				setinstructorHelper(e.target.value);
				setSelectedData4((prevData) => ({ //
				  ...prevData,
				  InstructorHelper: e.target.value
				}));
			  }}
			>
			<option>Pilih Jumlah</option>
			{/*{Array.from({ length: 99 }, (_, index) => (
				<option key={index + 1} value={index + 1}>{index + 1}</option>
			))}*/}
						{Array.from({ length: 4 }, (_, index) => (
				<option key={index + 1} value={index + 1}>{index + 1}</option>
			))}
			</select>
			</div>
			
			<div className='container'>
			<p className="p-generateqr2">&emsp;Masa Kehadiran:<b>*</b>&emsp;</p>

			<select
			title= 'Pilih Masa Kehadiran'
			  className="select-size-generateqr2"
			  value={attendanceTaken}
			  onChange={(e) => {
				setAttendanceTaken(e.target.value);
				setSelectedData4((prevData) => ({ //
				  ...prevData,
				  AttendanceTaken: e.target.value
				}));
			  }}
			>
			<option>Pilih Masa Kehadiran</option>
				<option value="AM"> AM </option>
				<option value="PM"> PM </option>
			</select>
			</div>
			</div>
			<br/>
			<div className='container'>
			<div className='container'>
			<p className="p-generateqr2">&emsp;Minggu:<b>*</b>&emsp;</p>
			<p className="p-generateqr2"><b>{classWeek}</b></p>

			</div>

						<br/>
						<div className='container'>
				<p className="p-generateqr2">Kod dan Tajuk Unit Kompetensi:<b>*</b></p>
					{programData
						.filter((program) => program.programCode === classItem.ProgramCode)
						.map((program) => (
							program.programLevels.map((level) => (
								<select
									key={level.programLevel}
									value={btecCoursepick}
									className="select-size-generateqr"
									onChange={(e) => handleCourseChange(e, program.programCode, level.programLevel)}
								>
									<option value="">Select Course</option>
									{level.courses.map((course, index) => (
										<option key={index} value={course}>{course}</option>
									))}
								</select>
							))
						))
					}
					</div>
					</div>

			<p><br /></p>
	  
			<div className="p-generateqr3">
			  <p className="p-generateqr2">&emsp;Date:</p>
			  <DatePicker
			  title='Select date for your Class'
				className="p-generateqr4"
				selected={startDate}
				onChange={(date) => {
					const week = getWeekFromDate(date);
				  setStartDate(date);
				  //setClassWeek(week); // Set the class week based on the selected date
				  setClassWeek(week); 
				  setSelectedData4((prevData) => ({
					...prevData,
					startDate: date,
					ClassWeek: week // Also update the selectedData4 state with the week
				  }));
				}}
				dateFormat={datepickerCustomFormat}
				minDate={new Date()}
				maxDate={addMonths(new Date(), 5)}
				showDisabledMonthNavigation
			  />
	  
			  <p><br/><br/></p>

			</div>
	  
			<p><br/></p>
			{/*<button title="Generate Class QR" className="button-admin-view-attendance-new8" onClick={() => {generateqrandsave(classItem, startDate, Venue);}}><FaEye className='admin-view-attendance-icon3'/>&nbsp;<b>GENERATE QR</b></button>*/}
			<button title="Generate Class QR" className="button-admin-view-attendance-new8" onClick={() => {generateqrandsave(classItem, startDate, btecCoursepick, classWeek, attendanceTaken, instructorHelper);}}><FaEye className='admin-view-attendance-icon3'/>&nbsp;<b>GENERATE QR</b></button>
			<p><br/></p>

			The form with the mark <b>*</b> is compulsory to be fill.
			{/*<p className="p-generateqr-sidenote">*Click the <b>"View Student List Button"</b> each time changing which class to view</p>*/}
		  </div>
		)}
	  </Popup>
	  {/*<p>&emsp;</p>*/}
	  <Popup trigger={<button title = 'View the Student List in this class' className="button-GenQR-new">&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;VIEW STUDENT</button>} modal nested>
		{(close) => (
		  <div className="modal">
			<button title = 'Close' className="button-studentclass4" onClick={() =>{close(); cleartable();}}>&times;</button>
			<p><br /><br /></p>
	  
			<h2 className="h2-studentclass">Student List</h2>
			<p><br/></p>

			<p className="p-history2">Program Code: <b>{classItem.ProgramCode}</b></p>
			<p className="p-history2">Level: <b>{classItem.Level}</b></p>
			<p className="p-history2">Lecturer: <b>{classItem.Instructor}</b></p>
			<p className="p-history2">Duration: <b>{classItem.DurationFrom} Until {classItem.DurationUntil}</b></p>
			<p><br/></p>
		   <button title = 'View Student List' className="button-admin-view-attendance-new8 " onClick={() => findStudentClass(classItem)}>&nbsp;<FaEye className='.admin-view-attendance-icon5'/>&nbsp;&nbsp;<b>VIEW STUDENT LIST</b></button>
		  <p><br/></p>
		  <div className="table-container-editclass2">
			<table>
			  <thead className="th-generateqr">
				<tr>
				  <th>Student ID</th>
				  <th>Name</th>
				</tr>
			  </thead>
			  <tbody>
			  {filteredStudentClass.length > 0 ? (
				filteredStudentClass
				.sort((a, b) => a.StudentName.localeCompare(b.StudentName))
				.map((studentClassItem) => (
				  <tr key={studentClassItem.studentID}>
					<td className="editclass-td"><b>{studentClassItem.Student}</b></td>
					<td className="editclass-td"><b>{studentClassItem.StudentName}</b></td>
				  </tr>
				))
				
			  ) : (
				  <tr>
				  <td colSpan="6">No Student found.</td>
				</tr>
			  )
			  }
			  </tbody>
			</table>
			</div>
			<p><br></br></p>
			<p className="p-generateqr-sidenote"><b>*</b>Click the <b>"VIEW STUDENT LIST"</b> to view the student List for the selected Subject.</p>
			<p className="p-generateqr-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
		  </div>
		)}
	  </Popup>
	  </div>
				  </td>
				</tr>
			  );
		  })
			  ) : (
      	<tr>
        	<td colSpan="5">No Class found under you.</td>
      	</tr>
    	)}
  				</tbody>
				</table>

				</div>

				<p><br/></p>
				<p>*You must <b>Generate QR</b> for the class first in order to <b>view the Current Total Student.</b></p>
				<p>*If you want to <b>View</b> the <b>Initial total student</b> in your class, you can view it in the <b>Class List page</b>.</p>
				<p><br/></p>

				{currentRows.length > 0 && totalPages > 1 && currentRows2.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange(pageNumber)}
						className={`button-addStudent5 ${currentPage === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows2.length > 0 && totalPages2 > 1 && (
					<div className="pagination">
					{Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange2(pageNumber)}
						className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}
				</div>
				</div>
				</div>	
				
				</Btecsidebar>
				</div>
				);
				};
				
				export default BtecGenerateAttendance;