import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 16,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderBottomWidth: 0.5,
    borderLeftWidth: 1,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

const SetPDF = ({ attendanceData }) => {
  console.log('Attendance Data:', attendanceData);

  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Attendance Report</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Student ID</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Student Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Attendance Status</Text>
            </View>
          </View>
          {attendanceData.length > 0 ? (
            attendanceData.map((attendance, index) => (
              <View style={styles.tableRow} key={`${attendance.StudentUID}-${index}`}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{attendance.StudentID}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{attendance.StudentName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{attendance.AttendanceStatus}</Text>
                </View>
              </View>
            ))
          ) : (
            <Text>No Student.</Text>
          )}
        </View>
      </View>
    </Page>
  </Document>
  );
};

export default SetPDF;
