import React, { useState, useEffect, useRef } from 'react';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import { getDatabase, ref, set, get, onValue, child, remove, query, orderByChild, equalTo, push } from "firebase/database";
import '../admregister/AdminEditClass.css';
import { auth } from "../firebase";
import { FcSearch } from "react-icons/fc";
import { MdDelete } from "react-icons/md";

import * as XLSX from 'xlsx';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { MdAddCircle } from "react-icons/md";
import { FaEye, FaFileUpload } from "react-icons/fa";

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths, format } from "date-fns"; //enabled the date past the current date

const AdminbtecEditClass= () => {

      const [Faculty, setFaculty] = useState("");
      const [Faculty2, setFaculty2] = useState("");
      const [Programmes, setProgrammes] = useState("");
      const [SubjectCode, setSubjectCode] = useState("");
      const [SubjectName, setSubjectName] = useState("");
      //const [Semester, setSemester] = useState("");
      const [Month, setMonth] = useState("");
      const [Year, setYear] = useState("");
      const [filteredStudentSession, setFilteredStudentSession] = useState([]);
      const [filteredAllStudFacul, setFilteredAllStudFacul] = useState([]);
      const [searchClass, setSearchClass] = useState("");
      const [filteredSearchClass, setfilteredSearchClass] = useState([]);

      const [BtecProgramName, setBtecProgramName] = useState("");
      const [BtecProgramCode, setBtecProgramCode] = useState("");
      const [BtecProgramLevel, setBtecProgramLevel] = useState("");
      const [BtecProgramCourse, setBtecProgramCourse] = useState([]);
      const [BtecTempCourse, setBtecTempCourse] = useState("");
      //const [BtecnewCourse, setBtecNewCourse] = useState("");
      //const [BtecDateFrom, setBtecDateFrom] = useState(new Date());
      //const [BtecDateUntil, setBtecDateUntil] = useState(new Date());
      const [BtecselectedInstructor, setBtecselectedInstructor] = useState("");
      const [BtecselectedProgram, setBtecselectedProgram] = useState("");
      const [BtecselectedProgramName, setBtecselectedProgramName] = useState("");
      const [BtecselectedProgramLevel, setBtecselectedProgramLevel] = useState("");

      const [skmfrom2, setskmfrom2] = useState(new Date());
      const [skmuntil2, setskmuntil2] = useState(new Date());
      
      const [selectedLecturer, setSelectedLecturer] = useState("");
      
      const [data, setData] = useState([]); //data for class
      const [data2, setData2] = useState([]); //data for fetching lecturers
      const [data3, setData3] = useState([]);
      const [data4, setData4] = useState([]); //data for fetching Instructors
      const [data5, setData5] = useState([]); //data for fetching Program
      const [data6, setData6] = useState([]); //data for fetching Program C0
      const [programData2, setProgramData2] = useState([]);

      const [MonthSession, setMonthSession] = useState("");
      const [YearSession, setYearSession] = useState("");
      const [IntakeMonth, setIntakeMonth] = useState("");
      const [IntakeYear, setIntakeYear] = useState("");
      const [SessionFaculty, setSessionFaculty] = useState("");
      const [SessionProgrammes, setSessionProgrammes] = useState("");
      const [SessionClassFaculty, setSessionClassFaculty] = useState("");
      const [SessionClassProgrammes, setSessionClassProgrammes] = useState("");
      const [SubjectCodeSession, setSubjectCodeSession] = useState("");
      const [SubjectNameSession, setSubjectNameSession] = useState("");
      const [selectedSessionLecturer, setSelectedSessionLecturer] = useState("");
      const [SessionArray, setSessionArray] = useState([]);
      const [SessionArray2, setSessionArray2] = useState([]);
      
      const [currentPage, setCurrentPage] = useState(1);
      const [currentPage2, setCurrentPage2] = useState(1);
      const [rowsPerPage, setRowsPerPage] = useState(8);

      const [currentUser, setCurrentUser] = useState(null);
      const [show, setShow] = useState(false);
      const [show2, setShow2] = useState(false);

      useEffect(() => {
      // listen for changes to the auth object
      const unsubscribe = auth.onAuthStateChanged((user) => {
      // update the component with the current user
      setCurrentUser(user);
      });

      // cleanup function to unsubscribe when the component unmounts
      return unsubscribe;
      }, []);

      useEffect(() => {
      // retrieve the current user's name from the database
      if (currentUser) {
        const db = getDatabase();
        const userRef = ref(db, `Admin/${currentUser.uid}`);
        get(userRef)
        .then(snapshot => {
          const userData = snapshot.val();
          setCurrentUser({
          ...currentUser,
          adminName: userData.AdminName
          });
        })
        .catch(error => {
          console.error(error);
        });
      }
      }, [currentUser]);

      useEffect(() => {
        const fetchData = async () => {
          const db = getDatabase();
          const btecProgramsRef = ref(db, 'BTECProgram');
          
          onValue(btecProgramsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const programs = Object.entries(data).map(([programCode, programLevelData]) => {
                const programLevels = Object.entries(programLevelData).map(([programLevel, levelData]) => {
                  // Extracting program course data
                  const courses = Object.values(levelData.BtecProgramCourse || {});
                  
                  // Extracting other level data
                  const { BtecProgramLevel, BtecProgramName } = levelData;
                  
                  return {
                    programLevel,
                    BtecProgramLevel,
                    BtecProgramName,
                    courses,
                  };
                });
    
                return {
                  programCode,
                  programLevels,
                };
              });
    
              setProgramData2(programs);
              console.log("programs: " , programs);
  
            } else {
              console.log('No BTEC programs found.');
            }
          });
        };
    
        fetchData();
      }, []);

    useEffect(() => {
      //retrieve the every lecturer data to display on option,select
      const fetchInstructor = async () => {
        try {
        const db = getDatabase();
        const instructorRef = ref(db, "BTECInstructor");
        const snapshot = await get(instructorRef);
        if (snapshot.exists()) {
          const InstructorsData = snapshot.val();
          const InstructorArray = Object.values(InstructorsData);
          setData4(InstructorArray);
        } else {
          setData4([]);
        }
        } catch (error) {
        console.log(error.message);
        }
      };
      //console.log("data4: ", data4 )
    
      fetchInstructor(); //run the function fetchInstructor
      }, []);

      useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getDatabase();
                const programRef = ref(db, "BTECProgram");
    
                // Get the snapshot of the BTECProgram node
                const snapshot = await get(programRef);
    
                if (snapshot.exists()) {
                    // Extract the value of the snapshot
                    const programData = snapshot.val();
    
                    // Create an array to hold the extracted data
                    const extractedData = [];
    
                    // Iterate over each BtecProgramCode
                    Object.keys(programData).forEach((code) => {
                        // Iterate over each BtecProgramLevel
                        Object.keys(programData[code]).forEach((level) => {
                            // Get the data at this level
                            const levelData = programData[code][level];
    
                            // Extract BtecProgramName, BtecProgramCode, and BtecProgramLevel
                            const { BtecProgramName, BtecProgramCode, BtecProgramLevel } = levelData;
    
                            // Extract BtecProgramCourse data
                            const BtecProgramCourseData = levelData.BtecProgramCourse;
    
                            // Push the extracted data to the array
                            extractedData.push({
                                BtecProgramName,
                                BtecProgramCode,
                                BtecProgramLevel,
                                BtecProgramCourseData
                            });
                        });
                    });
    
                    // Update the state with the extracted data
                    setData5(extractedData);
                } else {
                    console.log("No BTEC program data found.");
                }
            } catch (error) {
                console.error("Error fetching BTEC program data:", error);
            }
        };
        console.log("data5: ", data5);
    
        fetchData();
    }, []);

    useEffect(() => {
      const db = getDatabase();
      const dbRef = ref(db, "BTECStudent");
  
      onValue(dbRef, (snapshot) => {
        const firebaseData = snapshot.val();
        if (firebaseData) {
        const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
          uid,
          durationfrom: studentData.durationfrom,
          durationuntil: studentData.durationuntil,
          email: studentData.email,
          icnumber: studentData.icnumber,
          level: studentData.level,
          name: studentData.name,
          skmprogram: studentData.skmprogram,
          skmprogramcode: studentData.skmprogramcode,
          student: studentData.student,
        }));
        setData6(dataArray);
        }
      });
      }, []);

      useEffect(() => {
        //retrieve the every lecturer data to display on option,select
        const fetchLecturers = async () => {
          try {
          const db = getDatabase();
          const lecturersRef = ref(db, "Lecturer");
          const snapshot = await get(lecturersRef);
          if (snapshot.exists()) {
            const lecturersData = snapshot.val();
            const lecturersArray = Object.values(lecturersData);
            setData2(lecturersArray);
          } else {
            setData2([]);
          }
          } catch (error) {
          console.log(error.message);
          }
        };
      
        fetchLecturers(); //run the function fetchlecturer
        }, []);

      //add data to 'Faculty'
      const registerSubject = async (e) => { //handle the registration of the subject to the faculty node
      e.preventDefault();
      try {
        const db = getDatabase();
        const usersRef = ref(db, "Faculty");
        const facultyRef = child(usersRef, Faculty);
        const programmesRef = child(facultyRef, Programmes);
        const yearref = child(programmesRef, Year);
        const monthref = child(yearref, Month);
        const subjectRef = child(monthref, SubjectName);
        const lectureRef = child(subjectRef, selectedLecturer);

          //input into firebase 'Faculty'
        await set(lectureRef, {
        Faculty,
        Programmes,
        SubjectCode,
        SubjectName,
        Month,
        Year,
        lecturerName: selectedLecturer,
        //Semester,
        });
        alert("Subject have been added!.")
        console.log("Subject have been added!");

      } catch (error) {
        //alert("Something went wrong.")
        console.log(error.message);
      }
      };

      const registerBTECClass = async (e) => { //handle the registration of the subject to the faculty node
        e.preventDefault();
        const formattedfrom = format(skmfrom2, 'dd-MM-yyyy');
        const formatteduntil = format(skmuntil2, 'dd-MM-yyyy');
        try {
          const db = getDatabase();
          const usersRef = ref(db, "BTECClass");
          const instructorRef = child(usersRef, BtecselectedInstructor);
          const programlevelRef = child(instructorRef, BtecselectedProgramLevel);
          const programcodeRef = child(programlevelRef, BtecselectedProgram);
          const datefromRef = child(programcodeRef, formattedfrom);
  
            //input into firebase 'BTECClass'
          await set(datefromRef, {
          Instructor: BtecselectedInstructor,
          Level: BtecselectedProgramLevel,
          ProgramCode: BtecselectedProgram,
          ProgramName: BtecselectedProgramName,
          DurationFrom: formattedfrom,
          DurationUntil: formatteduntil,
          });
          alert("Class have been created for this batch!.")
          console.log("Class have been created!");
  
        } catch (error) {
          //alert("Something went wrong.")
          console.log(error.message);
        }
        };

            //add data to 'Faculty Session'
            const registerSubjectSession = async (e) => { //handle the registration of the subject to the faculty node
              e.preventDefault();

              try {
                const db = getDatabase();
                const usersRef = ref(db, "Faculty");
                //const facultyRef = child(usersRef, SessionClassFaculty);
                const facultyRef = child(usersRef, Faculty2);
                const programmesRef = child(facultyRef, SessionClassProgrammes);
                const yearref = child(programmesRef, YearSession);
                const monthref = child(yearref, MonthSession);
                const subjectRef = child(monthref, SubjectNameSession);
                const lectureRef = child(subjectRef, selectedSessionLecturer);
        
                  //input into firebase 'Faculty'
                await set(lectureRef, {
                //Faculty: SessionClassFaculty,
                Faculty: Faculty2,
                Programmes: SessionClassProgrammes,
                SubjectCode: SubjectCodeSession,
                SubjectName: SubjectNameSession,
                Month: MonthSession,
                Year: YearSession,
                lecturerName: selectedSessionLecturer,
                });
                //alert("Subject and Student have been added!.")
                //console.log("Subject have been added!");
        
              } catch (error) {
                //alert("Something went wrong.")
                console.log(error.message);
              }
              };

      //add data to 'Class'
      const createclass = async (e) => { //handle the registration of the subject to the Class node
      e.preventDefault();
      try {
        const db = getDatabase();
        const usersRef1 = ref(db, "Class");
        const yearref1 = child(usersRef1, Year);
        const monthref1 = child(yearref1, Month);
        const subjectRef1 = child(monthref1, SubjectName);
        const lectRef1 = child(subjectRef1, selectedLecturer);

        await set(lectRef1, {
        Faculty,
        Programmes,
        SubjectCode,
        SubjectName,
        Month,
        Year,
        lecturerName: selectedLecturer,
        //Intake,
        //Semester,
        });
        /*alert("Subject have been added!.")
        console.log("Subject have been added!");*/
        console.log("Class have been added!");

      } catch (error) {
        //alert("Something went wrong.")
        console.log(error.message);
      }
      };

            //add data to 'Class'
            const createclasssession = async (e) => { //handle the registration of the subject to the Class node
              e.preventDefault();
              try {
                const db = getDatabase();
                const usersRef1 = ref(db, "Class");
                const yearref1 = child(usersRef1, YearSession);
                const monthref1 = child(yearref1, MonthSession);
                const subjectRef1 = child(monthref1, SubjectNameSession);
                const lectRef1 = child(subjectRef1, selectedSessionLecturer);
        
                await set(lectRef1, {
                //Faculty : SessionClassFaculty,
                Faculty : Faculty2,
                Programmes : SessionClassProgrammes ,
                SubjectCode : SubjectCodeSession,
                SubjectName : SubjectNameSession,
                Month : MonthSession,
                Year : YearSession,
                lecturerName: selectedSessionLecturer,
                //Intake,
                //Semester,
                });
                /*alert("Subject have been added!.")
                console.log("Subject have been added!");*/
                console.log("Class have been added!");
        
              } catch (error) {
                //alert("Something went wrong.")
                console.log(error.message);
              }
              };

      const validateForm = () => { //check if all the form is filled
      if (
        Faculty === "" ||
        Programmes === "" ||
        SubjectCode === "" ||
        SubjectName === "" ||
        selectedLecturer === "" ||
        Month === "" ||
        Year === ""
      ) {
        alert("Please fill in all fields");
        return false;
      }
      //alert("");
      return true;
      };

      const MonthList = () => { //list the month
      const months = [];

      for (let month = 1; month <= 12; month++) {
        months.push(month);
      }

      return months.map((month) => ( //map in option
        <option key={month} value={month}>
        {month}
        </option>
      ));
      };

      const YearList = () => { //list the year 2023-2099
      const years = [];

      for (let year = 2023; year <= 2099; year++) {
        years.push(year);
      }

      return years.map((year) => (
        <option key={year} value={year}>
        {year}
        </option>
      ));
      };
      
      const searchSpecificClass = (e) => {
        e.preventDefault();
      
        // Check if the search input is empty
        if (!searchClass) {
          alert('Please enter the Subject Name/Code/Lecturer');
          return; // Return early without making any changes
        }
      
        // Convert the search input to lowercase for case-insensitive comparison
        const searchInput = searchClass.toLowerCase();
      
        // Filter the program data based on the search input
        const filteredSearchClass = programData2.filter((program) => {
          const { programCode, programLevels } = program;
      
          // Check if the programCode includes the search input
          const isProgramCodeMatch = programCode.toLowerCase().includes(searchInput);
      
          // Check if any of the program levels match the search input
          const isProgramLevelMatch = programLevels.some((level) => {
            const { BtecProgramName, courses } = level;
      
            // Check if BtecProgramName includes the search input
            const isProgramNameMatch = BtecProgramName.toLowerCase().includes(searchInput);
      
            // Check if any of the courses include the search input
            const isCourseMatch = courses.some((course) => {
              const { courseName, courseCode, lecturer } = course;
              return (
                (courseName && courseName.toLowerCase().includes(searchInput)) ||
                (courseCode && courseCode.toLowerCase().includes(searchInput)) ||
                (lecturer && lecturer.toLowerCase().includes(searchInput))
              );
            });
      
            return isProgramNameMatch || isCourseMatch;
          });
      
          // Return true if programCode, programName, or any course matches
          return isProgramCodeMatch || isProgramLevelMatch;
        });
      
        // Update the state with the filtered search results
        setfilteredSearchClass(filteredSearchClass);
      
        if (filteredSearchClass.length === 0) {
          alert('The Subject Name/Code/Lecturer does not exist.');
        } else {
          alert('Results Found!');
        }
      
        console.log('filtered Class:', filteredSearchClass);
      };
      

        // handle deletion of class
        const AdminDeleteClass = (SubjectCode, subjectName, lectName, month, year, Facultyref, Programmes) => {
          const db6 = getDatabase();
          const confirmDelete = window.confirm(`Are you sure you want to delete ${SubjectCode} ${subjectName} for Session ${month}/${year}?`); //confirmation windows
          
          if (confirmDelete) {
            //delete from Class node
            const refToDelete = ref(db6, `Class/${year}/${month}/${subjectName}/${lectName}`);
            //console.log("year:", year); // Log the reference for debugging purposes
            //console.log("month:", month); // Log the reference for debugging purposes
            //console.log("subjectName:", subjectName); // Log the reference for debugging purposes
            //console.log("lectName:", lectName);

            //delete from Faculty node
            const refToDelete2 = ref(db6, `Faculty/${Facultyref}/${Programmes}/${year}/${month}/${subjectName}/${lectName}`);
            //console.log("Facultyref:", Facultyref);
            //console.log("Programmes:", Programmes);
            //console.log("year:", year); // Log the reference for debugging purposes
            //console.log("month:", month); // Log the reference for debugging purposes
            //console.log("subjectName:", subjectName); // Log the reference for debugging purposes
            //console.log("lectName:", lectName);

            const refToDelete3 = ref(db6, `ClassStudentList/${year}/${month}/${subjectName}/${lectName}`);

            remove(refToDelete) //delete from Class
            remove(refToDelete2) //delete from Faculty
            remove(refToDelete3) //delete from Class Student
            .then(() => {
              alert("Class have been deleted.");
              console.log("Data deleted successfully.");
              console.log(`Data for SubjectName: ${subjectName}, Lecturer: ${lectName}, Session: ${month}/${year} deleted successfully.`);
            })
            .catch((error) => {
              alert("Error on removing the Class.");
              console.error("Error deleting data:", error);
              console.error(`Error deleting data for SubjectName: SubjectName: ${subjectName}, Lecturer: ${lectName}, Session: ${month}/${year}`);
            });
          }

        };

          const sortedRows = [...data].sort((a, b) => { //sort the data for class by month/year
            // Sort by year
            if (b.year !== a.year) {
              return b.year - a.year;
            }
            // If years are the same, sort by month in descending order
            return b.month - a.month;
            });
        
            const sortedRows2 = [...filteredSearchClass].sort((a, b) => { //sort the data for searched class by month/year
            // Sort by year
            if (b.year !== a.year) {
              return b.year - a.year;
            }
            // If years are the same, sort by month in descending order
            return b.month - a.month;
            });
            
            //pagination for class
            const indexOfLastRow = currentPage * rowsPerPage;
            const indexOfFirstRow = indexOfLastRow - rowsPerPage;
            const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
            const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
            
            //pagination for searched class
            const indexOfLastRow2 = currentPage2 * rowsPerPage;
            const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
            const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
            const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);	  

              // Define a function to retrieve the faculty of a selected lecturer
            const getLecturerFaculty = (lecturerName) => {
              const selectedLecturerData = data2.find((lecturer) => lecturer.lecturerName === lecturerName);
              return selectedLecturerData ? selectedLecturerData.registerFaculty : "";
            };

            const getLecturerFaculty2 = (lecturerName) => {
              const selectedLecturerData2 = data2.find((lecturer) => lecturer.lecturerName === lecturerName);
              return selectedLecturerData2 ? selectedLecturerData2.registerFaculty : "";
            };

            const handleInstructorChange = (e) => {
              const selectedInstructor = e.target.value;
              setBtecselectedInstructor(selectedInstructor);
            };

            const handleBTECProgramChange = (event) => {
              const selectedProgramCode = event.target.value;
            
              // Extract program name and level based on the selected program code
              const selectedProgramData = data5.find((program) => program.BtecProgramCode === selectedProgramCode);
              const selectedProgramName = selectedProgramData ? selectedProgramData.BtecProgramName : "";
              const selectedProgramLevel = selectedProgramData ? selectedProgramData.BtecProgramLevel : "";
            
              // Update state with the selected program name and level
              setBtecselectedProgramName(selectedProgramName);
              setBtecselectedProgramLevel(selectedProgramLevel);
            
              // Update state with the selected program code
              setBtecselectedProgram(selectedProgramCode);
            };

            // Handle lecturer selection change
            const handleLecturerSessionChange = (e) => {
              const selectedSessionLecturer = e.target.value;
              setSelectedSessionLecturer(selectedSessionLecturer);
              const lecturerFaculty2 = getLecturerFaculty2(selectedSessionLecturer);
              setFaculty2(lecturerFaculty2); // Set the selected faculty
              console.log("selected session lecturer: ",selectedSessionLecturer);
              console.log("lecfaculty: ",lecturerFaculty2);
            };

            const [excelFile, setExcelFile] = useState (null);
            const [typeError, setTypeError] = useState (null);
            const [excelData, setExcelData] = useState (null);
            const [excelName, setExcelName] = useState (null);

            const inputRef = useRef(null);

            const handleFile = (e) => {
              let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
              let selectedFile = e.target.files[0];
              if(selectedFile){
                if (selectedFile && fileTypes.includes(selectedFile.type)){
                  setTypeError(null);
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(selectedFile);
                  reader.onload = (e) => {
                    setExcelFile(e.target.result);
                  }
                }
                else{
                  setTypeError('Please select only excel file types!');
                  alert("Please select only excel file types!");
          
                      // Reset the file input value
                      if (inputRef.current) {
                        inputRef.current.value = null;
                        }
                  
                  setExcelFile(null);
                }
          
                        console.log(selectedFile.type);
                        console.log('selectedfile: ', selectedFile);
                        console.log('Filename: ', selectedFile.name);
        
                        // Extract only the file name without extension
                      const fileNameWithoutExtension = selectedFile.name.split('.')[0];
                      setExcelName(fileNameWithoutExtension);
                console.log('Filename2: ', selectedFile.name);
                      console.log('Filename without: ', excelName);
              }
              else{
          
          
                console.log("Please select your file.");
              }
            };

            const handleFileSubmitBTEC = (e) => {
              e.preventDefault();
              if(excelFile !== null){
                const workbook = XLSX.read(excelFile,{type: 'buffer'});
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                //setExcelData(data.slice(0,10));
                setExcelData(data);
          
                alert("Student Data have been extracted! Please click the 'VIEW STUDENT LIST' to make sure the right data is extracted!")
                console.log(excelData);
          
              } else {
          
                alert("The file format selected is not supported!");
              }
            };

            const AddStudenttoProgram = async(e) =>{
              e.preventDefault();

              if (excelData !== null) {
        
              const confirmAddStudent = window.confirm("Add the student in the selected program?"); //confirmation windows
        
              if(confirmAddStudent){
        
                if (
                  BtecselectedInstructor === "" ||
                  BtecselectedProgram === "" ||
                  BtecselectedProgramName === "" ||
                  BtecselectedProgramLevel === "" ||
                  skmfrom2 === "" ||
                  skmuntil2 === ""
                  ) {
                  alert("Please fill in all fields");
                  return;
                  }
        
              if (excelFile === null) {
                alert('Please select a file.');
                return;
          
                } else if (excelData === null) {
                alert('Please click the Upload Button.');
                return;
                }

                console.log("BtecselectedInstructor: ",BtecselectedInstructor);
                console.log("BtecselectedProgram: ",BtecselectedProgram);
                console.log("BtecselectedProgramName: ",BtecselectedProgramName);
                console.log("BtecselectedProgramLevel: ",BtecselectedProgramLevel);
                console.log("skmfrom2: ",skmfrom2);
                console.log("skmuntil2: ",skmuntil2);
        
              registerBTECClass(e);

              const formattedfrom2 = format(skmfrom2, 'dd-MM-yyyy');
              const formatteduntil2 = format(skmuntil2, 'dd-MM-yyyy');
        
              const db3 = getDatabase();
              const usersRef = ref(db3, "BTECClassStudentList");
        
                const instructorRef = child(usersRef, BtecselectedInstructor);
                const programlevelRef = child(instructorRef, BtecselectedProgramLevel);
            
                const programcodeRef = child(programlevelRef, BtecselectedProgram);
                const fromRef = child(programcodeRef, formattedfrom2);
            
                  excelData.forEach(async(studentData) => {
                    // Assuming your Excel data has the following columns: email, faculty, name, programmes, student
                    const {
                      "Student Name": extractstudentname,
                      "Student ID": extractstudentid,
                    } = studentData;

                    //new code if want to change child node to student uid instead of studentid
                    const existingStudent = data6.find((student) => student.student === extractstudentid);

                    if (existingStudent) {
                      const studentuid = existingStudent.uid;
            
                  await set(child(fromRef, studentuid), {
                  studentname: extractstudentname,
                  student: extractstudentid,
                  uid: studentuid,
                  durationFrom: formattedfrom2,
                  durationUntil: formatteduntil2,
                  Instructor: BtecselectedInstructor,
                  programcode: BtecselectedProgram,
                  });
                }else {
                    //alert(`Student with ID ${extractstudentid} not found in data6.`);
                    alert(`Student with ID ${extractstudentid} not found in database.`);
                  }

                });
            
              alert("Students have been added to classes!");
              console.log("Students have been added to classes!");
            }
          } else {
            alert('Please fill in all the form and upload the necessary files!');

          }
        
            };

            const addtoclasssession = async (e) => {
              e.preventDefault();
            
              //await SearchSessionStudent(e);
              console.log("Year: ", YearSession);
              console.log("Month: ", MonthSession);
              console.log("Subject Name: ", SubjectNameSession);
              console.log("Lecturer: ", selectedSessionLecturer);
            
              const db3 = getDatabase();
              const usersRef = ref(db3, "ClassStudentList");
              const yearRef = child(usersRef, YearSession);
              const monthRef = child(yearRef, MonthSession);
              const subjectRef = child(monthRef, SubjectNameSession);
              console.log("subjectRef:", subjectRef);
            
              // Iterate through the SessionArray and save each item to Firebase
              SessionArray.forEach(async (item) => {
                const lectRef = child(subjectRef, selectedSessionLecturer);
                //await set(child(lectRef, item.student), { //change to student uid instead of id so that it can be use as a child node
                  await set(child(lectRef, item.uid), { //change to student uid instead of id so that it can be use as a child node
                  name: item.name,
                  student: item.student,
                  SubjectCode: SubjectCodeSession,
                  subjectName: SubjectNameSession,
                  registerlect: selectedSessionLecturer,
                });
              });
            
              //alert("Students have been added to classes!");
              //console.log("Students have been added to classes!");
            };

            const ClearStudentFile = () =>{

              setSelectedLecturer ("");
              setProgrammes ("");
              setFaculty ("");
              setSubjectCode ("");
              setSubjectName ("");
              setMonth ("");
              setYear ("");
              setExcelData (null);

            };

            const ClearStudentSession = () =>{

              setIntakeMonth ("");
              setIntakeYear ("");
              setSessionFaculty ("");
              setSessionProgrammes ("");
              setSubjectCodeSession ("");
              setSessionArray ([]);
              setSubjectNameSession ("");
              setSessionClassFaculty ("");
              setSessionClassProgrammes ("");
              setMonthSession ("");
              setYearSession ("");
              setSelectedSessionLecturer ("");

            };

            const addBTECCourse = (course) => {
              if (course.trim() === "") return; // Prevent adding empty course
          
              // Create a copy of the current array and append the new course
              const updatedCourseArray = [...BtecProgramCourse, course];
          
              // Set the state with the updated array
              setBtecProgramCourse(updatedCourseArray);
          
              // Clear the input field after adding the course
              setBtecTempCourse("");
              console.log("BtecProgramCourse: ", BtecProgramCourse)
          };
            
            const editBTECCO = (index) => {
            // Prompt the user to enter the new C0 code and name
            const newCourse = prompt("Enter the new C0 code and name (e.g., C01: New C0 Name):");

            // If the user cancels or enters empty value, return without editing
            if (!newCourse || newCourse.trim() === "") return;

            // Create a copy of the current array
            const updatedCourseArray = [...BtecProgramCourse];

            // Update the course at the specified index
            updatedCourseArray[index] = newCourse;

            // Set the state with the updated array
            setBtecProgramCourse(updatedCourseArray);
            };

            const addBTECCoursetoDB = () => {
            // Check if all required fields are filled
            if (!BtecProgramName || !BtecProgramCode || !BtecProgramLevel || BtecProgramCourse.length === 0) {
              alert("Please fill in all required fields and add at least one C0.");
              return;
            }

            // Construct the data object to be pushed to the database
            const newData = {
              BtecProgramName: BtecProgramName,
              BtecProgramCode: BtecProgramCode,
              BtecProgramLevel: BtecProgramLevel,
              BtecProgramCourse: BtecProgramCourse
            };

            // Get a reference to the Firebase database
            const db = getDatabase();
            //const programRef = ref(db, `BTECProgram/${BtecProgramName}/${BtecProgramLevel}`);
            const programRef = ref(db, `BTECProgram/${BtecProgramCode}/${BtecProgramLevel}`);

            // Push the new data to the database
            //push(programRef, newData)
            set(programRef, newData)
              .then(() => {
                  // Reset the input fields after successful submission
                  setBtecProgramName("");
                  setBtecProgramCode("");
                  setBtecProgramLevel("");
                  setBtecProgramCourse([]);
                  setBtecTempCourse("");
                  alert("BTEC program added successfully!");
              })
              .catch((error) => {
                  console.error("Error adding BTEC program: ", error);
                  alert("An error occurred while adding BTEC program. Please try again.");
              });

            };

        return (
          <div /*class="body-dashboard"*/>
            <Btecadminsidebar>
                  <AdminbtecUsername />
                  <p><br/><br/></p>
            
			<div>
			<h1 class="h1-adminedit">Admin Add/Delete Class</h1>
      <p><br/></p>
      <label>
          <input
          title='Add BTEC Program'
            type="checkbox"
            onChange={() => {setShow(!show); setShow2(false); ClearStudentFile();}} // Set show2 to false if show is being checked
            checked={show}
            className="checkbox-size-addstudent"
          />
          {/*<b>Add Student By Session</b>*/}
          <b>BTEC Add Code and Competency Unit</b>
        </label>
        <label>
          <input
          title='Add student by selecting an Excel File. The arrangement of the data must follow the template given by the administrator.'
            type="checkbox"
            onChange={() => {setShow2(!show2); setShow(false); ClearStudentSession();}} // Set show2 to false if show is being checked
            
            checked={show2}
            className="checkbox-size-addstudent"
          />
          <b>BTEC Add Student to Class</b>
        </label>
			</div>
            <div /*className="createclass-container"*/> 

			<p><br></br></p>
      {/*<div className="container-editclass">*/}
      {show2 &&(
        <div>
          <h2 class="h1-adminedit">BTEC Add/Delete Class</h2>
          <br/>
                    <div className="container">
                    <label class="p-editclass2">&emsp;<b>Instructor:</b> <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&nbsp;&nbsp;</label>

                    <select title='Select the Lecturer that will be assigned to this class (Lecturer must be registered first).' class="select-size-editclass2" 
                    value={BtecselectedInstructor}
                    onChange={handleInstructorChange}
                    >
                    <option value="">Select an Instructor</option>
                    {data4
                    .sort((a, b) => a.instructorName.localeCompare(b.instructorName))
                    .map((instructor) => (
                      <option key={instructor.uid} value={instructor.instructorName}>
                        {instructor.instructorName}
                      </option>
                    ))}
                    </select>

                    <label class="p-editclass2">&emsp;&emsp;&emsp;&emsp;<b>Program:</b> <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&nbsp;&nbsp;</label>

                  <select title='Select the Lecturer that will be assigned to this class (Lecturer must be registered first).' class="select-size-editclass2" 
                  value={BtecselectedProgram}
                  onChange={handleBTECProgramChange}
                  >
                  <option value="">Select a Program</option>
                  {data5
                  .sort((a, b) => a.BtecProgramName.localeCompare(b.BtecProgramName))
                  .map((program) => (
                    <option key={program.BtecProgramCode} value={program.BtecProgramCode}>
                      {program.BtecProgramCode} {program.BtecProgramName} Level {program.BtecProgramLevel}
                    </option>
                  ))}
                  </select>
                    </div>
                    {/*</div>*/}
                    <p><br></br></p>

                    <div className="container">

                <div className="container">
                <label title='The Program will be automatically generated based on the Program selected above.' class="p-createclass2">&emsp;<b>Program Selected:</b> <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&emsp;</label>
                <p title='The Program will be automatically generated based on the Program selected above.'>{BtecselectedProgramName ? BtecselectedProgramName : "Please select a program"}</p>
                </div>

                <br/>

                <div className="container">
                <label title='The Program Level will be automatically generated based on the Program selected above.' class="p-createclass2">&emsp;&emsp;<b>Program Level:</b> <b className="important-createclass">*</b>&emsp;&emsp;&emsp;&emsp;</label>
                <p title='The Program will be automatically generated based on the Program selected above.'>{BtecselectedProgramLevel ? BtecselectedProgramLevel : "Please select a program"}</p>
                </div>
                </div>

                <br/>
              <div className="container">
                <p>&emsp;<b>Tarikh Pengajian (Daripada)/ Duration of Study (From):</b>&emsp;</p>
                <DatePicker
                className="input-History "
                selected={skmfrom2}
                onChange={(date) => setskmfrom2(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="From"
                />

                <p>&emsp;<b>Tarikh Pengajian (Sehingga)/ Duration of Study (Until): </b>&emsp;</p>
                <DatePicker
                className="input-History "
                selected={skmuntil2}
                onChange={(date) => setskmuntil2(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="After"
                />
                </div>

            <div className="container">
              
        </div>

                    <p><br></br></p>

                    <p><br></br></p>

        <p>&emsp;Select File:<b className="important-createclass">*</b> &emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;</p>
				<div className="container">
				<p>&emsp;</p>
				<input title="Select Excel file to upload" className="p-createclass registerstudent-container3" type="file" required onChange={handleFile} ref={inputRef}></input>
				<div className="container">
				<button title='Upload the selected file' onClick={handleFileSubmitBTEC} className='button-GenQR'><FaFileUpload className='admin-view-attendance-icon3'/><b>UPLOAD FILE</b></button>
				
				<Popup trigger={<button title='View the Student List from the selected Excel file' className='button-GenQR'>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;VIEW STUDENT LIST</button>} modal nested>
		{(close) => (
		  <div className="modal">
			<button className="button-studentclass4" onClick={() =>{close();}}>&times;</button>
			<p><br /><br /></p>
	  
			<h2 className="h2-studentclass">Student List</h2>
			<p><br/><br/></p>

		   {typeError&&(
                <div role="alert"> {typeError} </div>
            )}
            <p><br/></p>
		  
			 {excelData?(
               <div class="table-container-editclass2">
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(excelData[0]).map((key) => (
                                    <th key={key}>{key}</th>

                                ))}
                            </tr>
                        </thead>
                        
                        <tbody>
                            {excelData.map((individualExcelData, index) => (
                                <tr key={index}>
                                    {Object.keys(individualExcelData).map((key)=>(
                                    <td key={key}>{individualExcelData[key]}</td>
                                    ))}
                                </tr>
                            
                            ))}
                        </tbody>

                    </table>
                </div>

            ):(
                <div>No file Uploaded!</div>
            )}
			

                <p><br/></p>
                <p className="p-generateqr-sidenote"><b>*</b>You are advised to confirm the Student Data extracted before adding them to the selected Class from the Class List.</p>
                <p className="p-generateqr-sidenote"><b>*</b>The button must be <b>CLICK AGAIN EACH TIME</b> when selecting a new Subject in ordet to view the new Class List.</p>
                </div>
              )}
              </Popup>
              </div>
                  </div>
                  <p><br/></p>
                  <button title='Add the Subject to the database and add the Student from File to the newly created Class' onClick={AddStudenttoProgram} className='button-GenQR'/*className="button-addStudent6"*/><MdAddCircle className='admin-view-attendance-icon3'/><b>ADD STUDENT TO PROGRAM</b></button>
                  </div>
                  )}

        {/*BTEC ADD PROGRAM*/}

        {show && (
          <div>
        <h2 class="h1-adminedit">BTEC Add Program</h2>
        <p><br/></p>
                    <p>&emsp;</p>

        <label class="p-createclass2"><b>Program Name/Nama Program:</b> <b className="important-createclass">*</b>&emsp;&emsp;&nbsp;&nbsp;</label>
				<input
        title='Please enter the Program Name'
                class="p-createclass createclass-container3"
					type="text"
					placeholder="Enter the Program Name"
					value={BtecProgramName}
					onChange={(e) => setBtecProgramName(e.target.value) }
                    >
					</input>

          <p>&emsp;</p>

          <label class="p-createclass2"><b>Program Code/Kod Program:</b> <b className="important-createclass">*</b>&emsp;&emsp;&nbsp;&nbsp;</label>
          <input
          title='Please enter the Program Code'
          class="p-createclass createclass-container3"
          type="text"
          placeholder="Enter the Program Code"
          value={BtecProgramCode}
          onChange={(e) => setBtecProgramCode(e.target.value) }
          >
          </input>
          <p><br/></p>
          <label class="p-createclass2"><b>Level/Tahap: &emsp;</b></label>
            <select title='Select Program'  value={BtecProgramLevel}
              onChange={(e) => setBtecProgramLevel(e.target.value)}>
				<option>Select Level</option>
				<option value="1">Level 1</option>
				<option value="2">Level 2</option>
				<option value="3">Level 3</option>
				</select>

        <p><br/></p>

        <form onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              addBTECCourse(BtecTempCourse);
          }}>
              <div className='container'>
                  <div> 
                      <label><b>C0 Code: (C0 Name):</b> <b className="important-createclass">*</b>&emsp;</label>
                      <input
                          title='Please Code and C0 Name'
                          className="p-createclass createclass-container3"
                          type="text"
                          placeholder="Enter the C0"
                          value={BtecTempCourse}
                          onChange={(e) => setBtecTempCourse(e.target.value)}
                      />
                  </div>
                  <p><br/>&emsp;</p>
                  <button title="View the selected excel file" type="submit" className='button-admin-view-attendance11'>
                      {/*<FaEye className='admin-view-attendance-icon6'/>*/}ADD C0
                  </button>
              </div>
          </form>
            {/*<p><b className="important-createclass">*</b>Example: <b>"C03 : Security Risk Situation Control"</b></p>*/}
            <p><br/></p>

            {/*Table to show the added C0*/}
            <table>
              <thead>
                  <tr>
                      <th>C0 Code</th>
                      <th>C0 Name</th>
                      <th>Edit C0</th>
                  </tr>
              </thead>
              <tbody>
                  {BtecProgramCourse.map((course, index) => {
                      const [code, name] = course.split(":").map(item => item.trim());
                      return (
                          <tr key={index}>
                              <td>{code}</td>
                              <td>{name}</td>
                              <td><button className='button-admin-view-attendance-new8' /*className="button-delete-adminregister-new"*/ onClick={() => editBTECCO(index)}>Edit</button></td>
                          </tr>
                      );
                  })}
              </tbody>
          </table>

            <p><br/></p>
            <button title="View the selected excel file" onClick={addBTECCoursetoDB} className='button-admin-view-attendance11'>
                      {/*<FaEye className='admin-view-attendance-icon6'/>*/}Add BTEC Program
                  </button>
                  <p><br/></p>

        <hr
        style={{
          background: 'rgb(190,52,85)',
          color: 'rgb(190,52,85)',
          borderColor: 'rgb(190,52,85)',
          height: '3px',
        }}
      />

      <p><br/></p>

          <p><b>Instruction:</b></p>
          <p><b>--------------------------------------------------------------------------------------------------------------------------------------------</b></p>
          <p>FORMAT: <b>C0<b className="important-createclass">n</b> [1x space] : [1x space] Name of C0</b></p>
          
          <p><b className="important-createclass">* </b>The letter <b className="important-createclass">n</b> represents the number of C0</p>
          <p><b className="important-createclass">* </b>Format Example:<b>"C03 : Security Risk Situation Control"</b></p>

          <p><b>--------------------------------------------------------------------------------------------------------------------------------------------</b></p>
          <p><br/></p>
        <p><b>1.</b> Please follow this format to enter the data inside the <b>"C0 Code: (C0 Name)"</b>, (Format Example:<b>"C03 : Security Risk Situation Control"</b>)</p>
        <p><b>2.</b> It is a <b>MUST</b> for you to type based on the <b>Format Example</b> to avoid any <b>inconsistency</b>.</p>
        <p><b>3.</b> Make sure that the <b>'C0'</b> that is entered is registered in Ascending Order <b>(Eg.: C01 : ...., C02 : ...., C03 : ....).</b></p>

        </div>
        )}

        <p><br/></p>

        <p><b>*The form marked with <b className="important-createclass">*</b> is compulsory.</b></p>

				<p><br/></p>

				<div>
				<p class="p-createclass4">Class with Courses List:</p>

        <div className="adminregister-search-container">
				<form onSubmit={searchSpecificClass}>
				<label>Search:&emsp;</label>
        <div className="input-icon-container">
				<input 
				className="input-editclass2"
				type="text"
				placeholder="Enter Subject Name/Code/Lecturer"
				value={searchClass}
				onChange={(e) => setSearchClass(e.target.value)}
				>	
				</input>
        <FcSearch className="search-icon" onClick={searchSpecificClass} title="Search"/>
				</div>
				</form>
				</div>
        <p><br/><br/></p>

        <div className="table-container-editclass">
        <table>
          <thead className="th-addstudent">
            <tr>
              <th style={{ fontSize: '18px' }}>Program Code / Program Name</th>
              <th style={{ fontSize: '18px' }}>Level</th>
              <th style={{ fontSize: '18px' }}>Course</th>
              {/*<th style={{ fontSize: '18px' }}>Action</th>*/}
            </tr>
          </thead>
          <tbody>
          {currentRows2.length > 0 ? (
  currentRows2.map((program, programIndex) => (
    program.programLevels.map((level, levelIndex) => (
      <tr key={`${program.programCode}_${level.programLevel}`}>
        <td className="editclass-td" style={{ fontWeight: 'bolder' }}>
          {program.programCode} / {level.BtecProgramName}
        </td>
        <td className="editclass-td" style={{ fontWeight: 'bolder' }}>
          {level.BtecProgramLevel}
        </td>
        <td className="editclass-td" style={{ fontWeight: 'bolder' }}>
     {/* Displaying each course on a new line */}
     {level.courses.map((course, courseIndex) => (
            <span key={courseIndex}>{course}<br/></span>
          ))}
        </td>
      </tr>
    ))
  ))
) : (
  <tr>
    <td colSpan="4">No Class found.</td>
  </tr>

)}

          </tbody>
        </table>
      </div>
 
        <p><br/></p>

				</div>
        </div>

      </Btecadminsidebar>
	  
    </div>
  );
};

export default AdminbtecEditClass;