import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, child, set, remove} from "firebase/database";
import '../attendance/QRattendancepage.css';
import QRCode from "react-qr-code";
import "react-datepicker/dist/react-datepicker.css";
import QRattendancepage from '../attendance/QRattendancepage';

const BtecQRAttendancepage = () => {
  //const props = { placeholder: 'Please Select...' };
  const [startDate, setStartDate] = useState(new Date());
  const [FilteredAttendStudent, SetFilteredAttendStudent] = useState([]);
 
  const [data, setData] = useState([]);
  
  const queryParams = new URLSearchParams(window.location.search); //fetch data from BtecGenerateAttendance page

  const passedLecturer2 = queryParams.get('Lecturer');
  const [passedLecturer, setPassedLecturer] = useState("");

  const passedSubject2 = queryParams.get('Subject');
  const [passedSubject, setPassedSubject] = useState("");

  const passedVenue2 = queryParams.get('Venue');
  const [passedVenue, setPassedVenue] = useState("");

//new
  const qrCodeValue2 = queryParams.get('qrValue');
  const [qrCodeValue, setQRCodeValue] = useState("");

  const passedDate2 = queryParams.get('Date');
  const [passedDate, setPassedDate] = useState("");

  const passedInstructor2 = queryParams.get('Instructor');
  const [passedInstructor, setPassedInstructor] = useState("");

  const passedProgramName2 = queryParams.get('ProgramName');
  const [passedProgramName, setPassedProgramName] = useState("");

  const passedProgramCode2 = queryParams.get('ProgramCode');
  const [passedProgramCode, setPassedProgramCode] = useState("");

  const passedCourse2 = queryParams.get('Course');
  const [passedCourse, setPassedCourse] = useState("");

  const passedClassWeek2 = queryParams.get('ClassWeek');
  const [passedClassWeek, setPassedClassWeek] = useState("");

  const passedAttendanceTaken2 = queryParams.get('AttendanceTaken');
  const [passedAttendanceTaken, setPassedAttendanceTaken] = useState("");

  const passedLevel2 = queryParams.get('Level');
  const [passedLevel, setPassedLevel] = useState("");

  const passedInstructorHelper2 = queryParams.get('InstructorHelper');
  const [passedInstructorHelper, setPassedInstructorHelper] = useState("");

  useEffect(() => {

    const generateUniqueKey = () => {
      const random = Math.random().toString(36).substring(2, 10); // Generate a random string
      return `${random}`;
    };

    const updateQRCodeValue = async () => {
      const uniqueKey = generateUniqueKey();
      //const formattedStartDate = format(startDate, 'dd-MM-yyyy');
      const newQRCodeValue = `${qrCodeValue2},${uniqueKey}`;
      setQRCodeValue(newQRCodeValue);

      try {
        const db = getDatabase();
        const qrRef = ref(db, "BTECQR");
        const programcodeRef = child(qrRef, passedProgramCode2);
        const levelRef = child(programcodeRef, passedLevel2);
        const dateRef = child(levelRef, passedDate2);
        const instructorRef = child(dateRef, passedInstructor2);
        const attendanceTakenRef = child(instructorRef, passedAttendanceTaken2);
        //const uniqueKeyRef = child(lecturerRef, uniqueKey);
        
          //input into firebase 'Faculty'
        await set(attendanceTakenRef, {
        ProgramName: passedProgramName2,
        ProgramCode: passedProgramCode2,
        Level: passedLevel2,//added 24072024
        TakenFor: passedAttendanceTaken2,//added 24072024
        ClassDate: passedDate2,
        Instructor: passedInstructor2,
        ClassWeek: passedClassWeek2,
        Helper: passedInstructorHelper2,
        CO: passedCourse2,
        Uniquekey: uniqueKey,
        });
        //alert("QR have beeen adddded.")
        //console.log("Subject have been added!");
  
      } catch (error) {
        //alert("Something went wrong.")
        console.log(error.message);
      }
    };

    setPassedDate(passedDate2);
    setPassedProgramName(passedProgramName2);
    setPassedProgramCode(passedProgramCode2);
    setPassedInstructor(passedInstructor2);
    setPassedClassWeek(passedClassWeek2);
    setPassedInstructorHelper(passedInstructorHelper2);
    setPassedCourse(passedCourse2);
    setPassedLecturer(passedLecturer2);
    setPassedSubject(passedSubject2);
    setPassedVenue(passedVenue2);
    setPassedLevel(passedLevel2);
    setPassedAttendanceTaken(passedAttendanceTaken2);

    // Set the initial QR code value
    updateQRCodeValue();

    const timer = setInterval(updateQRCodeValue, 4000); //update QR value each 30 seconds
    console.log("qrCodeValue: ", qrCodeValue)
    // Cleanup the timer on component unmount
    return () => {
      clearInterval(timer);

          // Get the unique key from the current qrCodeValue
    const uniqueKey = qrCodeValue.split(",")[1];
    // Call the function to delete QR code data passing the unique key
    deleteQRCodeData(uniqueKey, passedProgramCode2, passedLevel2, passedDate2, passedInstructor2, passedAttendanceTaken2);

    };
  }, [startDate,qrCodeValue2,passedInstructor2,
    passedLevel2,passedDate2,passedAttendanceTaken2,
    passedClassWeek2,passedCourse2,passedInstructorHelper2,
    passedProgramCode2,passedProgramName2]);

  //console.log(qrCodeValue);
  //console.log(passedDate);
  //console.log(passedLecturer);

  /*const deleteQRCodeData = async (uniqueKey, subname, classvenue, dateclass, classlec) => {*/
    const deleteQRCodeData = async (uniqueKey, passedProgramCode2, passedLevel2, 
      passedDate2, passedInstructor2, passedAttendanceTaken2) => {
    try {
      const db = getDatabase();
      const qrRef = ref(db, "BTECQR");
      const programcodeRef = child(qrRef, passedProgramCode2);
      const levelRef = child(programcodeRef, passedLevel2);
      const dateRef = child(levelRef, passedDate2);
      const instructorRef = child(dateRef, passedInstructor2);
      const attendanceTakenRef = child(instructorRef, passedAttendanceTaken2);

      const refToDelete = ref(db, `BTECQR/${passedProgramCode2}/${passedLevel2}/${passedDate2}/${passedInstructor2}/${passedAttendanceTaken2}`);
  
      // Remove the data from the database
      remove(refToDelete);
      //await set(lecturerRef, null);
  
      console.log("BTECQR code data removed successfully.");
    } catch (error) {
      console.log("Error deleting BTECQR code data:", error.message);
    }
  };

  useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECQrAttendance");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef, (snapshot) => {
		  const BTECQRAttendanceData= snapshot.val();
		  if (BTECQRAttendanceData) {
			const dataArray = Object.entries(BTECQRAttendanceData).flatMap(([ProgramCode, LevelData]) => {
			  return Object.entries(LevelData).flatMap(([Level, DateData]) => {
				return Object.entries(DateData).flatMap(([ClassDate, InstructorNameData]) => {
          return Object.entries(InstructorNameData).flatMap(([InstructorName, TakenFromItem]) => {
            return Object.entries(TakenFromItem).flatMap(([StudUID, UIDItem]) => {
          return Object.entries(UIDItem).map(([TakenFrom, QRAttendanceItem]) => {
				  return {
					ProgramCode,
					Level,
					ClassDate,
					InstructorName,
                    TakenFrom,
                    StudUID,

                    CoursePick: QRAttendanceItem.CoursePick,
                    DurationFrom: QRAttendanceItem.DurationFrom,
                    DurationUntil: QRAttendanceItem.DurationUntil,
                    InstHelper: QRAttendanceItem.InstHelper,
                    //Instructor: QRAttendanceItem.Instructor,
                    //ProgramCode: QRAttendanceItem.ProgramCode,
                    StudentName: QRAttendanceItem.StudentName,
                    TakenFor: QRAttendanceItem.TakenFor,
                    TimeScanned: QRAttendanceItem.TimeScanned,
                    Week: QRAttendanceItem.Week,
                    AttendanceStatus: QRAttendanceItem.status,
                    studentID: QRAttendanceItem.studentID,
                    //uid: QRAttendanceItem.uid,
                    };
                    });
                    });
                    });
                });
                            });
                            });
                            setData(dataArray);
                            //console.log("data8: ",data8);

                        } else {
                            // No data available in the "Class" location
                            console.log("No data available.");
                        }
                        });
                    }, []);

  useEffect(() => {
		const FilteredAttendStudent = data.filter((QRAttendanceItem) => {
		  return QRAttendanceItem.InstructorName === passedInstructor2 &&  QRAttendanceItem.ProgramCode === passedProgramCode2 &&
      QRAttendanceItem.Level === passedLevel2 && QRAttendanceItem.TakenFor === passedAttendanceTaken2 && QRAttendanceItem.ClassDate === passedDate2 && QRAttendanceItem.AttendanceStatus === "1";
		});
	  
		SetFilteredAttendStudent(FilteredAttendStudent);

	  }, [data, passedDate2, passedInstructor2, passedProgramCode2, passedLevel2, passedAttendanceTaken2]);

  return (
    <div class="body-qrattendancepage">
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: '70px' ,height: '70%' }}>
            <div>
            <p><br/><br/><br/><br/><br/><br/></p>
            <QRCode
        size={450}
        bgcolor="white"
        fgColor="black"
        value={qrCodeValue}
        />
        <p><br/><br/><br/><br/></p>
        {/*<p className="p-qrattendancepage">QR value: {qrCodeValue}</p>*/}
        <p className="p-qrattendancepage"><b>Date: {passedDate}</b></p>
        <p className="p-qrattendancepage"><b>Instructor: {passedInstructor}</b></p>
        <p className="p-qrattendancepage"><b>Program Code: {passedProgramCode}</b></p>
        <p className="p-qrattendancepage"><b>Program Name: {passedProgramName}</b></p>
        <p className="p-qrattendancepage"><b>Program Level: {passedLevel}</b></p>
        <p className="p-qrattendancepage"><b>CO: {passedCourse}</b></p>
        <p className="p-qrattendancepage"><b>Taken For: {passedAttendanceTaken}</b></p>
        </div>


        <div className="background-qrattendance-new">
        <h1 class="h1-qrattendancepage">Class {passedProgramCode} {passedProgramName} Level {passedLevel} Attendance List</h1>
        <h1 class="h1-qrattendancepage">CO: {passedCourse}</h1>
        <table className="table-qrattendance">
        <thead className="th-generateqr">
          <tr>
            <th>Student ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
		{FilteredAttendStudent.length > 0 ? (
          FilteredAttendStudent.map((QRAttendanceItem) => (
            <tr key={QRAttendanceItem.uid}>
              <td className="generateqr-td">{QRAttendanceItem.studentID}</td>
              <td className="generateqr-td">{QRAttendanceItem.StudentName}</td>
            </tr>
          ))
		):(
			<tr>
			<td colSpan="6">No Student.</td>
		  </tr>
		)
		}
        </tbody>
      </table>
      </div>
        </div>

        <p><br></br></p>

      {/*</Sidebar>*/}

    </div>
  );
};

export default BtecQRAttendancepage;