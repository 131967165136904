//receive year, month, subject code, subject name, lecturername from attendancehistorymain
import React, { useState, useEffect } from 'react';
import Btecadminsidebar from '../Btecadminsidebar';
import AdminbtecUsername from './AdminbtecUsername';
import { getDatabase, ref, onValue, get,set, remove} from "firebase/database";
import '../attendance/AttendanceHistoryMain.css';
import '../attendance/GenerateQR.css';
import '../attendance/FacultyList';
import { auth } from "../firebase";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DownloadPDF from '../generatepdf/DownloadPDF';
import BtecDownloadWeekPDF from '../btecpages/BtecDownloadWeekPDF';
import BtecDownloadAttPDF from '../btecpages/BTECDownloadAttPDF';
import { FcSearch } from "react-icons/fc";
import DownloadCompiledClass from '../attendance/DownloadCompiledClass';
import { MdDelete } from "react-icons/md";
import { FaFilePdf, FaEye, FaUserClock } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
/*import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';*/
import { PieChart, Pie, Cell } from 'recharts';
import emailjs from 'emailjs-com';

//for DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; //enabled the date past the current date

const AdminbtecViewAttendance = ({  item, attendanceData }) => {
	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
	const [data6, setData6] = useState([]);
	const [data7, setData7] = useState([]);
    const [data8, setData8] = useState([]);

	//const [Venue, setVenue] = useState("");
	const [selectdate, setSelectedDate] = useState(new Date());

	const [searchClass, setSearchClass] =  useState("");
    const [filteredSearchClass, setFilteredSearchClass] = useState([]);
 
	const [filteredDate, setFilteredDate] = useState([]);
	const [filteredClass, setFilteredClass] = useState([]);
    const [FilteredAttendanceClass, setFilteredAttendanceClass] = useState([]);
	const [FilteredStudentAttendance, setFilteredStudentAttendance] = useState([]);
	const [FilteredStudentAttendanceNew, setFilteredStudentAttendanceNew] = useState([]);
	const [filteredUniqueDates, setFilteredUniqueDates] = useState([]);
	const [FilteredStudentCopick, setFilteredStudentCopick] = useState([]);
	const [FilteredWeekMonthCO, setFilteredWeekMonthCO] = useState([]);
	const [FilteredDateMonthCO, setFilteredDateMonthCO] = useState([]);
	const [timeAttStatus, settimeAttStatus] = useState("");
	const [changeAttStatus, setchangeAttStatus] = useState("");
	const [searchWeekCO, setSearchWeekCO] = useState("");
	const [searchMonthCO, setSearchMonthCO] = useState("");

	const [filteredDatanew, setFilteredDatanew] = useState([]);
	const [filteredSortCO, setFilteredSortCO] = useState([]);
	const [filteredSortCONew, setFilteredSortCONew] = useState([]);
	const [filteredTotalStudentsNew, setFilteredTotalStudentsNew] = useState([]);
	const [filteredClassNew, setFilteredClassNew] = useState([]);

	const [weekSelect, setWeekSelect] = useState([]);
	const [COSelect, setCOSelect] = useState([]);

	const [FilteredCompiledAttendance, setFilteredCompiledAttendance] = useState([]);
    const [filteredTotalStudents, setfilteredTotalStudents] = useState([]);
	const [filteredTotal, setfilteredTotal] = useState([]);
    const [filteredPresentStudents, setFilteredPresentStudents] = useState([]);

	const [filteredSumClass, setFilteredSumClass] = useState([]);
	const [filteredSumClassTotal, setFilteredSumClassTotal] = useState([]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);

	const [show, setShow] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [currentPage3, setCurrentPage3] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(13);

	const queryParams2 = new URLSearchParams(window.location.search); //fetch data from GenerateQR page
	const classdetails2 = queryParams2.get('classdetails');
	const [classdetails, setclassdetails] = useState("");
  
	const year2 = queryParams2.get('year');
	const [passedyear, setPassedyear] = useState("");
  
	const month2 = queryParams2.get('month');
	const [passedmonth, setPassedmonth] = useState("");
  
	const subjectcode2 = queryParams2.get('subjectcode');
	const [passedsubjectcode, setPassedsubjectcode] = useState("");
  
	const subjectname2 = queryParams2.get('subjectname');
	const [passedsubjectname, setPassedsubjectname] = useState("");

	const lecturername2 = queryParams2.get('lecturername');
	const [passedlecturername, setPassedlecturername] = useState("");

    const ProgramCode2 = queryParams2.get('ProgramCode');
	const [passedProgramCode, setProgramCode] = useState("");
    
    const ProgramName2 = queryParams2.get('ProgramName');
	const [passedProgramName, setPassedProgramName] = useState("");
    
    const DurationFrom2 = queryParams2.get('DurationFrom');
	const [passedDurationFrom, setPassedDurationFrom] = useState("");

	const DurationUntil2 = queryParams2.get('DurationUntil');
	const [passedDurationUntil, setPassedDurationUntil] = useState("");

	const Instructor2 = queryParams2.get('Instructor');
	const [passedInstructor, setPassedInstructor] = useState("");

	const Level2 = queryParams2.get('Level');
	const [passedLevel, setPassedLevel] = useState("");

	const CoursePick2 = queryParams2.get('CoursePick');
	const [passedCoursePick, setPassedCoursePick] = useState("");

	const [monthOptions, setMonthOptions] = useState([]);
	const [weekOptions, setWeekOptions] = useState([]);

	//console.log("classdetails: ", classdetails2);

	//for finding student database

      useEffect(() => {
        //to receive student data in realtime
            const db = getDatabase();
            const dbRef = ref(db, "BTECStudent");
    
            onValue(dbRef, (snapshot) => {
              const firebaseData = snapshot.val();
              if (firebaseData) { 
                const dataArray = Object.entries(firebaseData).map(([uid, studentData]) => ({
                  uid,
                  durationfrom: studentData.durationfrom,
                  durationuntil: studentData.durationuntil,
                  email: studentData.email,
                  faculty: studentData.faculty,
                  icnumber: studentData.icnumber,
                  imageUrl: studentData.imageUrl,
                  level: studentData.level,
                  name: studentData.name,
                  skmprogram: studentData.skmprogram,
                  skmprogramcode: studentData.skmprogramcode,
                  student: studentData.student,
                  //uid: studentData.uid,
                }));
                setData5(dataArray);
              }
            });
          }, []);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECClass");

		onValue(dbRef, (snapshot) => {
		  const classData = snapshot.val();
		  if (classData) {
			const dataArray = Object.entries(classData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).map(([durationfrom, BTECClassitem]) => {
					//return Object.entries(lectNameData).map(([lectName, classItem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					ProgramName: BTECClassitem.ProgramName,
					DurationFrom: durationfrom,
                    DurationUntil: BTECClassitem.DurationUntil,
				  };
				});
				});
			  });
			});
			setData6(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No data available.");
		  }
		});
	}, []);

    useEffect(() => {
		const db5 = getDatabase();
		const dbRef5 = ref(db5, "BTECClassStudentList");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef5, (snapshot) => {
		  const StudentClassData = snapshot.val();
		  if (StudentClassData) {
			const dataArray = Object.entries(StudentClassData).flatMap(([instructor, levelData]) => {
				return Object.entries(levelData).flatMap(([level, programCodeData]) => {
                    return Object.entries(programCodeData).flatMap(([programCode, durationfromData]) => {
					return Object.entries(durationfromData).flatMap(([durationfrom, studentUIDData]) => {
                    return Object.entries(studentUIDData).map(([studentUID, BTECClassListitem]) => {
				  return {
                    Instructor: instructor,
					Level: level,
					ProgramCode: programCode,
					DurationFrom: durationfrom,
					DurationUntil: BTECClassListitem.durationUntil,
                    uid: studentUID,
                    Student: BTECClassListitem.student,
					StudentName: BTECClassListitem.studentname,

					// Add more properties as needed
				  };
				});
				});
			  });
			});
		});
			setData7(dataArray);
		  } else {
			// No data available in the "Class" location
			console.log("No student under this class found.");
		  }
		});
	}, []);

    useEffect(() => {
		const db = getDatabase();
		const dbRef = ref(db, "BTECQrAttendance");

		//data Class structure Class> Month > Year > SubjectCode
		onValue(dbRef, (snapshot) => {
		  const BTECQRAttendanceData= snapshot.val();
		  if (BTECQRAttendanceData) {
			const dataArray = Object.entries(BTECQRAttendanceData).flatMap(([ProgramCode, LevelData]) => {
			  return Object.entries(LevelData).flatMap(([Level, DateData]) => {
				return Object.entries(DateData).flatMap(([ClassDate, InstructorNameData]) => {
          return Object.entries(InstructorNameData).flatMap(([InstructorName, TakenFromItem]) => {
            return Object.entries(TakenFromItem).flatMap(([TakenFrom, UIDItem]) => {
          return Object.entries(UIDItem).map(([StudUID, QRAttendanceItem]) => {
				  return {
					ProgramCode,
					Level,
					ClassDate,
					InstructorName,
                    TakenFrom,
                    StudUID,

                    CoursePick: QRAttendanceItem.CoursePick,
                    DurationFrom: QRAttendanceItem.DurationFrom,
                    DurationUntil: QRAttendanceItem.DurationUntil,
                    InstHelper: QRAttendanceItem.InstHelper,
                    InstructorDB: QRAttendanceItem.Instructor,
					ProgramName: ProgramName2,
                    ProgramCodeDB: QRAttendanceItem.ProgramCode,
                    StudentName: QRAttendanceItem.StudentName,
                    TakenFor: QRAttendanceItem.TakenFor,
					ClassDateDB: QRAttendanceItem.ClassDate,
                    TimeScanned: QRAttendanceItem.TimeScanned,
					dayScannedDB: QRAttendanceItem.dayScanned,
                    Week: QRAttendanceItem.Week,
                    AttendanceStatus: QRAttendanceItem.status,
                    studentID: QRAttendanceItem.studentID,
                    StudUIDDB: QRAttendanceItem.uid,
                    };
                    });
                    });
                    });
                });
                            });
                            });
                            setData8(dataArray);
                            //console.log("data8: ",data8);

                        } else {
                            // No data available in the "Class" location
                            console.log("No data available.");
                        }
                        });
                    }, []);
			
			useEffect(() => {
				const filteredDatanew = data8.filter(QRHistoryItem => {

					return (
						QRHistoryItem.InstructorDB === Instructor2
						&& QRHistoryItem.AttendanceStatus !== 0 //NEW
						&& QRHistoryItem.ProgramCode.trim().toLowerCase() === ProgramCode2.trim().toLowerCase()
						&& QRHistoryItem.DurationFrom.trim().toLowerCase() === DurationFrom2.trim().toLowerCase()
						&& QRHistoryItem.DurationUntil.trim().toLowerCase() === DurationUntil2.trim().toLowerCase()
					);
				});
				setFilteredDatanew(filteredDatanew);
				//console.log("filteredDatanew: ",filteredDatanew);
				
				const filteredSortCO = filteredDatanew.map((classItem) => {
				const filteredSortedStud  =  data8.filter(QRHistoryItem => {
					//console.log("QRHistoryItem: ", QRHistoryItem);
					return (
							QRHistoryItem.InstructorDB === Instructor2 &&
							QRHistoryItem.ProgramCodeDB === ProgramCode2 &&
							QRHistoryItem.DurationFrom === DurationFrom2 &&
							QRHistoryItem.DurationUntil === DurationUntil2 &&
							QRHistoryItem.Level === Level2 &&
							QRHistoryItem.CoursePick === classItem.CoursePick
						);
					})
						return {
							ProgramCode: ProgramCode2,
							Level: Level2,
							Instructor: Instructor2,
							DurationFrom: DurationFrom2,
							DurationUntil: DurationUntil2,
							CoursePick: classItem.CoursePick,
							ProgramName: ProgramName2,
							InstHelper: classItem.InstHelper,
							TakenFor: classItem.TakenFor,
							Week: classItem.Week,
							ClassDate: classItem.ClassDate,
							totalStudents1: filteredSortedStud.length // Count the students that exist in data4
						};
						});
						setFilteredSortCO(filteredSortCO);
						//console.log("filteredSortCO: " , filteredSortCO);

						// Extract unique CoursePick values
						const uniqueCoursePicks = Array.from(new Set(filteredSortCO.map(item => item.CoursePick)));

						const filteredSortCONew = uniqueCoursePicks.map(coursePick => {
							const item = filteredSortCO.find(item => item.CoursePick === coursePick);
							if (item) {
								return {
									...item,
									ProgramCode: item.ProgramCode.trim(),
									ProgramName: item.ProgramName.trim(),
									DurationFrom: item.DurationFrom.trim(),
									DurationUntil: item.DurationUntil.trim()
								};
							}
							return null;
						}).filter(item => item !== null);
						setFilteredSortCONew(filteredSortCONew);

						const filteredClassNew = data6.filter((classItem) => {
							return classItem.Instructor === Instructor2;
						  });
						  //console.log(filteredClassNew);
						  setFilteredClassNew(filteredClassNew);

						const filteredTotalStudentsNew = filteredClassNew.map((classItem) => { //data6
							const totalStudents = data7.filter((classStudentItem) => 
							classStudentItem.ProgramCode === classItem.ProgramCode &&
							classStudentItem.Level === classItem.Level &&
							classStudentItem.Instructor === classItem.Instructor &&
							classStudentItem.DurationFrom === classItem.DurationFrom &&
							classStudentItem.DurationUntil === classItem.DurationUntil
							);
				
							const filteredStudentsInData8 = totalStudents.filter((student) => {
							  return data8.some((data8Student) => 
								data8Student.ProgramCode === student.ProgramCode &&
							  data8Student.Level === student.Level &&
							  data8Student.InstructorName === student.Instructor &&
							  data8Student.DurationFrom === student.DurationFrom &&
							  data8Student.DurationUntil === student.DurationUntil &&
							  data8Student.StudentName === student.StudentName &&
							  data8Student.studentID === student.Student
							  );
							});
				
							return {
								ProgramCode: classItem.ProgramCode,
								Level: classItem.Level,
								Instructor: classItem.Instructor,
								DurationFrom: classItem.DurationFrom,
								DurationUntil: classItem.DurationUntil,
								totalStudents: filteredStudentsInData8.length
								};
						  });
						  setFilteredTotalStudentsNew(filteredTotalStudentsNew);
						  //console.log("filteredTotalStudentsNew: ",filteredTotalStudentsNew);
						  //console.log("filteredTotalStudentsNew: ",filteredTotalStudentsNew.totalStudents);
				
					}, [DurationFrom2,DurationUntil2,Instructor2,Level2,ProgramCode2,ProgramName2,data8,data6,data7,currentUser]);
	  
			//comment on 20082024

      useEffect(() => {
		const filteredClass = data6.filter((classItem) => {
			/*console.log("---------classitem--------");
			console.log("Instructor: ",classItem.Instructor);
			console.log("ProgramCode: ",classItem.ProgramCode);
			console.log("ProgramName: ",classItem.ProgramName);
			console.log("DurationFrom: ",classItem.DurationFrom );
			console.log("DurationUntil: ",classItem.DurationUntil);
			console.log("---------classitem--------");*/
		  /*return classItem.Instructor === currentUser.instructorName 
		  && classItem.ProgramCode === ProgramCode2 
		  && classItem.ProgramName === ProgramName2
		  && classItem.DurationFrom === DurationFrom2
		  && classItem.DurationUntil === DurationUntil2;*/
		  return classItem.Instructor === Instructor2
		});
		setFilteredClass(filteredClass);
		//console.log(filteredClass.length);
		//console.log(filteredClass);
		//console.log("data6: ",data6);
		/*console.log("--------passed data--------");
		console.log(currentUser.instructorName);
		console.log("ProgramCode2: ",ProgramCode2);
		console.log("ProgramName2: ",ProgramName2);
		console.log("DurationFrom2: ",DurationFrom2);
		console.log("DurationUntil2: ",DurationUntil2);
		console.log("--------passed data--------");*/
		//console.log("Level2: ",Level2);
	  
		const uniqueClasses = [];
		const filteredAttendanceClass = [];

		/*console.log(DurationUntil2);
		console.log(DurationFrom2);
		console.log(ProgramCode2);
		console.log(currentUser.instructorName);*/
		
		data8.forEach((QRHistoryItem) => {
		  const isDuplicateClass = uniqueClasses.some((classItem) =>
			classItem.ProgramCode === QRHistoryItem.ProgramCode &&
			classItem.ClassDate === QRHistoryItem.ClassDate &&
			classItem.TakenFrom === QRHistoryItem.TakenFrom &&
			classItem.DurationFrom === QRHistoryItem.DurationFrom &&
			classItem.DurationUntil === QRHistoryItem.DurationUntil
		);
		//console.log("here1");
		  if (!isDuplicateClass && QRHistoryItem.InstructorName === Instructor2 
			&& QRHistoryItem.ProgramCode === ProgramCode2 
			&& QRHistoryItem.DurationFrom === DurationFrom2
			&& QRHistoryItem.DurationUntil === DurationUntil2
			//&& QRHistoryItem.CoursePick === CoursePick2
		) 
			{// console.log("here1");
			/*console.log("InstructorName:", QRHistoryItem.InstructorName);
			console.log("ProgramCode:", QRHistoryItem.ProgramCode);
			console.log("DurationFrom:", QRHistoryItem.DurationFrom);
			console.log("DurationUntil:", QRHistoryItem.DurationUntil);*/
			uniqueClasses.push(QRHistoryItem);
			filteredAttendanceClass.push(QRHistoryItem);
		  }
		});

		if (filteredAttendanceClass.length > 0) {
		  setFilteredAttendanceClass(filteredAttendanceClass);
		  console.log("filteredAttendanceClass: ", filteredAttendanceClass);
		  console.log("uniqueclass: ", uniqueClasses);
		} else {
		  // console.log("No matching classes found.");
		  setFilteredAttendanceClass([]); // or set to an appropriate default value
		}
		
		  const filteredPresentStudents = filteredAttendanceClass.map((classItem) => {
			const presentStudents = data8.filter((QRHistoryItem) =>
			  QRHistoryItem.InstructorName === Instructor2 &&
			  QRHistoryItem.ProgramCode === classItem.ProgramCode &&
			  QRHistoryItem.ClassDate === classItem.ClassDate &&
			  QRHistoryItem.TakenFor === classItem.TakenFor &&
			  (QRHistoryItem.AttendanceStatus === "1" || QRHistoryItem.AttendanceStatus === "3")
			);
			// console.log("test", presentStudents);
			return {
				ProgramCode: classItem.ProgramCode,
			  ProgramName: classItem.ProgramName,
			  ClassDate: classItem.ClassDate,
			  presentStudents: presentStudents.length
			};
		  });
		  //console.log("test", filteredPresentStudents);
		  setFilteredPresentStudents(filteredPresentStudents);

	  }, [currentUser, data6, data7, data8, DurationFrom2, DurationUntil2, ProgramCode2, ProgramName2]);

	  const findAttendStudent2 = (AttendItem) => {
		
		const FilteredStudentAttendance = data8.filter((QRHistoryItem) => {
			console.log("here11");
			return QRHistoryItem.InstructorName === Instructor2 
			&& QRHistoryItem.ProgramCode === AttendItem.ProgramCode
			&& QRHistoryItem.CoursePick === AttendItem.CoursePick
			//&& QRHistoryItem.TakenFor === AttendItem.TakenFor
			&& QRHistoryItem.DurationFrom === AttendItem.DurationFrom
			&& QRHistoryItem.DurationUntil === AttendItem.DurationUntil;
		  });
		  //console.log("FilteredStudentAttendance: ",FilteredStudentAttendance);
		  setFilteredStudentAttendance(FilteredStudentAttendance);
		  //console.log("FilteredStudentAttendance length: ", FilteredStudentAttendance.length);
		  if (FilteredStudentAttendance.length === 0){
			alert("No class has been done for this CO/EO yet! Please create the class based on this CO/EO!");
		  }
		  else{
			alert("Student Attendances found.");
		  }
	  };

	  const setdefaultstate = () => {
		setSelectedRows([]);
		setchangeAttStatus("");
		settimeAttStatus("");
	  };
	  const setdefaultstate2 = () => {
		setSelectedRows([]);
		setSearchWeekCO("");
		setSearchMonthCO("");
	  };

	  //selectedrows should be unique to its studentuid
  
	  useEffect(() => {
		// listen for changes to the auth object
		const unsubscribe = auth.onAuthStateChanged((user) => {
		  // update the component with the current user
		  setCurrentUser(user);
		});
		// cleanup function to unsubscribe when the component unmounts
		return unsubscribe;
	  }, []);

      useEffect(() => {
        if (currentUser) {
            const db = getDatabase();
            const userRef = ref(db, `BTECInstructor/${currentUser.uid}`);
            get(userRef)
              .then(snapshot => {
                const userData = snapshot.val();
                setCurrentUser({
                  ...currentUser,
                  instructorName: userData.instructorName,
                  Faculty: userData.btecregisterFaculty,
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
        }, [currentUser]);

	  const showsoption = () => {
		setShow((prevState) => !prevState);
	  };

	  const resetshow = () => {
		setFilteredStudentAttendance([]);
		setFilteredCompiledAttendance([]);
		setFilteredStudentCopick([]);
	  };

	  const resetshow2 = () => {
		setFilteredUniqueDates([]);
	  };

	  const resetshow3 = () => {
		setFilteredSumClass([]);
		setFilteredSumClassTotal([]);
	  };

	  const resetshow4 = () => {
		setFilteredDateMonthCO([]);
		setFilteredWeekMonthCO([]);
	  };

	  const FindbyDate = () => {
		setFilteredDate([]);
		if (selectdate) {
		  const formattedDate = format(selectdate, 'dd-MM-yyyy');
		  console.log(formattedDate);
	  
		  // Filter the data based on the selected date
		  const processedSubjectNames = new Set();
		  const newFilteredDate = [];
		  
		  FilteredAttendanceClass.forEach((item) => {
			if (item.ClassDate === formattedDate 
				&& item.Instructor === Instructor2
				&& item.DurationFrom === DurationFrom2
				&& item.ProgramCode === ProgramCode2
				&& item.DurationUntil === DurationUntil2
				&& item.Level === Level2) {
			  // Check if the subject name has already been processed for this date
			  if (!processedSubjectNames.has(item.ProgramCode)) {
				newFilteredDate.push(item);
				processedSubjectNames.add(item.ProgramCode);
			  }
			}
		  });
	  
		  console.log("Filtered Date:", filteredDate); // Output: Filtered data based on the selected date
	  
		  // Perform any desired logic with the filtered data
		  if (newFilteredDate.length > 0) {
			setFilteredDate(newFilteredDate);
			alert(`Classes found on ${formattedDate}.`);
		  } else {
			alert(`No classes found on ${formattedDate}.`);
		  }
		} else {
		  console.log("No date selected");
		  // Handle the case where no date is selected
		}
	  };
	  
	  const FindSubject = (e) => {
		e.preventDefault();
		// Check if the search input is empty
		if (!searchClass) {
		alert('Please enter the Subject Name/Code');
		return; // Return early without making any changes
		}
  
		// Perform the search logic based on the selected student ID
		const filteredSearchClass = FilteredAttendanceClass.filter((classsearch) => {
		const SearchClassName = classsearch.SubjectNameDB.toLowerCase();
		const SearchClassCode = classsearch.SubjectCode.toLowerCase();
		//const SearchClassLecturer = classsearch.Lecturer.toLowerCase();
		const searchInput = searchClass.toLowerCase();
  
		return SearchClassName.includes(searchInput) || SearchClassCode.includes(searchInput) /*|| SearchClassLecturer.includes(searchInput)*/;
		});
  
		// Update the foundStudents state with the filtered students array
		setFilteredSearchClass(filteredSearchClass);
  
		if (filteredSearchClass.length === 0) {
		alert('The Subject Name/Code does not exists.');
		}
		console.log(`filtered Class: `, filteredSearchClass);
	  };

	  const sortedRows = [...filteredSortCONew].sort((a, b) => {
		if (a.CoursePick < b.CoursePick) {
			return -1;
		  }
		  if (a.CoursePick > b.CoursePick) {
			return 1;
		  }
		  return 0;
		});

	  const sortedRows2 = [...filteredSearchClass].sort((a, b) => {
		// Sort by Date
		const dateA = new Date(
			a.ClassDate.split('-').reverse().join('-')
		  );
		  const dateB = new Date(
			b.ClassDate.split('-').reverse().join('-')
		  );
		  return dateB - dateA; // Sort by Date in descending order
	  });

	  const sortedRows3 = [...filteredDate].sort((a, b) => {
		// Sort by Date
		const dateA = new Date(
			a.ClassDate.split('-').reverse().join('-')
		  );
		  const dateB = new Date(
			b.ClassDate.split('-').reverse().join('-')
		  );
		  return dateB - dateA; // Sort by Date in descending order
	  });

	  const indexOfLastRow = currentPage * rowsPerPage;
	  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
	  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);

	  const indexOfLastRow2 = currentPage2 * rowsPerPage;
	  const indexOfFirstRow2 = indexOfLastRow2 - rowsPerPage;
	  const currentRows2 = sortedRows2.slice(indexOfFirstRow2, indexOfLastRow2);
	  const totalPages2 = Math.ceil(sortedRows2.length / rowsPerPage);
	  
	  const indexOfLastRow3 = currentPage3 * rowsPerPage;
	  const indexOfFirstRow3 = indexOfLastRow3 - rowsPerPage;
	  const currentRows3 = sortedRows3.slice(indexOfFirstRow3, indexOfLastRow3);
	  const totalPages3 = Math.ceil(sortedRows3.length / rowsPerPage);	  

		const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		};

		const handlePageChange2 = (pageNumber) => {
			setCurrentPage2(pageNumber);
		};

		const handlePageChange3 = (pageNumber) => {
			setCurrentPage3(pageNumber);
		};

		  const attendanceByStudentAndDate = {};
			data8.forEach((entry) => {
			const dateKey = entry.ClassDate.substring(0, 5); // Extract 'dd-MM' from 'dd-MM-yyyy'
			if (!attendanceByStudentAndDate[entry.StudentName]) {
				attendanceByStudentAndDate[entry.StudentName] = {};
			}
			attendanceByStudentAndDate[entry.StudentName][dateKey] = entry.AttendanceStatus;
			});

			//console.log("attendanceByStudentAndDate: ", attendanceByStudentAndDate);

			  // Extract unique dates from attendanceData
			//const uniqueDates = [...new Set(data4.map(item => item.AttendanceDate))];

			// Create a mapping of date to attendance status for each student

			const filteredDataForClass = data8.filter(item =>
				item.ProgramCode === ProgramCode2 &&
				item.InstructorName=== Instructor2 &&
				item.DurationFrom === DurationFrom2 &&
				item.DurationUntil === DurationUntil2 //&&
				//item.IntakeYear === year2
			  );

			  const dateToAttendanceMap = {};
			  filteredDataForClass.forEach(item => {
				if (!dateToAttendanceMap[item.StudentName]) {
				  dateToAttendanceMap[item.StudentName] = {};
				}
				dateToAttendanceMap[item.StudentName][item.ClassDate] = {
					Status: item.AttendanceStatus,
					StudentID: item.studentID, // Include StudentID in the map
				  };
			  });
			//console.log("dateToAttendanceMap: ", dateToAttendanceMap);

		  const sendStudentData = (studentName, studentID, year, month, subjectcode, subjectname, lecturername) => {
			const studentSumDetails = `${studentName},${studentID},${year},${month},${subjectcode},${subjectname},${lecturername}`;
			const url = `/attendance/student_summary?classdetails=${encodeURIComponent(studentSumDetails)}
			&studentName=${encodeURIComponent(studentName)}&studentID=${encodeURIComponent(studentID)}
			&year=${encodeURIComponent(year)}&month=${encodeURIComponent(month)}
			&subjectcode=${encodeURIComponent(subjectcode)}
			&subjectname=${encodeURIComponent(subjectname)}
			&lecturername=${encodeURIComponent(lecturername)}`;
			const newTab = window.open(url, '_blank');

			if (newTab) {
				newTab.focus(); // Focus on the new tab if it was successfully opened
			} else {
				alert('The pop-up blocker prevented opening the new tab.');
			}
		};

		const [programData2, setProgramData2] = useState([]);
		const [btecCoursepick2, setBtecCoursePick2] = useState([]);

		useEffect(() => {
			const fetchData = async () => {
				const db = getDatabase();
				const btecProgramsRef = ref(db, 'BTECProgram');
				
				onValue(btecProgramsRef, (snapshot) => {
					const data = snapshot.val();
					if (data) {
						const programs = Object.entries(data).map(([programCode, programLevelData]) => {
							const programLevels = Object.entries(programLevelData).map(([programLevel, levelData]) => {
								// Extracting program course data
								const courses = Object.values(levelData.BtecProgramCourse || {});
								
								// Extracting other level data
								const { BtecProgramLevel, BtecProgramName } = levelData;
								
								return {
									programLevel,
									BtecProgramLevel,
									BtecProgramName,
									courses,
								};
							});
	
							return {
								programCode,
								programLevels,
							};
						});
	
						setProgramData2(programs);
						console.log(programs);

					} else {
						console.log('No BTEC programs found.');
					}
				});
			};
	
			fetchData();
		}, []);

		const monthMapping = {
			'01': 'JAN',
			'02': 'FEB',
			'03': 'MAR',
			'04': 'APR',
			'05': 'MAY',
			'06': 'JUN',
			'07': 'JUL',
			'08': 'AUG',
			'09': 'SEP',
			'10': 'OCT',
			'11': 'NOV',
			'12': 'DEC',
		  };

		  const getDayOfWeek = (dateString) => { //get the day based on the date
			// Convert from DD-MM-YYYY to YYYY-MM-DD
			const [day, month, year] = dateString.split('-');
			const isoDateString = `${year}-${month}-${day}`;
			
			const date = new Date(isoDateString);
			return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
		  };

			const groupedAttendance = (attendances) => {
			  return attendances.reduce((acc, attendance) => {
				const key = `${attendance.StudentName}-${attendance.studentID}-${attendance.ClassDate}`;
				if (!acc[key]) {
				  acc[key] = {
					...attendance,
					AMStatus: '',
					PMStatus: '',
				  };
				}
				if (attendance.TakenFor === 'AM') {
				  acc[key].AMStatus = attendance.AttendanceStatus;
				} else if (attendance.TakenFor === 'PM') {
				  acc[key].PMStatus = attendance.AttendanceStatus;
				}
				return acc;
			  }, {});
			};

			const renderRows = (attendances,item) => {	
				return Object.values(attendances)
				  .sort((a, b) => a.ClassDate.localeCompare(b.ClassDate))
				  .map((attendance, index) => (
					<tr key={`${attendance.StudentUID}-${index}`}>
					  <td className="editclass-td"><b>{attendance.StudentName}</b></td>
					  <td className="editclass-td"><b>{attendance.studentID}</b></td>
					  {/*<td className="editclass-td"><b>{attendance.ClassDate.split('-')[0]}-{getMonthName(attendance.ClassDate)}-{attendance.ClassDate.split('-')[2]}</b></td>*/}
					  <td className="editclass-td"><b>{attendance.ClassDate}</b> <b>({getDayOfWeek(attendance.ClassDate)})</b></td>
					  <td className="editclass-td"><b>{attendance.Week}</b></td>
					  {/*<td className="editclass-td"><b>{attendance.CoursePick}</b></td>*/}
					  <td className="editclass-td"><b>
						{attendance.AMStatus === "1" ? 'O' : 
						 attendance.AMStatus === "0" ? 'X' :
						 attendance.AMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					  <td className="editclass-td"><b>
						{attendance.PMStatus === "1" ? 'O' : 
						 attendance.PMStatus === "0" ? 'X' :
						 attendance.PMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					  <td className="editclass-td">
						<input
						  title='Select Student'
						  className="checkbox-size-addstudent"
						  type="checkbox"
						  onChange={() =>
							handleCheckboxChange(
								attendance.StudUID,
								attendance.StudentName,
								attendance.studentID,
								attendance.ClassDate,
								attendance.Week,
								attendance.CoursePick,
								attendance.Level
							)
						  }
						  checked={selectedRows.some(
							(row) =>
							row.StudUID === attendance.StudUID &&
							row.StudentName === attendance.StudentName &&
							row.studentID === attendance.studentID &&
							row.ClassDate === attendance.ClassDate &&
							row.Week === attendance.Week &&
							row.CoursePick === attendance.CoursePick &&
							row.Level === attendance.Level
						  )}
						/>
					  </td>
					</tr>
				  ));
			  };

			  const renderRows2 = (attendances,item) => {	
				return Object.values(attendances)
				  .sort((a, b) => a.ClassDate.localeCompare(b.ClassDate))
				  .map((attendance, index) => (
					<tr key={`${attendance.StudentUID}-${index}`}>
					  <td className="editclass-td"><b>{attendance.StudentName}</b></td>
					  <td className="editclass-td"><b>{attendance.studentID}</b></td>
					  {/*<td className="editclass-td"><b>{attendance.ClassDate.split('-')[0]}-{getMonthName(attendance.ClassDate)}-{attendance.ClassDate.split('-')[2]}</b></td>*/}
					  <td className="editclass-td"><b>{attendance.ClassDate}</b> <b>({getDayOfWeek(attendance.ClassDate)})</b></td>
					  <td className="editclass-td"><b>{attendance.Week}</b></td>
					  {/*<td className="editclass-td"><b>{attendance.CoursePick}</b></td>*/}
					  <td className="editclass-td"><b>
						{attendance.AMStatus === "1" ? 'O' : 
						 attendance.AMStatus === "0" ? 'X' :
						 attendance.AMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					  <td className="editclass-td"><b>
						{attendance.PMStatus === "1" ? 'O' : 
						 attendance.PMStatus === "0" ? 'X' :
						 attendance.PMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					</tr>
				  ));
			  };

			  const renderRows3 = (attendances,item) => {	
				return Object.values(attendances)
				  //.sort((a, b) => a.ClassDate.localeCompare(b.ClassDate))
				  .sort((a, b) => {
					// Convert ClassDate from DD-MM-YYYY to YYYY-MM-DD for correct sorting
					const [dayA, monthA, yearA] = a.ClassDate.split('-');
					const [dayB, monthB, yearB] = b.ClassDate.split('-');
			  
					const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
					const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
			  
					return dateB - dateA; // Sort in descending order (change to dateA - dateB for ascending)
				  })
				  .map((attendance, index) => (
					<tr key={`${attendance.StudentUID}-${index}`}>
					  <td className="editclass-td"><b>{attendance.StudentName}</b></td>
					  <td className="editclass-td"><b>{attendance.studentID}</b></td>
					  <td className="editclass-td"><b>{attendance.ClassDate}</b> <b>({getDayOfWeek(attendance.ClassDate)})</b></td>
					  <td className="editclass-td"><b>{attendance.Week}</b></td>
					  <td className="editclass-td"><b>
						{attendance.AMStatus === "1" ? 'O' : 
						 attendance.AMStatus === "0" ? 'X' :
						 attendance.AMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					  <td className="editclass-td"><b>
						{attendance.PMStatus === "1" ? 'O' : 
						 attendance.PMStatus === "0" ? 'X' :
						 attendance.PMStatus === "3" ? 'L' :
						 ''}
					  </b></td>
					  <td className="editclass-td">
						<input
						  title='Select Student'
						  className="checkbox-size-addstudent"
						  type="checkbox"
						  onChange={() =>
							handleCheckboxChange(
								attendance.StudUID,
								attendance.StudentName,
								attendance.studentID,
								attendance.ClassDate,
								attendance.Week,
								attendance.CoursePick,
								attendance.Level
							)
						  }
						  checked={selectedRows.some(
							(row) =>
							row.StudUID === attendance.StudUID &&
							row.StudentName === attendance.StudentName &&
							row.studentID === attendance.studentID &&
							row.ClassDate === attendance.ClassDate &&
							row.Week === attendance.Week &&
							row.CoursePick === attendance.CoursePick &&
							row.Level === attendance.Level
						  )}
						/>
					  </td>
					</tr>
				  ));
			  };

			  const handleCheckboxChange = (StudUID, StudentName, studentID, ClassDate, Week, CoursePick, Level) => {
				/*console.log("StudUID: ",StudUID);
				console.log("StudentName: ",StudentName);
				console.log("studentID: ",studentID);
				console.log("ClassDate: ",ClassDate);
				console.log("Week: ", Week);
				console.log("CoursePick: ", CoursePick);*/
				const isSelected = selectedRows.some(
				  (row) =>
					row.StudUID === StudUID &&
					row.StudentName === StudentName &&
					row.studentID === studentID &&
					row.ClassDate === ClassDate &&
					row.Week === Week &&
					row.CoursePick === CoursePick &&
					row.Level === Level
				);
			  
				if (isSelected) {
				  setSelectedRows(selectedRows.filter(
					(row) =>
					  !(row.StudUID === StudUID &&
						row.StudentName === StudentName &&
						row.studentID === studentID &&
						row.ClassDate === ClassDate &&
						row.Week === Week &&
						row.CoursePick === CoursePick &&
						row.Level === Level)
				  ));
				} else {
				  setSelectedRows([
					...selectedRows,
					{StudUID, StudentName, studentID, ClassDate, Week, CoursePick, Level}
				  ]);
				}
			  };

			  useEffect(() => {
				const selectedData = data8.filter((QRHistoryItem) =>
				  selectedRows.some(
					(row) =>
					row.StudentName=== QRHistoryItem.StudentName &&
					row.studentID === QRHistoryItem.studentID &&
					  row.ClassDate === QRHistoryItem.ClassDate &&
					  row.Week === QRHistoryItem.Week &&
					  row.CoursePick === QRHistoryItem.CoursePick &&
					  row.Level === QRHistoryItem.Level
				  )
				);
				console.log("Selected Data1:", selectedData);

			  }, [selectedRows, data8]);

			  const ChangeAttendBTEC = (changeAttStatus, timeAttStatus, ProgramCode, InstructorName) => {
				// Check if changeAttStatus or timeAttStatus is empty
				if (!changeAttStatus || !timeAttStatus || changeAttStatus === "Select Status" || timeAttStatus === "Select Time") {
				  alert("Please select both time and attendance status.");
				  return;
				}
			  
				const confirmAttend = window.confirm("Change Attendance Status for the selected Student?");
				if (confirmAttend) {
				  const db = getDatabase();
			  
				  selectedRows.forEach((row) => {
					const { StudUID, StudentName, studentID, ClassDate, Week, CoursePick, Level, ProgramName, DurationFrom, DurationUntil } = row;
					const dbRef = ref(db, `BTECQrAttendance/${ProgramCode}/${Level}/${ClassDate}/${InstructorName}/${timeAttStatus}/${StudUID}/status`);
			  
					get(dbRef).then((snapshot) => {
					  if (snapshot.exists()) {
						// If the status exists, update it
						set(dbRef, changeAttStatus)
						  .then(() => {
							console.log(`AttendanceStatus updated to ${changeAttStatus} for StudentUID: ${StudUID}`);
							alert(`AttendanceStatus updated for Student Name: ${StudentName}, Student ID: ${studentID}, At: ${timeAttStatus}`);
						  })
						  .catch((error) => {
							console.log("Error updating AttendanceStatus:", error);
						  });
					  } else {
						// If the status is NULL, show an error message
						console.log("Class has not been listed yet!");
						alert("Class has not been listed yet!");
					  }
					}).catch((error) => {
					  console.log("Error retrieving AttendanceStatus:", error);
					});
				  });
			  
				  console.log("AttendanceStatus update process completed.");
				}
			  };

			  const filterDateCO = (AttendItem) => {
				const FilteredDateMonthCO = data8.filter((QRHistoryItem) => {
					console.log("here11");
					return QRHistoryItem.InstructorName === Instructor2
					&& QRHistoryItem.ProgramCode === AttendItem.ProgramCode
					&& QRHistoryItem.CoursePick === AttendItem.CoursePick
					&& QRHistoryItem.DurationFrom === AttendItem.DurationFrom
					&& QRHistoryItem.DurationUntil === AttendItem.DurationUntil;
				  });
				  setFilteredDateMonthCO(FilteredDateMonthCO);
				  
				  if (FilteredDateMonthCO.length === 0){
					alert("No class has been done for this CO/EO yet! Please create the class based on this CO/EO!");
				  }
				  else{
					alert("Student Attendances found.");
					// Extract unique months and weeks from FilteredStudentAttendance
					const uniqueMonths = [...new Set(FilteredDateMonthCO.map(item => item.ClassDate.split('-')[1]))];
					//const uniqueWeeks = [...new Set(FilteredDateMonthCO.map(item => item.Week))];

							setMonthOptions(uniqueMonths);
							//setWeekOptions(uniqueWeeks);
							//console.log ("uniqueMonths: ", uniqueMonths);
							//console.log ("uniqueWeeks: ", uniqueWeeks);
				  }
			  };

			  const handleMonthChange = (e) => {
				const selectedMonth = e.target.value;
				setSearchMonthCO(selectedMonth);
			  
				if (selectedMonth) {
				  const filteredWeeks = [...new Set(
					FilteredDateMonthCO
					  .filter(item => item.ClassDate.split('-')[1] === selectedMonth)
					  .map(item => item.Week)
				  )];
				  setWeekOptions(filteredWeeks);
				} else {
				  setWeekOptions([]);
				}
			  };
			  
			  const searchAttendanceCOWM = (AttendItem) =>{
				const FilteredWeekMonthCO = data8.filter((QRHistoryItem) => {
					const classMonth = QRHistoryItem.ClassDate.split('-')[1];
					//console.log("classmonth: ", classMonth);
					//console.log("AttendItem.searchWeekCO: ", searchWeekCO);
					//console.log("AttendItem: ", AttendItem);
					
					return QRHistoryItem.InstructorName === Instructor2
					&& QRHistoryItem.ProgramCode === AttendItem.ProgramCode
					&& QRHistoryItem.CoursePick === AttendItem.CoursePick
					&& QRHistoryItem.Week === searchWeekCO
					&& QRHistoryItem.DurationFrom === AttendItem.DurationFrom
					&& QRHistoryItem.DurationUntil === AttendItem.DurationUntil
					&& classMonth === searchMonthCO;
				  });
				  //console.log("FilteredStudentAttendance: ",FilteredStudentAttendance);
				  setFilteredWeekMonthCO(FilteredWeekMonthCO);
				  //console.log("FilteredStudentAttendance length: ", FilteredStudentAttendance.length);
				  if (FilteredWeekMonthCO.length === 0){
					alert("No class has been done for this CO/EO yet! Please create the class based on this CO/EO!");
				  }
				  else{
					alert("Student Attendances found.");
				  }
				  //console.log("FilteredWeekMonthCO: ",FilteredWeekMonthCO)
			  };

			  const searchAttendanceCOWMView = (AttendItem) =>{
				const FilteredWeekMonthCO = data8.filter((QRHistoryItem) => {
					const classMonth = QRHistoryItem.ClassDate.split('-')[1];
					//console.log("classmonth: ", classMonth);
					//console.log("AttendItem.searchWeekCO: ", searchWeekCO);
					//console.log("AttendItem: ", AttendItem);
					
					return QRHistoryItem.InstructorName === currentUser.instructorName 
					&& QRHistoryItem.ProgramCode === AttendItem.ProgramCode
					&& QRHistoryItem.CoursePick === AttendItem.CoursePick
					&& QRHistoryItem.Week === searchWeekCO
					&& QRHistoryItem.DurationFrom === AttendItem.DurationFrom
					&& QRHistoryItem.DurationUntil === AttendItem.DurationUntil
					&& classMonth === searchMonthCO;
				  });
				  //console.log("FilteredStudentAttendance: ",FilteredStudentAttendance);
				  setFilteredWeekMonthCO(FilteredWeekMonthCO);
				  //console.log("FilteredStudentAttendance length: ", FilteredStudentAttendance.length);
				  if (FilteredWeekMonthCO.length === 0){
					alert("No class has been done for this CO/EO yet! Please create the class based on this CO/EO!");
				  }
				  else{
					alert("Student Attendances found.");
				  }
				  //console.log("FilteredWeekMonthCO: ",FilteredWeekMonthCO)
			  };

			/*New on 24092024*/
			const toggleAllCheckboxes = () => {
				const confirmAction = window.confirm("Are you sure you want to select/deselect all students?");

				if (!confirmAction) {
					return; // Exit if the user cancels the action
				}

				console.log("toggleAllCheckboxes selectedRows: ", selectedRows);
				const allSelected = selectedRows.length === FilteredWeekMonthCO.length; // Check if all are currently selected
				console.log("allSelected: ", allSelected);
			
				// If all are selected, deselect them; otherwise, select all
				const newSelection = allSelected ? [] : FilteredWeekMonthCO.map(attendance => ({
					StudUID: attendance.StudUID,
					StudentName: attendance.StudentName,
					studentID: attendance.studentID,
					ClassDate: attendance.ClassDate,
					Week: attendance.Week,
					CoursePick: attendance.CoursePick,
					Level: attendance.Level
				}));
				console.log("newSelection: ", newSelection);
			
				setSelectedRows(newSelection); // Update selected rows
				console.log("newSelection2: ", newSelection);
				//setSelectedRows(allSelected);
				console.log("selectedRowssss: ", selectedRows);
				console.log("selectedRowssss length: ", selectedRows.length);
			};

    return (

		<div>
			<Btecadminsidebar> {/*show/call sidebar*/}
			<AdminbtecUsername /> {/*show/call username*/}
			<p><br/><br/></p>
            <div>
			<h1 class="h1-addstudent">{ProgramCode2} {ProgramName2}, <br/>{DurationFrom2} Until {DurationUntil2} Attendance History</h1>
            </div>
            <div>
			
            <p><br/></p>
            {currentUser && (
            <>
			<div className='container'>
            <p class="p-generateqr">&emsp;Lecturer Name: <b>{Instructor2}</b></p>
            <p class="p-generateqr">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<></>Faculty: <b>{currentUser.Faculty}</b></p>
			</div>
			</>
			
            )}
            <p><br/><br/></p>

				<div>
  				<div>
				<p class="p-addstudent4">Class Attendance History</p>

				<p><br/><br/><br/><br/></p>
				<div className="table-container-editclass">
				<table>
        <thead class="th-generateqr">
            <tr>
                <th>Program</th>
                <th>Duration</th>
                <th>Instructor</th>
				<th>CO</th>
				<th>Action</th>
            </tr>
        </thead>
        <tbody>
				{currentRows.map((item, index) => {
				const totalStudents = filteredTotalStudentsNew.find((item2) => 
					item2.ProgramCode=== item.ProgramCode
					&& item2.Instructor === item.Instructor
					&& item2.DurationFrom === item.DurationFrom
					&& item2.DurationUntil === item.DurationUntil);
				return(
                <tr key={`${item.ProgramCode}-${item.DurationFrom}-${item.DurationUntil}-${item.CoursePick}-${index}`}>
                    <td className="editclass-td">{item.ProgramCode} {item.ProgramName}</td>
                    <td className="editclass-td">{item.DurationFrom}<br/>Until<br/>{item.DurationUntil}</td>
                    <td className="editclass-td">{item.Instructor}</td>
					<td className="editclass-td">{item.CoursePick}</td>
					<td className="editclass-td">
					<Popup trigger={<button title='Edit Attendance/ Download PDF' className="button-GenQR4-new" onClick={() => {findAttendStudent2(item);}}><FaFilePdf className='admin-view-attendance-icon'/>VIEW/EDIT ATTENDANCE</button>} modal nested>
					{close => (
		  				<div className="modal">
						<button title='Close' className="button-studentclass4" onClick={() => { close(); setdefaultstate(); resetshow();}}>&times;</button>
						<div>
						<p className="p-history2">&emsp;Tenaga Pengajar(PP): <b>{Instructor2}</b></p>
						<p className="p-history2">&emsp;Program: <b>{item.ProgramCode} {item.ProgramName}</b></p>
						<p className="p-history2">&emsp;Tempoh Pengajian: <b>{item.DurationFrom} Until {item.DurationUntil}</b></p>
						<p className="p-history2">&emsp;Kod dan Tajuk Unit Kompetensi: <b>{item.CoursePick}</b></p>
						<p className="p-history2">&emsp;Jumlah Pelajar: <b>{totalStudents ? totalStudents.totalStudents : 0}</b></p>
						<p style={{fontSize: "13px", color: "blue"}}><br/>*Please press the <b>Show All Attendance List Button</b> after changing the status of an attendance to view the changes.</p>
						<br/>
						<button title='Show Attendance Info' className="button-admin-view-attendance-new8" onClick={() => {findAttendStudent2(item); showsoption();}}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;SHOW All ATTENDANCE LIST</button>
						{/*<button title='Show Attendance Info' className="button-admin-view-attendance-new8" onClick={() => {filterDateCO(item); showsoption();}}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;SHOW ATTENDANCE</button>*/}
						<br/>

						<br/>
						<div className="table-container-editclass">
							<table className='table-editstudent2'>
							<thead className="th-addstudent">
							<tr>
							<th>Nama Pelatih</th>
							<th>No. Kad Pelajar</th>
							<th>Tarikh Kelas</th>
							<th>Minggu</th>
							<th>AM</th>
							<th>PM</th>
							<th>Action</th>
							</tr>
							</thead>
							<tbody>
							{FilteredStudentAttendance.length > 0 ? (
          					renderRows(groupedAttendance(FilteredStudentAttendance),item)
        					) : FilteredStudentCopick.length > 0 ? (
          					renderRows(groupedAttendance(FilteredStudentCopick),item)
        				) : (
					<tr>
					<td colSpan="8">No Student.</td>
						</tr>
						)
						}
							</tbody>
							</table>
							</div>
							<br/>
							
							<div className='container'>
							<div className='container'>
							<p>Change Time to:</p>
							<select title='Select Time to change' class="select-size-generateqr3" value={timeAttStatus}
							onChange={(e) => settimeAttStatus(e.target.value)}>
							<option>Select Time</option>
							<option value="AM">AM</option>
							<option value="PM">PM</option>
							</select>
							</div>
							&emsp;
							<div className='container'>
							<p>Change Status to:</p>
							<select title='Select Time to change' class="select-size-generateqr3" value={changeAttStatus}
							onChange={(e) => setchangeAttStatus(e.target.value)}>
							<option>Select Status</option>
							<option value="1">Present</option>
							<option value="0">Absent</option>
							<option value="3">Late</option>
							</select>
							</div>
							<button className="button-admin-view-attendance-new8" onClick={() => { ChangeAttendBTEC(changeAttStatus, timeAttStatus, item.ProgramCode, Instructor2);}}>Change Attendance Status</button>
							</div>

						</div>
						</div>
			  			)}
					</Popup>

					{/*<Popup trigger={<button title='Edit Attendance/ Download PDF' className="button-GenQR4-new" onClick={() => {findAttendStudent2(item);}}><FaFilePdf className='admin-view-attendance-icon'/>VIEW/EDIT ATTENDANCE</button>} modal nested>
					{close => (
		  				<div className="modal">
						<button title='Close' className="button-studentclass4" onClick={() => { close(); setdefaultstate2(); resetshow4();}}>&times;</button>

						<p className="p-history2">&emsp;Tenaga Pengajar(PP): <b>{currentUser.instructorName}</b></p>
						<p className="p-history2">&emsp;Program: <b>{item.ProgramCode} {item.ProgramName}</b></p>
						<p className="p-history2">&emsp;Tempoh Pengajian: <b>{item.DurationFrom} Until {item.DurationUntil}</b></p>
						<p className="p-history2">&emsp;Kod dan Tajuk Unit Kompetensi: <b>{item.CoursePick}</b></p>
						<p className="p-history2">&emsp;Jumlah Pelajar: <b>{totalStudents ? totalStudents.totalStudents : 0}</b></p>
						<p style={{fontSize: "13px", color: "blue"}}>&emsp;*Press the <b>SHOW ATTENDANCE</b> button to view the class history and change the student status.</p>
						<br/>

						<button title='Show Attendance Info' className="button-admin-view-attendance-new8" onClick={() => {filterDateCO(item); showsoption();}}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;SHOW ATTENDANCE</button>
						
						<br/>
						{FilteredDateMonthCO.length > 0 ? (
							<div>
								<p style={{fontSize: "13px", color: "blue"}}>&emsp;*Select the <b>Month</b> and <b>Week</b> to view the specific class history.</p>
								<br/>
								<div className='container'>
          							<div className='container'>
										<p>Month:</p>
										<select title='Select Month' className="select-size-generateqr4" value={searchMonthCO} onChange={handleMonthChange}>
										<option>Select Month</option>
										{monthOptions.map(month => (
										<option key={month} value={month}>{monthMapping[month]}</option>
										))}
										</select>
											</div>
													  
											<div className='container'>
											<p>Week:</p>
											<select title='Select Week' className="select-size-generateqr4" value={searchWeekCO} onChange={(e) => setSearchWeekCO(e.target.value)}>
											<option>Select Week</option>
											{weekOptions.map(week => (
											<option key={week} value={week}>{week}</option>
											))}
										</select>
										<p>&emsp;</p>
										<button className="button-admin-view-attendance-new9" onClick={() =>searchAttendanceCOWMView(item)}>Search</button> 
										<p>&emsp;</p>
										<button className="button-admin-view-attendance-new9" onClick={toggleAllCheckboxes}>Select/Deselect All</button>
											</div>

										
									</div>
								<br/>
							<div className="table-container-editclass">
							<table className='table-editstudent2'>
							<thead className="th-addstudent">
							<tr>
							<th>Nama Pelatih</th>
							<th>No. Kad Pelajar</th>
							<th>Tarikh Kelas</th>
							<th>Minggu</th>
							<th>AM</th>
							<th>PM</th>
							<th>Action</th>
							</tr>
							</thead>
							<tbody>
							{FilteredWeekMonthCO.length > 0 ? (
							renderRows3(groupedAttendance(FilteredWeekMonthCO),item)
							) : (
							<tr>
								<td colSpan="8">No Student.</td>
							</tr>
							)}
							</tbody>
							</table>
							</div>
							<div className='container'>
							<div className='container'>
							<p>Change Time to:</p>
							<select title='Select Time to change' class="select-size-generateqr3" value={timeAttStatus}
							onChange={(e) => settimeAttStatus(e.target.value)}>
							<option>Select Time</option>
							<option value="AM">AM</option>
							<option value="PM">PM</option>
							</select>
							</div>
							&emsp;
							<div className='container'>
							<p>Change Status to:</p>
							<select title='Select Time to change' class="select-size-generateqr3" value={changeAttStatus}
							onChange={(e) => setchangeAttStatus(e.target.value)}>
							<option>Select Status</option>
							<option value="1">Present</option>
							<option value="0">Absent</option>
							<option value="3">Late</option>
							</select>
							</div>
							<button className="button-admin-view-attendance-new8" onClick={() => { ChangeAttendBTEC(changeAttStatus, timeAttStatus, item.ProgramCode, currentUser.instructorName);}}>Change Attendance Status</button>
							</div>
							</div>
        					) : (<p>Press the button above</p>)
							};
						</div>
			  			)}
					</Popup>*/}
					
					<Popup trigger={<button title='Edit Attendance/ Download PDF' className="button-GenQR4-new" onClick={() => {findAttendStudent2(item);}}><FaFilePdf className='admin-view-attendance-icon'/>DOWNLOAD PDF</button>} modal nested>
					{close => (
		  				<div className="modal">
						<button title='Close' className="button-studentclass4" onClick={() => { close(); setdefaultstate2(); resetshow4();}}>&times;</button>

						<p className="p-history2">&emsp;Tenaga Pengajar(PP): <b>{Instructor2}</b></p>
						<p className="p-history2">&emsp;Program: <b>{item.ProgramCode} {item.ProgramName}</b></p>
						<p className="p-history2">&emsp;Tempoh Pengajian: <b>{item.DurationFrom} Until {item.DurationUntil}</b></p>
						<p className="p-history2">&emsp;Kod dan Tajuk Unit Kompetensi: <b>{item.CoursePick}</b></p>
						<p className="p-history2">&emsp;Jumlah Pelajar: <b>{totalStudents ? totalStudents.totalStudents : 0}</b></p>
						<p style={{fontSize: "13px", color: "blue"}}>&emsp;*Press the <b>SHOW MONTH AND WEEK</b> button to view the class history and to download the attendance.</p>
						<br/>

						<button title='Show Attendance Info' className="button-admin-view-attendance-new8" onClick={() => {filterDateCO(item); showsoption();}}>&nbsp;<FaEye className='admin-view-attendance-icon3'/>&nbsp;SHOW MONTH AND WEEK</button>
						<br/>
						{FilteredDateMonthCO.length > 0 ? (
							<div>
								<p style={{fontSize: "13px", color: "blue"}}>&emsp;*Select the <b>Month</b> and <b>Week</b> to view the specific class history.</p>
								<br/>
								<div className='container'>
          							<div className='container'>
										<p>Month:</p>
										<select title='Select Month' className="select-size-generateqr3" value={searchMonthCO} /*onChange={(e) => setSearchMonthCO(e.target.value)}*/ onChange={handleMonthChange}>
										<option>Select Month</option>
										{monthOptions.map(month => (
										<option key={month} value={month}>{monthMapping[month]}</option>
										))}
										</select>
											</div>
													  
											<div className='container'>
											<p>Week:</p>
											<select title='Select Week' className="select-size-generateqr3" value={searchWeekCO} onChange={(e) => setSearchWeekCO(e.target.value)}>
											<option>Select Week</option>
											{weekOptions.map(week => (
											<option key={week} value={week}>{week}</option>
											))}
										</select>
											</div>
							  
										<button className="button-admin-view-attendance-new8" onClick={() =>searchAttendanceCOWM(item)}>Search</button> {/*search by the month and week based on the CO*/} 
									</div>
								<br/>
							<div className="table-container-editclass">
							<table className='table-editstudent2'>
							<thead className="th-addstudent">
							<tr>
							<th>Nama Pelatih</th>
							<th>No. Kad Pelajar</th>
							<th>Tarikh Kelas</th>
							<th>Minggu</th>
							{/*<th>CO</th>*/}
							<th>AM</th>
							<th>PM</th>
							</tr>
							</thead>
							<tbody>
							{FilteredWeekMonthCO.length > 0 ? (
							renderRows2(groupedAttendance(FilteredWeekMonthCO),item)
							) : (
							<tr>
								<td colSpan="8">No Student.</td>
							</tr>
							)}
							</tbody>
							</table>
							</div>
							{FilteredWeekMonthCO.length > 0 && (	
							<BtecDownloadAttPDF attendanceData={FilteredWeekMonthCO} />
							)}
							</div>
        					) : (<p>Press the button above</p>)};
			
						</div>
			  			)}
					</Popup>

						{/*<button className='button-GenQR2-new' onClick={() => {COpick();}}>VIEW</button>*/}
						
						</td>
                </tr>
			);
			}
            )}
				</tbody>
			</table>
			</div>

			<br/>

				<p><br/></p>

				{currentRows.length > 0 && totalPages > 1 && currentRows2.length === 0 &&  currentRows3.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange(pageNumber)}
						className={`button-addStudent5 ${currentPage === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows2.length > 0 && totalPages2 > 1 && currentRows3.length === 0 && (
					<div className="pagination">
					{Array.from({ length: totalPages2 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange2(pageNumber)}
						className={`button-addStudent5 ${currentPage2 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}

				{currentRows3.length > 0 && totalPages3 > 1 && (
					<div className="pagination">
					{Array.from({ length: totalPages3 }, (_, i) => i + 1).map((pageNumber) => (
						<button
						key={pageNumber}
						onClick={() => handlePageChange3(pageNumber)}
						className={`button-addStudent5 ${currentPage3 === pageNumber ? "active" : ""}`}
						>
						{pageNumber}
						</button>
					))}
					</div>
				)}
				<p><br/><br/></p>

				<p><b>*If there is any extra student in the file, the student is probably deleted from the class.</b></p>
				<p><b>*If there is any empty space in the compiled student attendance, the student may have been deleted from the class.</b></p>
				
				</div>
				</div>
				</div>	
				
				</Btecadminsidebar>
				</div>
				);
				};
				
				export default AdminbtecViewAttendance;