import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from "./firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { getDatabase, ref, get, set, child } from "firebase/database";

import './Register.css';

const Register = () => {
	const [registerEmail, setRegisterEmail] = useState("");
	const [registerPassword, setRegisterPassword] = useState("");
	const [registerFaculty, setRegisterFaculty] = useState("");
	const [lecturerName, setLecturerName] = useState("");
	const navigate = useNavigate();

	const RegisterNew = async () => {
		try {
			const user = await createUserWithEmailAndPassword(
				auth,
				registerEmail,
				registerPassword
			);
			console.log(user);
			await sendEmailVerification(auth.currentUser);
			alert(`Registration email sent to ${registerEmail}. Please verify your email.`);
			navigate('/');
		} catch (error) {
			console.log(error.message);
		}
	};

	const registernewLec = async (e) => {
		e.preventDefault();
		try {
			const db = getDatabase();
			const usersRef = ref(db, "Lecturer");

			const snapshot = await get(child(usersRef, auth.currentUser.uid));
			const userData = snapshot.val();

			if (userData && userData.registerEmail === registerEmail) {
				// Email already exists in the database
				alert("Email is already registered.");
				console.log("Email is already registered.");
			} else {
				await set(child(usersRef, auth.currentUser.uid), {
					uid: auth.currentUser.uid,
					registerEmail,
					registerFaculty,
					lecturerName,
				});

				console.log("New lecturer registered:", auth.currentUser.uid);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	const register = async (e) => {
		e.preventDefault();
		await RegisterNew();
		await registernewLec(e);
	};

	const gotosignin = () => {
		navigate('./SignIn');
	};

	return (
		<div className="body">
			<div className="register-container">
				<div className="logo-position-register">
					<img src="images/logo.png" alt="" />
				</div>
				<h2>New Registration</h2>
				<div className="user-detail">
					<div>
						<label>Name:</label>
						<input
							type="text"
							placeholder="Enter your name"
							value={lecturerName}
							onChange={(e) => setLecturerName(e.target.value)}>
						</input>
					</div>
					<label><br></br>E-mail:</label>
					<input
						type="email"
						placeholder="Enter email"
						value={registerEmail}
						onChange={(e) => setRegisterEmail(e.target.value)}>
					</input>
					<label><br></br>Password:</label>
					<input
						type="password"
						placeholder="Enter password"
						value={registerPassword}
						onChange={(e) => setRegisterPassword(e.target.value)}>
					</input>
					<label><br></br>Faculty/Department:</label>
					<select
						className="select-size"
						value={registerFaculty}
						onChange={(e) => setRegisterFaculty(e.target.value)}>
						<option>Select Faculty</option>
						<option value="Borneo Business School">
							Borneo Business School
						</option>
						<option value="Faculty of Hospitality & Tourism">
							Faculty of Hospitality & Tourism
						</option>
						<option value="Faculty of Security & Governance">
							Faculty of Security & Governance
						</option>
						<option value="Faculty of Engineering & Survey">
							Faculty of Engineering & Survey
						</option>
						<option value="Faculty of Computing & Innovative Technology">
							Faculty of Computing & Innovative Technology
						</option>
						<option value="Borneo TVET College (BTC)">
							Borneo TVET College (BTC)
						</option>
					</select>
					<div><p>&nbsp;</p></div>
					<div>
						<button onClick={register} className="button-register">
							<b>Register</b>
						</button>
					</div>
				</div>
				<form onSubmit={gotosignin}>
					<div>
						<p><br/><br/></p>
						<label className="account-notification">Already have an account? </label>
						<Link to ='/' className="register-link">Sign In</Link>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Register;
