import React, { useState, useEffect } from 'react';
import { AiOutlineQrcode, AiFillContacts, AiOutlineUser } from "react-icons/ai";
import { FaBars, FaEdit, FaUserEdit, FaUserTie, FaRegAddressBook } from "react-icons/fa";
import { BsQrCodeScan } from "react-icons/bs";
import { RiLogoutCircleLine, RiArrowDownSFill, RiArrowUpSFill, RiAdminFill } from "react-icons/ri";
import { MdEditNote, MdOutlineAdminPanelSettings, MdSystemSecurityUpdate, MdWorkHistory } from "react-icons/md";
import { NavLink, Link } from "react-router-dom";
import { getDatabase, ref, get} from "firebase/database";
import { auth } from "./firebase";
import { BsFiletypePdf } from "react-icons/bs";
import './Sidebar.css';

const Btecadminsidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [currentUser, setCurrentUser] = useState(null);

  /*useEffect(() => {
    if (currentUser) {
        const db = getDatabase();
        const userRef = ref(db, `Admin/${currentUser.uid}`);
        get(userRef)
          .then(snapshot => {
            const userData = snapshot.val();
            setCurrentUser({
              ...currentUser,
             adminName: userData.AdminName,
              adminRole: userData.AdminRole,
              adminEmail: userData.AdminEmail,
              adminImage: userData.ImageURL,
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
      //console.log("Current Lecturer Email:", currentUser?.uid); // Add this line
    }, [currentUser]);*/

    useEffect(() => {
      if (currentUser) {
          const db = getDatabase();
          const userRef = ref(db, `BtecAdmin/${currentUser.uid}`);
          get(userRef)
            .then(snapshot => {
              const userData = snapshot.val();
              setCurrentUser({
                ...currentUser,
               adminName: userData.AdminName,
                adminRole: userData.AdminRole,
                adminEmail: userData.AdminEmail,
                adminImage: userData.ImageURL,
              });
            })
            .catch(error => {
              console.error(error);
            });
        }
        //console.log("Current Lecturer Email:", currentUser?.uid); // Add this line
      }, [currentUser]);

	  useEffect(() => {
      // listen for changes to the auth object
      const unsubscribe = auth.onAuthStateChanged((user) => {
        // update the component with the current user
        setCurrentUser(user);
      });
    
      // cleanup function to unsubscribe when the component unmounts
      return unsubscribe;
      }, []);

  const menuItem = [

    {
        id: "AdminDashboard",
        path: "/btec/adminbtec/Admin_BTEC_Dashboard",
        name: "Admin Dashboard",
        icon: <RiAdminFill />,
        iconClosed: <RiArrowDownSFill />,
        iconOpened: <RiArrowUpSFill />,
      },
    {
      id: "AdminMenu",
      name: "Admin",
      icon: <RiAdminFill />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      subMenu: [
        {
          id: "adminregisterAdmin",
          path: "/btec/adminbtec/Admin_BTEC_Register_Admin",
          name: "Register Admin",
          icon: <MdOutlineAdminPanelSettings />,
          hidden: currentUser && currentUser.adminRole !== "Super",
        },
        {
          id: "registeruser",
          path: "/btec/adminbtec/Admin_BTEC_Register_Instructor",
          name: "Register Instructor",
          icon: <MdEditNote />,
          hidden: currentUser && currentUser.adminRole !== "Super",
        },
        {
            id: "adminregisterstudent",
            path: "/btec/adminbtec/Admin_BTEC_Register_Student",
            name: "Register BTEC Student to N.A.I.S",
            icon: <MdSystemSecurityUpdate />,
            hidden: currentUser && currentUser.adminRole !== "Super",
          },
          {
            id: "adminviewclasshistory",
            path: "/btec/adminbtec/Admin_BTEC_View_Class",
            name: "View Instructor Class History",
            icon: <MdWorkHistory />,
            //hidden: currentUser && currentUser.adminRole !== "Super",
            
          },
          {
            id: "adminprofile",
            path: "/btec/adminbtec/Admin_BTEC_Profile",
            name: "Admin Profile",
            icon: <FaUserTie />,
          },
        {
          id: "admineditclass",
          path: "/btec/adminbtec/Admin_BTEC_Class",
          name: "Admin BTEC Edit Class",
          icon: <FaEdit />,
        },
        {
          id: "adminremovestudent",
          path: "/btec/adminbtec/Admin_BTEC_Edit_Student",
          name: "Admin Add/Delete Student",
          icon: <FaUserEdit />,
        },
        {
          id: "admineditstudentlog",
          path: "/btec/adminbtec/Admin_BTEC_Edit_Log",
          name: "Admin Edit Student Login Status",
          icon: <FaRegAddressBook />,
          hidden: currentUser && currentUser.adminRole !== "Super",
        },
        {
          id: "adminenabledisablepdfdl",
          path: "/btec/adminbtec/Admin_BTEC_Enabledisable_PDF_DLD",
          name: "Enable/Disable PDF Download",
          icon: <BsFiletypePdf />,
          hidden: currentUser && currentUser.adminRole !== "Super",
        },
      ]
    },
    {
      id: "logout",
      path: "/btec/adminbtec/Admin_BTEC_Logout",
      name: "Logout",
      icon: <RiLogoutCircleLine />
    },
  ];

  const [activeMenu, setActiveMenu] = useState(null);
  const showSubMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };


  return (
    <div className="container">
      <div style={{ width: isOpen ? "300px" : "60px" }} className="sidebar-Admin">
        <div className="top_section">
        
        <Link to = '/btec/adminbtec/Admin_BTEC_Dashboard'>
        <img src="/images/BTECLOGOwhite.png" alt="Profile" style={{ display: isOpen ? "block" : "none" }} className="btec-image-sidebar" />
        </Link>

          <div style={{ marginLeft: isOpen ? "30px" : "0px" ,cursor:"pointer"}} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item) => {

          return (
            <div key={item.id}>
              {item.path ? (
                <NavLink
                  to={item.path}
                  className={`link ${activeMenu === item.id ? "active" : ""}`}
                  onClick={() => showSubMenu(item.id)}
                >
                  <div className="icon">{item.icon}</div>
                  <div style={{ display: isOpen ? "block" : "none" }} className="link_text"><b>{item.name}</b></div>
                  {item.subMenu && (activeMenu === item.id ? item.iconOpened : item.iconClosed)}
                </NavLink>
              ) : (
                <div
                  className={`link ${activeMenu === item.id ? "active" : ""}`}
                  onClick={() => showSubMenu(item.id)}
                >
                  <div className="icon">{item.icon}</div>
                  <div style={{ display: isOpen ? "block" : "none" }} className="link_text"><b>{item.name}</b></div>
                  {item.subMenu && (activeMenu === item.id ? item.iconOpened : item.iconClosed)}
                </div>
              )}

              {item.subMenu && activeMenu === item.id && (
                <ul className="subMenu">
                  {item.subMenu.map((subItem) => (
                    // Conditionally render the submenu item
                    // based on its "hidden" property and the currentUser.adminRole
                    !subItem.hidden || (currentUser && currentUser.adminRole === "Super") ? (
                    <NavLink to={subItem.path} key={subItem.id} className="sublink">
                      <div style={{ display: isOpen ? "block" : "none" }} className="subIcon">{subItem.icon}</div>
                      <div style={{ display: isOpen ? "block" : "none" }} className="sublink_text"><b>{subItem.name}</b></div>
                    </NavLink>
                    ) : null
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Btecadminsidebar;